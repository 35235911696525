	[class^=icon-shortcut] { display: inline-block; flex-shrink: 0; background-image: url(/images/ui22/sprite/shortcut.png);
	background-repeat: no-repeat; background-size:490px
	440px; white-space:nowrap; text-indent:200%; overflow:hidden; }

	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { [class^=icon-shortcut] {
	background-image: url(/images/ui22/sprite/shortcut@2x.png); } }

	.icon-shortcut-academy{ width:40px; height:40px; background-position:
	0px
	0px; }
	.icon-shortcut-academy_hover{ width:40px; height:40px; background-position:
	-50px
	0px; }
	.icon-shortcut-application{ width:40px; height:40px; background-position:
	0px
	-50px; }
	.icon-shortcut-application_hover{ width:40px; height:40px; background-position:
	-50px
	-50px; }
	.icon-shortcut-calculator{ width:40px; height:40px; background-position:
	-100px
	0px; }
	.icon-shortcut-calculator_hover{ width:40px; height:40px; background-position:
	-100px
	-50px; }
	.icon-shortcut-car-trend{ width:40px; height:40px; background-position:
	0px
	-100px; }
	.icon-shortcut-car-trend_hover{ width:40px; height:40px; background-position:
	-50px
	-100px; }
	.icon-shortcut-cn-study{ width:40px; height:40px; background-position:
	-100px
	-100px; }
	.icon-shortcut-cn-study_hover{ width:40px; height:40px; background-position:
	-150px
	0px; }
	.icon-shortcut-core-value{ width:40px; height:40px; background-position:
	-150px
	-50px; }
	.icon-shortcut-core-value_hover{ width:40px; height:40px; background-position:
	-150px
	-100px; }
	.icon-shortcut-cst{ width:40px; height:40px; background-position:
	0px
	-150px; }
	.icon-shortcut-cst_hover{ width:40px; height:40px; background-position:
	-50px
	-150px; }
	.icon-shortcut-e-book{ width:40px; height:40px; background-position:
	-100px
	-150px; }
	.icon-shortcut-e-book_hover{ width:40px; height:40px; background-position:
	-150px
	-150px; }
	.icon-shortcut-e-library{ width:40px; height:40px; background-position:
	-200px
	0px; }
	.icon-shortcut-e-library_hover{ width:40px; height:40px; background-position:
	-200px
	-50px; }
	.icon-shortcut-e-pass{ width:40px; height:40px; background-position:
	-200px
	-100px; }
	.icon-shortcut-e-pass_hover{ width:40px; height:40px; background-position:
	-200px
	-150px; }
	.icon-shortcut-e-test{ width:40px; height:40px; background-position:
	0px
	-200px; }
	.icon-shortcut-e-test_hover{ width:40px; height:40px; background-position:
	-50px
	-200px; }
	.icon-shortcut-edu-institution{ width:40px; height:40px; background-position:
	-100px
	-200px; }
	.icon-shortcut-edu-institution_hover{ width:40px; height:40px; background-position:
	-150px
	-200px; }
	.icon-shortcut-electronic-library{ width:40px; height:40px; background-position:
	-200px
	-200px; }
	.icon-shortcut-electronic-library_hover{ width:40px; height:40px; background-position:
	-250px
	0px; }
	.icon-shortcut-eng-tel{ width:40px; height:40px; background-position:
	-250px
	-50px; }
	.icon-shortcut-eng-tel_hover{ width:40px; height:40px; background-position:
	-250px
	-100px; }
	.icon-shortcut-finance{ width:40px; height:40px; background-position:
	-250px
	-150px; }
	.icon-shortcut-finance_hover{ width:40px; height:40px; background-position:
	-250px
	-200px; }
	.icon-shortcut-flow{ width:40px; height:40px; background-position:
	0px
	-250px; }
	.icon-shortcut-flow_hover{ width:40px; height:40px; background-position:
	-50px
	-250px; }
	.icon-shortcut-gec{ width:40px; height:40px; background-position:
	-100px
	-250px; }
	.icon-shortcut-gec_hover{ width:40px; height:40px; background-position:
	-150px
	-250px; }
	.icon-shortcut-group-edu{ width:40px; height:40px; background-position:
	-200px
	-250px; }
	.icon-shortcut-group-edu_hover{ width:40px; height:40px; background-position:
	-250px
	-250px; }
	.icon-shortcut-hrdi{ width:40px; height:40px; background-position:
	-300px
	0px; }
	.icon-shortcut-hrdi_hover{ width:40px; height:40px; background-position:
	-300px
	-50px; }
	.icon-shortcut-idp{ width:40px; height:40px; background-position:
	-300px
	-100px; }
	.icon-shortcut-idp_hover{ width:40px; height:40px; background-position:
	-300px
	-150px; }
	.icon-shortcut-job-profile{ width:40px; height:40px; background-position:
	-300px
	-200px; }
	.icon-shortcut-job-profile_hover{ width:40px; height:40px; background-position:
	-300px
	-250px; }
	.icon-shortcut-kmooc{ width:40px; height:40px; background-position:
	0px
	-300px; }
	.icon-shortcut-kmooc_hover{ width:40px; height:40px; background-position:
	-50px
	-300px; }
	.icon-shortcut-language-study{ width:40px; height:40px; background-position:
	-100px
	-300px; }
	.icon-shortcut-language-study_hover{ width:40px; height:40px; background-position:
	-150px
	-300px; }
	.icon-shortcut-law-edu{ width:40px; height:40px; background-position:
	-200px
	-300px; }
	.icon-shortcut-law-edu_hover{ width:40px; height:40px; background-position:
	-250px
	-300px; }
	.icon-shortcut-lecture-room{ width:40px; height:40px; background-position:
	-300px
	-300px; }
	.icon-shortcut-lecture-room_hover{ width:40px; height:40px; background-position:
	-350px
	0px; }
	.icon-shortcut-my-learning1{ width:40px; height:40px; background-position:
	-350px
	-50px; }
	.icon-shortcut-my-learning1_hover{ width:40px; height:40px; background-position:
	-350px
	-100px; }
	.icon-shortcut-my-learning2{ width:40px; height:40px; background-position:
	-350px
	-150px; }
	.icon-shortcut-my-learning2_hover{ width:40px; height:40px; background-position:
	-350px
	-200px; }
	.icon-shortcut-online-edu{ width:40px; height:40px; background-position:
	-350px
	-250px; }
	.icon-shortcut-online-edu_hover{ width:40px; height:40px; background-position:
	-350px
	-300px; }
	.icon-shortcut-regular-study{ width:40px; height:40px; background-position:
	0px
	-350px; }
	.icon-shortcut-regular-study_hover{ width:40px; height:40px; background-position:
	-50px
	-350px; }
	.icon-shortcut-safety-edu{ width:40px; height:40px; background-position:
	-100px
	-350px; }
	.icon-shortcut-safety-edu_hover{ width:40px; height:40px; background-position:
	-150px
	-350px; }
	.icon-shortcut-smart-learning-flatform{ width:40px; height:40px; background-position:
	-200px
	-350px; }
	.icon-shortcut-smart-learning-flatform_hover{ width:40px; height:40px; background-position:
	-250px
	-350px; }
	.icon-shortcut-smart-learning{ width:40px; height:40px; background-position:
	-300px
	-350px; }
	.icon-shortcut-smart-learning_hover{ width:40px; height:40px; background-position:
	-350px
	-350px; }
	.icon-shortcut-spa{ width:40px; height:40px; background-position:
	-400px
	0px; }
	.icon-shortcut-spa2{ width:40px; height:40px; background-position:
	-400px
	-50px; }
	.icon-shortcut-spa2_hover{ width:40px; height:40px; background-position:
	-400px
	-100px; }
	.icon-shortcut-spa_hover{ width:40px; height:40px; background-position:
	-400px
	-150px; }
	.icon-shortcut-speakerphone{ width:40px; height:40px; background-position:
	-400px
	-200px; }
	.icon-shortcut-speakerphone_hover{ width:40px; height:40px; background-position:
	-400px
	-250px; }
	.icon-shortcut-steel-mooc{ width:40px; height:40px; background-position:
	-400px
	-300px; }
	.icon-shortcut-steel-mooc_hover{ width:40px; height:40px; background-position:
	-400px
	-350px; }
	.icon-shortcut-tbl{ width:40px; height:40px; background-position:
	0px
	-400px; }
	.icon-shortcut-tbl_hover{ width:40px; height:40px; background-position:
	-50px
	-400px; }
	.icon-shortcut-touch-class{ width:40px; height:40px; background-position:
	-100px
	-400px; }
	.icon-shortcut-touch-class_hover{ width:40px; height:40px; background-position:
	-150px
	-400px; }
	.icon-shortcut-training-guide{ width:40px; height:40px; background-position:
	-200px
	-400px; }
	.icon-shortcut-training-guide_hover{ width:40px; height:40px; background-position:
	-250px
	-400px; }
	.icon-shortcut-video-call{ width:40px; height:40px; background-position:
	-300px
	-400px; }
	.icon-shortcut-video-call_hover{ width:40px; height:40px; background-position:
	-350px
	-400px; }
	.icon-shortcut-wallet{ width:40px; height:40px; background-position:
	-400px
	-400px; }
	.icon-shortcut-wallet_hover{ width:40px; height:40px; background-position:
	-450px
	0px; }
