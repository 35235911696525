.main-visual {
  height: 490px;
  // background: url($imgPath + 'main/main-bg.png') 50% / cover no-repeat;
  &-content {
    width: $min-width;
    margin-right: auto;
    margin-left: auto;
  }
  .swiper-slide {
    overflow: hidden;
  }
}

.main-visual-header {
  position: relative;
  .main-visual-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      position: absolute;
      top: 0;
      left: 50%;
      width: 2560px;
      transform: translateX(-50%);
      filter: blur(10px);
      -webkit-filter: blur(10px);
    }
  }
  .main-visual-content {
    position: relative;
    width: $min-width;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
  }
  .main-visual-text {
    width: 640px;
    padding-top: 60px;
    // margin-left: 80px;
  }
  .main-visual-tit {
    @include h1;
    @include ellipsis-multi(1, 52px, 100%);
    color: #fff;
  }
  .main-visual-desc {
    @include b1-medium;
    @include ellipsis-multi(2, 29px, 100%);
    margin-top: 10px;
    color: #fff;
  }
  .main-visual-banner {
    overflow: hidden;
    position: absolute;
    top: 40px;
    right: 0;
    z-index: $level01;
    width: 700px;
    height: 296px;
    border-radius: 40px;
    // box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  }
  .swiper-container {
    height: 490px;
  }
  .swiper-ctrl-container {
    position: absolute;
    top: 256px;
    top: 312px;
    left: 50%;
    margin-left: -720px;
    z-index: $level01;
  }
  .main-visual-button-prev,
  .main-visual-button-next {
    position: absolute;
    top: 120px;
    z-index: $level01;
    cursor: pointer;
  }
  .main-visual-button-prev {
    left: 50%;
    margin-left: -770px;
    i[class^='icon'] {
      transform: rotate(180deg);
    }
  }
  .main-visual-button-next {
    right: 50%;
    margin-right: -770px;
  }
}

.main-status-container {
  @include clfix;
  position: relative;
  z-index: $level01;
  margin-top: -174px;
  & + .main-status-container {
    margin-top: 40px;
  }
  > div {
    float: left;
    position: relative;
    width: 700px;
    height: 296px;
    padding: 16px 40px;
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    & + div {
      margin-left: 40px;
    }
  }
  .overflow-y-content {
    height: 198px;
  }
  .main-status-empty {
    @include b2;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 198px;
    padding: 20px;
    background-color: $primary-color04;
    border-radius: 20px;
    text-align: center;
  }
  .main-status-util {
    display: flex;
    position: absolute;
    top: 25px;
    right: 40px;
    .slide-ctrl-container {
      margin-left: 20px;
      & + .buttons-wrapper {
        margin-left: 20px;
      }
    }
  }
  .tab {
    position: absolute;
    top: 22px;
    left: 184px;
    &:before {
      content: none;
    }
    &.char03 {
      left: 126px;
    }
    [role='tab'] {
      @include b3;
      padding-bottom: 2px;
      & + [role='tab'] {
        margin-left: 16px;
      }
      &[aria-selected='true'] {
        @include b3-medium;
        &:before {
          height: 2px;
        }
      }
    }
  }
  .performance-container {
    display: flex;
    .main-performance-list {
      margin-left: 40px;
    }
  }
  .chart-container {
    display: flex;
    width: 300px;

    > div {
      flex: 1;
      & + div {
        margin-left: 20px;
      }
      > strong {
        @include b2-medium;
        display: block;
        text-align: center;
      }
    }
    .chart-area {
      position: relative;
      height: 140px;
      margin-top: 20px;
      .text {
        @include b4-medium;
        display: flex;
        width: 140px;
        height: 140px;
        justify-content: center;
        align-items: center;
        > strong {
          @include b2-medium;
          margin-left: 4px;
        }
      }
      .chart {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &-inner {
          position: absolute;
          top: -43px;
          left: 0;
          // width: 100%;
          // height: 100%;
        }
      }
    }
  }
  .main-performance-list {
    padding-top: 34px;
    .main-performance-item {
      @include b3;
      position: relative;
      padding-left: 22px;
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
      & + .main-performance-item {
        margin-top: 12px;
      }
    }
    .main-performance-item01 {
      &:before {
        background-color: $graph-color03;
      }
    }
    .main-performance-item02 {
      &:before {
        background-color: $graph-color02;
      }
    }
    .main-performance-item03 {
      &:before {
        background-color: $graph-color;
      }
    }
    .main-performance-item04 {
      &:before {
        background-color: $graph-color05;
      }
    }
    .main-performance-item05 {
      &:before {
        background-color: $graph-color04;
      }
    }
  }
  .learning-status {
    position: relative;
    padding-top: 20px;
    padding-bottom: 20px;
    &-swiper {
      height: 236px;
      margin-top: -20px;
    }
    & + .learning-status {
      border-top: 1px solid $gray-scale-color06;
    }
    .form-flex {
      > div {
        & + div {
          position: relative;
          margin-left: 12px;
          padding-left: 13px;
          &:before {
            content: '';
            position: absolute;
            top: 5px;
            left: 0;
            width: 1px;
            height: 12px;
            background-color: $gray-scale-color06;
          }
        }
      }
    }
    .date {
      @include b3;
      strong {
        @include b3-medium;
      }
    }
    .learning-status-tit {
      @include b2-medium;
      @include ellipsis-multi(1, 26px, 70%);
    }
    .learning-status-util {
      position: absolute;
      right: 0;
      bottom: 20px;
      [class^='learning-status-type'] {
        @include b3-medium;
        width: 130px;
        margin-bottom: 4px;
        text-align: center;
      }
      .learning-status-type01 {
        color: $primary-color02;
      }
      .learning-status-type02 {
        color: $gray-scale-color03;
      }
      .learning-status-type03 {
        color: $secondary-color02;
      }
      [role='button'] {
        @include b3-medium;
        white-space: nowrap;
      }
    }
    .edu-card-progress-desc {
      @include b3;
      margin-left: 0;
    }
    .edu-card-progress-content {
      width: 120px;
      margin-left: 8px;
    }
    .edu-card-progress-rate {
      @include b3-medium;
      color: $primary-color03;
    }
    .progress-current {
      background-color: $primary-color03;
    }
  }
}

// Main Card
.main {
  &-card-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    > div {
      display: flex;
      align-items: center;
    }
    h2 {
      @include text(32px, 42px, 500);
      padding-bottom: 0;
      & + [role='button'] {
        margin-left: 20px;
      }
      .small {
        @include h2;
        color: $gray-scale-color03;
      }
    }
    .main-card-ctl {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      & + .buttons-wrapper {
        margin-left: 20px;
      }
    }
    .main-card-swiper-next,
    .main-card-swiper-prev {
      display: inline-flex;
      width: 32px;
      height: 32px;
      cursor: pointer;
      &.swiper-button-disabled {
        filter: grayscale(100%) opacity(50%);
      }
    }
    .main-card-swiper-prev {
      transform: rotate(180deg);
    }
    .main-card-swiper-pagination {
      width: auto;
      margin: 0 10px;
      font-weight: 500;
    }
    [class^='text-button'] {
      align-self: flex-end;
    }
  }
  &-card-container {
    overflow: hidden;
    width: 1460px;
    margin-left: -10px;
    margin-bottom: -20px;
    padding: 0 10px 20px;
    .card {
      box-shadow: none;
    }
    .card-tit {
      @include ellipsis-multi(2, 26px, 100%);
      height: 52px;
      margin-top: 10px;
    }
    .card-util {
      margin-top: 20px;
    }
    .no-data {
      @include b3;
      padding: 70px;
      background-color: #fff;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      text-align: center;
    }
  }
  &-card-swiper {
    overflow: visible;
  }
  @at-root {
    [data-bgColor] {
      .card {
        box-shadow: 0px 10px 10px rgb(0 0 0 / 10%);
      }
    }
  }
}

.theme-white .main {
  &-card-header {
    position: relative;
    z-index: $level01;
    margin-top: -170px;
    h2 {
      color: #fff;
      & + [role='button'] {
        color: #fff;
        [class^='icon'] {
          @include commonSprite;
          background-position: -319px 0;
        }
      }
    }
    .main-card-swiper-pagination {
      color: #fff;
    }
    .main-card-swiper-next,
    .main-card-swiper-prev {
      position: relative;
      &:before {
        @include commonSprite;
        content: '';
        display: block;
        width: 32px;
        height: 32px;
        background-position: -336px 0;
      }
      [class^='icon'] {
        display: none;
      }
    }
  }
}
