[data-modal-name='change-password'] {
  .table {
    td {
      padding-left: 20px;
    }
  }
  .modal-subsection {
    padding-bottom: 40px;
  }
  .type--refer {
    @include b3;
  }
  .form-flex {
    align-items: center;
    .form-input + span {
      margin-left: 10px;
    }
  }
  .change-password-notice {
    @include b3;
  }
}
