[data-modal-name='logout'] {
  .desc {
    @include b2;
    text-align: center;
  }
  .time-remaining {
    @include b2-medium;
    margin-top: 20px;
    text-align: center;
    color: $primary-color02;
  }
}
