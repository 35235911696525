.is-spinner {
  overflow: hidden;
}
.spinner-set {
  position: absolute;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  width: 48px;
  height: 48px;
}
.spinner {
  @extend .spinner-set;
  position: relative;
  border: 5px solid $primary-color03;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotation 1s linear infinite;
  &-container {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $level11;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7);
    &.is-active {
      display: block;
    }
  }
  // &:before,
  // &:after{
  // 	position: absolute;
  // 	content: '';
  // 	border-radius: 50%;
  // }
  // &:before{
  //   content: '';
  //   @extend .spinner-set;
  //   border-top: 4px solid #fff;
  //   border-right: 4px solid transparent;
  //   border-radius: 50%;
  //   animation: spinner 1s linear infinite;
  // }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
