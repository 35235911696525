.app-classroom {
  &-ng {
  }
  &-header {
    height: 138px;
    padding: 30px 40px;
    background: linear-gradient(255.15deg, #784ef0 2.96%, rgba(98, 58, 213, 0) 98.99%), #205dd3;
    border-radius: 20px 20px 0px 0px;
    > span {
      display: block;
      color: #fff;
    }
    > strong {
      @include text(32px, 42px, 500);
      display: block;
      margin-top: 10px;
      color: #fff;
    }
  }
  &-list {
    padding: 15px 30px;
    border: 1px solid $gray-scale-color06;
    border-radius: 0 0 20px 20px;
    > li {
      & + li {
        border-top: 1px solid $gray-scale-color07;
      }
      > a {
        display: block;
        position: relative;
        padding: 17px 40px;
        transition: color 0.22s $easing;
        &:before {
          @include commonSprite;
          content: '';
          position: absolute;
          top: 18px;
          left: 10px;
          width: 24px;
          height: 24px;
          background-position-y: -177px;
        }
        &.classroom-home {
          &:before {
            background-position-x: 0;
          }
        }
        &.classroom-intro {
          &:before {
            background-position-x: -25px;
          }
        }
        &.classroom-notice {
          &:before {
            background-position-x: -50px;
          }
        }
        &.classroom-learning {
          &:before {
            background-position-x: -75px;
          }
        }
        &.classroom-progress {
          &:before {
            background-position-x: -100px;
          }
        }
        &.classroom-student {
          &:before {
            background-position-x: -125px;
          }
        }
        &.classroom-instructor {
          &:before {
            background-position-x: -150px;
          }
        }
        &.classroom-reaction {
          &:before {
            background-position-x: -175px;
          }
        }
        &.classroom-test {
          &:before {
            background-position-x: -200px;
          }
        }
        &.classroom-task {
          &:before {
            background-position-x: -225px;
          }
        }
        &.classroom-qna {
          &:before {
            background-position-x: -250px;
          }
        }
        &.classroom-board {
          &:before {
            background-position-x: -275px;
          }
        }
        &.classroom-library {
          &:before {
            background-position-x: -300px;
          }
        }
        &.classroom-review {
          &:before {
            background-position-x: -325px;
          }
        }
        &:hover {
          &:before {
            background-position-y: -202px;
          }
        }
      }
      &.is-active {
        a {
          color: $primary-color02;
          &:before {
            background-position-y: -202px;
          }
        }
      }
    }
  }
}
