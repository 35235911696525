.is-menu {
  overflow: hidden;
  .app-aside-menu {
    display: none;
  }
}
.sitemap {
  // position: absolute;
  // top:130px;
  // left:calc(50% - 720px);
  > ul {
    width: $min-width;
    margin-right: auto;
    margin-left: auto;
    > li {
      & + li {
        margin-top: 20px;
      }
      > [role='button'] {
        @include h2;
        display: block;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 2px solid $gray-scale-color03;
        border-bottom: 1px solid #bbb;
        &:before {
          content: '';
          @include commonSprite;
          position: absolute;
          top: 5px;
          right: 0;
          width: 32px;
          height: 32px;
          background-position: -286px 0;
          transform: rotateX(180deg);
          transition: transform 0.22s $easing;
        }
      }
      > ul {
        display: none;
        flex-wrap: wrap;
        > li {
          flex: 0 0 220px;
          margin-right: 20px;
          padding-top: 20px;
          padding-bottom: 20px;
          > [role='button'] {
            @include b1-medium;
            display: inline-block;
            &:hover {
              @include b1-bold;
              color: $primary-color02;
            }
          }
          > ul {
            padding: 10px 8px 10px 20px;
            > li {
              > [role='button'] {
                @include b3;
                &:hover {
                  @include b3-medium;
                  color: $primary-color02;
                  text-decoration: underline;
                }
              }
              & + li {
                margin-top: 8px;
              }
            }
          }
          &:hover {
            > [role='button'] {
              @include b1-bold;
              color: $primary-color02;
            }
          }
        }
      }
      &.is-active {
        > [role='button'] {
          &:before {
            transform: rotateX(0deg);
          }
        }
        > ul {
          display: flex;
        }
      }
    }
  }
}
