// * -------------------------------
// * Web Font
// * -------------------------------
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: local('※'), url($fontPath + 'NotoSansKR-Thin.woff2') format('woff2'), url($fontPath + 'NotoSansKR-Thin.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: local('※'), url($fontPath + 'NotoSansKR-Regular.woff2') format('woff2'), url($fontPath + 'NotoSansKR-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: local('※'), url($fontPath + 'NotoSansKR-Regular.woff2') format('woff2'), url($fontPath + 'NotoSansKR-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: local('※'), url($fontPath + 'NotoSansKR-Medium.woff2') format('woff2'), url($fontPath + 'NotoSansKR-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: local('※'), url($fontPath + 'NotoSansKR-Bold.woff2') format('woff2'), url($fontPath + 'NotoSansKR-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: local('※'), url($fontPath + 'NotoSansKR-Black.woff2') format('woff2'), url($fontPath + 'NotoSansKR-Bold.woff') format('woff');
}
