[data-modal-name='share'] {
  .share {
    &-header {
      > ul {
        @include b3;
        > li {
          position: relative;
          padding-left: 20px;
          &:before {
            content: '';
            position: absolute;
            top: 9px;
            left: 9px;
            width: 3px;
            height: 3px;
            background-color: $gray-scale-color02;
            border-radius: 50%;
          }
        }
      }
      .form-search-content {
        > div {
          > strong {
            width: 58px;
          }
        }
      }
      .form-search-container {
        padding: 0;
        border: none;
        border-radius: 0;
        background-color: transparent;
        [role='button'] {
          top: auto;
          bottom: 0;
        }
      }
    }
    &-main {
      margin-top: 20px;
      > div {
        & + div {
          margin-top: 40px;
          > strong {
            @include b2-medium;
            display: block;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}
