.app-content {
  min-height: calc(100vh - 178px - 166px);
  padding-bottom: 120px;
}

.section-top {
  width: $min-width;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-top: 50px;
  padding-bottom: 20px;
  border-bottom: 2px solid $gray-scale-color02;
  h1 {
    @include h1;
    font-weight: 500;
  }
  .tooltip {
    [class^='icon'] {
      margin-right: 10px;
    }
    &-button {
      @include h1;
      font-weight: 500;
    }
  }
}

.section {
  width: $min-width;
  margin-right: auto;
  margin-left: auto;
  & + .section {
    padding-top: 60px;
  }
  &--full {
    > div {
      @extend .section;
    }
    & + .section,
    & + .section--full {
      padding-top: 60px;
    }
  }
  &[data-bgColor],
  &--full[data-bgColor] {
    margin-top: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  &[data-bgColor='blue'],
  &--full[data-bgColor='blue'] {
    background-color: $primary-color04;
  }
  &--full[data-bgColor] {
    & + [data-bgColor] {
      margin-top: -60px;
    }
  }
  &-header {
    position: relative;
    .more-link-button {
      position: absolute;
      top: 8px;
      right: 0;
    }
    .select-container {
      display: flex;
      align-items: center;
      &-tit {
        @include b2-medium;
        margin-right: 20px;
      }
    }
    .select-container,
    .buttons-wrapper {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .buttons {
      display: flex;
      margin-top: -5px;
      margin-left: 20px;
      [role='button'] {
        & + [role='button'] {
          margin-left: 10px;
        }
      }
    }
  }
  h3 {
    > span {
      @include b2;
      color: $gray-scale-color03;
    }
  }
}

// Main Section
.main-section {
  &:last-child {
    // margin-bottom: -120px;
  }
}

// Login
.pg-login {
  .app-content {
    min-height: calc(100vh - 100rem - 166rem);
    padding-bottom: 60px;
  }
}
// Class Room
.pg-classroom {
  .app-content {
    min-height: calc(100vh - 166px);
  }
  .app-classroom-container {
    display: flex;
    width: $min-width;
    margin-right: auto;
    margin-left: auto;
    padding-top: 70px;
  }
  .app-classroom-ng {
    flex: 0 0 280px;
  }
  .app-classroom-main {
    flex: 1;
    margin-left: 40px;
    > .section {
      width: 1120px;
    }
  }
  .application-main {
    .application-info {
      flex: 0 0 545px;
      padding: 9px 29px;
    }
  }
  h3 {
    @include text(20px, 32px, 500);
    padding-bottom: 20px;
  }
}
