.app-header {
  position: relative;
  min-width: $min-width;
  // height: 128px;
  z-index: $level07;
  background-color: #fff;
  &-content {
    position: relative;
    // height: 100%;
    min-height: 128px;
  }
  .content-header {
    width: $min-width;
    height: 68px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 20px;
  }
  .content-main {
    overflow: hidden;
    position: absolute;
    top: 84px;
    right: 0;
    left: 0;
    z-index: $level02;
    height: 44px;
    background-color: #fff;
    transition: height 0.44s 0.1s $easing, box-shadow 0.44s 0.44s $easing;
    &.is-active {
      height: 534px;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
      transition: height 0.44s 0.1s $easing, box-shadow 0.44s 0.1s $easing;
    }
    &-wrapper {
      width: $min-width;
      margin-right: auto;
      margin-left: auto;
    }
  }
  .content-footer {
    margin-top: 60px;
  }
  .content-util {
    display: none;
    margin-top: 60px;
    padding-bottom: 220px;
  }
  &-ci {
    // @include commonSprite;
    display: block;
    position: absolute;
    top: 24px;
    left: 50%;
    z-index: $level01;
    width: 142px;
    height: 40px;
    margin-left: -659px;
    // background-position: 0 -59px;
    transition: top 0s $easing, margin-left 0s $easing;
  }
  &-menu-container {
    position: absolute;
    top: 28px;
    left: 50%;
    width: 32px;
    height: 32px;
    margin-left: -720px;
    .menu-label {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
      z-index: $level01;
      cursor: pointer;
    }
    .menu-anchor-close {
      display: none;
    }
    .menu-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
    }
    .menu-icon-line {
      display: block;
      position: absolute;
      top: 15px;
      left: 1px;
      width: 30px;
      height: 2px;
      background-color: $gray-scale-color02;
      border-radius: 100px;
      // transition: all 0.33s 0.22s $easing;
    }
    .menu-icon-line-top {
      transform: translateY(-12px);
    }
    .menu-icon-line-bottom {
      transform: translateY(12px);
    }
    .menu-anchor {
      position: absolute;
      top: 0;
      width: 32px;
      height: 32px;
      &.menuanchor-close {
        display: none;
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 126px;
    right: 0;
    left: 0;
    z-index: $level03;
    height: 2px;
    background-color: $primary-color02;
    pointer-events: none;
  }
}

// Contents Search
.contents-search {
  position: absolute;
  top: 20px;
  left: 50%;
  z-index: $level03;
  width: 880px;
  margin-left: -440px;
}

// Sign Info Container
.sign-info-container {
  position: absolute;
  top: 24px;
  right: 50%;
  z-index: $level03;
  margin-right: -720px;
  cursor: pointer;
  .sign-info-header {
    display: flex;
    align-items: center;
    .sign-in-image {
      overflow: hidden;
      display: inline-flex;
      width: 40px;
      margin-right: 10px;
      border-radius: 50%;
    }
    .sign-in-user {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 3px;
        left: 0;
        height: 1px;
        background: $gray-scale-color;
      }
      .sign-in-user-name {
        @include b3;
        display: flex;
      }
      em {
        @include ellipsis(180px);
        font-weight: 500;
        margin-right: 4px;
      }
    }
  }
  .sign-info-main {
    padding-top: 52px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: $level07;
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
    .sign-info-main-content {
      width: 176px;
      border: 1px solid $gray-scale-color06;
      background-color: #fff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 20px;
    }
    ul {
      padding: 8px 19px;
    }
    li {
      @include b3;
      a {
        display: flex;
        align-items: center;
        position: relative;
        padding-top: 12px;
        padding-bottom: 12px;
        &:before {
          @include commonSprite;
          content: '';
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        &.si-payment:before {
          background-position: -63px 0;
        }
        &.si-scrap-and-share:before {
          background-position: -84px 0;
        }
        &.si-share-group-settings:before {
          background-position: -105px 0;
        }
        &.si-user-notice:before {
          background-position: -126px 0;
        }
        &.si-profile:before {
          background-position: -147px 0;
        }
        &.si-user-settings:before {
          background-position: -168px 0;
        }
        &.si-sign-out:before {
          background-position: -189px 0;
        }
        &:hover {
          color: $primary-color02;
          transition: color 0.22s $easing;
          &:before {
            background-position-y: -21px;
          }
        }
        &.si-user-notice[data-state='new'] {
          &:after {
            @include commonSprite;
            content: '';
            width: 12px;
            height: 12px;
            margin-left: 4px;
            background-position: -50px -42px;
          }
        }
      }
      & + li {
        border-top: 1px solid $gray-scale-color07;
      }
    }
    &.is-active {
      transform: translateY(0);
      transition: transform 0.33s $easing, opacity 0.44s $easing;
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.pg-layout {
  .app-header {
    background-color: $primary-color;
    &-content {
      position: relative;
      width: $min-width;
      margin-right: auto;
      margin-left: auto;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

// Login Header
.pg-login {
  .app-header {
    z-index: inherit;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100px;
    background-color: $primary-color04;
    &:before {
      content: none;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: $min-width;
      height: 40px;
      margin-right: auto;
      margin-left: auto;
    }
    &-ci {
      top: 0;
      left: 0;
      margin-left: 0;
    }
    .e-campus-global {
      position: absolute;
      top: 0;
      right: 50%;
      margin-right: -720px;
      mix-blend-mode: multiply;
    }
  }
  .app-content {
    > .section,
    > .section--full {
      margin-top: 0;
    }
    .notice-wrapper {
      margin-top: -280px;
    }
  }
}

// Header Theme
// Default
.app-header {
  .ng-content > li {
    &.is-active {
      > a {
        color: $primary-color02;
      }
    }
  }
}
.app-header[data-theme='default'] {
  background-color: #fff;
}

// blue
.app-header[data-theme='blue'] {
  background-color: $primary-color02;
  &:before {
    background-color: $primary-color02;
  }
  .app-header-ci {
    background-position-y: -101px;
  }
  .app-header-menu-container .menu-icon-line {
    background-color: #fff;
  }
  .sign-in-user {
    color: #fff;
  }
  .content-main {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 44px;
      background-color: $primary-color02;
      pointer-events: none;
    }
  }
  .ng-content > li {
    color: #fff;
  }
  .ng-content > li > ul li {
    color: $gray-scale-color;
    a:hover {
      color: $primary-color02;
    }
  }
}
// Green
.app-header[data-theme='green'] {
  background-color: $secondary-color;
  &:before {
    background-color: $secondary-color;
  }
  .app-header-ci {
    background-position-y: -101px;
  }
  .app-header-menu-container .menu-icon-line {
    background-color: #fff;
  }
  .sign-in-user {
    color: #fff;
  }
  .content-main {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 44px;
      background-color: $secondary-color;
      pointer-events: none;
    }
  }
  .ng-content > li {
    color: #fff;
  }
  .ng-content > li > ul li {
    color: $gray-scale-color;
    a:hover {
      color: $secondary-color;
    }
  }
}

// Recommended
.contents-rcd-contents {
  position: absolute;
  top: 58px;
  left: 0;
  z-index: $level03;
  width: 880px;
  padding: 29px;
  border: 1px solid $gray-scale-color06;
  background-color: #fff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  opacity: 0;
  pointer-events: none;
  > .rcd-section {
    position: relative;
    > strong {
      @include b2-medium;
      display: block;
    }
    > ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: 16px;
      margin-bottom: -12px;
      > li {
        margin-right: 12px;
        margin-bottom: 12px;
        > .button {
          height: 32px;
          padding: 0 20px;
          border-radius: 20px;
        }
      }
    }
    & + .rcd-section {
      margin-top: 40px;
    }
    &.recommend {
      > ul {
        > li {
          .button {
            background-color: $gray-scale-color08;
            transition: all 0.11s $easing;
            &:before {
              content: '#';
            }
            &:hover {
              background-color: $primary-color02;
              color: #fff;
            }
          }
        }
      }
    }
    &.recent {
      > ul {
        > li {
          position: relative;
          .button {
            padding-right: 48px;
            border: 1px solid $gray-scale-color06;
          }
          .icon-button {
            position: absolute;
            top: 10px;
            right: 20px;
          }
        }
      }
      .recent-no-data {
        display: none;
        @include b3;
        margin-top: 20px;
        color: $gray-scale-color03;
        &.is-active {
          display: block;
        }
      }
      .recent-delete-button {
        @include b3;
        position: absolute;
        top: 1px;
        right: 0;
        color: $gray-scale-color03;
      }
    }
    &.popular {
      > ul {
        counter-reset: section;
        > li {
          .button {
            height: 32px;
            padding: 0 20px;
            border: 1px solid $gray-scale-color06;
            border-radius: 20px;
            &:before {
              @include b3-medium;
              counter-increment: section;
              content: counter(section);
              margin-right: 10px;
              color: $primary-color02;
            }
          }
        }
      }
    }
  }
  &.is-active {
    pointer-events: auto;
    opacity: 1;
    transition: opacity 0.22s $easing;
  }
}

// Automatic search
.contents-am-contents {
  position: absolute;
  top: 58px;
  left: 0;
  z-index: $level03;
  width: 880px;
  padding: 10px;
  border: 1px solid $gray-scale-color06;
  background-color: #fff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  opacity: 0;
  pointer-events: none;
  .am-list {
    button {
      display: block;
      width: 100%;
      position: relative;
      padding-top: 11px;
      padding-right: 20px;
      padding-bottom: 11px;
      padding-left: 48px;
      border-radius: 10px;
      text-align: left;
      &.sc:before {
        @include commonSprite;
        content: '';
        position: absolute;
        top: 14px;
        left: 22px;
        width: 20px;
        height: 20px;
        background-position: -193px -59px;
      }
      &.sc--history:before {
        @include commonSprite;
        content: '';
        position: absolute;
        top: 14px;
        left: 22px;
        width: 20px;
        height: 20px;
        background-position: -172px -59px;
      }
      > span {
        @include b2;
        @include ellipsis(100%);
        display: block;
      }
      &:hover {
        background-color: $gray-scale-color08;
      }
    }
  }
  &.is-active {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 0.22s $easing;
  }
}

// GNB
.menu-state {
  display: none;
}
.ng-content {
  display: flex;
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 44px;
    bottom: 0;
    width: 1px;
    background-color: $gray-scale-color07;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
  a {
    display: block;
  }
  > li {
    @include b1-medium;
    flex: 1;
    text-align: center;
    > ul {
      padding-top: 35px;
      padding-bottom: 30px;
      li {
        @include b2;
        > a {
          display: block;
          padding: 0 20px;
          word-break: keep-all;
          &:hover {
            text-decoration: underline;
            font-weight: 500;
            color: $primary-color02;
          }
        }
        & + li {
          margin-top: 20px;
        }
      }
    }
    & + li {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 44px;
        bottom: -30px;
        left: 0;
        width: 1px;
        background-color: $gray-scale-color07;
      }
    }
  }
}

// 전체 메뉴 활성화
.menu-state:checked ~ .app-header {
  overflow-y: auto;
  height: 100vh;
  &:before {
    top: 80px;
    // content: none;
  }
  .app-header-ci {
    top: 20px;
    margin-left: -720px;
  }
  .app-header-menu-container {
    top: 28px;
    right: 50%;
    left: auto;
    width: 24px;
    height: 24px;
    margin-right: -720px;
    margin-left: auto;
    .menu-label,
    .menu-icon {
      width: 24px;
      height: 24px;
    }
    .menu-icon-line {
      top: 11px;
      left: 0;
      width: 24px;
      // transition: all 0.22s 0s $easing;
    }
    .menu-icon-line-top {
      transform: rotate(45deg);
    }
    .menu-icon-line-middle {
      opacity: 0;
    }
    .menu-icon-line-bottom {
      transform: rotate(-45deg);
    }
  }
  // Hidden
  .content-footer {
    display: none;
  }
  .content-util {
    display: block;
  }
  .sign-info-container,
  .content-main,
  .content-footer,
  .contents-search {
    opacity: 0;
    pointer-events: none;
  }
}
