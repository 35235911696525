	[class^=icon-idp] { display: inline-block; flex-shrink: 0; background-image: url(/images/ui22/sprite/idp.png);
	background-repeat: no-repeat; background-size:70px
	30px; white-space:nowrap; text-indent:200%; overflow:hidden; }

	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { [class^=icon-idp] {
	background-image: url(/images/ui22/sprite/idp@2x.png); } }

	.icon-idp-process-step--check{ width:30px; height:30px; background-position:
	0px
	0px; }
	.icon-idp-process-step{ width:30px; height:30px; background-position:
	-40px
	0px; }
