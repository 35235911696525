.app-popup-container {
  margin-right: auto;
  margin-left: auto;
  .popup-main {
    > .section {
      width: auto;
    }
  }
  // Size (lg > md < sm)
  &--sm {
    @extend .app-popup-container;
    // width: 600px;
    .popup-main {
      padding: 40px;
      > .section {
        // width: 520px;
        .section-header {
          h1 {
            @include h3;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
  &--md {
    @extend .app-popup-container;
    // width: 800px;
    .popup-main {
      padding: 30px 40px;
      > .section {
        // width: 720px;
        .section-header {
          h1 {
            @include h3;
            padding-bottom: 20px;
          }
        }
      }
    }
    .popup-footer {
      padding: 30px 40px;
    }
  }
  &--lg {
    @extend .app-popup-container;
    // width: 1080px;
    .popup-main {
      padding: 40px;
      padding-bottom: 180px;
      > .section {
        // width: 1000px;
        .section-header {
          h1 {
            @include h3;
            padding-bottom: 20px;
          }
        }
        .board-header {
          h1 {
            @include h3;
            // padding-bottom: 20px;
          }
          // .board-header-case {
          //   @include b3;
          // }
        }
      }
    }
    .popup-footer {
      padding: 40px;
    }
  }
  &--full {
    @extend .app-popup-container;
    width: 100%;
  }
  .table-wrapper {
    overflow-x: visible;
    overflow-y: visible;
    .table {
      width: 100%;
    }
  }
}

.popup-footer {
  &[data-bgcolor='grey'] {
    background-color: $gray-scale-color08;
  }
}
