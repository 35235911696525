	[class^=icon-groups] { display: inline-block; flex-shrink: 0; background-image: url(/images/ui22/sprite/groups.png);
	background-repeat: no-repeat; background-size:212px
	138px; white-space:nowrap; text-indent:200%; overflow:hidden; }

	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { [class^=icon-groups] {
	background-image: url(/images/ui22/sprite/groups@2x.png); } }

	.icon-groups-business{ width:64px; height:64px; background-position:
	0px
	0px; }
	.icon-groups-management{ width:64px; height:64px; background-position:
	-74px
	0px; }
	.icon-groups-purchase{ width:64px; height:64px; background-position:
	0px
	-74px; }
	.icon-groups-quality{ width:64px; height:64px; background-position:
	-74px
	-74px; }
	.icon-groups-rnd{ width:64px; height:64px; background-position:
	-148px
	0px; }
	.icon-groups-scm{ width:64px; height:64px; background-position:
	-148px
	-74px; }
