@use 'sass:math';

// * -------------------------------
// * Common Sprite
// * -------------------------------
@mixin commonSprite {
  background-image: url($imgPath + 'common/common.png');
  background-size: 400px auto;
  background-repeat: no-repeat;
}

// * -------------------------------
// * Text
// * -------------------------------
@mixin text($size: 16px, $lh: 1.6, $weight: 400) {
  font-size: $size;
  line-height: $lh;
  font-weight: $weight;
}

// * -------------------------------
// * Clear Fix
// * -------------------------------
@mixin clfix {
  //Float 해제
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

// * -------------------------------
// * Heading Tag
// * -------------------------------
@mixin h1 {
  font-size: 40px;
  line-height: 52px;
  font-weight: 700;
}
@mixin h2 {
  font-size: 40px;
  line-height: 41px;
  font-weight: 500;
}
@mixin h2 {
  font-size: 24px;
  line-height: 38px;
  font-weight: 500;
}
@mixin h3 {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
}
@mixin h4 {
  font-size: 18px;
  line-height: 29px;
  font-weight: 500;
}

@mixin b1 {
  font-size: 18px;
  line-height: 29px;
  font-weight: 400;
}
@mixin b1-medium {
  font-size: 18px;
  line-height: 29px;
  font-weight: 500;
}
@mixin b1-bold {
  font-size: 18px;
  line-height: 29px;
  font-weight: 700;
}

@mixin b2 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
}
@mixin b2-medium {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}
@mixin b2-bold {
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}

@mixin b3 {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}
@mixin b3-medium {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

@mixin b4 {
  font-size: 12px;
  line-height: 19px;
  font-weight: 400;
}
@mixin b4-medium {
  font-size: 12px;
  line-height: 19px;
  font-weight: 500;
}

// * -------------------------------
// * Ellipsis
// * -------------------------------
@mixin ellipsis($w) {
  //한줄 말줄임
  overflow: hidden;
  display: inline-block;
  max-width: $w;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis-multi($line, $lh, $w) {
  // 여러줄 말줄임 (IE에서는 ... 없음) : 줄수, line-height, 넓이
  overflow: hidden;
  display: -webkit-box;
  width: $w;
  max-height: $line * $lh;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
}

// * -------------------------------
// * A11y
// * -------------------------------
@mixin a11y-hidden {
  //Element 숨김
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
  border: 0;
}

// * -------------------------------
// * ICON
// * -------------------------------

// X 닫기 버튼
@mixin iconCancel($size: 24rem, $thick: 2rem, $color: #000, $padding: 0, $display: inline-block, $position: relative) {
  position: $position;
  display: $display;
  width: $size + $padding * 2;
  height: $size + $padding * 2;
  background-color: transparent;
  border: none;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc($size * $sqrt_2 - $thick);
    height: $thick;
    background-color: $color;
  }

  &:before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    transform: translate(-50%, -50%) rotate(135deg);
  }
  & > span {
    overflow: hidden;
    display: block;
    text-indent: 100%;
    white-space: nowrap;
  }
}

// 꽉찬 삼각형
@mixin iconTriangle($direction: 'up', $width: 20rem, $height: 10rem, $color: #000, $display: inline-block) {
  display: $display;
  width: $width;
  height: $height;
  &:before {
    content: '';
    display: block;
    @if ($direction == 'up') {
      border-bottom: $height solid $color;
      border-left: calc($width / 2) solid transparent;
      border-right: calc($width / 2) solid transparent;
    } @else if($direction == 'down') {
      border-top: $height solid $color;
      border-left: calc($width / 2) solid transparent;
      border-right: calc($width / 2) solid transparent;
    } @else if($direction == 'left') {
      border-right: $width solid $color;
      border-top: calc($height / 2) solid transparent;
      border-bottom: calc($height / 2) solid transparent;
    } @else if($direction == 'right') {
      border-left: $width solid $color;
      border-top: calc($height / 2) solid transparent;
      border-bottom: calc($height / 2) solid transparent;
    }
  }
}

// 화살표 모양
@mixin iconBullet($direction: 'up', $width: 50px, $height: 50px, $thick: 2px, $color: #000, $display: inline-block, $position: relative) {
  position: $position;
  display: $display;
  width: $width;
  height: $height;

  @if ($direction == 'up' or $direction == 'down') {
    $size: math.floor(calc($width / $sqrt_2));
    $rate: calc(2 * $height/$width);
    $margin-top: math.round(calc(($rate * $size/(2 * $sqrt_2))));

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: $size;
      height: $size;
      @if ($rate == 1) {
        transform: translate(-50%, -50%) rotate(45deg);
      } @else {
        transform: translate(-50%, -50%) scale($rate, 1) rotate(45deg);
      }

      @if ($direction == 'up') {
        margin-top: $margin-top;
        border-top: $thick solid $color;
        border-left: $thick solid $color;
      } @else if($direction == 'down') {
        margin-top: -$margin-top;
        border-right: $thick solid $color;
        border-bottom: $thick solid $color;
      }
    }
  } @else if($direction == 'left' or $direction == 'right') {
    $size: math.floor(calc($height/$sqrt_2 - 2 * $thick));
    $rate: calc(2 * $width/$height);
    $margin-left: math.round(calc($rate * $size/(2 * $sqrt_2)));
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: $size;
      height: $size;
      @if ($rate == 1) {
        transform: translate(-50%, -50%) rotate(45deg);
      } @else {
        transform: translate(-50%, -50%) scale(1, $rate) rotate(45deg);
      }

      @if ($direction == 'left') {
        margin-left: $margin-left;
        border-left: $thick solid $color;
        border-bottom: $thick solid $color;
      } @else if($direction == 'right') {
        margin-left: -$margin-left;
        border-top: $thick solid $color;
        border-right: $thick solid $color;
      }
    }
  }
}

// 더하기 모양
@mixin iconPlus($width: 12rem, $height: 12rem, $thick: 2rem, $padding: 0rem, $background-color: #484b51, $display: inline-block, $position: relative) {
  position: $position;
  display: $display;
  box-sizing: border-box;
  width: $width + (2 * $padding);
  height: $height + (2 * $padding);

  &:before,
  &:after {
    position: absolute;
    content: '';
    display: block;
    background-color: $background-color;
    border-radius: $thick;
  }
  &:before {
    width: $width;
    height: $thick;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:after {
    width: $thick;
    height: $height;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@mixin col($flex: 0 0 auto) {
  flex: 0 0 calc(100% / ($gridColumn / $flex));
}
@mixin colWidth($width) {
  max-width: calc(100% / ($gridColumn / $width));
}
