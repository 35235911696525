// * -------------------------------
// * Button
// * -------------------------------

// Size (xl > lg > md > sm > xs)
.button {
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.button-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.solid-button {
  @extend .button;
  position: relative;
  background-color: $gray-scale-color03;
  border-radius: 24px;
  color: #fff;
  transition: background 0.22s $easing;
  // Size
  &--lg {
    @extend .solid-button;
    @include b2-medium;
    height: 56px;
    padding: 0 40px;
  }
  &--md {
    @extend .solid-button;
    @include b2-medium;
    height: 48px;
    padding: 0 40px;
    white-space: nowrap;
    i[class^='icon'] {
      margin-right: 8px;
    }
  }
  &--sm {
    @extend .solid-button;
    @include b3-medium;
    height: 40px;
    padding: 0 20px;
    i[class^='icon'] {
      margin-right: 8px;
    }
  }
  &--xs {
    @extend .solid-button;
    @include b3-medium;
    height: 32px;
    padding: 0 16px;
    i[class^='icon'] {
      margin-right: 8px;
    }
  }
  // Color
  &[data-theme='black'] {
    background-color: $gray-scale-color02;
  }
  &[data-theme='grey'] {
    background-color: $gray-scale-color03;
  }
  &[data-theme='light-grey'] {
    background-color: $gray-scale-color05;
  }
  &[data-theme='light-grey02'] {
    background-color: $gray-scale-color04;
  }
  &[data-theme='light-blue'] {
    background-color: $primary-color03;
  }
  &[data-theme='light-blue02'] {
    background-color: $secondary-color04;
  }
  &[data-theme='blue'] {
    background-color: $primary-color;
  }
  // Disabled
  &.is-disabled {
    background-color: $gray-scale-color06;
    pointer-events: none;
  }
}

// Rect Solid Button
.rect-solid-button {
  @extend .button;
  @include b2-medium;
  min-width: 180px;
  height: 56px;
  padding: 0 40px;
  background-color: $gray-scale-color03;
  border-radius: 10px;
  color: #fff;
  &--sm {
    @extend .rect-solid-button;
    @include b2-medium;
    height: 40px;
    padding: 0 20px;
    i[class^='icon'] {
      margin-right: 8px;
    }
  }
  // Color
  &[data-theme='black'] {
    background-color: $gray-scale-color02;
  }
  &[data-theme='blue'] {
    background-color: $primary-color;
  }
  &[data-theme='light-grey'] {
    background-color: $gray-scale-color05;
  }
  &[data-theme='light-grey02'] {
    background-color: $gray-scale-color04;
  }
  &[data-theme='dark-grey'] {
    background-color: $gray-scale-color02;
  }
  &[data-theme='light-blue'] {
    background-color: $primary-color03;
  }
  // Disabled
  &.is-disabled {
    background-color: $gray-scale-color05;
    pointer-events: none;
  }
}

// Rect Solid Button
.rect-line-button {
  @extend .button;
  @include b2-medium;
  min-width: 180px;
  height: 56px;
  border: 2px solid $gray-scale-color02;
  background-color: #fff;
  border-radius: 10px;
  // Disabled
  &.is-disabled {
    background-color: $gray-scale-color05;
    pointer-events: none;
  }
}

// Line Button
.line-button {
  @extend .button;
  border: 1px solid $gray-scale-color02;
  background-color: #fff;
  border-radius: 20px;
  transition: background 0.11s $easing, color 0.11s $easing;
  white-space: nowrap;
  &--md {
    @extend .line-button;
    @include b2-medium;
    min-width: 80px;
    height: 48px;
    border-radius: 24px;
  }
  &--sm {
    @extend .line-button;
    @include b3-medium;
    height: 40px;
    padding: 0 19px;
    i[class^='icon'] {
      margin-right: 6px;
    }
    .button-text + i[class^='icon'] {
      margin-left: 6px;
      margin-right: 0;
    }
  }
  &--xs {
    @extend .line-button;
    @include b3-medium;
    height: 32px;
    padding: 0 19px;
  }
  // Color
  &[data-theme='grey'] {
    border-color: $gray-scale-color03;
    color: $gray-scale-color03;
    &.is-active,
    &:hover {
      background-color: $gray-scale-color03;
    }
  }
  &[data-theme='blue'] {
    border-color: $primary-color02;
    color: $primary-color02;
    &.is-active,
    &:hover {
      background-color: $primary-color02;
      color: #fff;
    }
  }
  // Disabled
  &.is-disabled {
    border-color: $gray-scale-color05;
    background-color: $gray-scale-color08;
    color: $gray-scale-color04;
    pointer-events: none;
    [class^='icon'] {
      filter: grayscale(100%) opacity(40%);
    }
  }
}

// More Button
.more-button {
  @include b2;
  display: inline-flex;
  border: 1px solid $gray-scale-color06;
  background-color: #fff;
  border-radius: 100px;
  > .button-content {
    width: 196px;
    height: 44px;
  }
  &-wrapper {
    padding-top: 30px;
    text-align: center;
  }
  &--sm {
    width: 24px;
    height: 24px;
    border: 1px solid $gray-scale-color06;
    border-radius: 4px;
    > .button-content {
      width: auto;
      height: auto;
      padding: 3px;
    }
    .icon-common-more {
      filter: grayscale(100%) opacity(60%);
    }
    &--wh {
      @extend .more-button--sm;
      border-color: #fff;
    }
  }
}

// Text Button
.text-button {
  @include b2;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  .button-content {
    display: inline;
    text-align: left;
  }
  [class^='icon'],
  [class^='flag'] {
    margin-left: 5px;
  }
  &--underline {
    @extend .text-button;
    text-decoration: underline;
    text-underline-position: under;
    &:hover {
      text-decoration: underline;
    }
  }
  &--md {
    @extend .text-button;
    @include b2-medium;
    .button-text + i[class^='icon'] {
      margin-left: 8px;
    }
    i[class^='icon'] + .button-text {
      margin-right: 8px;
    }
  }
  &--sm {
    @extend .text-button;
    @include b3-medium;
    .button-text + i[class^='icon'] {
      margin-left: 6px;
    }
    i[class^='icon'] + .button-text {
      margin-right: 6px;
    }
  }
  &--icon {
    &--md {
      @include b2-medium;
      .button-text + i[class^='icon'] {
        margin-left: 6px;
      }
      i[class^='icon'] + .button-text {
        margin-right: 6px;
      }
    }
    &--sm {
      @include b3-medium;
      .button-text + i[class^='icon'] {
        margin-left: 6px;
      }
      i[class^='icon'] + .button-text {
        margin-right: 6px;
      }
    }
  }
}

// Rectangle Button
.rect-button {
  @extend .button;
  @include b3-medium;
  position: relative;
  height: 40px;
  padding: 0 16px;
  border: 1px solid $gray-scale-color04;
  background-color: #fff;
  border-radius: 4px;
  color: $gray-scale-color04;
  transition: color 0.11s $easing;
  &:before {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: $level01;
    border: 2px solid transparent;
    border-radius: 4px;
    transition: border 0.11s $easing;
    pointer-events: none;
  }
  .button-text + [class^='icon'],
  [class^='icon'] + .button-text {
    margin-left: 6px;
  }
  &.is-active,
  &:hover {
    color: $gray-scale-color;
    &:before {
      border-color: $gray-scale-color;
    }
  }
  &[data-theme='green'] {
    &.is-active,
    &:hover {
      background-color: $secondary-color;
      color: #fff;
      &:before {
        border-color: $secondary-color;
      }
    }
  }
  &[data-theme='mint'] {
    &.is-active,
    &:hover {
      background-color: $graph-color02;
      color: #fff;
      &:before {
        border-color: $graph-color02;
      }
    }
  }
  &[data-theme='purple'] {
    &.is-active,
    &:hover {
      background-color: $graph-color;
      color: #fff;
      &:before {
        border-color: $graph-color;
      }
    }
  }
  &[data-theme='yellow'] {
    &.is-active,
    &:hover {
      background-color: $graph-color03;
      color: #fff;
      &:before {
        border-color: $graph-color03;
      }
    }
  }
  &[data-theme='red'] {
    &.is-active,
    &:hover {
      background-color: $graph-color04;
      color: #fff;
      &:before {
        border-color: $graph-color04;
      }
    }
  }
}

// Icon Button
.icon-button {
  display: inline-flex;
  align-items: center;
}

.buttons-wrapper {
  display: flex;
  align-items: center;
  [role='button'] + [role='button'] {
    margin-left: 10px;
  }
  &.flex-center {
    justify-content: center;
  }
  &.flex-end {
    justify-content: flex-end;
  }
  &.space-between {
    justify-content: space-between;
  }
}
