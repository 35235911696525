.depth3 {
  padding-top: 20px;
  &-content {
    min-width: $min-width;
  }
  &-container {
    display: flex;
    flex-wrap: wrap;
    width: 1300px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: -20px;
    > li {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
  &--lv2 {
    overflow: hidden;
    position: absolute;
    // top: 182px;
    left: 0;
    width: 100%;
    height: 0;
    // border-top: 1px solid transparent;
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    transition: height 0.44s 0.1s $easing, box-shadow 0.44s 0.44s $easing;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: $level01;
      width: 100%;
      height: 1px;
      background-color: $primary-color02;
      pointer-events: none;
      transition: opacity 0.45s 0.3s $easing;
      opacity: 0;
      pointer-events: none;
    }
    &.is-active {
      height: 463px;
      // border-color: $primary-color02;
      transition: height 0.44s 0.1s $easing, box-shadow 0.44s 0.1s $easing;
      &:before {
        opacity: 1;
        transition: opacity 0.22s 0.1s $easing;
      }
    }
    .depth3-content {
      display: flex;
      position: relative;
      max-width: $min-width;
      height: 100%;
      margin: 0 auto;
      border-right: 1px solid $gray-scale-color07;
      border-left: 1px solid $gray-scale-color07;
      > div {
        flex: 0 0 25%;
      }
    }
    .depth-wrapper {
      position: relative;
      & + .depth-wrapper {
        border-left: 1px solid $gray-scale-color07;
      }
      ul {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding-top: 10px;
        padding-bottom: 13px;
        > li {
          a {
            // @include ellipsis-multi(1, 40px, 100%);
            display: block;
            padding: 10px 70px 4px 70px;
            &:hover {
              background-color: $primary-color02;
              color: #fff;
            }
            span {
              @include b2;
              @include ellipsis(100%);
              vertical-align: top;
            }
          }
          &.is-active {
            a {
              background-color: $primary-color02;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
