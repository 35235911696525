// * -------------------------------
// * Heading Tag
// * -------------------------------
h1 {
  @include h1;
}
h2 {
  @include h2;
  padding-bottom: 20px;
}
h3 {
  @include h4;
}

// * -------------------------------
// * Sign in
// * -------------------------------
.sign-in {
  overflow: hidden;
  width: 400px;
  border: 1px solid $gray-scale-color06;
  border-radius: 20px 20px 0 0;
  .tab {
    display: flex;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    [role='tab'] {
      @include b2;
      flex: 1;
      height: 54px;
      background-color: #f3f3f3;
      color: #999;
      border-top: none;
      border-bottom: 1px solid #d9d9d9;
      &:first-child,
      &:last-child {
        border-right: 0;
        border-left: 0;
      }
      &[aria-selected='true'] {
        @include b1-medium;
        background-color: #fff;
        border-right: none;
        border-bottom: 1px solid #fff;
        border-left: none;
        color: $primary-color;
        &:first-child,
        &:last-child {
          border-top: 0;
          border-right: 0;
        }
      }
      & + [role='tab'] {
        border-left: 1px solid #d9d9d9;
      }
    }
  }
  > strong {
    @include h3;
    display: block;
    padding: 20px 20px 15px;
    background-color: #fff;
    color: $primary-color02;
  }
  .tab-pannel {
    padding: 0 20px 30px;
    background-color: #fff;
  }
  .form-input--img {
    & + .form-input--img {
      margin-top: 10px;
    }
  }
  .sign-in-button {
    margin-top: 10px;
  }
  &-util {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding-top: 16px;
    padding-right: 20px;
    padding-bottom: 18px;
    padding-left: 20px;
    border-right: 1px solid $gray-scale-color06;
    border-bottom: 1px solid $gray-scale-color06;
    border-left: 1px solid $gray-scale-color06;
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    .tooltip-button {
      @include b3;
      color: $gray-scale-color04;
    }
  }
}

// * -------------------------------
// * Login
// * -------------------------------
.login-wrapper {
  display: flex;
  padding-bottom: 220px;
  > div:nth-child(1) {
    flex: 0 0 400px;
  }
  > div:nth-child(2) {
    flex: 1;
    margin-left: 20px;
  }
  .login-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 380px;
    padding: 92px;
    background-image: url($imgPath + 'util/hyundai-autoever-e-campus.png');
    border-radius: 20px;
    > strong {
      @include text(40px, 64px, 700);
      color: #fff;
    }
    > p {
      @include text(18px, 29px, 500);
      margin-top: 20px;
      color: #fff;
    }
  }
}

.notice {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  li {
    background: #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  &-content {
    padding: 24px;
  }
  &-main {
    position: relative;
    padding-bottom: 50px;
    > strong {
      @include text($text-lg, 26px, 500);
      @include ellipsis-multi(3, 26px, 100%);
      display: block;
      height: 78px;
    }
    .attached-file {
      position: absolute;
      bottom: 13px;
      right: 0;
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid #cecece;
    .hits {
      @include text($text-sm, 19px, 400);
      color: #666;
      em {
        color: #000;
        font-weight: 500;
      }
    }
    .date {
      @include text($text-sm, 19px, 400);
      color: #000;
    }
  }
}

.education-manager {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 45px;
  > li {
    padding-top: 29px;
    padding-bottom: 25px;
    border-top: 2px solid $primary-color02;
    border-bottom: 1px solid #cecece;
  }
  &-content {
    padding-right: 8px;
    padding-left: 8px;
    text-align: right;
    .group {
      display: block;
      font-size: $text-lg;
      line-height: 26px;
      color: #666;
    }
    .manager {
      display: block;
      margin-top: 5px;
      font-size: 20px;
      line-height: 32px;
      color: #000;
    }
  }
  &-footer {
    padding-right: 27px;
    padding-left: 27px;
    margin-top: 16px;
    font-size: $text-md;
    line-height: 25px;
    color: #666;
    > div {
      display: flex;
      position: relative;
      padding-left: 28px;
      [class^='icon'] {
        position: absolute;
        top: 4px;
        left: 0;
      }
    }
  }
}

// * -------------------------------
// * Table
// * -------------------------------
.table {
  border-top: 2px solid #333;
  th {
    @include b2-medium;
    height: 50px;
    border-bottom: 1px solid $gray-scale-color06;
    background-color: $secondary-color07;
    vertical-align: middle;
    & + td,
    & + th {
      border-left: 1px solid $gray-scale-color06;
    }
    .tooltip-container {
      top: 3px;
      text-align: left;
    }
    .tooltip {
      align-items: flex-start;
      &-button {
        @include b2-medium;
      }
    }
  }
  td {
    @include b2;
    height: 50px;
    padding: 12px 24px;
    border-bottom: 1px solid $gray-scale-color06;
    vertical-align: middle;
    & + td {
      border-left: 1px solid $gray-scale-color06;
    }
    &.no-data {
      padding: 36px 0;
      text-align: center;
    }
    &.is-reply {
      &::before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border: 1px solid #aaa;
        border-top: 0;
        border-right: 0;
        margin-right: 5px;
      }
      &--depth2 {
        @extend .is-reply;
        &:before {
          margin-left: 34px;
        }
      }
    }
    &.is-file,
    &.is-lock {
      [class^='icon'] {
        margin-left: 7px;
        vertical-align: middle;
      }
    }
    &.is-new,
    &.is-hot,
    &.is-reply,
    &.is-reply--depth2 {
      span + span {
        margin-left: 5px;
      }
    }
  }
  .notice-row {
    td {
      background-color: #faf8ee;
    }
  }
  &-button--text-icon {
    i[class^='icon'] {
      margin-left: 20px;
    }
  }
  // Color Grey
  &--border-grey {
    @extend .table;
    border-width: 1px;
    border-color: $gray-scale-color06;
  }
  //
  &-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    > .table {
      width: 1440px;
    }
  }
  &--lv2 {
    @extend .table;
    th,
    td {
      padding-right: 24px;
      padding-left: 30px;
    }
    th {
      > span:not(.tooltip-container) {
        @include b3-medium;
      }
      & + td {
        border-left: 0;
      }
    }
  }
  &--lv2-high {
    @extend .table--lv2;
    th,
    td {
      height: 80px;
    }
  }
  &--lv3 {
    @extend .table;
    th {
      @include b3-medium;
      height: 40px;
      padding-top: 8px;
      padding-bottom: 10px;
    }
    td {
      @include b3;
      height: 40px;
      padding-top: 8px;
      padding-right: 12px;
      padding-bottom: 10px;
      padding-left: 12px;
    }
  }
  .border-left {
    border-left: 1px solid $gray-scale-color06;
  }
  .bg-gray {
    background-color: $gray-scale-color08;
  }
  &.border-none {
    border-top: 0;
  }
}

// * -------------------------------
// * Pagination
// * -------------------------------
.pagination {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  &:empty {
    display: none;
  }
  a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
  }
  > a {
    border: 1px solid $gray-scale-color06;
    &:before {
      @include commonSprite;
      content: '';
      width: 12px;
      height: 12px;
    }
    & + a {
      margin-left: 10px;
    }
  }
  .btn-first {
    &:before {
      background-position: 0 -21px;
    }
  }
  .btn-prev {
    &:before {
      background-position: -13px -21px;
    }
  }
  .btn-next {
    &:before {
      background-position: -13px -21px;
      transform: rotate(-180deg);
    }
  }
  .btn-last {
    &:before {
      background-position: 0 -21px;
      transform: rotate(-180deg);
    }
  }
  .page {
    display: flex;
    padding: 0 10px;
    > a {
      @include b2;
      text-align: center;
      &.is-current {
        @include b2-medium;
        color: $primary-color02;
        text-decoration: underline;
      }
    }
  }
}

// Tooltip
.tooltip {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
  &-container {
    display: inline-flex;
    align-items: center;
    position: relative;
    .tooltip {
      position: static;
    }
  }
  &-button {
    display: inline-flex;
    .button-content {
      display: inline-flex;
      align-items: center;
      [class^='icon'] {
        margin-left: 8px;
      }
    }
  }
  &-aside {
    &--left {
      .tooltip-aside-container {
        right: auto;
        left: 0;
      }
    }
    &--right {
      .tooltip-aside-container {
        right: 0;
        left: auto;
      }
    }
  }
  &-aside-container {
    position: absolute;
    top: 30px;
    z-index: $level06;
    width: 324px;
    // margin-top: 6px;
    padding-top: 20px;
    padding-right: 54px;
    padding-bottom: 20px;
    padding-left: 20px;
    background-color: $gray-scale-color02;
    border-radius: 10px;
    color: #fff;
    transform: translateY(20px);
    opacity: 0;
    pointer-events: none;
    strong {
      @include b3-medium;
      display: block;
    }
    ol {
      @include b3;
      margin-top: 10px;
      padding-left: 16px;
      li {
        list-style: decimal;
      }
    }
    .desc {
      @include b3;
      display: block;
    }
    // &:before {
    //   @include commonSprite;
    //   content: '';
    //   position: absolute;
    //   top: -9px;
    //   right: 20px;
    //   width: 13px;
    //   height: 9px;
    //   background-position: -63px -42px;
    // }
    &.is-active {
      transform: translateY(0);
      opacity: 1;
      pointer-events: auto;
      transition: opacity 0.22s $easing, transform 0.22s $easing;
    }
  }
  &-aside-close-button {
    @include iconCancel(16px, 1.4px, #fff);
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

// Select Box
.select {
  display: inline-flex;
  width: 320px;
  &-content {
    display: block;
    width: 100%;
    position: relative;
    > select {
      display: none;
    }
    &:before {
      @include commonSprite;
      content: '';
      position: absolute;
      top: 13px;
      right: 30px;
      z-index: $level01;
      width: 24px;
      height: 24px;
      background-position: -147px -59px;
      transition: transform 0.22s linear;
      pointer-events: none;
    }
  }
  &-button {
    @include b2;
    display: inline-flex;
    position: relative;
    width: 100%;
    height: 48px;
    padding-top: 10px;
    padding-right: 64px;
    padding-bottom: 10px;
    padding-left: 30px;
    border: 1px solid $gray-scale-color06;
    background-color: #fff;
    border-radius: 30px;
    text-align: left;
    // color: $gray-scale-color04;
    color: $gray-scale-color;
    span {
      @include ellipsis(100%);
    }
  }
  &-list {
    position: absolute;
    top: 58px;
    right: 0;
    left: 0;
    z-index: $level06;
    padding: 10px;
    border: 1px solid $gray-scale-color06;
    background-color: #fff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    opacity: 0;
    pointer-events: none;
    &-content {
      overflow: hidden;
      overflow-y: auto;
      max-height: 240px;
      &::-webkit-scrollbar {
        border-radius: 20px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 20px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
      }
    }
    button {
      @include b2;
      display: flex;
      align-items: center;
      width: 100%;
      height: 48px;
      padding: 11px 12px;
      border-radius: 10px;
      span {
        @include ellipsis(100%);
      }
      &:hover {
        background-color: $gray-scale-color08;
      }
    }
    .is-active {
      button {
        color: $primary-color02;
      }
    }
  }
  // Active
  &.is-active {
    .select-content:before {
      transform: rotateX(-180deg);
    }
    .select-list {
      opacity: 1;
      pointer-events: auto;
      &.is-reverse {
        top: auto;
        bottom: 58px;
      }
    }
  }
  &.is-selected {
    .select-button {
      // color: $gray-scale-color;
    }
  }
  // Disabled
  &.is-disabled {
    pointer-events: none;
    .select-content:before {
      filter: grayscale(100%) opacity(40%);
    }
    .select-button {
      background-color: $gray-scale-color08;
      color: $gray-scale-color03;
    }
  }
}

// Form Vertical
.form {
  &--vertical {
    .form-row {
      display: flex;
      & + .form-row {
        margin-top: 20px;
      }
    }
  }
  &-tit {
    @include b2-medium;
    display: inline-flex;
    align-items: center;
    flex: 0 0 140px;
    padding-right: 20px;
  }
  &-content {
    flex: 1;
  }
}

// Required
.required {
  &:after {
    content: ' *';
    color: $secondary-color02;
  }
}

.type--refer {
  padding-left: 16px;
  li {
    text-indent: -16px;
    & + li {
      margin-top: 6px;
    }
  }
}

// notice-container
.notice-view__container {
  .notice-view__header {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 2px solid $gray-scale-color02;
    border-bottom: 2px solid $gray-scale-color06;
    &.border-top-none {
      border-top: 0;
    }
  }
  .notice-view__tit {
    @include h3;
    display: block;
    [class^='icon'] {
      position: relative;
      top: 2px;
      margin-left: 12px;
      & + [class^='icon'] {
        margin-left: 0;
      }
    }
    [class^='flag'] {
      position: relative;
      top: -4px;
      margin-left: 15px;
      & + [class^='flag'] {
        margin-left: 0;
      }
    }
  }
  .notice-view__info {
    @include b2;
    display: flex;
    margin-top: 10px;
    > span {
      & + span {
        position: relative;
        margin-left: 20px;
        padding-left: 21px;
        &:before {
          content: '';
          position: absolute;
          top: 5px;
          left: 0;
          width: 1px;
          height: 12px;
          background-color: $gray-scale-color05;
        }
      }
    }
  }
  .notice-view__main {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-scale-color06;
  }
  .notice-view__attached-file {
    display: flex;
    align-items: center;
    margin-top: 20px;
    padding: 20px 0;
    background-color: $secondary-color07;
    border-radius: 20px;
    &-tit {
      @include b2;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 135px;
      text-align: center;
      [class^='icon'] {
        margin-right: 7px;
      }
    }
    &-content {
      flex: 1;
      position: relative;
      padding-left: 41px;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        background-color: $secondary-color04;
      }
      > div {
        & + div {
          margin-top: 8px;
        }
      }
    }
  }
  .notice-view__content {
    @include b2;
  }
  // Group
  &-group {
    .questions {
      .notice-view__container {
        .notice-view__main {
          border-bottom: 0;
        }
      }
    }
    .answer {
      padding-bottom: 20px;
      border-bottom: 1px solid $gray-scale-color06;
      .notice-view__container {
        padding-right: 40px;
        padding-bottom: 20px;
        padding-left: 40px;
        background-color: $gray-scale-color08;
        border-radius: 20px;
        .notice-view__header {
          border-top: 0;
        }
        .notice-view__main {
          border-bottom: 0;
        }
      }
    }
  }
}

// Flag
.flag {
  @include text(10px, 14px, 500);
  display: inline-flex;
  padding: 3px 8px;
  color: #fff;
  border-radius: 4px;
  &--red {
    @extend .flag;
    background-color: $secondary-color02;
  }
  &--purple {
    @extend .flag;
    background-color: $graph-color;
  }
  &--grey {
    @extend .flag;
    background-color: $gray-scale-color04;
  }
  &--lv2 {
    @include b4-medium;
    display: inline-flex;
    height: 20px;
    padding: 0 10px;
    border-radius: 4px;
    line-height: 18px;
    &--red {
      @extend .flag--lv2;
      border: 1px solid $secondary-color02;
      color: $secondary-color02;
    }
    &--green {
      @extend .flag--lv2;
      border: 1px solid $secondary-color;
      color: $secondary-color;
    }
    &--purple {
      @extend .flag--lv2;
      border: 1px solid $secondary-color03;
      color: $secondary-color03;
    }
    &--grey {
      @extend .flag--lv2;
      border: 1px solid $gray-scale-color03;
      background-color: $gray-scale-color08;
      color: $gray-scale-color03;
    }
    &--white {
      @extend .flag--lv2;
      border: 1px solid $gray-scale-color03;
      background-color: #fff;
      color: $gray-scale-color03;
    }
    &--mint {
      @extend .flag--lv2;
      border: 1px solid #54cbaa;
      background-color: #fff;
      color: #54cbaa;
    }
    &--pink {
      @extend .flag--lv2;
      border: 1px solid #dc58bf;
      background-color: #fff;
      color: #dc58bf;
    }
    &--blue {
      @extend .flag--lv2;
      border: 1px solid #1596df;
      background-color: #fff;
      color: #1596df;
    }
    &--blue02 {
      @extend .flag--lv2;
      border: 1px solid $primary-color03;
      background-color: #e2f5ff;
      color: $primary-color03;
    }
    &--orange {
      @extend .flag--lv2;
      border: 1px solid #ed892c;
      background-color: #fff;
      color: #ed892c;
    }
    &--olive {
      @extend .flag--lv2;
      border: 1px solid #7f9c06;
      background-color: #fff;
      color: #7f9c06;
    }
  }
  &--lv3 {
    &--red {
      @extend .flag--lv2;
      color: $secondary-color02;
    }
    &--green {
      @extend .flag--lv2;
      color: $secondary-color;
    }
    &--purple {
      @extend .flag--lv2;
      color: $secondary-color03;
    }
    &--grey {
      @extend .flag--lv2;
      color: $gray-scale-color03;
    }
    &--white {
      @extend .flag--lv2;
      color: $gray-scale-color03;
    }
    &--mint {
      @extend .flag--lv2;
      color: #54cbaa;
    }
    &--pink {
      @extend .flag--lv2;
      color: #dc58bf;
    }
    &--blue {
      @extend .flag--lv2;
      color: #1596df;
    }
    &--blue02 {
      @extend .flag--lv2;
      color: $primary-color03;
    }
    &--orange {
      @extend .flag--lv2;
      color: #ed892c;
    }
    &--olive {
      @extend .flag--lv2;
      color: #7f9c06;
    }
  }
}

// 팝업 유틸
.popup-util {
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;
  position: relative;
  &-content {
    position: fixed;
    bottom: 30px;
  }
  .popup-up-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $secondary-color05;
  }
}

// Card
.card {
  width: 330px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  &-image {
    overflow: hidden;
    position: relative;
    width: 330px;
    height: 180px;
    border-radius: 20px;
    img {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      border-radius: 20px 20px 0 0;
    }
    .image-info-top {
      position: absolute;
      top: 16px;
      right: 16px;
      left: 16px;
      > span.image-info-right {
        float: right;
      }
      > span.limage-info-left {
        float: left;
      }
    }
    .image-info {
      display: flex;
      justify-content: space-between;
      position: absolute;
      right: 10px;
      bottom: 10px;
      left: 10px;
      > div {
        > span {
          @include b3-medium;
          display: inline-flex;
          align-items: center;
          height: 32px;
          padding: 5px 10px;
          background: rgba(0, 0, 0, 0.7);
          border-radius: 20px;
          color: #fff;
          [class^='icon'] {
            margin-right: 8px;
          }
        }
      }
      &--lv2 {
        position: absolute;
        top: 0;
        right: 0;
        > div {
          > span {
            @include b3-medium;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            min-width: 80px;
            height: 40px;
            border-radius: 0 20px;
            text-align: center;
            color: #fff;
            &.info {
              &__red {
                background-color: $secondary-color02;
              }
              &__blue {
                background-color: $primary-color03;
              }
            }
          }
        }
      }
    }
  }
  &-state {
    margin-bottom: -8px;
    > span {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
  &-tit {
    display: block;
  }
  &-info {
    margin-top: 20px;
  }
  &-util {
    > div {
      display: flex;
      & + div {
        margin-top: 6px;
      }
      > strong {
        @include b3;
        margin-right: 8px;
        color: $gray-scale-color03;
      }
      > span {
        @include b3-medium;
      }
      > ul {
        display: flex;
        flex: 1;
        > li {
          display: flex;
          align-items: center;
          & + li {
            position: relative;
            margin-left: 16px;
            padding-left: 17px;
            &:before {
              content: '';
              position: absolute;
              top: 5px;
              left: 0;
              width: 1px;
              height: 12px;
              background-color: $gray-scale-color05;
            }
          }
          > strong {
            @include b3;
            color: $gray-scale-color03;
          }
          > span {
            @include b3-medium;
          }
          > strong,
          [class^='icon'] {
            margin-right: 8px;
          }
        }
      }
    }
  }
  &-breadcrumb {
    display: flex;
    align-items: center;
    > ul {
      display: flex;
      > li {
        @include b2;
        // color: $gray-scale-color02;
        & + li {
          position: relative;
          padding-left: 26px;
          &:before {
            @include commonSprite;
            content: '';
            position: absolute;
            top: 8px;
            left: 10px;
            width: 6px;
            height: 10px;
            background-position: -227px -59px;
            pointer-events: none;
            filter: grayscale(100%) opacity(40%);
          }
        }
        // &:last-child {
        //   font-weight: 500;
        //   color: $gray-scale-color;
        // }
      }
    }
  }
  &-progress {
    display: flex;
    justify-content: space-between;
    .progress-container {
      position: relative;
      height: 16px;
      margin-top: 3px;
      .progress-content {
        overflow: hidden;
        height: 100%;
        background-color: $gray-scale-color07;
        border-radius: 4px;
      }
      .progress-current {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: $primary-color02;
        border-radius: 4px;
      }
      .progress-rate {
        @include b3-medium;
        position: absolute;
        top: -3px;
        left: calc(100% + 10px);
        white-space: nowrap;
        color: $primary-color02;
      }
    }
    .state {
      @include b3-medium;
      text-align: right;
    }
  }
  &-before-learn {
    @include b3;
    margin-top: 11px;
    padding: 5px;
    background: #f5f5f5;
    border-radius: 4px;
    text-align: center;
  }
  &-period {
    @include b3;
    display: flex;
    margin-top: 6px;
    > strong {
      margin-right: 8px;
      font-weight: 400;
      color: $gray-scale-color03;
    }
    > span {
      @include b3-medium;
    }
  }
}

// Card : Main
.card {
  .card-main {
    padding: 20px 20px 24px;
  }
}

// Card Lv2 : Knowledge+
.card--lv2 {
  width: 330px;
  .card-main {
    .card-tit {
      @include b2-medium;
      margin-top: 10px;
      .card-tit-sequence {
        display: none;
      }
    }
  }
}

.view-type01 {
  .card--lv2 {
    display: flex;
    position: relative;
    width: auto;
    padding: 40px;
    border: 1px solid $gray-scale-color06;
    border-radius: 20px;
    .card-image {
      flex: 0 0 330px;
      margin-right: 40px;
      img {
        border-radius: 20px;
      }
    }
    .card-main {
      flex: 1;
      .card-tit {
        @include h3;
        @include ellipsis-multi(1, 64px, 100%);
        .card-tit-sequence {
          display: inline-flex;
        }
        & + .card-desc {
          margin-top: 10px;
        }
      }
      .card-util {
        position: absolute;
        top: 40px;
        right: 40px;
      }
      .card-breadcrumb {
        margin-top: 20px;
        > strong {
          @include b1-medium;
          margin-right: 20px;
        }
        & + .card-tag {
          margin-top: 10px;
        }
      }
      .card-progress {
        margin-top: 30px;
        > div {
          flex: 0 0 860px;
        }
      }
      .card-tag {
        @include b2;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -20px;
        color: $gray-scale-color04;
        > li {
          margin-right: 20px;
          margin-bottom: 20px;
        }
      }
      .card-desc {
        @include b2;
        @include ellipsis-multi(1, 26px, 100%);
      }
    }
  }
  &--lv2 {
    @extend .view-type01;
    .card--lv2 {
      padding: 0;
      border: 0;
    }
  }
}

.view-type02 {
  .card--lv2 {
    .card-image {
      img {
        // border-radius: 20px;
        border-radius: 0;
      }
    }
    .card-main {
      position: relative;
      padding-top: 30px;
      padding-right: 10px;
      padding-bottom: 10px;
      padding-left: 10px;
      .card-state {
        margin-top: 10px;
      }
      .card-tit {
        @include b2-medium;
        @include ellipsis-multi(2, 26px, 100%);
        height: 52px;
        .card-tit-sequence {
          display: none;
        }
        & + .card-breadcrumb {
          margin-top: 20px;
        }
      }
      .card-util {
        margin-top: 20px;
      }
      .card-breadcrumb {
        margin-top: 6px;
        > strong {
          display: none;
        }
        > ul {
          > li {
            @include b3;
            &:last-child {
              @include b3-medium;
            }
          }
        }
      }
      .card-progress {
        position: absolute;
        top: 8px;
        right: 10px;
        left: 10px;
        > div {
          flex: 0 0 180px;
        }
      }
    }
  }
}

// 배경 컬러가 있을 경우
[data-bgColor] .view-type02 {
  .card-image {
    img {
      // border-radius: 20px 20px 0 0;
      border-radius: 0;
    }
  }
  .card--lv2 {
    height: 100%;
    .card-main {
      height: calc(100% - 180px);
      padding: 40px 10px 20px;
      background-color: #fff;
      border-radius: 0 0 20px 20px;
    }
    .card-tit,
    .card-state {
      margin-top: 0;
    }
    // .card-progress {
    //   right: 10px;
    //   left: 10px;
    //   // > div {
    //   //   flex: 0 0 180px;
    //   // }
    // }
  }
}

.post-container {
  margin-top: 40px;
  > ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
  }
  &.view-type01 {
    > ul {
      grid-template-columns: 1fr;
    }
    .no-data {
      border: 1px solid $gray-scale-color06;
      justify-content: center;
      box-shadow: none;
    }
  }
  &.view-type02 {
    > ul {
      grid-template-columns: repeat(4, 1fr);
      &.view-type-no-data {
        grid-template-columns: repeat(1, 1fr);
        .no-data {
          border: 1px solid $gray-scale-color06;
          justify-content: center;
          box-shadow: none;
        }
      }
    }
  }
  .no-data {
    @include b3;
    padding: 70px;
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    text-align: center;
  }
}

.post-swiper {
  &-ctl {
    display: flex;
    align-items: center;
    [role='button'] {
      & + [role='button'] {
        margin-left: 20px;
      }
    }
  }
  &-prev {
    [class^='icon'] {
      transform: rotate(180deg);
    }
  }
  &-prev,
  &-next {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    transform-origin: 50%;
    cursor: pointer;
    &.swiper-button-disabled {
      filter: grayscale(100%) opacity(50%);
    }
  }
}

[data-bgcolor] .card {
  // box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  &-image {
    position: relative;
    border-radius: 20px 20px 0 0;
  }
}

// Find Course
.find-course {
  padding: 24px 40px;
  border: 1px solid $gray-scale-color06;
  border-radius: 20px;
  background-color: $secondary-color07;
  > div {
    display: flex;
    justify-content: space-between;
    & + div {
      margin-top: 10px;
    }
  }
  &-sec {
    display: flex;
    align-items: center;
    > strong {
      @include b2-medium;
      display: block;
      margin-right: 30px;
    }
    .select,
    .form-input {
      width: 260px;
    }
  }
  &-button {
    margin-left: 20px;
  }
}

// Course
.course {
  &-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 40px;
    border: 1px solid $gray-scale-color06;
    border-radius: 20px;
    & + .course-item {
      margin-top: 20px;
    }
  }
  &-header {
    flex: 1;
  }
  &-main {
    flex: 0 0 400px;
    text-align: right;
    &-flex-end {
      @extend .course-main;
    }
    .course-status {
      @include b1-bold;
      width: 180px;
      margin-left: auto;
      text-align: center;
      .course-status-approval-waiting {
        @include b1-bold;
        color: $secondary-color02;
      }
      .course-status-learning {
        @include b2;
        display: flex;
        justify-content: center;
        align-items: baseline;
        height: 40px;
        color: $primary-color;
        > strong {
          @include h2;
          font-weight: 700;
        }
        > span {
          margin-left: 4px;
          font-weight: 500;
        }
      }
      .course-status-completion {
        @include h2;
        display: block;
        height: 40px;
        color: $primary-color02;
        font-weight: 700;
      }
    }
    .buttons-wrapper {
      justify-content: flex-end;
      margin-top: 10px;
      [role='button'] {
        margin-top: 0;
      }
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    margin-top: 28px;
    padding-top: 22px;
    background: url($imgPath + 'common/dotted-line.png');
    background-size: 8px auto;
    background-repeat: repeat-x;
  }
  &-footer-left {
    display: flex;
    flex: 1;
  }
  // &-footer-right {
  //   flex: 1;
  // }
  &-item-button {
    margin-top: 40px;
  }
  &-category {
    > span {
      & + span {
        margin-left: 8px;
      }
    }
  }
  &-breadcrumb {
    @include b2;
    display: flex;
    margin-top: 12px;
    > li {
      & + li {
        position: relative;
        &:before {
          content: '>';
          display: inline-flex;
          margin: 0 4px;
        }
      }
    }
  }
  &-tit {
    @include h3;
    display: block;
    margin-top: 18px;
  }
  &-period {
    @include b2;
    display: flex;
    align-items: center;
    [class^='icon'] {
      margin-right: 5px;
    }
    > span {
      margin-left: 4px;
    }
    [class^='flag'] {
      margin-left: 8px;
    }
    & + .course-review-period,
    & + .course-progress {
      margin-left: 80px;
    }
  }
  &-review-period {
    @include b2;
    display: flex;
    align-items: center;
    [class^='icon'] {
      margin-right: 5px;
    }
    > strong {
      @include b3-medium;
      margin-right: 10px;
      color: $gray-scale-color03;
    }
  }
  &-tag {
    @include b3;
    display: flex;
    flex-wrap: wrap;
    color: $gray-scale-color03;
    > span + span {
      margin-left: 20px;
    }
  }
  &-progress {
    &-container {
      display: flex;
      align-items: center;
      position: relative;
      [class^='icon'] {
        margin-right: 8px;
      }
    }
    &-content {
      overflow: hidden;
      position: relative;
      width: 160px;
      height: 16px;
      background-color: $gray-scale-color07;
      border-radius: 4px;
      .progress-current {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: $primary-color02;
        border-radius: 4px;
      }
    }
    &-rate {
      @include b2-medium;
      position: absolute;
      top: -1px;
      left: calc(100% + 10px);
      color: $primary-color02;
    }
  }
}

.section-header {
  &--line {
    @extend .section-header;
    margin-bottom: 20px;
    border-bottom: 2px solid rgba(34, 34, 34, 0.9);
  }
  &--line--star {
    @extend .section-header--line;
    display: flex;
    .star-point-container {
      margin-top: -20px;
      margin-left: 20px;
    }
  }
}

.teacher-info {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  > li {
    flex: 0 0 264px;
  }
  figure {
    img {
      border: 1px solid $gray-scale-color06;
      border-radius: 10px;
    }
    figcaption {
      margin-top: 20px;
      strong {
        @include h3;
        display: block;
        > span {
          @include b2;
          display: inline-flex;
          position: relative;
          top: -1px;
          margin-left: 5px;
        }
      }
      p {
        @include b2;
        margin-top: 10px;
        color: $gray-scale-color03;
      }
    }
  }
  &[data-view='4'] {
    grid-template-columns: repeat(4, 1fr);
  }
}

.text-and-button {
  > .text + [role='button'] {
    margin-left: 7px;
  }
}

.share-info {
  @include b3;
  display: flex;
  align-items: center;
  min-width: 310px;
  justify-content: flex-end;
  > span {
    display: inline-flex;
    align-items: center;
    > strong {
      @include b2;
      margin-right: 8px;
      color: $gray-scale-color03;
    }
    [class^='icon'] {
      margin-right: 8px;
    }
    & + span {
      position: relative;
      margin-left: 16px;
      padding-left: 17px;
      &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        width: 1px;
        height: 16px;
        background-color: $gray-scale-color05;
      }
    }
  }
}

.application-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 29px 30px 29px 40px;
  border: 1px solid $gray-scale-color06;
  background-color: $secondary-color07;
  border-radius: 20px;
  > strong {
    @include h2;
    flex: 1;
    [role='button'] {
      position: relative;
      top: -3px;
    }
  }
  .learning-util-container {
    flex: 1;
    margin-left: 40px;
    .learning-util {
      justify-content: flex-end;
    }
  }
  &--lv2 {
    padding: 29px 30px 29px 40px;
    border: 1px solid $gray-scale-color06;
    background-color: $secondary-color07;
    border-radius: 20px;
    .header {
      > strong {
        @include h2;
      }
    }
    .util {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
    }
  }
}

.application-main {
  display: flex;
  margin-top: 30px;
  figure {
    flex: 1;
    margin-right: 30px;
    img {
      border-radius: 20px;
    }
  }
  .application-info {
    flex: 0 0 640px;
    padding: 19px 39px;
    border: 1px solid $gray-scale-color06;
    border-radius: 20px;
    > div {
      display: flex;
      padding: 20px 0;
      & + div {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: 2px;
          background-image: url($imgPath + 'common/dotted-line.png');
          background-position: center;
          background-size: 8px auto;
          background-repeat: repeat-x;
        }
      }
      > strong {
        display: inline-flex;
        align-items: center;
        flex: 0 0 90px;
        margin-right: 20px;
        [class^='icon'] {
          margin-right: 5px;
        }
      }
      > div {
        flex: 1;
        font-weight: 500;
      }
      .course-tag {
        margin-bottom: -20px;
        > span {
          margin-right: 20px;
          margin-bottom: 20px;
          & + span {
            margin-left: 0;
          }
        }
      }
      .share-info {
        > span {
          @include b2-medium;
        }
      }
      [class^='flag--lv2'] {
        height: 28px;
        line-height: 27px;
      }
    }
    .sort-breadcrumb {
      display: flex;
      flex-wrap: wrap;
      font-weight: 400;
      > li {
        & + li {
          position: relative;
          padding-left: 26px;
          &:before {
            @include commonSprite;
            content: '';
            position: absolute;
            top: 6px;
            left: 10px;
            width: 6px;
            height: 10px;
            background-position: -227px -59px;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.review {
  border-top: 2px solid rgba(34, 34, 34, 0.9);
  // border-bottom: 1px solid rgba(206, 206, 206, 0.9);
  > ul {
    > li {
      padding: 20px 20px 30px;
      border-bottom: 1px solid rgba(206, 206, 206, 0.9);
      & + li {
        // border-top: 1px solid rgba(206, 206, 206, 0.9);
      }
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    > div {
      strong {
        @include b1-medium;
        display: flex;
        align-items: center;
        > span {
          @include b2;
          display: inline-flex;
          margin-left: 10px;
          color: $gray-scale-color03;
        }
      }
    }
    > strong {
      @include b1-medium;
      display: flex;
      align-items: center;
      > span {
        @include b2;
        display: inline-flex;
        margin-left: 10px;
        color: $gray-scale-color03;
      }
    }
  }
  &-main {
    @include b2;
    margin-top: 10px;
    // .form-textarea {
    //   padding-bottom: 30px;
    // }
    p {
      white-space: pre-line;
    }
    &-util {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;
      [role='button'] {
        & + [role='button'] {
          margin-left: 10px;
        }
      }
    }
  }
  @at-root {
    .form-textarea + .review {
      // margin-top: 20px;
      // border-width: 1px;
      // border-color: $gray-scale-color06;
      border-top: 0;
    }
  }
  &-register-container {
    padding-top: 20px;
    border-top: 2px solid $gray-scale-color02;
  }
}

// Star Point
.star-point-container {
  display: flex;
  align-items: center;
  .star-point {
    display: flex;
  }
  .star-point-item {
    @include commonSprite;
    width: 24px;
    height: 24px;
    background-position: -193px -101px;
    & + span {
      margin-left: 5px;
    }
  }
  .star-point-score {
    em {
      font-weight: 500;
      color: $primary-color02;
    }
  }
  .star-point-apply {
    margin-left: 10px;
  }
  // Size : sm
  &[data-size='sm'] {
    .star-point-item {
      width: 16px;
      height: 16px;
      background-position: -193px -126px;
      & + span {
        margin-left: 2px;
      }
    }
    .star-point {
      position: relative;
      top: -2px;
      &[data-star='1'] {
        > .star-point-button:nth-last-child(n + 5) .star-point-item,
        > .star-point-item:nth-last-child(n + 5) {
          background-position: -210px -126px;
        }
      }
      &[data-star='2'] {
        > .star-point-button:nth-last-child(n + 4) .star-point-item,
        > .star-point-item:nth-last-child(n + 4) {
          background-position: -210px -126px;
        }
      }
      &[data-star='3'] {
        > .star-point-button:nth-last-child(n + 3) .star-point-item,
        > .star-point-item:nth-last-child(n + 3) {
          background-position: -210px -126px;
        }
      }
      &[data-star='4'] {
        > .star-point-button:nth-last-child(n + 2) .star-point-item,
        > .star-point-item:nth-last-child(n + 2) {
          background-position: -210px -126px;
        }
      }
      &[data-star='5'] {
        > .star-point-button:nth-last-child(n + 1) .star-point-item,
        > .star-point-item:nth-last-child(n + 1) {
          background-position: -210px -126px;
        }
      }
    }
    .star-point-button + .star-point-button {
      margin-left: 5px;
    }
    .star-point-score {
      @include b2-medium;
      margin-left: 5px;
    }
  }
  // Size : md
  &[data-size='md'] {
    .star-point-item {
      width: 24px;
      height: 24px;
      background-position: -193px -101px;
      & + span {
        margin-left: 5px;
      }
    }
    .star-point {
      position: relative;
      top: -2px;
      &[data-star='1'] {
        > .star-point-button:nth-last-child(n + 5) .star-point-item,
        > .star-point-item:nth-last-child(n + 5) {
          background-position: -218px -101px;
        }
      }
      &[data-star='2'] {
        > .star-point-button:nth-last-child(n + 4) .star-point-item,
        > .star-point-item:nth-last-child(n + 4) {
          background-position: -218px -101px;
        }
      }
      &[data-star='3'] {
        > .star-point-button:nth-last-child(n + 3) .star-point-item,
        > .star-point-item:nth-last-child(n + 3) {
          background-position: -218px -101px;
        }
      }
      &[data-star='4'] {
        > .star-point-button:nth-last-child(n + 2) .star-point-item,
        > .star-point-item:nth-last-child(n + 2) {
          background-position: -218px -101px;
        }
      }
      &[data-star='5'] {
        > .star-point-button:nth-last-child(n + 1) .star-point-item,
        > .star-point-item:nth-last-child(n + 1) {
          background-position: -218px -101px;
        }
      }
    }
    .star-point-button + .star-point-button {
      margin-left: 5px;
    }
    .star-point-score {
      @include b2-medium;
      margin-left: 10px;
    }
  }
  &.align-c {
    justify-content: center;
  }
}

// Accordion
.accordion {
  > div {
    border: 1px solid $gray-scale-color06;
    border-radius: 20px;
    & + div {
      margin-top: 20px;
    }
  }
  div.accordion-button {
    > strong {
      cursor: pointer;
    }
  }
  &-button {
    display: block;
    padding: 30px;
    > strong {
      @include b1-medium;
      display: block;
      position: relative;
      padding-left: 40px;
      &:before {
        @include commonSprite;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 30px;
        height: 30px;
        background-color: $secondary-color04;
        background-position: -265px -59px;
        border-radius: 50%;
      }
    }
    &.icon-right {
      > strong {
        padding-right: 40px;
        padding-left: 0;
        &:before {
          right: 0;
          left: auto;
        }
      }
    }
    > div {
      display: none;
      margin-top: 20px;
    }
  }
  .is-active {
    .accordion-button {
      > strong {
        cursor: pointer;
        &:before {
          background-color: $primary-color03;
          background-position: -234px -59px;
        }
      }
      > div {
        display: block;
      }
    }
  }
  &-answer {
    border-top: 1px solid $gray-scale-color06;
    padding-top: 20px;
  }
}

.classification {
  display: flex;
  border: 1px solid $gray-scale-color06;
  border-radius: 20px;
  > div {
    flex: 1;
    padding-top: 8px;
    padding-bottom: 8px;
    &:nth-child(1) {
      display: flex;
      align-items: center;
      flex: 0 0 188px;
      padding: 0 40px;
    }
    &:nth-child(2),
    &:nth-child(3) {
      flex: 0 0 216px;
    }
    &:nth-child(4) {
      flex: 1;
      ul {
        li {
          a {
            &:before {
              content: '+' !important;
            }
            &.is-active {
              &:before {
                content: '-' !important;
              }
            }
          }
        }
      }
    }
    & + div {
      flex: 0 0 380px;
      padding-right: 10px;
      border-left: 1px solid $gray-scale-color07;
    }
    ul {
      li {
        a {
          display: block;
          padding-top: 12px;
          padding-bottom: 14px;
          padding-left: 20px;
          &:before {
            content: '+';
            display: inline-block;
            margin-right: 4px;
            vertical-align: top;
          }
        }
        &.is-active {
          > a {
            color: $gray-scale-color;
            background-color: #f1f6fb;
            &:before {
              content: '-';
            }
          }
        }
      }
    }
    > ul {
      @include b3;
      overflow-y: scroll;
      height: 182px;
      color: $gray-scale-color03;
      &::-webkit-scrollbar {
        width: 20px;
        height: 100px;
      }
      &::-webkit-scrollbar-track {
        background-color: $gray-scale-color07;
      }
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $gray-scale-color05;
        border-radius: 3px;
      }
      > li {
        &.is-active {
          > a {
            @include b3-medium;
          }
        }
        ul {
          display: none;
          overflow: visible;
          height: auto;
          margin-left: 20px;
          li {
            // @include b4;
            color: $gray-scale-color03;
            &.is-active {
              > a {
                // @include b4-medium;
                color: $gray-scale-color;
                background-color: #f1f6fb;
              }
            }
          }
        }
      }
    }
  }
  // ul {
  //   ul {
  //     padding: 20px;
  //     li {
  //       @include b4;
  //       color: $gray-scale-color03;
  //       > a {
  //         padding: 0;
  //       }
  //       &.is-active {
  //         > a {
  //           @include b4-medium;
  //           color: $gray-scale-color;
  //         }
  //       }
  //     }
  //   }
  // }
  &--equality {
    @extend .classification;
    > div {
      &:nth-child(1) {
        flex: 0 0 293px;
      }
      &:nth-child(2) {
        flex: 0 0 381px;
      }
      &:nth-child(3) {
        flex: 0 0 381px;
      }
      &:nth-child(4) {
        flex: 0 0 381px;
      }
    }
  }
}

.teacher-detail {
  display: flex;
  flex-wrap: wrap;
  padding: 40px;
  border: 1px solid $gray-scale-color06;
  border-radius: 20px;
  figure {
    flex: 0 0 224px;
    margin-right: 40px;
    img {
      border: 1px solid $gray-scale-color06;
      border-radius: 10px;
    }
  }
  &-main {
    display: flex;
    .teacher-detail-section {
      flex: 0 0 280px;
    }
  }
  &-footer {
    flex: 0 0 100%;
    border-top: 1px solid $gray-scale-color06;
  }
  &-section {
    > div {
      display: flex;
      padding-top: 14px;
      padding-bottom: 14px;
      border-bottom: 1px solid $gray-scale-color06;
      &.border-none {
        border-bottom: 0;
      }
      > strong {
        @include b2-medium;
        flex: 0 0 100px;
      }
      > span {
        @include b2;
        flex: 1;
      }
    }
    & + .teacher-detail-section {
      margin-left: 40px;
    }
  }
}

.underline {
  text-decoration: underline;
}

.learning-goals {
  border: 1px solid $gray-scale-color06;
  border-radius: 20px;
  > div {
    padding: 40px 40px 20px;
    & + div {
      border-top: 1px solid $gray-scale-color06;
    }
    > strong {
      @include h3;
      display: block;
      padding-bottom: 20px;
      border-bottom: 2px solid rgba(34, 34, 34, 0.9);
    }
  }
  ul {
    padding: 0 20px;
    > li {
      padding: 20px 0;
      & + li {
        border-top: 1px solid $gray-scale-color06;
      }
    }
  }
}

.page-search {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  > div {
    & + div {
      margin-left: 10px;
    }
  }
  .select {
    width: 240px;
  }
  .form-input--icon-button {
    width: 440px;
  }
}

.post-sort-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  padding: 26px 40px;
  border: 1px solid $gray-scale-color06;
  border-radius: 20px;
  > strong {
    @include h2;
  }
  .type-select {
    [role='button'] {
      & + [role='button'] {
        margin-left: 8px;
      }
    }
  }
  .form-flex {
    > li {
      & + li {
        margin-left: 16px;
      }
    }
  }
  .posting-order {
    display: flex;
    > li {
      & + li {
        position: relative;
        margin-left: 16px;
        padding-left: 17px;
        &:before {
          content: '';
          position: absolute;
          top: 5px;
          left: 0;
          width: 1px;
          height: 16px;
          background-color: $gray-scale-color07;
        }
      }
      [role='button'] {
        color: $gray-scale-color04;
        transition: color 0.11s $easing;
        &.is-active,
        &:hover {
          color: $gray-scale-color;
        }
      }
    }
  }
  .sort-view-control {
    display: flex;
    margin-left: 40px;
  }
  .sort-method {
    display: flex;
    [role='button'] {
      width: 48px;
      height: 48px;
      & + [role='button'] {
        margin-left: 8px;
      }
      &.sort-view-type01 {
        &:after {
          @include commonSprite;
          content: '';
          position: absolute;
          top: calc(50% - 12px);
          left: calc(50% - 12px);
          width: 24px;
          height: 24px;
          background-position: -210px 0;
        }
        &:hover:after,
        &.is-active:after {
          background-position: -235px 0;
        }
      }
      &.sort-view-type02 {
        &:after {
          @include commonSprite;
          content: '';
          position: absolute;
          top: calc(50% - 12px);
          left: calc(50% - 12px);
          width: 24px;
          height: 24px;
          background-position: -210px -25px;
        }
        &:hover:after,
        &.is-active:after {
          background-position: -235px -25px;
        }
      }
    }
  }
  .select {
    width: 240px;
    margin-left: 30px;
  }
}

.ctrl-buttons {
  margin-left: 10px;
}
.swiper-ctrl-container {
  display: flex;
  align-items: center;
  .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    opacity: 1;
    transition: all 0.11s $easing;
    & + .swiper-pagination-bullet {
      margin-left: 10px;
    }
  }
  .swiper-pagination-bullet-active {
    width: 60px;
    height: 8px;
    border-radius: 4px;
  }
}

[data-autoplay='true'] {
  .ctrl-buttons {
    .play-button {
      display: none;
    }
    .pause-button {
      display: inline-flex;
    }
  }
}
[data-autoplay='false'] {
  .ctrl-buttons {
    .play-button {
      display: inline-flex;
    }
    .pause-button {
      display: none;
    }
  }
}

.section-sub-main {
  overflow: hidden;
  // display: flex;
  margin-top: 50px;
  border-radius: 20px;
  &-header {
    position: absolute;
    left: 60px;
    bottom: 60px;
    z-index: $level02;
  }
  &-content {
    position: relative;
    .txt-wrap {
      position: absolute;
      top: 0;
      left: 0;
      width: 390px;
      height: 100%;
      padding: 60px;
      background-color: $primary-color02;
      > strong {
        @include text(32px, 42px, 500);
        color: #fff;
      }
      > p {
        @include b1-medium;
        margin-top: 20px;
        color: #fff;
      }
    }
    .img-wrap {
      overflow: hidden;
      position: relative;
      width: 1050px;
      height: 490px;
      margin-left: 390px;
      // > img {
      //   position: absolute;
      //   top:50%;
      //   right: 0;
      //   bottom: 0;
      //   left:0;
      //   width: 100%;
      //   transform: translateY(-50%);
      // }
    }
  }
}

.swiper-ctrl-container {
  .swiper-pagination {
    position: relative;
    top: auto;
    left: auto;
  }

  &[data-theme='white'] {
    .swiper-pagination-bullet {
      background-color: #fff;
    }
  }
}

// Page Bottom Buttons Wrapper
.page-bottom-buttons-wrapper {
  @include clfix;
  padding-top: 40px;
  border-top: 2px solid rgba(34, 34, 34, 0.9);
  .buttons-left {
    float: left;
  }
  .buttons-right {
    float: right;
  }
  [role='button'] + [role='button'] {
    margin-left: 10px;
  }
  .buttons-center {
    text-align: center;
  }
  &.border-none {
    border-top: 0;
  }
}

.application-util {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
  > li {
    // display: inline-flex;
    // align-items: center;

    border: 1px solid $gray-scale-color06;
    [class^='icon'] {
      position: relative;
      top: -1px;
      margin-right: 2px;
      vertical-align: middle;
    }
  }
  &[data-size='sm'] {
    @include b4-medium;
    line-height: 32px;
    > li {
      height: 32px;
      margin-right: 5px;
      margin-bottom: 5px;
      padding: 0 9px;
      border-radius: 6px;
    }
  }
  &[data-size='md'] {
    @include b2-medium;
    line-height: 46px;
    > li {
      height: 46px;
      margin-right: 5px;
      margin-bottom: 10px;
      padding: 0 6px;
      border-radius: 10px;
    }
  }
}

// lookup-period
.lookup-period {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24px 100px 24px 40px;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 20px;
  &__tit {
    display: inline-block;
    margin-right: 20px;
  }
  &__list {
    display: flex;
    align-items: center;
    grid-gap: 32px;
    margin-right: 10px;
  }
  .lookup-period--button {
    position: absolute;
    top: 24px;
    right: 40px;
    border-radius: 24px;
  }
}
.select-calendar {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  em {
    display: inline-block;
    margin: 0 15px;
    font-weight: 400;
  }
}

.edu-card {
  margin-top: 40px;
  &-item {
    padding: 30px;
    border: 1px solid $gray-scale-color06;
    border-radius: 20px;
    & + .edu-card-item {
      margin-top: 20px;
    }
    &--lv2 {
      @extend .edu-card-item;
      position: relative;
      min-height: 269px;
      // padding-left: 444px;
      padding-left: 460px;
      .card-image {
        position: absolute;
        top: 30px;
        left: 30px;
        // width: 374px;
        // height: 204px;
        width: 400px;
        height: 218px;
        img {
          width: 100%;
        }
      }
      .edu-card-tit {
        @include ellipsis-multi(2, 32px, 100%);
        height: 64px;
      }
      .card-desc {
        @include ellipsis-multi(1, 22px, calc(100% - 210px));
        // @include ellipsis(100%);
      }
      .edu-card-section {
        & + .edu-card-section {
          margin-top: 20px;
        }
      }
      .edu-card-footer {
        flex-direction: column;
        justify-content: flex-start;
        > div {
          & + div {
            justify-content: flex-start;
            margin-top: 10px;
          }
        }
      }
    }
  }
  &-section {
    display: flex;
    > div {
      display: flex;
      align-items: center;
      flex: auto;
      & + div {
        flex: 1;
        justify-content: flex-end;
        margin-left: 40px;
      }
      > div {
        & + div {
          margin-left: 60px;
        }
      }
    }
    & + .edu-card-section {
      margin-top: 10px;
    }
    &--line {
      @extend .edu-card-section;
      position: relative;
      margin-top: 20px;
      padding-top: 22px;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 2px;
        background-image: url($imgPath + 'common/dotted-line.png');
        background-position: center;
        background-size: 8px auto;
        background-repeat: repeat-x;
      }
    }
    &.no-data {
      justify-content: center;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 20px;
    padding-top: 22px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 2px;
      background-image: url($imgPath + 'common/dotted-line.png');
      background-position: center;
      background-size: 8px auto;
      background-repeat: repeat-x;
    }
    > div {
      display: flex;
      > div {
        // & + div {
        //   margin-left: 60px;
        // }
      }
      & + div {
        justify-content: flex-end;
      }
    }
    .edu-card-progress-container {
      padding-right: 0;
    }
    .edu-card-progress-rate {
      position: static;
      margin-left: 10px;
    }
    .edu-card-organizer {
      & + .edu-card-person,
      & + .edu-card-place {
        margin-left: 40px;
      }
    }
    .edu-card-person {
      & + .edu-card-organizer,
      & + .edu-card-place {
        margin-left: 40px;
      }
    }
    .edu-card-place {
      & + .edu-card-organizer,
      & + .edu-card-person {
        margin-left: 40px;
      }
    }
  }
  &-tit {
    @include h3;
    display: block;
    align-self: center;
  }
  .form-selector {
    input[type='checkbox'] + label:before {
      top: 5px;
    }
    & + .flag-wrapper {
      margin-left: 12px;
    }
  }
  .flag-wrapper {
    align-self: center;
  }
  &--lv2 {
    @extend .edu-card;
    .edu-card-item {
      padding: 30px 40px;
    }
  }
}

.flag-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  // [class^='flag'] {
  //   & + [class^='flag'] {
  //     margin-left: 8px;
  //   }
  // }
  & + .edu-card-tit {
    margin-top: 10px;
  }
  & + div {
    > .edu-card-tit {
      margin-left: 20px;
    }
  }
}

.edu-card-date {
  > strong {
    @include b2-medium;
    color: $primary-color;
  }
  .edu-card-date-day {
    @include b3-medium;
    margin-left: 10px;
    padding: 1px 10px;
    background-color: $secondary-color07;
    border-radius: 100px;
  }
}

.edu-card-period {
  display: flex;
  align-items: center;
  [class^='edu-card-period'] + [class^='flag'] {
    margin-left: 10px;
  }
  &-desc {
    @include b2-medium;
    margin-left: 5px;
  }
  &-remaining {
    margin-left: 4px;
    color: $gray-scale-color03;
  }
  .edu-card-period-main {
    @include b2;
    margin-left: 20px;
  }
  & + .edu-card-period,
  & + .edu-card-department,
  & + .edu-card-review-period,
  & + .edu-card-progress {
    margin-left: 40px;
  }
}

.edu-card-tag {
  display: flex;
  > span {
    @include b3;
    color: $gray-scale-color03;
    & + span {
      margin-left: 20px;
    }
  }
}

.edu-card-breadcrumb {
  @include b2;
  display: flex;
  margin-top: 10px;
  > li {
    & + li {
      position: relative;
      &:before {
        content: '>';
        display: inline-flex;
        margin: 0 4px;
        color: $gray-scale-color05;
      }
    }
  }
}

.edu-card-progress {
  & + .edu-card-progress {
    margin-left: 40px;
  }
  &-container {
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 50px;
  }
  &-content {
    overflow: hidden;
    position: relative;
    width: 160px;
    height: 16px;
    margin-left: 20px;
    background-color: $gray-scale-color07;
    border-radius: 4px;
    .progress-current {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: $primary-color02;
      border-radius: 4px;
    }
  }
  &-desc {
    @include b2-medium;
    margin-left: 5px;
  }
  &-rate {
    @include b2-medium;
    position: absolute;
    top: 0;
    left: calc(100% - 40px);
    // right: 0;
    color: $primary-color02;
  }
  // Theme
  &-container[data-theme='blue'] {
    .progress-current {
      background-color: $primary-color02;
    }
    .edu-card-progress-rate {
      color: $primary-color02;
    }
  }
  &-container[data-theme='grey'] {
    .progress-current {
      background-color: #939393;
    }
    .edu-card-progress-rate {
      color: $gray-scale-color03;
    }
  }
}

.edu-card-review-period {
  @include b2;
  display: flex;
  align-items: center;
  [class^='icon'] {
    margin-right: 5px;
  }
  > strong {
    @include b3-medium;
    margin-right: 10px;
    color: $gray-scale-color03;
  }
}
.edu-card-organizer {
  &-content {
    display: flex;
    align-items: center;
  }
  &-desc {
    @include b2-medium;
    margin-left: 5px;
  }
  &-data {
    @include b2-medium;
    margin-left: 20px;
    color: $primary-color;
  }
}
.edu-card-person {
  &-content {
    display: flex;
    align-items: center;
  }
  &-desc {
    @include b2-medium;
    margin-left: 5px;
  }
  &-data {
    @include b2-medium;
    margin-left: 20px;
    color: $primary-color;
  }
}
.edu-card-place {
  &-content {
    display: flex;
    align-items: center;
  }
  &-desc {
    @include b2-medium;
    margin-left: 5px;
  }
  &-data {
    @include b2-medium;
    margin-left: 20px;
    color: $primary-color;
  }
  &-util {
    margin-left: 10px;
  }
}
.status-learning {
  @include b1-bold;
  display: block;
  width: 180px;
  // margin-bottom: 10px;
  text-align: center;
  color: $primary-color;
  > strong {
    @include b1-bold;
    position: relative;
    top: 0;
    color: $primary-color03;
    // font-weight: 700;
  }
}
.status-completion {
  @include b1-bold;
  display: block;
  width: 180px;
  // margin-bottom: 10px;
  text-align: center;
  color: $primary-color;
}
.status-order-can-change {
  @include b1-bold;
  display: block;
  width: 180px;
  // margin-bottom: 10px;
  text-align: center;
  color: $primary-color;
}
.status-approval-waiting {
  @include b1-bold;
  display: block;
  width: 180px;
  // margin-bottom: 10px;
  text-align: center;
  color: $primary-color;
}

.edu-card-department {
  display: flex;
  align-items: center;
  [class^='icon'] {
    margin-right: 5px;
  }
}

.order-schedule {
  display: inline-flex;
  align-items: center;
  &-container {
    > div {
      & + div {
        margin-top: 12px;
      }
    }
  }
  > span {
    display: flex;
    align-items: center;
    @include b2-medium;
    & + span {
      @include b2;
      margin-left: 10px;
    }
  }
  [class^='icon'] {
    margin-right: 5px;
  }
}

.modal-buttons {
  [role='button'] {
    & + [role='button'] {
      margin-left: 10px;
    }
  }
}

.overflow-y {
  &-wrapper {
    padding: 19px 10px 19px 30px;
    border: 1px solid $gray-scale-color06;
    border-radius: 20px;
    &--lv2 {
      padding: 0;
    }
  }
  &-content {
    overflow: hidden;
    overflow-y: scroll;
    height: 158px;
    padding-right: 20px;
  }
}

.changeable-order-list {
  display: flex;
  > div {
    flex: 1;
    border: 1px solid $gray-scale-color06;
    border-radius: 20px;
    & + div {
      position: relative;
      margin-left: 120px;
      &:before {
        @include commonSprite;
        content: '';
        position: absolute;
        top: 114px;
        left: -72px;
        width: 24px;
        height: 44px;
        background-position: -260px 0;
        pointer-events: none;
      }
    }
    > strong {
      @include h3;
      display: block;
      padding: 19px 40px;
      background-color: $secondary-color07;
      border-radius: 20px 20px 0 0;
    }
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 176px;
    }
    .overflow-y {
      &-wrapper {
        width: 100%;
        padding: 24px 40px;
        border: 0;
        border-radius: 0;
      }
      &-content {
        height: 128px;
        padding-right: 30px;
      }
    }
    .order-schedule-container {
      > div {
        display: flex;
        justify-content: space-between;
        height: 32px;
      }
    }
  }
}

.desired-change-index {
  display: flex;
  justify-content: space-between;
  > div {
    > strong {
      @include b2-medium;
    }
    .select {
      margin-left: 20px;
    }
  }
}

.learning-util {
  display: flex;
  align-items: center;
  .learning-status {
    display: flex;
    > span {
      @include b3-medium;
      display: flex;
      align-items: center;
      [class^='icon'] {
        margin-right: 8px;
      }
      & + span {
        position: relative;
        margin-left: 10px;
        padding-left: 11px;
        &:before {
          content: '';
          position: absolute;
          top: 4px;
          left: 0;
          width: 1px;
          height: 14px;
          background-color: $gray-scale-color05;
        }
      }
    }
  }
  .buttons-wrapper {
    margin-left: 20px;
    [role='button'] {
      @include b3-medium;
      position: relative;
      &:before {
        @include commonSprite;
        content: '';
        display: inline-flex;
        margin-right: 4px;
        width: 16px;
        height: 16px;
      }
      &.scrap-button {
        &:before {
          background-position: 0 -143px;
        }
        &.is-active,
        &:hover {
          border-color: $secondary-color05;
          background-color: $secondary-color05;
          color: #fff;
          &:before {
            background-position: 0 -160px;
          }
        }
      }
      &.recomm-button {
        &:before {
          background-position: -17px -143px;
        }
        &.is-active,
        &:hover {
          border-color: $secondary-color02;
          background-color: $secondary-color02;
          color: #fff;
          &:before {
            background-position: -17px -160px;
          }
        }
      }
      &.share-button {
        &:before {
          background-position: -34px -143px;
        }
        &.is-active,
        &:hover {
          border-color: $primary-color03;
          background-color: $primary-color03;
          color: #fff;
          &:before {
            background-position: -34px -160px;
          }
        }
      }
    }
  }
}

.is-temp {
  position: relative;
  padding-top: 50%;
  background-color: $gray-scale-color02;
  &:before {
    content: '임시 영역 (개발 시 .is-temp 삭제)';
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
  }
}

.form-address {
  > div {
    display: flex;
    align-items: center;
    & + div {
      margin-top: 8px;
    }
    > span {
      margin-left: 10px;
    }
    > .form-selector {
      margin-left: 20px;
    }
    > .form-input {
      & + .form-input {
        margin-left: 10px;
      }
    }
  }
  .address-desc {
    @include b3;
    display: block;
    margin-top: 16px;
  }
}

.form-search-container {
  padding: 24px 40px;
  border: 1px solid $gray-scale-color06;
  background-color: $secondary-color07;
  border-radius: 20px;
  > div {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 48px;
    padding-right: 120px;
    & + div {
      margin-top: 10px;
    }
    > strong {
      @include b1-medium;
      align-self: flex-start;
      // flex: 0 0 82px;
      margin-right: 40px;
      padding-top: 9px;
    }
    .form-search-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex: 1;
      margin-bottom: -10px;
      > div {
        display: flex;
        align-items: center;
        margin-right: 60px;
        margin-bottom: 10px;
        &.flex--full {
          flex: 1;
        }
        > strong {
          @include b2-medium;
          display: block;
          margin-right: 20px;
        }
      }
      &--flex {
        > div {
          > strong {
            @include b2-medium;
            display: block;
          }
        }
      }
      &--grid {
        flex: 1;
        > div {
          display: flex;
          align-items: center;
          > strong {
            @include b2-medium;
            display: block;
            white-space: nowrap;
          }
        }
      }
    }
    > [role='button'] {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &.border-none {
    padding: 0;
    background-color: transparent;
    border: 0;
  }
}

.form-flex {
  display: flex;
  > div {
    display: flex;
    align-items: center;
    > strong {
      @include b2-medium;
      display: block;
      margin-right: 20px;
    }
    & + div {
      margin-left: 10px;
    }
  }
  .form-col {
    flex: 1;
    display: block;
  }
  &--align-c {
    @extend .form-flex;
    align-items: center;
  }
  &--jc-between {
    @extend .form-flex;
    justify-content: space-between;
  }
  &--inline {
    @extend .form-flex;
    display: inline-flex;
  }
}

.form-nameset {
  display: inline-block;
  .form-input-name {
    vertical-align: middle;
    & + .form-input-content {
      margin-left: 20px;
    }
  }
  .form-input-content {
    display: inline-flex;
    vertical-align: middle;
    & + .form-input-name {
      margin-left: 10px;
    }
  }
  &.form-input .form-input-content,
  .form-input-content {
    display: inline-flex;
    vertical-align: middle;
  }
  .form-input--datepicker + .form-input-name {
    margin-left: 10px;
  }
  & + .form-nameset {
    margin-left: 40px;
  }
  &.form-input--datepicker .form-input-content {
    display: inline-flex;
  }
}

.video-content {
  .video-area {
    overflow: hidden;
    position: relative;
    height: 810px;
    &--temp {
      @extend .video-area;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
      &:before {
        content: '플레이어 위치';
        color: #fff;
      }
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .video-info {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $gray-scale-color06;
    > div {
      padding: 20px;
      & + div {
        position: relative;
        padding-bottom: 0;
        &:before {
          content: '';
          position: absolute;
          top: -1px;
          right: 0;
          left: 0;
          height: 2px;
          background-image: url($imgPath + 'common/dotted-line.png');
          background-position: center;
          background-size: 8px auto;
          background-repeat: repeat-x;
        }
      }
    }
    &-header {
      > strong {
        @include h2;
        @include ellipsis-multi(2, 76px, 100%);
      }
    }
    &-summary {
      > strong {
        @include h3;
        display: block;
      }
      .util {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
      }
      .script-button {
        .script-close-button {
          display: none;
          [class^='icon'] {
            transform: rotateX(180deg);
          }
        }
        &.is-active {
          .script-open-button {
            display: none;
          }
          .script-close-button {
            display: inline-flex;
          }
        }
      }
      .script-content {
        @include b2;
        display: none;
        margin-top: 20px;
        padding: 30px 45px 30px 30px;
        background-color: #f8f8f8;
        color: $gray-scale-color03;
        &.is-active {
          display: block;
        }
      }
    }
    &-buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
    &-details {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
    }
  }
  .card-breadcrumb {
    > strong {
      @include h3;
      display: block;
      margin-right: 20px;
    }
  }
}

.board-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 40px;
  padding-bottom: 20px;
  > div {
    display: flex;
    align-items: baseline;
  }
  .board-header-tit {
    @include h2;
    padding-bottom: 0;
    & + .board-header-desc {
      margin-top: 10px;
    }
    &.h3 {
      @include h3;
    }
  }
  .board-header-subtit {
    @include h3;
  }
  &-desc {
    @include b2;
    i[class^='icon'] {
      position: relative;
      top: -2px;
      margin: 0 4px;
      vertical-align: middle;
    }
    &[data-prefix-bullet] {
      position: relative;
      margin-left: 10px;
      padding-left: 14px;
      &:before {
        content: '\2022';
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  &-case {
    @include b2;
    position: relative;
    top: -2px;
    margin-left: 8px;
    > strong {
      font-weight: 500;
    }
  }
  &--align-c {
    @extend .board-header;
    align-items: center;
  }
  &-notice {
    .info-msg {
      @include b2-medium;
      &[data-prefix] {
        padding-left: 20px;
      }
    }
  }
  &--align-r {
    @extend .board-header;
    justify-content: flex-end;
  }
}

.tab-header {
  position: relative;
  > .buttons-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    z-index: $level01;
  }
}

.info-msg {
  @include b3;
  display: flex;
  margin-top: 10px;
  color: $gray-scale-color02;
  &[data-prefix] {
    position: relative;
    padding-left: 17px;
    &:before {
      position: absolute;
      top: 0;
      left: 2px;
    }
  }
  &:before {
    content: attr(data-prefix);
  }
  &--md {
    @extend .info-msg;
    @include b2;
    margin-top: 0;
    &[data-prefix] {
      display: flex;
      padding-left: 0;
      &:before {
        position: relative;
        margin-left: 4px;
        margin-right: 10px;
      }
    }
  }
  &-box {
    padding: 20px 30px;
    border-radius: 20px;
    background-color: $secondary-color07;
    .info-msg {
      margin-top: 0;
    }
    &.border {
      border: 1px solid $gray-scale-color06;
    }
  }
}

.survey-summary {
  &-list {
    padding: 30px 40px;
    border: 1px solid $gray-scale-color06;
    border-radius: 20px;
    li {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      & + li {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid $gray-scale-color07;
      }
    }
    &__tit {
      flex: 0 0 140px;
      font-weight: 500;
    }
    &__detail {
      flex: 1;
    }
  }
}

.dev-result {
  display: flex;
  > div {
    & + div {
      flex: 1;
    }
    > strong {
      @include b3;
      margin-right: 10px;
      padding: 5px 20px;
      background-color: $secondary-color07;
      border-radius: 20px;
      color: $primary-color02;
    }
    > span {
      flex: 1;
    }
  }
}

.ellipsis {
  @include ellipsis(100%);
  vertical-align: top;
}

.user-settings {
  text-align: center;
  li {
    & + li {
      margin-top: 20px;
    }
    &.is-active {
      [role='button'] {
        font-weight: 500;
        color: $primary-color02;
        pointer-events: none;
      }
    }
  }
  [role='button'] {
    @include b2;
    &:hover {
      font-weight: 500;
      color: $primary-color02;
    }
  }
}

// 결재라인 추가
.sign-line {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 10px;
  &--inline {
    @extend .sign-line;
    display: inline-flex;
  }
  li {
    & + li {
      &:before {
        content: '';
        display: inline-block;
        width: 9px;
        height: 9px;
        margin-right: 12px;
        vertical-align: middle;
        border: 1px solid $gray-scale-color05;
        border-left: 0;
        border-bottom: 0;
        transform: rotate(45deg);
      }
    }
  }
}

.auto-logout {
  > p {
    text-align: center;
    & + p {
      margin-top: 20px;
    }
  }
  .time-remaining {
    font-weight: 500;
  }
}

.profile {
  display: flex;
  padding: 40px;
  border: 1px solid $gray-scale-color06;
  background-color: $secondary-color07;
  border-radius: 20px;
  &-container {
    display: grid;
    row-gap: 20px;
  }
  &-header {
    flex: 0 0 380px;
    margin-right: 20px;
    background-color: #fff;
    border-radius: 20px;
    figure {
      img {
        width: 100%;
        height: 236px;
        border-radius: 20px 20px 0 0;
      }
    }
    figcaption {
      padding: 26px 20px;
      .profile-name {
        display: flex;
        justify-content: center;
        align-items: center;
        > strong {
          @include h2;
        }
        > span {
          @include b2;
          margin-left: 10px;
        }
      }
      .profile-desc {
        margin-top: 4px;
        text-align: center;
      }
    }
  }
  &-main {
    flex: 1;
  }
  &-contact {
    display: flex;
    padding: 10px 0;
    background-color: #fff;
    border-radius: 20px;
    > div {
      flex: 1;
      padding: 0 40px;
      & + div {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 7px;
          left: 0;
          width: 1px;
          height: 12px;
          background-color: $gray-scale-color05;
        }
      }
    }
    & + .profile-content {
      margin-top: 20px;
    }
  }
  &-content {
    min-height: 290px;
    padding: 10px 40px;
    background-color: #fff;
    border-radius: 20px;
    > div {
      padding-top: 20px;
      padding-bottom: 20px;
      & + div {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: -1px;
          right: 0;
          left: 0;
          height: 2px;
          background-image: url($imgPath + 'common/dotted-line.png');
          background-position: center;
          background-size: 8px auto;
          background-repeat: repeat-x;
        }
      }
    }
  }
  &-info {
    display: flex;
    align-items: center;
    [class^='icon'] {
      margin-right: 5px;
    }
    .profile-info-tit {
      @include b2-medium;
      display: flex;
      flex: 0 0 118px;
      padding-right: 10px;
      &-align-c {
        @extend .profile-info-tit;
        align-self: baseline;
      }
    }
    .profile-info-desc {
      @include b2;
      color: $gray-scale-color02;
    }
    .profile-breadcrumb {
      display: flex;
      & + .profile-breadcrumb {
        margin-top: 10px;
      }
      > li {
        & + li {
          position: relative;
          padding-left: 26px;
          &:before {
            @include commonSprite;
            content: '';
            position: absolute;
            top: 8px;
            left: 10px;
            width: 6px;
            height: 10px;
            background-position: -227px -59px;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

// 요약 카드 목록
.summary-card {
  &-list {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 40px;
    &[data-columns='5'] {
      grid-template-columns: repeat(5, auto);
    }
  }
  &-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 256px;
    padding: 24px 40px 25px;
    border: 1px solid $gray-scale-color06;
    border-radius: 20px;
    &.complete {
      background-color: $secondary-color07;
      .summary-card-info em {
        color: $primary-color02;
      }
    }
  }
  &-info {
    font-size: 20px;
    line-height: 1.6;
    font-weight: 500;
    color: $gray-scale-color04;
    em {
      display: inline-block;
      margin-right: 4px;
      font-size: 32px;
      line-height: 1.3;
      color: $gray-scale-color;
    }
  }
}

// 간단한 정보
.bo-info {
  padding: 24px 40px 14px 40px;
  border-radius: 20px;
  background-color: $secondary-color07;
  border: 1px solid $gray-scale-color06;
  &-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &-item {
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
    @include b2;
    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      content: '\00B7';
      display: block;
      font-size: 30px;
      line-height: 1;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 1px;
      height: 20px;
      margin: 0 40px;
      background-color: $gray-scale-color05;
      vertical-align: text-bottom;
    }
    &:last-child:after {
      display: none;
    }
    &.border-none:after {
      display: none;
    }
  }
  &-tit {
    display: inline-block;
    margin-right: 20px;
    @include b2-medium;
  }
}

.text-book {
  display: flex;
  padding: 30px;
  border: 1px solid $gray-scale-color06;
  border-radius: 20px;
  &-img {
    flex: 0 0 160px;
    img {
      border: 1px solid $gray-scale-color06;
      border-radius: 10px;
      // box-shadow: 0 0 0 1px $gray-scale-color06;
    }
  }
  &-content {
    flex: 1;
    margin-left: 40px;
    > div {
      margin-top: 10px;
      padding-bottom: 10px;
      & + div {
        margin-top: 20px;
      }
      > strong {
        @include b1-medium;
        display: block;
      }
      .sign-info {
        margin-top: 10px;
      }
      .sign-line {
        margin-top: 10px;
      }
    }
  }
}

.level-test-schedule {
  padding: 30px;
  border: 1px solid $gray-scale-color06;
  border-radius: 20px;
  h3 {
    @include text(20px, 32px, 500);
    padding-bottom: 20px;
  }
  &-content {
    display: flex;
    .table {
      flex: 1;
    }
  }
  &-notice {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 0 0 430px;
    margin-left: 10px;
    padding: 30px 40px 40px 30px;
    border-left: 1px solid $gray-scale-color06;
    background-color: $gray-scale-color08;
    [role='button'] {
      align-self: flex-end;
    }
  }
}

.round-square-box {
  padding: 30px 40px;
  background-color: $secondary-color07;
  border-radius: 20px;
  p {
    @include b2;
    em {
      @include b2-medium;
    }
  }
  & + .form-selector {
    margin-top: 20px;
  }
}

.tb-ct-pg {
  display: flex;
  padding: 30px 40px;
  border: 1px solid $gray-scale-color06;
  border-radius: 20px;
  &-container {
    &-tit {
      @include b1-medium;
      display: block;
      padding-bottom: 20px;
    }
    & + .tb-ct-pg-container {
      margin-top: 40px;
    }
  }
  &-wrapper {
    display: grid;
    row-gap: 20px;
  }
  &-status {
    @include b2-medium;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 60px;
    height: 60px;
    background-color: $gray-scale-color05;
    border-radius: 10px;
    color: #fff;
    &--inactive {
      @extend .tb-ct-pg-status;
      background-color: $secondary-color04;
    }
    &--active {
      @extend .tb-ct-pg-status;
      background-color: $primary-color03;
    }
  }
  > div {
    &.tb-ct-pg-status-wrapper {
      align-self: center;
      flex: 0 0 60px;
    }
    &.tb-ct-pg-content-wrapper {
      flex: 1;
      margin-left: 20px;
      > div {
        & + div {
          margin-top: 10px;
        }
      }
    }
    &.tb-ct-pg-buttons-wrapper {
      flex: 0 0 auto;
      align-self: center;
      margin-left: 20px;
      .tb-ct-pg-status-text {
        @include b2-medium;
      }
    }
  }
  &-tit {
    @include h3;
    @include ellipsis-multi(1, 32px, 100%);
  }
  .edu-card-progress {
    display: flex;
    align-items: center;
  }
  .edu-card-period-main {
    font-weight: 400;
  }
}

.l-w-status {
  display: none;
  &:checked ~ .l-w-container {
    .l-w {
      .l-w-left {
        margin-left: 0;
        iframe {
          width: 100%;
        }
      }
      .category-menu-open {
        display: none;
      }
      .category-menu-close {
        display: block;
      }
    }
  }
}

.l-w {
  display: flex;
  height: 100%;
  &-container {
    overflow: hidden;
    height: 100vh;
    min-height: 500px;
  }
  &-left {
    flex: 0 0 320px;
    position: relative;
    margin-left: -280px;
    background-color: $secondary-color07;
    transition: margin-left 0.55s $easing;
  }
  &-right {
    flex: 1;
    position: relative;
    background-color: #00061f;
    .learning-play-button {
      position: absolute;
      top: calc(50% - 40px);
      left: calc(50% - 40px);
    }
  }
}

.category {
  padding: 20px;
  border: 1px solid $gray-scale-color06;
  background-color: #fff;
  border-radius: 20px;
  &.is-active {
    ul {
      display: flex;
    }
    .category-button {
      @include b2-medium;
      &:before {
        background-position: -265px -126px;
      }
    }
  }
  &-area {
    overflow-y: auto;
    position: relative;
    padding: 20px 40px 20px 20px;
  }
  &-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &-button {
    @include b3-medium;
    display: inline-flex;
    position: relative;
    // height: 26px;
    padding-left: 34px;
    line-height: 26px;
    &:before {
      @include commonSprite;
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      width: 24px;
      height: 24px;
      background-position: -265px -101px;
    }
  }
  &-menu-container {
    position: absolute;
    top: 37px;
    right: -17px;
    transform: rotate(-90deg);
    label {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  &-menu-open,
  &-menu-close {
    @include b4;
    display: none;
    padding: 5px 10px 6px;
    background-color: $primary-color02;
    border-radius: 10px 10px 0 0;
    color: #fff;
  }
  &-menu-open {
    display: block;
  }
  ul {
    @include clfix;
    display: none;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    a {
      @include b3;
      position: relative;
      float: left;
      padding-left: 25px;
      &:before {
        @include commonSprite;
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        width: 20px;
        height: 20px;
        background-position: -244px -101px;
      }
    }
    .is-active {
      a {
        color: $primary-color02;
        &:before {
          background-position: -244px -122px;
        }
      }
    }
  }
}

.recipient {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
  > li {
    @include b2-medium;
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 9px 20px;
    border: 1px solid $gray-scale-color05;
    border-radius: 24px;
    color: $gray-scale-color03;
  }
  &-user {
    display: flex;
  }
  &-delete-button {
    position: relative;
    width: 14px;
    height: 14px;
    margin-left: 8px;
    > span {
      @include iconCancel($size: 14px, $thick: 2px, $color: #d9d9d9, $padding: 0, $display: inline-block, $position: relative);
    }
  }
}

.slide-ctrl {
  &-container {
    display: flex;
    align-items: center;
  }
  &-pagination {
    @include b3-medium;
    margin: 0 10px;
  }
  &-button-prev,
  &-button-next {
    width: 16px;
    height: 16px;
    i[class^='icon'] {
      vertical-align: top;
    }
    &.swiper-button-disabled {
      filter: grayscale(100%) opacity(50%);
    }
  }
  &-button-prev {
    i[class^='icon'] {
      transform: rotate(180deg);
    }
  }
}

.faq-swiper {
  overflow: visible;
  &-container {
    overflow: hidden;
    position: relative;
    padding: 0 55px;
    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: $level02;
      top: 0;
      width: 70px;
      height: 40px;
      background: linear-gradient(-270deg, #fff 55%, rgba(255, 255, 255, 0) 91.2%), transparent;
      pointer-events: none;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
      background: linear-gradient(270deg, #fff 50%, rgba(255, 255, 255, 0) 91.2%), transparent;
    }
  }
  &-button-prev,
  &-button-next {
    position: absolute;
    top: 6px;
    z-index: $level03;
    width: 15px;
    height: 27px;
    &.swiper-button-disabled {
      filter: grayscale(100%) opacity(50%);
    }
  }
  &-button-prev {
    @include iconBullet('left', 15px, 27px, 2px, #222, inline-block, absolute);
    left: 0;
  }
  &-button-next {
    @include iconBullet('right', 15px, 27px, 2px, #222, inline-block, absolute);
    right: 0;
  }
  .swiper-slide {
    width: auto;
    padding: 0 5px;
  }
  [aria-selected='true'],
  [role='tab']:hover {
    background-color: $primary-color03;
    border-color: $primary-color03;
    color: #fff;
  }
}

.form-time em {
  display: inline-block;
  margin: 0 15px;
}

.help {
  > div {
    & + div {
      margin-top: 20px;
    }
    > p {
      margin-top: 8px;
    }
  }
  .round-square-box {
    > ul {
      margin-top: 8px;
    }
  }
}

.process {
  &-step {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-item {
    flex: 1;
    &.is-checked {
      .process-bar {
        &:before {
          background-image: none;
          background-color: $primary-color03;
          border-radius: 50%;
        }
        &:after {
          @include commonSprite;
          content: '';
          position: absolute;
          top: 9px;
          left: 7px;
          z-index: $level01;
          width: 16px;
          height: 12px;
          background-position: -296px -59px;
        }
      }
      .process-bar:before {
        // @include commonSprite;
        // background-color: transparent;
        // background-position: 0 -226px;
        // border-radius: 0;
      }
      .bar {
        background-color: $primary-color03;
      }
    }
  }
  &-bar {
    position: relative;
    width: 100%;
    height: 30px;
    &:before {
      @include commonSprite;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: $level01;
      width: 30px;
      height: 30px;
      background-position: 0 -226px;
      border-radius: 0;
    }
    .bar {
      position: relative;
      top: calc(50% - 2px);
      width: 100%;
      height: 4px;
      // background-color: $gray-scale-color06;
      background-color: $primary-color03;
      color: transparent;
      font-size: 0;
    }
  }
  &-info {
    margin-top: 10px;
    .date {
      color: $gray-scale-color03;
      font-size: 14px;
      line-height: 1.6;
    }
  }
}

.search-filter {
  display: flex;
  justify-content: space-between;

  &-fixed-container {
    position: relative;
    margin-top: 10px;
    height: 158px;
    &.is-fixed {
      .search-filter-fixed-content {
        position: fixed;
        top: 0;
        z-index: $level08;
        width: $min-width;
      }
    }
  }
  &-fixed-content {
    padding: 30px 0;
    background-color: #fff;
  }
  > div {
    padding-right: 0;
    & + div {
      margin-top: 0;
      margin-left: 0;
    }
    > strong {
      margin-right: 20px;
    }
  }
}

.result-filter {
  display: flex;
  > li {
    & + li {
      position: relative;
      margin-left: 16px;
      padding-left: 17px;
      &:before {
        content: '';
        position: absolute;
        top: 7px;
        left: 0;
        width: 1px;
        height: 16px;
        background-color: $gray-scale-color07;
      }
    }
    [role='button'] {
      @include b2;
      color: $gray-scale-color04;
      &.is-active {
        @include b2-medium;
        color: $gray-scale-color02;
      }
    }
  }
}

.search-result {
  &-type {
    @include b1-medium;
    padding: 11px 40px;
    background-color: $primary-color;
    border-radius: 24px;
    color: #fff;
  }
  &-container {
    // display: grid;
    // border-top: 2px solid $gray-scale-color02;
    & + .search-result-container {
      margin-top: 60px;
    }
    .view-type01--lv2 {
      .card--lv2 {
        .card-util {
          top: 0;
        }
      }
    }
  }
  &-wrapper {
    display: grid;
    border-top: 2px solid $gray-scale-color02;
  }
  &-item {
    padding: 40px 0;
    border-bottom: 1px solid $gray-scale-color06;
  }
  &-item-no-data {
    padding-top: 70px;
    text-align: center;
    .desc {
      @include b2-medium;
      margin-top: 20px;
    }
  }
}

.search-rcmd {
  margin-top: 60px;
  padding: 0 60px;
  > strong {
    @include b2-medium;
    display: block;
    text-align: center;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    margin-top: 20px;
    > li {
      [role='button'] {
        height: auto;
        align-items: flex-start;
        background-color: $gray-scale-color08;
        color: #000;
        &.is-active {
          background-color: $primary-color02;
          color: #fff;
        }
        &:hover {
          background-color: $primary-color02;
          color: #fff;
        }
        &:before {
          content: '#';
        }
      }
    }
  }
}

.terms-content {
  > h2 {
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 2px solid $gray-scale-color02;
  }
}

.terms-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin-top: 20px;
  padding: 40px 62px;
  background-color: $secondary-color07;
  border-radius: 20px;
  [role='button'] {
    width: 100%;
    height: auto;
    min-height: 44px;
    padding: 9px;
    border-radius: 4px;
    &.is-active,
    &:hover {
      @include b3-medium;
      background-color: $primary-color02;
      border-color: $primary-color02;
      color: #fff;
    }
  }
}

.section-content {
  padding-top: 20px;
  padding-bottom: 20px;

  > h3 {
    @include h3;
  }
  > div {
    margin-left: 20px;
    > p {
      @include b2;
      margin-top: 10px;
      color: $gray-scale-color03;
      em {
        @include b2-medium;
        color: $gray-scale-color;
      }
    }
    .info-msg {
      color: $gray-scale-color;
    }
  }
}

.terms-t01 {
  margin-top: 20px;
  > li {
    padding-left: 25px;
    & + li {
      margin-top: 20px;
    }
    > div {
      margin-left: 10px;
      .info-msg {
        padding-left: 14px;
        color: $gray-scale-color03;
      }
    }
    > strong {
      @include b2-medium;
      display: inline-block;
      text-indent: -25px;
    }
    p {
      @include b2;
      margin-top: 10px;
      color: $gray-scale-color03;
    }
    .info-msg {
      span {
        @include b3;
        color: $gray-scale-color03;
      }
    }
  }
}

.terms-service {
  > div {
    > div {
      margin-left: 20px;
    }
    & + div {
      margin-top: 40px;
    }
  }
  h2 {
    @include h3;
    padding-bottom: 10px;
    & + p {
      @include b2;
      color: $gray-scale-color03;
    }
  }
}

.terms-sign {
  margin-top: 40px;
  border-top: 1px solid $gray-scale-color06;
  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 40px;
    > span {
      @include b2;
      & + span {
        margin-top: 10px;
      }
    }
  }
}

.report-notice {
  > p {
    @include b2;
    margin-bottom: 20px;
  }
  > div {
    & + div {
      margin-top: 40px;
    }
    > div {
      padding: 30px 40px 40px;
      border: 1px solid $gray-scale-color06;
      border-radius: 20px;
      > strong {
        @include b2-medium;
        display: block;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid $gray-scale-color07;
      }
      > div {
        min-height: 78px;
      }
    }
    .form-selector {
      margin-top: 20px;
    }
  }
  &-desc {
    @include b3;
    margin-top: 15px;
  }
}

.category-area {
  overflow-y: auto;
  position: relative;
  height: 100%;
  padding: 20px 40px 20px 20px;
  .dynatree-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
    padding: 0;
    border: 0;
    background-color: transparent;
    font-family: inherit;
    white-space: inherit;
    > li {
      padding: 20px;
      border: 1px solid $gray-scale-color06;
      background-color: #fff;
      border-radius: 20px;
      > .tree_con {
        > li {
          padding: 0;
          > .dynatree-node {
            a {
              @include b3-medium;
              position: relative;
              padding-top: 2px;
              padding-left: 34px;
              &:before {
                @include commonSprite;
                content: '';
                position: absolute;
                top: 1px;
                left: 0;
                width: 24px;
                height: 24px;
                background-position: -265px -101px;
              }
            }
            &.dynatree-expanded {
              a {
                @include b2-medium;
                padding-top: 0;
                &:before {
                  background-position: -265px -126px;
                }
              }
            }
            &.dynatree-active {
              // a {
              //   @include b2-medium;
              //   padding-top: 0;
              //   &:before {
              //     background-position: -265px -126px;
              //   }
              // }
            }
          }
        }
      }
    }
  }
  span.dynatree-icon {
    display: none;
  }
  span.dynatree-expander {
    display: none;
  }
  span.dynatree-connector {
    display: none;
  }
  span.dynatree-node a {
    @include b3;
    margin: 0;
    padding: 0;
    &:focus {
      background-color: transparent;
    }
  }
  span.dynatree-selected a {
    text-decoration: underline;
    font-style: normal;
    color: $gray-scale-color;
  }
  span.dynatree-active a {
    border: 0;
    background-color: transparent;
  }
  ul.dynatree-container ul {
    padding: 0;
  }
  ul.dynatree-container a {
    border: 0;
    &:hover {
      background-color: transparent;
    }
  }
  ul.dynatree-container img {
    display: none;
    position: absolute;
    top: 2px;
    left: 0;
    width: 20px;
    height: 20px;
    margin: 0;
  }
  ul.tree_con {
    ul.tree_con {
      margin-top: 16px;
      > li {
        img {
          display: block;
        }
        & + li {
          margin-top: 8px;
        }
      }
      span.dynatree-node {
        position: relative;
        padding-left: 25px;
      }
      // span.dynatree-icon {
      //   display: block;
      // }
    }
  }
}

// .category-area {
//   overflow-y: auto;
//   position: relative;
//   height: 100%;
//   padding: 20px 40px 20px 20px;
//   > .dynatree-container {
//     overflow: hidden;
//     display: flex;
//     flex-direction: column;
//     gap: 10px;
//     height: auto;
//     padding: 0;
//     border: 0;
//     background-color: transparent;
//     font-family: inherit;
//     white-space: inherit;
//     & + .dynatree-container {
//       margin-top: 10px;
//     }
//     .dynatree-node {
//       > img {
//         display: none;
//       }
//     }
//     .dynatree-icon,
//     .dynatree-expander {
//       display: none;
//     }
//     .dynatree-title {
//       margin-left: 0;
//       border: 0;
//       &:hover {
//         background-color: transparent;
//       }
//     }
//     > li {
//       padding: 20px;
//       border: 1px solid $gray-scale-color06;
//       background-color: #fff;
//       border-radius: 20px;
//       background-image: none;
//       > .tree_con {
//         > li {
//           & + li {
//             margin-top: 10px;
//           }
//           > .dynatree-node {
//             &.dynatree-expanded {
//               > .dynatree-title {
//                 @include b2-medium;
//                 &:before {
//                   background-position: -265px -126px;
//                 }
//               }
//             }
//           }
//           > .dynatree-active {
//             a {
//               background-color: transparent;
//             }
//           }
//           > .dynatree-selected {
//             a {
//               font-style: normal;
//             }
//           }
//         }
//         .tree_con {
//           margin-top: 10px;
//         }
//       }
//       > .tree_con {
//         > li {
//           > span {
//             // 1 Depth
//             .dynatree-title {
//               @include b3-medium;
//               line-height: 26px;
//               padding-left: 34px;
//               &:before {
//                 @include commonSprite;
//                 content: '';
//                 position: absolute;
//                 top: 1px;
//                 left: 0;
//                 width: 24px;
//                 height: 24px;
//                 background-position: -265px -101px;
//               }
//             }
//           }
//         }
//       }
//       > .dynatree-node {
//         .dynatree-title {
//           @include b3-medium;
//           display: inline-flex;
//           position: relative;
//           // height: 26px;
//           padding-left: 34px;
//           line-height: 26px;
//           // &:before {
//           //   @include commonSprite;
//           //   content: '';
//           //   position: absolute;
//           //   top: 1px;
//           //   left: 0;
//           //   width: 24px;
//           //   height: 24px;
//           //   background-position: -265px -101px;
//           // }
//         }
//       }
//       &.on {
//         > .dynatree-node {
//           .dynatree-title {
//             @include b2-medium;
//             // &:before {
//             //   background-position: -265px -126px;
//             // }
//           }
//         }
//       }
//     }
//     // 2 Depth
//     ul {
//       padding: 0;
//       > li {
//         position: relative;
//         padding: 0;
//         &.on {
//           .dynatree-title {
//             text-decoration: underline;
//           }
//         }
//       }
//       ul {
//         display: flex;
//         flex-direction: column;
//         gap: 10px;
//         > li {
//           .dynatree-title {
//             @include b3;
//             position: relative;
//             float: left;
//             padding-left: 25px;
//             border: 0;
//             &:focus,
//             &:hover {
//               background-color: transparent;
//             }
//             i[class^='icon'] {
//               position: absolute;
//               top: 1px;
//               left: 0;
//             }
//             // &:before {
//             //   @include commonSprite;
//             //   content: '';
//             //   position: absolute;
//             //   top: 1px;
//             //   left: 0;
//             //   width: 20px;
//             //   height: 20px;
//             //   background-position: -244px -101px;
//             // }
//           }
//           &.on {
//             .dynatree-title {
//               // color: $primary-color02;

//               // &:before {
//               //   background-position: -244px -122px;
//               // }
//             }
//           }
//         }
//       }
//     }
//   }
// }

.online-guide {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  &-item {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding: 30px 20px 20px;
    border: 1px solid $gray-scale-color06;
    box-shadow: 0px 11px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
  }
  p {
    @include b3-medium;
    margin-top: 40px;
    text-align: right;
  }
  [role='button'] {
    margin-top: 20px;
  }
  .ci-area {
    width: 100%;
    margin-top: 40px;
    padding: 34px 20px 20px;
    background-color: #e6f4f8;
    border-radius: 10px;
    text-align: right;
  }
}

.get-ratio {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: inherit;
  width: 100%;
  // height: 100%;
  transform: translate(-50%, -50%);
}
// .set-ratio {
//   position: absolute;
//   top:50%;
//   left:50%;
//   max-width: inherit;
//   transform: translate(-50%, -50%);
// }
.h-img {
  position: absolute;
  top: 50%;
  left: 0;
  // max-width: inherit;
  width: 100%;
  // height: 100%;
  transform: translateY(-50%);
}
.v-img {
  position: absolute;
  top: 50%;
  left: 0;
  max-width: inherit;
  width: 100%;
  transform: translateY(-50%);
}

.radius-box {
  width: 100%;
  border: 1px solid #cecece;
  border-radius: 20px;
  background-color: #fff;
  &--2020 {
    @extend .radius-box;
    padding: 20px;
  }
  &--2030 {
    @extend .radius-box;
    padding: 20px 30px;
  }
  &--2040 {
    @extend .radius-box;
    padding: 20px 40px;
  }
  &--3030 {
    @extend .radius-box;
    padding: 30px;
  }
  &--4040 {
    @extend .radius-box;
    padding: 40px;
  }
}

.toggle-container {
  display: inline-flex;
  position: relative;
  padding: 2px;
  background-color: $primary-color02;
  border-radius: 24px;
  .toggle-content {
    display: flex;
    align-items: center;
    color: #fff;
    > span {
      position: relative;
      height: 44px;
      padding: 0 26px;
      > span {
        position: relative;
        z-index: $level01;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 22px;
      }
      & + span {
        margin-left: 2px;
      }
      &:nth-child(1) {
        @include b2;
        line-height: 43px;
        color: #fff;
      }
      &:nth-child(2) {
        @include b2-medium;
        line-height: 43px;
        color: $primary-color02;
        &:before {
          background-color: #fff;
        }
      }
    }
  }
  input[type='checkbox'] {
    display: none;
    & + label {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $level02;
    }
    &:checked ~ .toggle-content {
      > span {
        &:nth-child(1) {
          @include b2-medium;
          line-height: 43px;
          color: $primary-color02;
          &:before {
            background-color: #fff;
          }
        }
        &:nth-child(2) {
          @include b2;
          line-height: 43px;
          color: #fff;
          &:before {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.terms-content {
  > strong {
    @include h2;
    & + p {
      @include b2;
      em {
        @include b2-medium;
      }
    }
  }
}
.terms-section {
  > strong {
    display: block;
    font-weight: 500;
    margin-bottom: 10px;
  }
  p {
    & + p {
      margin-top: 10px;
    }
    > em {
      @include b2-medium;
    }
  }
  & + .terms-section {
    margin-top: 20px;
  }
}

// .privacy {
//   &-header {
//     > strong {
//       @include h2;
//       & + p {
//         @include b2;
//         margin-top: 20px;
//         em {
//           @include b2-medium;
//         }
//       }
//     }
//   }
//   &-list {
//     display: grid;
//     grid-template-columns: repeat(4, 1fr);
//     column-gap: 20px;
//     row-gap: 10px;
//     margin-top: 20px;
//     padding: 30px 48px;
//     background-color: $secondary-color07;
//     border-radius: 20px;
//     [role='button'] {
//       width: 100%;
//       border-radius: 4px;
//       &.is-active,
//       &:hover {
//         border-color: $primary-color03;
//         background-color: $primary-color03;
//         color: #fff;
//       }
//     }
//   }
//   &-section {
//     padding-top: 40px;
//     > strong {
//       @include h3;
//       display: block;
//       & + p {
//         @include b2;
//         margin-top: 10px;
//         color: $gray-scale-color03;
//       }
//     }
//     p {
//       > em {
//         @include b2-medium;
//         color: $gray-scale-color;
//       }
//     }
//   }
//   &-article {
//     margin-top: 20px;
//     ul {
//       > li {
//         & + li {
//           margin-top: 20px;
//         }
//       }
//     }
//   }
// }
.grid-column {
  display: grid;
  &__col3 {
    @extend .grid-column;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
  }
}

.round-box {
  padding: 24px 40px;
  border-radius: 20px;
  em {
    font-weight: 500;
  }
}

.stop-watching {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 60px;
  border-top: 1px solid $gray-scale-color06;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    background-color: $gray-scale-color06;
  }
  > [role='button'] {
    @include b2;
    flex: 1;
    height: 60px;
    // border: 1px solid $gray-scale-color06;
    // &:nth-child(1) {
    //   border-radius: 0 0 0 10px;
    // }
    // &:nth-child(2) {
    //   border-radius: 0 0 10px 0;
    // }
  }
}

.dynatree-status-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 20px;
  border: 1px solid $gray-scale-color06;
  background-color: #fff;
  border-radius: 20px;
  > div {
    display: flex;
    align-items: center;
    > span {
      @include b4;
      margin-left: 5px;
    }
  }
}

.edu-application-form {
  > div {
    > strong {
      @include b1-medium;
      display: block;
      margin-bottom: 20px;
    }
    > div {
      @include b2;
      color: $gray-scale-color02;
    }
    & + div {
      margin-top: 40px;
    }
  }
  .form-footer {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid $gray-scale-color06;
  }
  .date {
    @include h2;
    margin-top: 30px;
    text-align: center;
  }
  .sign {
    @include b2;
    margin-top: 10px;
    text-align: right;
  }
}

.ckeditor-content {
  table {
    border-collapse: collapse;
  }
  td {
    border: 1px solid #b0b0b0;
    padding: 5px 10px;
  }
}

.notice-view {
  &__content {
    table {
      border-collapse: collapse;
    }
    td {
      border: 1px solid #b0b0b0;
      padding: 5px 10px;
    }
  }
}
