	[class^=icon-common] { display: inline-block; flex-shrink: 0; background-image: url(/images/ui22/sprite/common.png);
	background-repeat: no-repeat; background-size:466px
	462px; white-space:nowrap; text-indent:200%; overflow:hidden; }

	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { [class^=icon-common] {
	background-image: url(/images/ui22/sprite/common@2x.png); } }

	.icon-common-add{ width:20px; height:20px; background-position:
	-218px
	-220px; }
	.icon-common-admission{ width:12px; height:16px; background-position:
	-428px
	-50px; }
	.icon-common-arrow-down-bk-8{ width:10px; height:10px; background-position:
	-428px
	-76px; }
	.icon-common-arrow-down-bk{ width:18px; height:10px; background-position:
	-338px
	-236px; }
	.icon-common-arrow-left-bk-12{ width:12px; height:12px; background-position:
	-74px
	-446px; }
	.icon-common-arrow-left-bk{ width:32px; height:32px; background-position:
	0px
	-260px; }
	.icon-common-arrow-left{ width:24px; height:24px; background-position:
	-126px
	-260px; }
	.icon-common-arrow-right-60{ width:30px; height:60px; background-position:
	-298px
	-186px; }
	.icon-common-arrow-right-80{ width:40px; height:80px; background-position:
	-298px
	-96px; }
	.icon-common-arrow-right-bk-12{ width:12px; height:12px; background-position:
	-96px
	-446px; }
	.icon-common-arrow-right-bk-16{ width:16px; height:16px; background-position:
	-144px
	-416px; }
	.icon-common-arrow-right-bk{ width:32px; height:32px; background-position:
	-42px
	-260px; }
	.icon-common-arrow-right{ width:28px; height:23px; background-position:
	-180px
	-220px; }
	.icon-common-attached-file-20{ width:20px; height:20px; background-position:
	-248px
	-220px; }
	.icon-common-attached-file{ width:16px; height:16px; background-position:
	-170px
	-416px; }
	.icon-common-autoever{ width:142px; height:40px; background-position:
	-110px
	-60px; }
	.icon-common-book-grey-16{ width:16px; height:16px; background-position:
	-196px
	-416px; }
	.icon-common-book-grey{ width:24px; height:24px; background-position:
	-160px
	-260px; }
	.icon-common-book{ width:24px; height:24px; background-position:
	-194px
	-260px; }
	.icon-common-business-hours{ width:18px; height:18px; background-position:
	-60px
	-416px; }
	.icon-common-calculator{ width:40px; height:40px; background-position:
	-338px
	-186px; }
	.icon-common-calendar-16{ width:16px; height:16px; background-position:
	-222px
	-416px; }
	.icon-common-calender{ width:24px; height:24px; background-position:
	-228px
	-260px; }
	.icon-common-certificate{ width:16px; height:16px; background-position:
	-248px
	-416px; }
	.icon-common-checkbox-checked{ width:20px; height:20px; background-position:
	-204px
	-382px; }
	.icon-common-checkbox-default{ width:20px; height:20px; background-position:
	-234px
	-382px; }
	.icon-common-ci-28{ width:87px; height:28px; background-position:
	-348px
	-96px; }
	.icon-common-ci-blue{ width:142px; height:40px; background-position:
	-298px
	0px; }
	.icon-common-ci-grey{ width:178px; height:50px; background-position:
	-110px
	0px; }
	.icon-common-ci-white{ width:178px; height:50px; background-position:
	0px
	-110px; }
	.icon-common-clipboard-list-16{ width:16px; height:16px; background-position:
	-274px
	-416px; }
	.icon-common-clipboard-list{ width:24px; height:24px; background-position:
	-262px
	-260px; }
	.icon-common-contents-16{ width:16px; height:16px; background-position:
	-300px
	-416px; }
	.icon-common-contents{ width:24px; height:24px; background-position:
	-296px
	-260px; }
	.icon-common-counting-16{ width:16px; height:16px; background-position:
	-326px
	-416px; }
	.icon-common-credit-card-arrow-right-alt-16{ width:16px; height:16px; background-position:
	-352px
	-416px; }
	.icon-common-credit-card-arrow-right-alt{ width:24px; height:24px; background-position:
	-330px
	-260px; }
	.icon-common-ctrl-pause-bk{ width:24px; height:24px; background-position:
	-364px
	-260px; }
	.icon-common-ctrl-pause-wh{ width:24px; height:24px; background-position:
	-398px
	-260px; }
	.icon-common-ctrl-play-bk{ width:24px; height:24px; background-position:
	-37px
	-302px; }
	.icon-common-ctrl-play-wh{ width:24px; height:24px; background-position:
	-71px
	-302px; }
	.icon-common-delete-grey-bk{ width:20px; height:20px; background-position:
	-264px
	-382px; }
	.icon-common-delete-grey{ width:24px; height:24px; background-position:
	-105px
	-302px; }
	.icon-common-delete{ width:12px; height:12px; background-position:
	-118px
	-446px; }
	.icon-common-department{ width:24px; height:24px; background-position:
	-139px
	-302px; }
	.icon-common-download{ width:12px; height:14px; background-position:
	-52px
	-446px; }
	.icon-common-e-campus-global-26{ width:86px; height:26px; background-position:
	-348px
	-134px; }
	.icon-common-e-campus-global{ width:120px; height:36px; background-position:
	-298px
	-50px; }
	.icon-common-education-16{ width:16px; height:16px; background-position:
	-378px
	-416px; }
	.icon-common-education{ width:24px; height:24px; background-position:
	-173px
	-302px; }
	.icon-common-email{ width:24px; height:24px; background-position:
	-207px
	-302px; }
	.icon-common-essential-16{ width:16px; height:16px; background-position:
	-404px
	-416px; }
	.icon-common-essential{ width:24px; height:24px; background-position:
	-241px
	-302px; }
	.icon-common-exclamation-mark{ width:100px; height:100px; background-position:
	0px
	0px; }
	.icon-common-help-center{ width:24px; height:25px; background-position:
	-262px
	-60px; }
	.icon-common-learning-complete{ width:20px; height:20px; background-position:
	-294px
	-382px; }
	.icon-common-learning-ongoing{ width:20px; height:20px; background-position:
	-324px
	-382px; }
	.icon-common-like-line-bk{ width:20px; height:20px; background-position:
	-354px
	-382px; }
	.icon-common-like-line-wh{ width:24px; height:24px; background-position:
	-275px
	-302px; }
	.icon-common-like{ width:16px; height:16px; background-position:
	-450px
	0px; }
	.icon-common-lock-16{ width:16px; height:16px; background-position:
	-450px
	-26px; }
	.icon-common-lock{ width:24px; height:24px; background-position:
	-309px
	-302px; }
	.icon-common-mail{ width:18px; height:18px; background-position:
	-88px
	-416px; }
	.icon-common-major{ width:24px; height:24px; background-position:
	-343px
	-302px; }
	.icon-common-marker-16{ width:16px; height:16px; background-position:
	-450px
	-52px; }
	.icon-common-marker{ width:24px; height:24px; background-position:
	-377px
	-302px; }
	.icon-common-mobile-alt-2-16{ width:16px; height:16px; background-position:
	-450px
	-78px; }
	.icon-common-mobile-alt-2{ width:24px; height:24px; background-position:
	-411px
	-302px; }
	.icon-common-money{ width:24px; height:24px; background-position:
	0px
	-348px; }
	.icon-common-more-wh{ width:16px; height:16px; background-position:
	-450px
	-104px; }
	.icon-common-more{ width:16px; height:16px; background-position:
	-450px
	-130px; }
	.icon-common-move{ width:24px; height:24px; background-position:
	-34px
	-348px; }
	.icon-common-notice{ width:40px; height:40px; background-position:
	-388px
	-186px; }
	.icon-common-ok-16{ width:16px; height:16px; background-position:
	-450px
	-156px; }
	.icon-common-ok{ width:24px; height:24px; background-position:
	-68px
	-348px; }
	.icon-common-organization{ width:24px; height:24px; background-position:
	-102px
	-348px; }
	.icon-common-page-up{ width:27px; height:36px; background-position:
	0px
	-302px; }
	.icon-common-password{ width:24px; height:24px; background-position:
	-136px
	-348px; }
	.icon-common-person-16{ width:16px; height:16px; background-position:
	-450px
	-182px; }
	.icon-common-person-bl-40{ width:40px; height:40px; background-position:
	-188px
	-110px; }
	.icon-common-person-wh-40{ width:40px; height:40px; background-position:
	-238px
	-110px; }
	.icon-common-person{ width:24px; height:24px; background-position:
	-170px
	-348px; }
	.icon-common-phone{ width:18px; height:18px; background-position:
	-116px
	-416px; }
	.icon-common-place{ width:24px; height:24px; background-position:
	-204px
	-348px; }
	.icon-common-play-80{ width:80px; height:80px; background-position:
	0px
	-170px; }
	.icon-common-play{ width:16px; height:16px; background-position:
	-450px
	-208px; }
	.icon-common-popup-up{ width:20px; height:20px; background-position:
	-384px
	-382px; }
	.icon-common-question-bk{ width:20px; height:20px; background-position:
	-414px
	-382px; }
	.icon-common-question{ width:16px; height:16px; background-position:
	-450px
	-234px; }
	.icon-common-rate{ width:24px; height:24px; background-position:
	-238px
	-348px; }
	.icon-common-rate02{ width:24px; height:24px; background-position:
	-272px
	-348px; }
	.icon-common-recent-search{ width:20px; height:20px; background-position:
	0px
	-416px; }
	.icon-common-remove{ width:20px; height:2px; background-position:
	-348px
	-170px; }
	.icon-common-search-result{ width:80px; height:80px; background-position:
	-90px
	-170px; }
	.icon-common-search{ width:24px; height:24px; background-position:
	-306px
	-348px; }
	.icon-common-share-bk{ width:20px; height:20px; background-position:
	-30px
	-416px; }
	.icon-common-share{ width:16px; height:16px; background-position:
	-450px
	-260px; }
	.icon-common-sign-in{ width:24px; height:24px; background-position:
	-340px
	-348px; }
	.icon-common-sign-out-16{ width:16px; height:16px; background-position:
	-450px
	-286px; }
	.icon-common-sign-out-24{ width:24px; height:24px; background-position:
	-374px
	-348px; }
	.icon-common-sort-16{ width:16px; height:16px; background-position:
	-450px
	-312px; }
	.icon-common-sort{ width:24px; height:24px; background-position:
	-408px
	-348px; }
	.icon-common-star{ width:16px; height:16px; background-position:
	-450px
	-338px; }
	.icon-common-student{ width:16px; height:16px; background-position:
	-450px
	-364px; }
	.icon-common-study{ width:32px; height:32px; background-position:
	-84px
	-260px; }
	.icon-common-survey{ width:40px; height:40px; background-position:
	-180px
	-170px; }
	.icon-common-tel{ width:24px; height:24px; background-position:
	0px
	-382px; }
	.icon-common-time-16{ width:16px; height:16px; background-position:
	-450px
	-390px; }
	.icon-common-time-24{ width:24px; height:24px; background-position:
	-34px
	-382px; }
	.icon-common-time-wh-16{ width:16px; height:16px; background-position:
	-450px
	-416px; }
	.icon-common-time{ width:24px; height:24px; background-position:
	-68px
	-382px; }
	.icon-common-to{ width:24px; height:24px; background-position:
	-102px
	-382px; }
	.icon-common-user{ width:24px; height:24px; background-position:
	-136px
	-382px; }
	.icon-common-video-slash-16{ width:16px; height:16px; background-position:
	0px
	-446px; }
	.icon-common-video-slash{ width:24px; height:24px; background-position:
	-170px
	-382px; }
	.icon-common-view{ width:16px; height:16px; background-position:
	-26px
	-446px; }
	.icon-common-wallet{ width:40px; height:40px; background-position:
	-230px
	-170px; }
