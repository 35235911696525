// [EC-BA-7003]  설문참여
.survey-list-item {
  &__tit {
    em {
      display: inline-block;
      vertical-align: middle;
      width: auto;
      margin-right: 12px;
      padding: 0 10px;
      height: 30px;
      line-height: 30px;
      color: #fff;
      background-color: $primary-color03;
      border-radius: 10px;
    }
  }
}

// [EC-BA-7007] 시험응시
.learning-status__quiz {
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .quiz-timer {
    .value {
      @include text($size: 40px, $lh: 1.3, $weight: 700);
      color: $primary-color02;
      vertical-align: middle;
    }
  }
  .quiz-score {
    @extend .quiz-timer;
    > span {
      @include h3;
      vertical-align: middle;
    }
  }
  .advance-msg {
    padding: 20px 40px;
    background-color: $gray-scale-color08;
    border-radius: 20px;
    .info-msg {
      flex-wrap: wrap;
      margin-top: 0;
      ul {
        width: 100%;
        li {
          font-size: 14px;
        }
      }
    }
  }
  .remind-msg {
    padding: 50px 40px;
    background-color: $secondary-color07;
    border-radius: 20px;
    text-align: center;
    @include h2;
  }
  .quiz-list {
    &__item {
      margin-bottom: 20px;
      padding: 30px 40px;
      border: 1px solid $gray-scale-color06;
      border-radius: 20px;
      &-views {
        margin-top: 15px;
        padding-top: 20px;
        border-top: 1px solid $gray-scale-color07;
        .form-selector + .form-selector {
          margin-top: 15px;
        }
      }
      &-question {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .quiz-result {
        li {
          display: inline-block;
          min-width: 110px;
          padding: 5px 13px;
          background-color: $secondary-color07;
          border-radius: 10px;
          text-align: center;
          @include text($size: 14px, $lh: 1.6, $weight: 500);
          color: $primary-color;
          & + li {
            margin-left: 6px;
          }
        }
      }
      &-answer {
        padding: 20px 22px;
        border-radius: 20px;
        background-color: $gray-scale-color08;
        .tit {
          @extend .solid-button--xs;
          margin-right: 20px;
        }
      }
    }
  }
}
