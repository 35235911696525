	[class^=icon-education-work] { display: inline-block; flex-shrink: 0; background-image: url(/images/ui22/sprite/education-work.png);
	background-repeat: no-repeat; background-size:62px
	42px; white-space:nowrap; text-indent:200%; overflow:hidden; }

	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { [class^=icon-education-work] {
	background-image: url(/images/ui22/sprite/education-work@2x.png); } }

	.icon-education-work-tree-file{ width:16px; height:16px; background-position:
	0px
	0px; }
	.icon-education-work-tree-folder--close{ width:16px; height:16px; background-position:
	-26px
	0px; }
	.icon-education-work-tree-folder--open{ width:16px; height:16px; background-position:
	0px
	-26px; }
	.icon-education-work-tree-status--close{ width:10px; height:12px; background-position:
	-26px
	-26px; }
	.icon-education-work-tree-status--open{ width:10px; height:12px; background-position:
	-52px
	0px; }
