// 팝업 공지
.notice-popup__container {
  display: flex;
  flex-direction: column;
  .notice-popup__header {
    padding: 40px 40px 20px;
    > strong {
      @include h3;
      @include ellipsis-multi(2, 64px, 100%);
    }
  }
  .notice-popup__main {
    flex: 1;
    padding: 40px 40px 100px;
    line-height: 1;
    img {
      width: 100%;
    }
    &--temp {
      @include b1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 600px;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
    }
  }
  .notice-popup__footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
  }
  .notice-popup-buttons {
    display: flex;
    border-top: 1px solid $gray-scale-color06;
    button {
      @include b2;
      flex: 1;
      height: 60px;
      text-align: center;
      & + button {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          width: 1px;
          background-color: $gray-scale-color06;
        }
      }
    }
  }
}
