[data-modal-name='reset-password'] {
  .table {
    td {
      padding: 0 20px;
    }
  }
  .modal-subsection {
    padding-bottom: 40px;
  }
  .type--refer {
    @include b3;
  }
  .form-flex {
    align-items: center;
    .form-input + span {
      margin-left: 10px;
    }
  }
}
