.popup-main {
  h2 {
    @include h3;
  }
  h3 {
    padding-bottom: 20px;
  }
  .teacher-info {
    grid-template-columns: repeat(4, 1fr);
  }
  .application-header {
    padding: 20px 40px;
    > strong {
      @include b1-medium;
    }
    .share-info {
      padding-top: 4px;
    }
    &--lv2 {
      padding: 20px 40px;
      .header {
        > strong {
          @include b1-medium;
        }
      }
    }
  }
  .application-main {
    figure {
      flex: 0 0 47%;
      margin-right: 20px;
    }
    .application-info {
      flex: 1;
      padding: 16px 30px;
      > div {
        padding: 14px 0;
        > strong {
          @include b4-medium;
          line-height: 19px;
        }
        > div {
          @include b4;
          > [class^='flag'] {
            height: 24px;
            line-height: 22px;
          }
        }
      }
    }
  }
  .preview-course {
    .application-header {
      padding-top: 29px;
      padding-bottom: 29px;
      > strong {
        @include h3;
        text-align: center;
      }
    }
    .application-main {
      flex-direction: column;
      figure {
        flex: 1;
        margin-right: 0;
        img {
          width: 100%;
        }
      }
      .desc-container {
        margin-top: 40px;
        > strong {
          @include h3;
          display: block;
          padding-bottom: 20px;
          border-bottom: 2px solid rgba(34, 34, 34, 0.9);
        }
        > div {
          padding: 20px 0;
          border-bottom: 1px solid rgba(206, 206, 206, 0.9);
        }
      }
    }
  }
  .sort-breadcrumb {
    @include b4;
  }
  .review {
    > ul {
      > li {
        padding: 20px 0;
      }
    }
  }
  .learning-goals {
    > div {
      padding: 30px 30px 10px;
      > strong {
        @include b1-medium;
      }
    }
  }
  .post-container.view-type02 {
    > ul {
      gap: 20px;
    }
  }
  .card--lv2,
  .card-image {
    width: auto;
    height: auto;
  }
  .post-swiper {
    .card-image {
      width: 235px;
      height: 128px;
    }
  }
  .is-fixed.tab-container--fixed-mode {
    .tab--lv3 {
      left: 0;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      padding-right: 40px;
      padding-left: 40px;
    }
  }
}
.pop-buttons-wrapper {
  @include clfix;
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $gray-scale-color06;
  [role='button'] {
    & + [role='button'] {
      margin-left: 10px;
    }
  }
  .buttons-right {
    float: right;
  }
  .buttons-left {
    float: left;
  }
  .buttons-center {
    text-align: center;
  }
  &.border-none {
    margin-top: 0;
    border-top: 0;
  }
}

.check-list {
  padding: 0 160px 0 40px;
  border: 1px solid $gray-scale-color06;
  border-radius: 20px;
  > div {
    display: flex;
    align-content: center;
    width: 520px;
    padding: 20px 0 20px 96px;
    & + div {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: -1px;
        right: 0;
        left: 0;
        height: 2px;
        background-image: url($imgPath + 'common/dotted-line.png');
        background-position: center;
        background-size: 8px auto;
        background-repeat: repeat-x;
      }
    }
    > strong {
      @include h3;
    }
    > div {
      display: flex;
      align-items: center;
      flex: 1;
      margin-left: 40px;
      > strong {
        @include b2-medium;
        color: $primary-color02;
      }
      > span {
        @include b2;
        display: flex;
        align-items: center;
        margin-left: 10px;
        [class^='icon'] {
          margin-right: 5px;
        }
      }
    }
  }
}

.students-list {
  padding: 30px 40px;
  border: 1px solid $gray-scale-color06;
  border-radius: 20px;
  > div {
    & + div {
      display: flex;
      position: relative;
      margin-top: 20px;
      padding-top: 22px;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 2px;
        background-image: url($imgPath + 'common/dotted-line.png');
        background-position: center;
        background-size: 8px auto;
        background-repeat: repeat-x;
      }
      [class^='icon'] {
        margin-right: 5px;
      }
    }
    > strong {
      @include h3;
      display: block;
    }
    > span {
      @include h3;
      display: block;
      margin-top: 10px;
      color: $primary-color02;
    }
  }
}

.popup-main {
  .terms-list {
    grid-template-columns: repeat(4, 1fr);
    padding: 30px 48px;
  }
}
