// * -------------------------------
// * Content Aside
// * -------------------------------
.app-aside {
  position: relative;
  width: $min-width;
  margin-right: auto;
  margin-left: auto;
  &-content {
    width: 70px;
    margin-left: auto;
    margin-right: -158px;
  }
}
.aside-wrapper {
  position: fixed;
  bottom: 35px;
  z-index: $level06;
  transition: opacity 0.6s $easing;
  // &.is-break {
  //   position: absolute;
  //   bottom: -35px;
  // }
  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }
  &.is-visible {
    opacity: 1;
    pointer-events: auto;
  }
}
.aside-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  filter: drop-shadow(0px 7px 13px rgba(0, 0, 0, 0.1));
  & + .aside-button {
    margin-top: 12px;
  }
  &__text {
    display: block;
    margin-top: 4px;
    font-size: $text-xs;
    line-height: 14px;
    font-weight: 500;
    color: #fff;
  }
}
button.aside-button {
  @extend .aside-button;
  > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.page-up-button {
  background-color: #fff;
}
.help-center-button {
  background-color: $secondary-color05;
}
