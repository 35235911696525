.depth2 {
  background-color: #fff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  &-content {
    position: relative;
    min-width: $min-width;
  }
  &-container {
    position: relative;
    width: 1360px !important;
    padding: 0 10px;
    &:before,
    &:after {
      content: '';
      position: absolute;
      z-index: $level02;
      top: 6px;
      width: 37px;
      height: 38px;
      background: linear-gradient(-270deg, #fff 13.89%, rgba(255, 255, 255, 0) 91.2%), transparent;
    }
    &:before {
      left: 0px;
    }
    &:after {
      right: 0px;
      background: linear-gradient(270deg, #fff 13.89%, rgba(255, 255, 255, 0) 91.2%), transparent;
    }
  }
  &-item {
    @include b2-medium;
    width: auto;
    > a {
      display: block;
      padding: 12px 20px 16px;
      > span {
        display: block;
        position: relative;
      }
    }
    &.is-active {
      > a {
        > span {
          color: $primary-color02;
          &:before {
            content: '';
            position: absolute;
            right: 0;
            bottom: -6px;
            left: 0;
            height: 2px;
            background-color: $primary-color02;
          }
        }
      }
    }
  }
  &-button-next,
  &-button-prev {
    position: absolute;
    top: 13px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  &-button-next {
    right: calc(50% - 720px);
    [class^='icon'] {
      transform: rotate(180deg);
    }
  }
  &-button-prev {
    left: calc(50% - 720px);
  }
}
