// * -------------------------------
// * Text, Password
// * -------------------------------
.form-text {
  display: inline-block;
  position: relative;
  &-content {
    display: inline-block;
    width: 100%;
  }
  input[type='text'],
  input[type='password'] {
    min-width: 100px;
    height: 48px;
    padding-top: 11px;
    padding-right: 20px;
    padding-bottom: 11px;
    padding-left: 20px;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    font-size: $text-lg;
    line-height: 26px;
  }
}

// * -------------------------------
// * Textarea
// * -------------------------------
.form-textarea {
  display: inline-block;
  position: relative;
  width: 100%;
  &-content {
    display: inline-flex;
    width: 100%;
    > span {
      flex: 1;
      display: block;
    }
  }
  &-label {
    display: inline-flex;
    width: 100%;
    padding-top: 19px;
    padding-right: 9px;
    padding-bottom: 19px;
    padding-left: 29px;
    border: 1px solid $gray-scale-color06;
    border-radius: 24px;
    textarea {
      @include b2;
      width: 100%;
      height: 120px;
      padding-right: 10px;
      border: 0;
      resize: none;
    }
  }
  &-apply-button {
    @include b1-medium;
    flex: 0 0 230px;
    display: inline-block;
    width: 230px;
    height: 160px;
    margin-left: 10px;
    background-color: $gray-scale-color03;
    border-radius: 20px;
    color: #fff;
  }
  &-length {
    @include b3;
    margin-top: 10px;
    float: right;
    color: $gray-scale-color04;
    em {
      color: $gray-scale-color02;
      font-weight: 400;
    }
  }
  &-util {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    [role='button'] {
      & + [role='button'] {
        margin-left: 10px;
      }
    }
  }
  &.is-disabled .form-textarea-label {
    background: $gray-scale-color08;
  }
}

// * -------------------------------
// * Radio, Checkbox
// * -------------------------------
.form-selector {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
  input[type='radio'] {
    @include a11y-hidden;
    + label {
      @include b2;
      display: inline-block;
      position: relative;
      min-height: 20px;
      padding-left: 30px;
      &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        width: 20px;
        height: 20px;
        border: 1px solid $gray-scale-color04;
        background-color: #fff;
        border-radius: 50%;
      }
    }
    &[readonly] + label {
      &:before {
        border-color: #ccc;
        background-color: #f6f6f6;
        filter: grayscale(100%) opacity(40%);
      }
    }
    &:disabled + label {
      &:before {
        border-color: #ccc;
        background-color: #f6f6f6;
        filter: grayscale(100%) opacity(40%);
        pointer-events: none;
      }
    }
    &:checked + label {
      &:before {
        border: 6px solid $primary-color02;
        border-radius: 50%;
      }
    }
  }
  input[type='checkbox'] {
    @include a11y-hidden;
    + label {
      @include b2;
      display: inline-block;
      position: relative;
      min-height: 20px;
      padding-left: 30px;
      &:before {
        content: '';
        position: absolute;
        top: 3px;
        left: 0;
        width: 20px;
        height: 20px;
        border: 1px solid $gray-scale-color04;
        border-radius: 4px;
        background-color: #fff;
      }
      &:after {
        @include commonSprite;
        content: '';
        position: absolute;
        top: 8px;
        left: 4px;
        width: 11px;
        height: 9px;
        background-position: -42px 0;
        opacity: 0;
      }
    }
    &:disabled + label {
      &:before {
        border-color: $gray-scale-color05;
        background-color: $gray-scale-color08;
      }
    }
    &:checked + label {
      &:before {
        border: 0;
        background-color: $primary-color02;
      }
      &:after {
        opacity: 1;
      }
    }
    &:checked:disabled + label {
      &:before {
        filter: grayscale(100%) opacity(40%);
      }
    }
  }
  &.text-empty {
    input[type='radio'] + label {
      width: 20px;
      height: 20px;
      padding-left: 0;
      vertical-align: middle;
      &::before,
      &::after {
        top: 0;
      }
    }
    input[type='checkbox'] + label {
      width: 20px;
      height: 20px;
      padding-left: 0;
      vertical-align: middle;
      &::before {
        top: 0;
      }
      &::after {
        top: 5px;
      }
    }
  }
}

.form-selector__list {
  .form-selector {
    & + .form-selector {
      margin-left: 40px;
    }
  }
  &--ttb {
    .form-selector {
      display: block;
    }
  }
}

// Form Input
.form-input {
  display: inline-block;
  .form-input-content {
    display: flex;
    position: relative;
  }
  input[type='text'],
  input[type='password'] {
    @include b2;
    min-width: 100px;
    height: 48px;
    padding: 12px 29px;
    border: 1px solid $gray-scale-color06;
    border-radius: 30px;
    &:read-only {
      background-color: $gray-scale-color08;
      color: $gray-scale-color03;
    }
    &:disabled {
      background-color: $gray-scale-color08;
      color: $gray-scale-color03;
    }
  }
  // Error
  &[data-status='error'] {
    input[type='text'],
    input[type='password'] {
      border-color: $secondary-color02;
    }
    .form-input-msg {
      @include b3;
      display: flex;
      margin-top: 4px;
      padding: 0 30px;
      color: $secondary-color02;
    }
  }
  // Success
  &[data-status='success'] {
    input[type='text'],
    input[type='password'] {
      border-color: $primary-color02;
    }
    .form-input-msg {
      @include b3;
      display: flex;
      margin-top: 4px;
      padding: 0 30px;
      color: $primary-color02;
    }
  }
  // Option Img
  &--img {
    @extend .form-input;
    input[type='text'],
    input[type='password'] {
      padding-left: 64px;
    }
    [class^='icon'] {
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
      pointer-events: none;
    }
    &.input-user {
      .form-input-content {
        &:before {
          @include commonSprite;
          content: '';
          position: absolute;
          top: 11px;
          left: 30px;
          width: 24px;
          height: 24px;
          background-position: 0 -34px;
          pointer-events: none;
        }
      }
    }
    &.input-password {
      .form-input-content {
        &:before {
          @include commonSprite;
          content: '';
          position: absolute;
          top: 11px;
          left: 30px;
          width: 24px;
          height: 24px;
          background-position: -25px -34px;
          pointer-events: none;
        }
      }
    }
  }
  // Option: Datepicker
  &--datepicker {
    @extend .form-input;
    width: 200px;
    .form-input-content {
      &:before {
        @include commonSprite;
        content: '';
        position: absolute;
        top: 13px;
        right: 31px;
        width: 20px;
        height: 20px;
        pointer-events: none;
      }
    }
    input[type='text'],
    input[type='password'] {
      padding-right: 64px;
      &:read-only {
        background-color: #fff;
      }
      &:disabled {
        background-color: $gray-scale-color08;
      }
    }
    [class^='icon'] {
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
    }
    // Readonly
    &.is-readonly {
      .form-input-content {
        &:before {
          //filter: grayscale(100%) opacity(40%);
        }
      }
    }
    // Disabled
    &.is-disabled {
      .form-input-content {
        &:before {
          filter: grayscale(100%) opacity(40%);
        }
      }
    }
  }
  // Option: Search
  &--icon-button {
    @extend .form-input;
    position: relative;
    input[type='text'],
    input[type='password'] {
      padding-right: 94px;
      padding-left: 30px;
    }
    .search-button {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
    }
    .delete-button {
      position: absolute;
      top: 50%;
      right: 66px;
      width: 20px;
      height: 20px;
      transform: translateY(-50%);
      border-radius: 50%;
      background-color: $gray-scale-color07;
      opacity: 0;
      .button-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &.is-written {
      .delete-button {
        opacity: 1;
        transition: opacity 0.22s $easing;
      }
    }
    // Disabled
    &.is-disabled {
      .search-button {
        pointer-events: none;
        [class^='icon'] {
          filter: grayscale(100%) opacity(40%);
        }
      }
      .delete-button {
        display: none;
      }
    }
  }
  &--double {
    display: inline-flex;
    align-items: center;
    > em {
      margin: 0 10px;
    }
  }
}

// Form File
.form-file {
  display: inline-flex;
  position: relative;
  .form-input {
    display: flex;
    input[type='text'] {
      flex: 1;
      padding-right: 64px;
      background-color: #fff;
    }
  }
  &-desc {
    @extend .info-msg;
    margin-top: 10px;
  }
  &-notice {
    > li {
      margin-top: 6px;
    }
  }
  // New
  .browse-files-button {
    margin-left: 10px;
    cursor: pointer;
  }
  .form-upload {
    display: flex;
    & + .browse-reset-button {
      margin-left: 10px;
    }
  }
  .browse-add-button,
  .browse-remove-button {
    margin-left: 10px;
  }
  .browse-add-button,
  .browse-remove-button {
    & + .browse-reset-button {
      margin-left: 40px;
    }
  }
  .browse-add-button {
    border-color: $gray-scale-color04;
  }
  .browse-remove-button {
    border-color: $gray-scale-color07;
  }
  .browse-file-delete-button {
    position: absolute;
    top: 12px;
    right: 30px;
  }
  &.is-disabled {
    .form-input {
      input[type='text'] {
        background-color: $gray-scale-color08;
      }
    }
    .browse-files-button {
      pointer-events: none;
    }
  }
}
