$sqrt_2: 1.41421356237;

// * -------------------------------
// * Device size
// * -------------------------------
$min-width: 1440px;
$max-width: 1920px;

// * -------------------------------
// Size (xl > lg > md > sm > xs)
// * -------------------------------
$heading-lg: 40rem;
$heading-md: 32rem;
$heading-sm: 24rem;
$heading-xs: 20rem;

$text-lg: 18px;
$text-md: 16px;
$text-sm: 14px;
$text-xs: 12px;

// * -------------------------------
// * Color
// * -------------------------------
// Primary Color
$primary-color: #042b69; // 현대제철일 경우 변경
$primary-color02: #1472ce; // 현대제철일 경우 변경
$primary-color03: #5085ec; // 현대제철일 경우 변경
$primary-color04: #e5ecf8; // 현대제철일 경우 변경
$secondary-color07: #f2f7fb; // 현대제철일 경우 변경

// Secondary Color
$secondary-color: #1b8c6a;
$secondary-color02: #f65959;
$secondary-color03: #9a44de;
$secondary-color04: #a8b1c2;
$secondary-color05: #4a5366;
$secondary-color06: #7593cd;
$secondary-color08: #faf8ee;
$secondary-color09: #7f9c06;

// Gray Scale Color
$gray-scale-color: #000;
$gray-scale-color02: #222;
$gray-scale-color03: #666;
$gray-scale-color04: #888;
$gray-scale-color05: #b8b8b8;
$gray-scale-color06: #cecece;
$gray-scale-color07: #ddd;
$gray-scale-color08: #f4f4f4;

// Graph Color
$graph-color: #9673ff;
$graph-color02: #54cbaa;
$graph-color03: #ffbd3e;
$graph-color04: #ff7070;
$graph-color05: #badb5d;

// ETC Color
$etc-color: #d0e0ff;
$etc-color02: #88abef;

$placeholder: #666666;
$dim-color: rgba(0, 0, 0, 0.7);

// 현대제철
// $primary-color: #0c6046;
// $primary-color02: #1b8c6a;
// $primary-color03: #2aab84;
// $primary-color04: #ebf4e5;
// $secondary-color07: #f4faf1;
// $etc-color: #a6e9d5;
// $etc-color02: #76ceb4;

// * -------------------------------
// * z-index Level
// * -------------------------------
$level01: 1;
$level02: 10;
$level03: 50;
$level04: 100;
$level05: 150;
$level06: 200; /* Floating Bottom */
$level07: 250; /* Header */
$level08: 300; /* Fixed */
$level09: 350; /* Modal */
$level10: 400; /* Spinner */
$level11: 450; /* Spinner */

// * -------------------------------
// * Path
// * -------------------------------
$imgPath: '/images/ui22/';
$fontPath: '../fonts/';

// * -------------------------------
// * Easing
// * -------------------------------
$easing: cubic-bezier(0.04, 0.04, 0.12, 0.96);

// * -------------------------------
// * Grid
// * -------------------------------
$gridColumn: 11;
