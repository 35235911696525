.modal {
  overflow: auto;
  display: none;
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  &--standard {
    @extend .modal;
    background-color: $dim-color;
  }
  &--standard02 {
    @extend .modal;
    background-color: rgba(0, 0, 0, 0.3);
  }
  &-close-button {
    position: absolute;
    top: 30px;
    right: 30px;
  }
  &__content {
    display: flex;
    justify-content: center;
    -webkit-box-pack: center;
    width: 100%;
    height: 100%;
    &-table {
      overflow: hidden;
      display: table;
      width: 100%;
      height: 100%;
    }
    &-cell {
      display: table-cell;
      vertical-align: middle;
    }
    &-wrapper {
      margin-right: auto;
      margin-left: auto;
      padding-top: 120px;
      padding-bottom: 120px;
    }
    &-padding {
      position: relative;
      z-index: 1;
      margin-right: auto;
      margin-left: auto;
    }
  }
  &-content {
    background-color: #fff;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
    border-radius: 15px 15px 10px 10px;
  }
  &-header {
    background-color: $primary-color;
    border-radius: 10px 10px 0 0;
    color: #fff;
  }
  &-footer {
    &[data-bgColor='grey'] {
      background-color: $gray-scale-color08;
      border-radius: 0 0 10px 10px;
    }
  }
  &-buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  &__button-group__center {
    text-align: center;
  }
  &__button-group__left {
    float: left;
  }
  &__button-group__right {
    float: right;
  }
  &__button-group a + a,
  &__button-group a + button,
  &__button-group button + button,
  &__button-group button + a {
    margin-left: 10px;
  }
  // Size Setting
  &__content-wrapper--sm {
  }
  &__content-wrapper--md {
  }
  &-close-button {
    position: absolute;
    top: 0;
    right: 0;
    &__content {
      // @include iconCancel();
    }
  }
}
.is-modal {
  overflow: hidden;
}
.modal--open {
  display: block;
  z-index: $level09;
}

// Size (lg > md < sm < xs)
.modal__content-wrapper {
  &--lg {
    @extend .modal__content-wrapper;
    width: 1080px;
    .modal-header {
      @include h3;
      padding: 14px 104px 14px 40px;
      span {
        @include ellipsis-multi(2, 32px, 100%);
      }
    }
    .modal-footer {
      padding: 30px 40px;
    }
    .modal-subsection {
      padding: 30px 40px;
    }
    .modal-close-button {
      width: 74px;
      height: 60px;
      > span {
        @include commonSprite;
        align-items: center;
        display: flex;
        width: 24px;
        height: 24px;
        margin-left: 10px;
        background-position: -147px -101px;
      }
    }
  }
  &--md {
    @extend .modal__content-wrapper;
    width: 800px;
    .modal-header {
      @include h3;
      padding: 14px 104px 14px 40px;
    }
    .modal-footer {
      padding: 30px 40px;
    }
    .modal-subsection {
      padding: 40px;
    }
    .modal-close-button {
      width: 74px;
      height: 60px;
      > span {
        @include commonSprite;
        align-items: center;
        display: flex;
        width: 24px;
        height: 24px;
        margin-left: 10px;
        background-position: -147px -101px;
      }
    }
  }
  &--sm {
    @extend .modal__content-wrapper;
    width: 600px;
    .modal-header {
      @include h3;
      padding: 14px 56px 14px 20px;
    }
    .modal-subsection {
      padding: 40px;
    }
    .modal-footer {
      padding: 30px 20px;
    }
    .modal-close-button {
      width: 66px;
      height: 60px;
      > span {
        @include commonSprite;
        align-items: center;
        display: flex;
        width: 16px;
        height: 16px;
        margin-left: 10px;
        background-position: -147px -84px;
      }
    }
  }
  &--xs {
    @extend .modal__content-wrapper;
    width: 400px;
    .modal-header {
      @include b2-medium;
      padding: 11px 56px 11px 20px;
    }
    .modal-subsection {
      padding: 30px 20px;
    }
    .modal-footer {
      padding: 30px 20px;
    }
    .modal-close-button {
      width: 46px;
      height: 48px;
      > span {
        @include commonSprite;
        align-items: center;
        display: flex;
        width: 16px;
        height: 16px;
        margin-left: 10px;
        background-position: -147px -84px;
      }
    }
  }
}
