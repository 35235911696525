// * -------------------------------
// * Normalize v8.0.0
// * -------------------------------
html{line-height:1.15;-webkit-text-size-adjust:100%}
small{font-size:80%}
sub, sup{position:relative;line-height:0;font-size:75%;vertical-align:baseline}
sub{bottom:-0.25em}
sup{top:-0.5em}
button, input, optgroup, select, textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}
button, input{overflow:visible}
button, select{text-transform:none}
button, [type="button"], [type="reset"], [type="submit"]{-webkit-appearance:button}
button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner{border-style:none;padding:0}
button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring{outline:1px dotted ButtonText}
textarea{overflow:auto}
[type="checkbox"], [type="radio"]{box-sizing:border-box;padding:0}
[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button{height:auto}
[type="search"]{-webkit-appearance:textfield;outline-offset:-2px}
[type="search"]::-webkit-search-decoration{-webkit-appearance:none}
::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}
abbr[title]{border-bottom:none;text-decoration:underline dotted}
progress{vertical-align:baseline}
details{display:block}
summary{display:list-item}
[hidden], template, hr{display:none}
button > span{position:relative;}