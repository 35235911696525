.tab {
  display: flex;
  &-container {
    position: relative;
    &--fixed-mode {
      @extend .tab-container;
      height: 88px;
      margin-top: -40px;
      // margin-bottom: -40px;
      .tab--lv3 {
        padding-top: 40px;
        // padding-bottom: 40px;
        background-color: #fff;
      }
    }
    &.is-fixed {
      .tab--lv3 {
        position: fixed;
        top: 0;
        z-index: $level02;
        width: $min-width;
      }
    }
  }
  &--lv1 {
    @extend .tab;
    > [role='tab'] {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 48px;
      border-top: 1px solid #f4f4f4;
      border-bottom: 1px solid #c1c1c1;
      background-color: #f4f4f4;
      font-size: $text-lg;
      line-height: 26px;
      font-weight: 500;
      &[aria-selected='true'] {
        border-top: 1px solid #c1c1c1;
        border-right: 1px solid #c1c1c1;
        border-bottom: 1px solid #fff;
        border-left: 1px solid #c1c1c1;
        background-color: #fff;
      }
    }
  }
  &--lv2 {
    @extend .tab;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: $gray-scale-color06;
    }
    > [role='tab'] {
      @include b2;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 56px;
      border-top: 1px solid $gray-scale-color06;
      border-bottom: 1px solid $gray-scale-color06;
      background-color: $gray-scale-color08;
      color: $gray-scale-color04;
      &:first-child {
        border-left: 1px solid $gray-scale-color06;
        border-radius: 20px 0 0 0;
      }
      &:last-child {
        border-right: 1px solid $gray-scale-color06;
        border-radius: 0 20px 0 0;
        &[aria-selected='true'] {
          border-right: 1px solid $gray-scale-color06;
        }
      }
      &:first-child:last-child {
        border-radius: 20px 20px 0 0;
      }
      & + [role='tab'] {
        border-left: 1px solid $gray-scale-color06;
      }
      &[aria-selected='true'] {
        @include b1-medium;
        position: relative;
        border: 1px solid $gray-scale-color06;
        border-right: 0;
        border-bottom: 1px solid #fff;
        background-color: #fff;
        color: $primary-color;
      }
    }
  }
  &--lv3 {
    @extend .tab;
    > [role='button'] {
      @include b1-medium;
      flex: 1;
      padding: 10px;
      border-top: 1px solid $gray-scale-color06;
      border-bottom: 1px solid $gray-scale-color06;
      background-color: $gray-scale-color08;
      & + [role='button'] {
        border-left: 1px solid $gray-scale-color06;
      }
      &.is-active {
        @include b1-bold;
        border-color: $primary-color02;
        background-color: $primary-color02;
        color: #fff;
        & + [role='button'] {
          border-left-color: $primary-color02;
        }
      }
      &:first-child {
        border-left: 1px solid $gray-scale-color06;
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-right: 1px solid $gray-scale-color06;
        border-radius: 0 10px 10px 0;
      }
    }
  }
  &--lv4 {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: $gray-scale-color05;
    }
    > [role='tab'],
    > [role='button'] {
      display: inline-flex;
      @include b1-medium;
      position: relative;
      padding-bottom: 12px;
      & + [role='tab'],
      & + [role='button'] {
        margin-left: 80px;
      }
      &[aria-selected='true'],
      &.is-active {
        @include b1-bold;
        color: $primary-color02;
        &:before {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 3px;
          background-color: $primary-color02;
        }
      }
    }
  }
  &.search-tab {
    [role='tab'] {
      flex: 0 0 206px;
    }
  }
}
