@charset "UTF-8";
:lang(ko) {
  font-family: "Noto Sans KR", "Arial", sans-serif;
  word-break: break-all;
}

/* Floating Bottom */
/* Header */
/* Fixed */
/* Modal */
/* Spinner */
/* Spinner */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var,
b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: inherit;
  color: inherit;
  box-sizing: border-box;
}

dl, ul, ol, menu, li {
  list-style: none;
}

fieldset, img {
  border: 0 none;
  box-sizing: border-box;
}

input, select, textarea, button {
  vertical-align: middle;
  margin: 0;
  padding: 0;
  font-family: inherit;
  box-sizing: border-box;
}

button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
  box-sizing: border-box;
}

address, caption, cite, code, dfn, em, var {
  font-style: normal;
  font-weight: normal;
  box-sizing: border-box;
}

input, textarea, img {
  max-width: 100%;
}

a, a:hover, a:visited, a:active {
  text-decoration: none;
}

sup {
  font-size: small;
  vertical-align: text-top;
}

sub {
  font-size: small;
  vertical-align: text-bottom;
}

button, select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
} /* Hide Native Arrow IE 10, 11 */
table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

table caption {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
} /* HTML5 for older browsers */
section, main {
  display: block;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6, em, strong {
  font-weight: 600;
}

input:disabled,
textarea:disabled {
  opacity: 1;
  -webkit-text-fill-color: inherit;
}

textarea:focus-visible {
  outline: none;
}

input[type=text],
input[type=password] {
  -webkit-appearance: none;
  border-radius: 0;
}

:before,
:after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  line-height: 0;
  font-size: 75%;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

textarea {
  overflow: auto;
}

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

progress {
  vertical-align: baseline;
}

details {
  display: block;
}

summary {
  display: list-item;
}

[hidden], template, hr {
  display: none;
}

button > span {
  position: relative;
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: local("※"), url("../fonts/NotoSansKR-Thin.woff2") format("woff2"), url("../fonts/NotoSansKR-Thin.woff") format("woff");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: local("※"), url("../fonts/NotoSansKR-Regular.woff2") format("woff2"), url("../fonts/NotoSansKR-Regular.woff") format("woff");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: local("※"), url("../fonts/NotoSansKR-Regular.woff2") format("woff2"), url("../fonts/NotoSansKR-Regular.woff") format("woff");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: local("※"), url("../fonts/NotoSansKR-Medium.woff2") format("woff2"), url("../fonts/NotoSansKR-Medium.woff") format("woff");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: local("※"), url("../fonts/NotoSansKR-Bold.woff2") format("woff2"), url("../fonts/NotoSansKR-Bold.woff") format("woff");
}
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: local("※"), url("../fonts/NotoSansKR-Black.woff2") format("woff2"), url("../fonts/NotoSansKR-Bold.woff") format("woff");
}
html {
  font-size: 0.8px;
}

body {
  font-size: 16rem;
  line-height: 1.6;
  color: #000;
  letter-spacing: -0.02em;
}

body.is-hidden {
  overflow: hidden;
}

.app {
  overflow: hidden;
}

.a11y-hidden {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
  border: 0;
}

input[type=text],
input[type=password],
input[type=email],
input[type=number],
input[type=tel],
input[type=date],
input[type=datetime-local],
input[type=time],
input[type=month],
input[type=week] {
  width: 100%;
  border-radius: 0;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: inherit;
}

:-ms-input-placeholder {
  color: #888888;
} /* Internet Explorer 10-11 */
::-ms-input-placeholder {
  color: #888888;
} /* Microsoft Edge */
::placeholder {
  color: #888888;
  opacity: 1;
  text-align: left;
} /* Chrome, Firefox, Opera, Safari 10.1+ */
textarea:-ms-input-placeholder {
  font-size: inherit;
  color: #888888;
} /* Internet Explorer 10-11 */
textarea::-ms-input-placeholder {
  font-size: inherit;
  color: #888888;
} /* Microsoft Edge */
textarea::placeholder {
  font-size: inherit;
  color: #888888;
  opacity: 1;
}

/* ScrollBar */
::-webkit-scrollbar-track {
  background-color: #f4f4f4;
  border-radius: 3rem;
}

::-webkit-scrollbar {
  width: 8rem;
  height: 8rem;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3rem;
}

textarea::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #ddd;
}

textarea::-webkit-scrollbar {
  width: 8rem;
  height: 8rem;
  background-color: #fff;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #b8b8b8;
  border-radius: 3rem;
  right: 20rem;
}

.pd0 {
  padding: 0 !important;
}

.pd40 {
  padding: 40rem !important;
}

.pdt0 {
  padding-top: 0 !important;
}

.pdt10 {
  padding-top: 10rem !important;
}

.pdt20 {
  padding-top: 30rem !important;
}

.pdt30 {
  padding-top: 30rem !important;
}

.pdt40 {
  padding-top: 40rem !important;
}

.pdt60 {
  padding-top: 60rem !important;
}

.pdt70 {
  padding-top: 70rem !important;
}

.pdr0 {
  padding-right: 0 !important;
}

.pdr10 {
  padding-right: 10rem !important;
}

.pdr30 {
  padding-right: 30rem !important;
}

.pdb0 {
  padding-bottom: 0 !important;
}

.pdb10 {
  padding-bottom: 10rem !important;
}

.pdb15 {
  padding-bottom: 15rem !important;
}

.pdb20 {
  padding-bottom: 20rem !important;
}

.pdb30 {
  padding-bottom: 30rem !important;
}

.pdb40 {
  padding-bottom: 40rem !important;
}

.pdb60 {
  padding-bottom: 60rem !important;
}

.pdb100 {
  padding-bottom: 100rem !important;
}

.pdl0 {
  padding-left: 0 !important;
}

.pdl10 {
  padding-left: 10rem !important;
}

.pdl20 {
  padding-left: 20rem !important;
}

.pdl24 {
  padding-left: 24rem !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt4 {
  margin-top: 4rem !important;
}

.mt5 {
  margin-top: 5rem !important;
}

.mt6 {
  margin-top: 6rem !important;
}

.mt10 {
  margin-top: 10rem !important;
}

.mt16 {
  margin-top: 16rem !important;
}

.mt20 {
  margin-top: 20rem !important;
}

.mt26 {
  margin-top: 26rem !important;
}

.mt20 {
  margin-top: 20rem !important;
}

.mt30 {
  margin-top: 30rem !important;
}

.mt40 {
  margin-top: 40rem !important;
}

.mt50 {
  margin-top: 50rem !important;
}

.mt60 {
  margin-top: 60rem !important;
}

.mt80 {
  margin-top: 80rem !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr10 {
  margin-right: 10rem !important;
}

.mr20 {
  margin-right: 20rem !important;
}

.mr25 {
  margin-right: 25rem !important;
}

.mr30 {
  margin-right: 30rem !important;
}

.mr40 {
  margin-right: 40rem !important;
}

.mr50 {
  margin-right: 50rem !important;
}

.mr100 {
  margin-right: 100rem !important;
}

.mr180 {
  margin-right: 180rem !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb10 {
  margin-bottom: 10rem !important;
}

.mb20 {
  margin-bottom: 20rem !important;
}

.mr120 {
  margin-right: 120rem !important;
}

.ml8 {
  margin-left: 8rem !important;
}

.ml10 {
  margin-left: 10rem !important;
}

.ml14 {
  margin-left: 14rem !important;
}

.ml20 {
  margin-left: 20rem !important;
}

.ml24 {
  margin-left: 24rem !important;
}

.ml25 {
  margin-left: 25rem !important;
}

.ml30 {
  margin-left: 30rem !important;
}

.ml40 {
  margin-left: 40rem !important;
}

.ml60 {
  margin-left: 60rem !important;
}

a.link {
  color: blue;
  text-decoration: underline;
  word-break: break-all;
}

.full {
  width: 100% !important;
}

.align-l {
  text-align: left;
}

.align-c {
  text-align: center;
}

.align-r {
  text-align: right;
}

.w-44 {
  width: 44rem !important;
}

.w-50 {
  width: 50rem !important;
}

.w-58 {
  width: 58rem !important;
}

.w-60 {
  width: 60rem !important;
}

.w-66 {
  width: 66rem !important;
}

.w-70 {
  width: 70rem !important;
}

.w-72 {
  width: 72rem !important;
}

.w-76 {
  width: 76rem !important;
}

.w-78 {
  width: 78rem !important;
}

.w-82 {
  width: 82rem !important;
}

.w-87 {
  width: 87rem !important;
}

.w-96 {
  width: 96rem !important;
}

.w-100 {
  width: 100rem !important;
}

.w-105 {
  width: 105rem !important;
}

.w-110 {
  width: 110rem !important;
}

.w-120 {
  width: 120rem !important;
}

.w-122 {
  width: 122rem !important;
}

.w-130 {
  width: 130rem !important;
}

.w-140 {
  width: 140rem !important;
}

.w-160 {
  width: 160rem !important;
}

.w-156 {
  width: 156rem !important;
}

.w-160 {
  width: 160rem !important;
}

.w-170 {
  width: 170rem !important;
}

.w-180 {
  width: 180rem !important;
}

.w-190 {
  width: 190rem !important;
}

.w-200 {
  width: 200rem !important;
}

.w-210 {
  width: 210rem !important;
}

.w-220 {
  width: 220rem !important;
}

.w-240 {
  width: 240rem !important;
}

.w-250 {
  width: 250rem !important;
}

.w-268 {
  width: 268rem !important;
}

.w-280 {
  width: 280rem !important;
}

.w-293 {
  width: 293rem !important;
}

.w-300 {
  width: 300rem !important;
}

.w-310 {
  width: 310rem !important;
}

.w-320 {
  width: 320rem !important;
}

.w-340 {
  width: 340rem !important;
}

.w-360 {
  width: 360rem !important;
}

.w-390 {
  width: 390rem !important;
}

.w-364 {
  width: 364rem !important;
}

.w-440 {
  width: 440rem !important;
}

.w-490 {
  width: 490rem !important;
}

.w-500 {
  width: 500rem !important;
}

.w-530 {
  width: 530rem !important;
}

.w-540 {
  width: 540rem !important;
}

.w-546 {
  width: 546rem !important;
}

.w-580 {
  width: 580rem !important;
}

.w-596 {
  width: 596rem !important;
}

.w-600 {
  width: 600rem !important;
}

.w-620 {
  width: 620rem !important;
}

.w-630 {
  width: 630rem !important;
}

.w-656 {
  width: 656rem !important;
}

.w-690 {
  width: 690rem !important;
}

.w-705 {
  width: 705rem !important;
}

.w-760 {
  width: 760rem !important;
}

.w-777 {
  width: 777rem !important;
}

.w-780 {
  width: 777rem !important;
}

.w-830 {
  width: 830rem !important;
}

.w-848 {
  width: 848rem !important;
}

.w-857 {
  width: 857rem !important;
}

.w-1100 {
  width: 1100rem !important;
}

.w-1200 {
  width: 1200rem !important;
}

.w-1300 {
  width: 1300rem !important;
}

.h1 {
  font-size: 40rem;
  line-height: 52rem;
  font-weight: 700;
}

.h2 {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
}

.h3 {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
}

.h4 {
  font-size: 18rem !important;
  line-height: 29rem !important;
  font-weight: 500 !important;
}

.b1 {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 400;
}
.b1-bold {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 700;
}
.b1-medium {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
}

.b2 {
  font-size: 16rem !important;
  line-height: 26rem !important;
  font-weight: 400 !important;
}
.b2-medium {
  font-size: 16rem !important;
  line-height: 26rem !important;
  font-weight: 500 !important;
}
.b2.dashed-top {
  padding-top: 20rem;
  border-top: 1px dashed #b8b8b8;
}

.b3 {
  font-size: 14rem !important;
  line-height: 22rem !important;
  font-weight: 400 !important;
}
.b3-medium {
  font-size: 14rem !important;
  line-height: 22rem !important;
  font-weight: 500 !important;
}

.b4 {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 400;
}
.b4-medium {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 500;
}

.c-black02 {
  color: #222 !important;
}

.c-blue {
  color: #042b69 !important;
}

.c-blue02 {
  color: #1472ce !important;
}

.c-blue03 {
  color: #5085ec !important;
}

.c-grey {
  color: #666 !important;
}

.c-grey02 {
  color: #888 !important;
}

.c-red {
  color: #f65959 !important;
}

.c-yellow {
  color: #ffbd3e !important;
}

.bg-yellow {
  background-color: #ffbd3e !important;
}
.bg-yellow-op5 {
  background-color: rgba(255, 189, 62, 0.5) !important;
}

.bg-beige {
  background-color: #faf8ee !important;
}

.bg-blue03 {
  background-color: #f2f7fb !important;
}

.primary-color {
  background-color: #042b69;
}

.primary-color02 {
  background-color: #1472ce;
}

.primary-color03 {
  background-color: #5085ec;
}

.primary-color04 {
  background-color: #e5ecf8;
}

.secondary-color {
  background-color: #1b8c6a;
}

.secondary-color02 {
  background-color: #f65959;
}

.secondary-color03 {
  background-color: #9a44de;
}

.secondary-color04 {
  background-color: #a8b1c2;
}

.secondary-color05 {
  background-color: #4a5366;
}

.secondary-color06 {
  background-color: #7593cd;
}

.secondary-color07 {
  background-color: #f2f7fb;
}

.secondary-color08 {
  background-color: #faf8ee;
}

.gray-scale-color {
  background-color: #000;
}

.gray-scale-color02 {
  background-color: #222;
}

.gray-scale-color03 {
  background-color: #666;
}

.gray-scale-color04 {
  background-color: #888;
}

.gray-scale-color05 {
  background-color: #b8b8b8;
}

.gray-scale-color06 {
  background-color: #cecece;
}

.gray-scale-color07 {
  background-color: #ddd;
}

.gray-scale-color08 {
  background-color: #f4f4f4;
}

.graph-color {
  background-color: #9673ff;
}

.graph-color02 {
  background-color: #54cbaa;
}

.graph-color03 {
  background-color: #ffbd3e;
}

.graph-color04 {
  background-color: #ff7070;
}

.graph-color05 {
  background-color: #badb5d;
}

.jc-center {
  justify-content: center;
}

.border-bottom-2 {
  border-bottom: 2rem solid #222;
}

.ps-re {
  position: relative;
}

.self-align-r {
  justify-self: flex-end;
}

.flex-justify-between {
  justify-content: space-between !important;
}

.flex-align-stretch {
  align-items: stretch !important;
}

.flex-align-center {
  align-items: center !important;
}

.overflow-visible {
  overflow: visible !important;
}

.flex-box {
  display: flex !important;
}

.pos-s {
  position: static !important;
}

.font-medium {
  font-weight: 500 !important;
}

.line2-bottom {
  border-bottom: 2rem solid #666;
}

.main-last-section {
  margin-bottom: -120rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  row-gap: 10rem;
  column-gap: 40rem;
}
.grid-container > div {
  box-sizing: content-box;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10rem;
}

.gcs-1 {
  grid-column-start: 1;
}

.gcs-2 {
  grid-column-start: 2;
}

.gcs-3 {
  grid-column-start: 3;
}

.gcs-4 {
  grid-column-start: 4;
}

.gcs-5 {
  grid-column-start: 5;
}

.gcs-6 {
  grid-column-start: 6;
}

.gcs-7 {
  grid-column-start: 7;
}

.gcs-8 {
  grid-column-start: 8;
}

.gcs-9 {
  grid-column-start: 9;
}

.gcs-10 {
  grid-column-start: 10;
}

.gcs-11 {
  grid-column-start: 11;
}

.gcs-12 {
  grid-column-start: 12;
}

.gcs-13 {
  grid-column-start: 13;
}

.gcs-14 {
  grid-column-start: 14;
}

.gcs-15 {
  grid-column-start: 15;
}

.gcs-16 {
  grid-column-start: 16;
}

.gce-1 {
  grid-column-end: 1;
}

.gce-2 {
  grid-column-end: 2;
}

.gce-3 {
  grid-column-end: 3;
}

.gce-4 {
  grid-column-end: 4;
}

.gce-5 {
  grid-column-end: 5;
}

.gce-6 {
  grid-column-end: 6;
}

.gce-7 {
  grid-column-end: 7;
}

.gce-8 {
  grid-column-end: 8;
}

.gce-9 {
  grid-column-end: 9;
}

.gce-10 {
  grid-column-end: 10;
}

.gce-11 {
  grid-column-end: 11;
}

.gce-12 {
  grid-column-end: 12;
}

.gce-13 {
  grid-column-end: 13;
}

.gce-14 {
  grid-column-end: 14;
}

.gce-15 {
  grid-column-end: 15;
}

.gce-16 {
  grid-column-end: 16;
}

[class^=col-] {
  display: flex;
  align-items: center;
}

.col-auto {
  flex: auto;
  width: auto;
}

.col-1 {
  flex: 0 0 9.0909090909%;
  max-width: 9.0909090909%;
}

.col-2 {
  flex: 0 0 18.1818181818%;
  max-width: 18.1818181818%;
}

.col-3 {
  flex: 0 0 27.2727272727%;
  max-width: 27.2727272727%;
}

.col-4 {
  flex: 0 0 36.3636363636%;
  max-width: 36.3636363636%;
}

.col-5 {
  flex: 0 0 45.4545454545%;
  max-width: 45.4545454545%;
}

.col-6 {
  flex: 0 0 54.5454545455%;
  max-width: 54.5454545455%;
}

.col-7 {
  flex: 0 0 63.6363636364%;
  max-width: 63.6363636364%;
}

.col-8 {
  flex: 0 0 72.7272727273%;
  max-width: 72.7272727273%;
}

.col-9 {
  flex: 0 0 81.8181818182%;
  max-width: 81.8181818182%;
}

.col-10 {
  flex: 0 0 90.9090909091%;
  max-width: 90.9090909091%;
}

.col-11 {
  flex: 0 0 100%;
  max-width: 100%;
}

.app-header {
  position: relative;
  min-width: 1440rem;
  z-index: 250;
  background-color: #fff;
}
.app-header-content {
  position: relative;
  min-height: 128rem;
}
.app-header .content-header {
  width: 1440rem;
  height: 68rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20rem;
}
.app-header .content-main {
  overflow: hidden;
  position: absolute;
  top: 84rem;
  right: 0;
  left: 0;
  z-index: 10;
  height: 44rem;
  background-color: #fff;
  transition: height 0.44s 0.1s cubic-bezier(0.04, 0.04, 0.12, 0.96), box-shadow 0.44s 0.44s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.app-header .content-main.is-active {
  height: 534rem;
  box-shadow: 0px 10rem 10rem rgba(0, 0, 0, 0.1);
  transition: height 0.44s 0.1s cubic-bezier(0.04, 0.04, 0.12, 0.96), box-shadow 0.44s 0.1s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.app-header .content-main-wrapper {
  width: 1440rem;
  margin-right: auto;
  margin-left: auto;
}
.app-header .content-footer {
  margin-top: 60rem;
}
.app-header .content-util {
  display: none;
  margin-top: 60rem;
  padding-bottom: 220rem;
}
.app-header-ci {
  display: block;
  position: absolute;
  top: 24rem;
  left: 50%;
  z-index: 1;
  width: 142rem;
  height: 40rem;
  margin-left: -659rem;
  transition: top 0s cubic-bezier(0.04, 0.04, 0.12, 0.96), margin-left 0s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.app-header-menu-container {
  position: absolute;
  top: 28rem;
  left: 50%;
  width: 32rem;
  height: 32rem;
  margin-left: -720rem;
}
.app-header-menu-container .menu-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 32rem;
  height: 32rem;
  z-index: 1;
  cursor: pointer;
}
.app-header-menu-container .menu-anchor-close {
  display: none;
}
.app-header-menu-container .menu-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 32rem;
  height: 32rem;
}
.app-header-menu-container .menu-icon-line {
  display: block;
  position: absolute;
  top: 15rem;
  left: 1px;
  width: 30rem;
  height: 2rem;
  background-color: #222;
  border-radius: 100rem;
}
.app-header-menu-container .menu-icon-line-top {
  transform: translateY(-12rem);
}
.app-header-menu-container .menu-icon-line-bottom {
  transform: translateY(12rem);
}
.app-header-menu-container .menu-anchor {
  position: absolute;
  top: 0;
  width: 32rem;
  height: 32rem;
}
.app-header-menu-container .menu-anchor.menuanchor-close {
  display: none;
}
.app-header:before {
  content: "";
  position: absolute;
  top: 126rem;
  right: 0;
  left: 0;
  z-index: 50;
  height: 2rem;
  background-color: #1472ce;
  pointer-events: none;
}

.contents-search {
  position: absolute;
  top: 20rem;
  left: 50%;
  z-index: 50;
  width: 880rem;
  margin-left: -440rem;
}

.sign-info-container {
  position: absolute;
  top: 24rem;
  right: 50%;
  z-index: 50;
  margin-right: -720rem;
  cursor: pointer;
}
.sign-info-container .sign-info-header {
  display: flex;
  align-items: center;
}
.sign-info-container .sign-info-header .sign-in-image {
  overflow: hidden;
  display: inline-flex;
  width: 40rem;
  margin-right: 10rem;
  border-radius: 50%;
}
.sign-info-container .sign-info-header .sign-in-user {
  position: relative;
}
.sign-info-container .sign-info-header .sign-in-user:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 3rem;
  left: 0;
  height: 1px;
  background: #000;
}
.sign-info-container .sign-info-header .sign-in-user .sign-in-user-name {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  display: flex;
}
.sign-info-container .sign-info-header .sign-in-user em {
  overflow: hidden;
  display: inline-block;
  max-width: 180rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  margin-right: 4rem;
}
.sign-info-container .sign-info-main {
  padding-top: 52rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 250;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20rem);
}
.sign-info-container .sign-info-main .sign-info-main-content {
  width: 176rem;
  border: 1px solid #cecece;
  background-color: #fff;
  box-shadow: 0px 4rem 4rem rgba(0, 0, 0, 0.25);
  border-radius: 20rem;
}
.sign-info-container .sign-info-main ul {
  padding: 8rem 19rem;
}
.sign-info-container .sign-info-main li {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
}
.sign-info-container .sign-info-main li a {
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 12rem;
  padding-bottom: 12rem;
}
.sign-info-container .sign-info-main li a:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  width: 20rem;
  height: 20rem;
  margin-right: 8rem;
}
.sign-info-container .sign-info-main li a.si-payment:before {
  background-position: -63rem 0;
}
.sign-info-container .sign-info-main li a.si-scrap-and-share:before {
  background-position: -84rem 0;
}
.sign-info-container .sign-info-main li a.si-share-group-settings:before {
  background-position: -105rem 0;
}
.sign-info-container .sign-info-main li a.si-user-notice:before {
  background-position: -126rem 0;
}
.sign-info-container .sign-info-main li a.si-profile:before {
  background-position: -147rem 0;
}
.sign-info-container .sign-info-main li a.si-user-settings:before {
  background-position: -168rem 0;
}
.sign-info-container .sign-info-main li a.si-sign-out:before {
  background-position: -189rem 0;
}
.sign-info-container .sign-info-main li a:hover {
  color: #1472ce;
  transition: color 0.22s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.sign-info-container .sign-info-main li a:hover:before {
  background-position-y: -21rem;
}
.sign-info-container .sign-info-main li a.si-user-notice[data-state=new]:after {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  width: 12rem;
  height: 12rem;
  margin-left: 4rem;
  background-position: -50rem -42rem;
}
.sign-info-container .sign-info-main li + li {
  border-top: 1px solid #ddd;
}
.sign-info-container .sign-info-main.is-active {
  transform: translateY(0);
  transition: transform 0.33s cubic-bezier(0.04, 0.04, 0.12, 0.96), opacity 0.44s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  opacity: 1;
  pointer-events: auto;
}

.pg-layout .app-header {
  background-color: #042b69;
}
.pg-layout .app-header-content {
  position: relative;
  width: 1440rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.pg-login .app-header {
  z-index: inherit;
  padding-top: 30rem;
  padding-bottom: 30rem;
  height: 100rem;
  background-color: #e5ecf8;
}
.pg-login .app-header:before {
  content: none;
}
.pg-login .app-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1440rem;
  height: 40rem;
  margin-right: auto;
  margin-left: auto;
}
.pg-login .app-header-ci {
  top: 0;
  left: 0;
  margin-left: 0;
}
.pg-login .app-header .e-campus-global {
  position: absolute;
  top: 0;
  right: 50%;
  margin-right: -720rem;
  mix-blend-mode: multiply;
}
.pg-login .app-content > .section, .pg-login .section--full.app-content > div,
.pg-login .app-content > .section--full {
  margin-top: 0;
}
.pg-login .app-content .notice-wrapper {
  margin-top: -280rem;
}

.app-header .ng-content > li.is-active > a {
  color: #1472ce;
}

.app-header[data-theme=default] {
  background-color: #fff;
}

.app-header[data-theme=blue] {
  background-color: #1472ce;
}
.app-header[data-theme=blue]:before {
  background-color: #1472ce;
}
.app-header[data-theme=blue] .app-header-ci {
  background-position-y: -101rem;
}
.app-header[data-theme=blue] .app-header-menu-container .menu-icon-line {
  background-color: #fff;
}
.app-header[data-theme=blue] .sign-in-user {
  color: #fff;
}
.app-header[data-theme=blue] .content-main:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 44rem;
  background-color: #1472ce;
  pointer-events: none;
}
.app-header[data-theme=blue] .ng-content > li {
  color: #fff;
}
.app-header[data-theme=blue] .ng-content > li > ul li {
  color: #000;
}
.app-header[data-theme=blue] .ng-content > li > ul li a:hover {
  color: #1472ce;
}

.app-header[data-theme=green] {
  background-color: #1b8c6a;
}
.app-header[data-theme=green]:before {
  background-color: #1b8c6a;
}
.app-header[data-theme=green] .app-header-ci {
  background-position-y: -101rem;
}
.app-header[data-theme=green] .app-header-menu-container .menu-icon-line {
  background-color: #fff;
}
.app-header[data-theme=green] .sign-in-user {
  color: #fff;
}
.app-header[data-theme=green] .content-main:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 44rem;
  background-color: #1b8c6a;
  pointer-events: none;
}
.app-header[data-theme=green] .ng-content > li {
  color: #fff;
}
.app-header[data-theme=green] .ng-content > li > ul li {
  color: #000;
}
.app-header[data-theme=green] .ng-content > li > ul li a:hover {
  color: #1b8c6a;
}

.contents-rcd-contents {
  position: absolute;
  top: 58rem;
  left: 0;
  z-index: 50;
  width: 880rem;
  padding: 29rem;
  border: 1px solid #cecece;
  background-color: #fff;
  box-shadow: 0px 10rem 10rem rgba(0, 0, 0, 0.1);
  border-radius: 20rem;
  opacity: 0;
  pointer-events: none;
}
.contents-rcd-contents > .rcd-section {
  position: relative;
}
.contents-rcd-contents > .rcd-section > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: block;
}
.contents-rcd-contents > .rcd-section > ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16rem;
  margin-bottom: -12rem;
}
.contents-rcd-contents > .rcd-section > ul > li {
  margin-right: 12rem;
  margin-bottom: 12rem;
}
.contents-rcd-contents > .rcd-section > ul > li > .button, .contents-rcd-contents > .rcd-section > ul > li > .rect-button, .contents-rcd-contents > .rcd-section > ul > li > .line-button, .contents-rcd-contents > .rcd-section > ul > li > .line-button--xs, .contents-rcd-contents > .rcd-section > ul > li > .line-button--sm, .contents-rcd-contents > .rcd-section > ul > li > .line-button--md, .contents-rcd-contents > .rcd-section > ul > li > .rect-line-button, .contents-rcd-contents > .rcd-section > ul > li > .rect-solid-button, .contents-rcd-contents > .rcd-section > ul > li > .rect-solid-button--sm, .contents-rcd-contents > .rcd-section > ul > li > .solid-button, .contents-rcd-contents > .rcd-section > ul > li > .solid-button--xs, .learning-status__quiz .quiz-list__item-answer .contents-rcd-contents > .rcd-section > ul > li > .tit, .contents-rcd-contents > .rcd-section > ul > li > .solid-button--sm, .contents-rcd-contents > .rcd-section > ul > li > .solid-button--md, .contents-rcd-contents > .rcd-section > ul > li > .solid-button--lg {
  height: 32rem;
  padding: 0 20rem;
  border-radius: 20rem;
}
.contents-rcd-contents > .rcd-section + .rcd-section {
  margin-top: 40rem;
}
.contents-rcd-contents > .rcd-section.recommend > ul > li .button, .contents-rcd-contents > .rcd-section.recommend > ul > li .rect-button, .contents-rcd-contents > .rcd-section.recommend > ul > li .line-button, .contents-rcd-contents > .rcd-section.recommend > ul > li .line-button--xs, .contents-rcd-contents > .rcd-section.recommend > ul > li .line-button--sm, .contents-rcd-contents > .rcd-section.recommend > ul > li .line-button--md, .contents-rcd-contents > .rcd-section.recommend > ul > li .rect-line-button, .contents-rcd-contents > .rcd-section.recommend > ul > li .rect-solid-button, .contents-rcd-contents > .rcd-section.recommend > ul > li .rect-solid-button--sm, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button--xs, .contents-rcd-contents > .rcd-section.recommend > ul > li .learning-status__quiz .quiz-list__item-answer .tit, .learning-status__quiz .quiz-list__item-answer .contents-rcd-contents > .rcd-section.recommend > ul > li .tit, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button--sm, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button--md, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button--lg {
  background-color: #f4f4f4;
  transition: all 0.11s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.contents-rcd-contents > .rcd-section.recommend > ul > li .button:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .rect-button:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .line-button:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .line-button--xs:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .line-button--sm:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .line-button--md:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .rect-line-button:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .rect-solid-button:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .rect-solid-button--sm:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button--xs:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .learning-status__quiz .quiz-list__item-answer .tit:before, .learning-status__quiz .quiz-list__item-answer .contents-rcd-contents > .rcd-section.recommend > ul > li .tit:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button--sm:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button--md:before, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button--lg:before {
  content: "#";
}
.contents-rcd-contents > .rcd-section.recommend > ul > li .button:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .rect-button:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .line-button:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .line-button--xs:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .line-button--sm:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .line-button--md:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .rect-line-button:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .rect-solid-button:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .rect-solid-button--sm:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button--xs:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .learning-status__quiz .quiz-list__item-answer .tit:hover, .learning-status__quiz .quiz-list__item-answer .contents-rcd-contents > .rcd-section.recommend > ul > li .tit:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button--sm:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button--md:hover, .contents-rcd-contents > .rcd-section.recommend > ul > li .solid-button--lg:hover {
  background-color: #1472ce;
  color: #fff;
}
.contents-rcd-contents > .rcd-section.recent > ul > li {
  position: relative;
}
.contents-rcd-contents > .rcd-section.recent > ul > li .button, .contents-rcd-contents > .rcd-section.recent > ul > li .rect-button, .contents-rcd-contents > .rcd-section.recent > ul > li .line-button, .contents-rcd-contents > .rcd-section.recent > ul > li .line-button--xs, .contents-rcd-contents > .rcd-section.recent > ul > li .line-button--sm, .contents-rcd-contents > .rcd-section.recent > ul > li .line-button--md, .contents-rcd-contents > .rcd-section.recent > ul > li .rect-line-button, .contents-rcd-contents > .rcd-section.recent > ul > li .rect-solid-button, .contents-rcd-contents > .rcd-section.recent > ul > li .rect-solid-button--sm, .contents-rcd-contents > .rcd-section.recent > ul > li .solid-button, .contents-rcd-contents > .rcd-section.recent > ul > li .solid-button--xs, .contents-rcd-contents > .rcd-section.recent > ul > li .learning-status__quiz .quiz-list__item-answer .tit, .learning-status__quiz .quiz-list__item-answer .contents-rcd-contents > .rcd-section.recent > ul > li .tit, .contents-rcd-contents > .rcd-section.recent > ul > li .solid-button--sm, .contents-rcd-contents > .rcd-section.recent > ul > li .solid-button--md, .contents-rcd-contents > .rcd-section.recent > ul > li .solid-button--lg {
  padding-right: 48rem;
  border: 1px solid #cecece;
}
.contents-rcd-contents > .rcd-section.recent > ul > li .icon-button {
  position: absolute;
  top: 10rem;
  right: 20rem;
}
.contents-rcd-contents > .rcd-section.recent .recent-no-data {
  display: none;
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  margin-top: 20rem;
  color: #666;
}
.contents-rcd-contents > .rcd-section.recent .recent-no-data.is-active {
  display: block;
}
.contents-rcd-contents > .rcd-section.recent .recent-delete-button {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  position: absolute;
  top: 1px;
  right: 0;
  color: #666;
}
.contents-rcd-contents > .rcd-section.popular > ul {
  counter-reset: section;
}
.contents-rcd-contents > .rcd-section.popular > ul > li .button, .contents-rcd-contents > .rcd-section.popular > ul > li .rect-button, .contents-rcd-contents > .rcd-section.popular > ul > li .line-button, .contents-rcd-contents > .rcd-section.popular > ul > li .line-button--xs, .contents-rcd-contents > .rcd-section.popular > ul > li .line-button--sm, .contents-rcd-contents > .rcd-section.popular > ul > li .line-button--md, .contents-rcd-contents > .rcd-section.popular > ul > li .rect-line-button, .contents-rcd-contents > .rcd-section.popular > ul > li .rect-solid-button, .contents-rcd-contents > .rcd-section.popular > ul > li .rect-solid-button--sm, .contents-rcd-contents > .rcd-section.popular > ul > li .solid-button, .contents-rcd-contents > .rcd-section.popular > ul > li .solid-button--xs, .contents-rcd-contents > .rcd-section.popular > ul > li .learning-status__quiz .quiz-list__item-answer .tit, .learning-status__quiz .quiz-list__item-answer .contents-rcd-contents > .rcd-section.popular > ul > li .tit, .contents-rcd-contents > .rcd-section.popular > ul > li .solid-button--sm, .contents-rcd-contents > .rcd-section.popular > ul > li .solid-button--md, .contents-rcd-contents > .rcd-section.popular > ul > li .solid-button--lg {
  height: 32rem;
  padding: 0 20rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.contents-rcd-contents > .rcd-section.popular > ul > li .button:before, .contents-rcd-contents > .rcd-section.popular > ul > li .rect-button:before, .contents-rcd-contents > .rcd-section.popular > ul > li .line-button:before, .contents-rcd-contents > .rcd-section.popular > ul > li .line-button--xs:before, .contents-rcd-contents > .rcd-section.popular > ul > li .line-button--sm:before, .contents-rcd-contents > .rcd-section.popular > ul > li .line-button--md:before, .contents-rcd-contents > .rcd-section.popular > ul > li .rect-line-button:before, .contents-rcd-contents > .rcd-section.popular > ul > li .rect-solid-button:before, .contents-rcd-contents > .rcd-section.popular > ul > li .rect-solid-button--sm:before, .contents-rcd-contents > .rcd-section.popular > ul > li .solid-button:before, .contents-rcd-contents > .rcd-section.popular > ul > li .solid-button--xs:before, .contents-rcd-contents > .rcd-section.popular > ul > li .learning-status__quiz .quiz-list__item-answer .tit:before, .learning-status__quiz .quiz-list__item-answer .contents-rcd-contents > .rcd-section.popular > ul > li .tit:before, .contents-rcd-contents > .rcd-section.popular > ul > li .solid-button--sm:before, .contents-rcd-contents > .rcd-section.popular > ul > li .solid-button--md:before, .contents-rcd-contents > .rcd-section.popular > ul > li .solid-button--lg:before {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  counter-increment: section;
  content: counter(section);
  margin-right: 10rem;
  color: #1472ce;
}
.contents-rcd-contents.is-active {
  pointer-events: auto;
  opacity: 1;
  transition: opacity 0.22s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}

.contents-am-contents {
  position: absolute;
  top: 58rem;
  left: 0;
  z-index: 50;
  width: 880rem;
  padding: 10rem;
  border: 1px solid #cecece;
  background-color: #fff;
  box-shadow: 0px 10rem 10rem rgba(0, 0, 0, 0.1);
  border-radius: 20rem;
  opacity: 0;
  pointer-events: none;
}
.contents-am-contents .am-list button {
  display: block;
  width: 100%;
  position: relative;
  padding-top: 11rem;
  padding-right: 20rem;
  padding-bottom: 11rem;
  padding-left: 48rem;
  border-radius: 10rem;
  text-align: left;
}
.contents-am-contents .am-list button.sc:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 14rem;
  left: 22rem;
  width: 20rem;
  height: 20rem;
  background-position: -193rem -59rem;
}
.contents-am-contents .am-list button.sc--history:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 14rem;
  left: 22rem;
  width: 20rem;
  height: 20rem;
  background-position: -172rem -59rem;
}
.contents-am-contents .am-list button > span {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.contents-am-contents .am-list button:hover {
  background-color: #f4f4f4;
}
.contents-am-contents.is-active {
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.22s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}

.menu-state {
  display: none;
}

.ng-content {
  display: flex;
  position: relative;
}
.ng-content:before, .ng-content:after {
  content: "";
  position: absolute;
  top: 44rem;
  bottom: 0;
  width: 1px;
  background-color: #ddd;
}
.ng-content:before {
  left: 0;
}
.ng-content:after {
  right: 0;
}
.ng-content a {
  display: block;
}
.ng-content > li {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  flex: 1;
  text-align: center;
}
.ng-content > li > ul {
  padding-top: 35rem;
  padding-bottom: 30rem;
}
.ng-content > li > ul li {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
}
.ng-content > li > ul li > a {
  display: block;
  padding: 0 20rem;
  word-break: keep-all;
}
.ng-content > li > ul li > a:hover {
  text-decoration: underline;
  font-weight: 500;
  color: #1472ce;
}
.ng-content > li > ul li + li {
  margin-top: 20rem;
}
.ng-content > li + li {
  position: relative;
}
.ng-content > li + li:before {
  content: "";
  position: absolute;
  top: 44rem;
  bottom: -30rem;
  left: 0;
  width: 1px;
  background-color: #ddd;
}

.menu-state:checked ~ .app-header {
  overflow-y: auto;
  height: 100vh;
}
.menu-state:checked ~ .app-header:before {
  top: 80rem;
}
.menu-state:checked ~ .app-header .app-header-ci {
  top: 20rem;
  margin-left: -720rem;
}
.menu-state:checked ~ .app-header .app-header-menu-container {
  top: 28rem;
  right: 50%;
  left: auto;
  width: 24rem;
  height: 24rem;
  margin-right: -720rem;
  margin-left: auto;
}
.menu-state:checked ~ .app-header .app-header-menu-container .menu-label,
.menu-state:checked ~ .app-header .app-header-menu-container .menu-icon {
  width: 24rem;
  height: 24rem;
}
.menu-state:checked ~ .app-header .app-header-menu-container .menu-icon-line {
  top: 11rem;
  left: 0;
  width: 24rem;
}
.menu-state:checked ~ .app-header .app-header-menu-container .menu-icon-line-top {
  transform: rotate(45deg);
}
.menu-state:checked ~ .app-header .app-header-menu-container .menu-icon-line-middle {
  opacity: 0;
}
.menu-state:checked ~ .app-header .app-header-menu-container .menu-icon-line-bottom {
  transform: rotate(-45deg);
}
.menu-state:checked ~ .app-header .content-footer {
  display: none;
}
.menu-state:checked ~ .app-header .content-util {
  display: block;
}
.menu-state:checked ~ .app-header .sign-info-container,
.menu-state:checked ~ .app-header .content-main,
.menu-state:checked ~ .app-header .content-footer,
.menu-state:checked ~ .app-header .contents-search {
  opacity: 0;
  pointer-events: none;
}

.app-content {
  min-height: calc(100vh - 178rem - 166rem);
  padding-bottom: 120rem;
}

.section-top {
  width: 1440rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40rem;
  padding-top: 50rem;
  padding-bottom: 20rem;
  border-bottom: 2rem solid #222;
}
.section-top h1 {
  font-size: 40rem;
  line-height: 52rem;
  font-weight: 700;
  font-weight: 500;
}
.section-top .tooltip [class^=icon] {
  margin-right: 10rem;
}
.section-top .tooltip-button {
  font-size: 40rem;
  line-height: 52rem;
  font-weight: 700;
  font-weight: 500;
}

.section, .section--full > div {
  width: 1440rem;
  margin-right: auto;
  margin-left: auto;
}
.section + .section, .section--full > div + .section, .section--full > .section + div, .section--full > div + div {
  padding-top: 60rem;
}
.section--full + .section, .section--full > .section--full + div, .section--full + .section--full {
  padding-top: 60rem;
}
.section[data-bgColor], .section--full > div[data-bgColor], .section--full[data-bgColor] {
  margin-top: 60rem;
  padding-top: 60rem;
  padding-bottom: 60rem;
}
.section[data-bgColor=blue], .section--full > div[data-bgColor=blue], .section--full[data-bgColor=blue] {
  background-color: #e5ecf8;
}
.section--full[data-bgColor] + [data-bgColor] {
  margin-top: -60rem;
}
.section-header, .section-header--line, .section-header--line--star {
  position: relative;
}
.section-header .more-link-button, .section-header--line .more-link-button, .section-header--line--star .more-link-button {
  position: absolute;
  top: 8rem;
  right: 0;
}
.section-header .select-container, .section-header--line .select-container, .section-header--line--star .select-container {
  display: flex;
  align-items: center;
}
.section-header .select-container-tit, .section-header--line .select-container-tit, .section-header--line--star .select-container-tit {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-right: 20rem;
}
.section-header .select-container, .section-header--line .select-container, .section-header--line--star .select-container,
.section-header .buttons-wrapper,
.section-header--line .buttons-wrapper,
.section-header--line--star .buttons-wrapper {
  position: absolute;
  right: 0;
  bottom: 0;
}
.section-header .buttons, .section-header--line .buttons, .section-header--line--star .buttons {
  display: flex;
  margin-top: -5rem;
  margin-left: 20rem;
}
.section-header .buttons [role=button] + [role=button], .section-header--line .buttons [role=button] + [role=button], .section-header--line--star .buttons [role=button] + [role=button] {
  margin-left: 10rem;
}
.section h3 > span, .section--full > div h3 > span {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  color: #666;
}

.pg-login .app-content {
  min-height: calc(100vh - 100rem - 166rem);
  padding-bottom: 60rem;
}

.pg-classroom .app-content {
  min-height: calc(100vh - 166rem);
}
.pg-classroom .app-classroom-container {
  display: flex;
  width: 1440rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 70rem;
}
.pg-classroom .app-classroom-ng {
  flex: 0 0 280rem;
}
.pg-classroom .app-classroom-main {
  flex: 1;
  margin-left: 40rem;
}
.pg-classroom .app-classroom-main > .section, .pg-classroom .section--full.app-classroom-main > div {
  width: 1120rem;
}
.pg-classroom .application-main .application-info {
  flex: 0 0 545rem;
  padding: 9rem 29rem;
}
.pg-classroom h3 {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  padding-bottom: 20rem;
}

.app-footer {
  position: relative;
  height: 166rem;
  background-color: #a8b1c2;
}
.app-footer-content {
  display: flex;
  width: 1440rem;
  margin-right: auto;
  margin-left: auto;
  padding-top: 40rem;
  padding-bottom: 30rem;
}
.app-footer-content .app-footer-ci {
  flex: 0 0 178rem;
}
.app-footer-content > div {
  flex: 1;
  margin-left: 48rem;
}
.app-footer__util {
  display: flex;
}
.app-footer__util > li {
  position: relative;
  font-size: 14rem;
  line-height: 22rem;
  color: #fff;
}
.app-footer__util > li + li {
  margin-left: 16rem;
  padding-left: 17rem;
}
.app-footer__util > li + li:before {
  content: "";
  position: absolute;
  top: 5rem;
  left: 0;
  width: 1px;
  height: 12rem;
  background-color: #fff;
}
.app-footer__address {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  display: flex;
  color: #fff;
}
.app-footer__business-hours {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  margin-left: 42rem;
  color: #fff;
}
.app-footer__copyright {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  color: #fff;
  opacity: 0.6;
}

.app-aside {
  position: relative;
  width: 1440rem;
  margin-right: auto;
  margin-left: auto;
}
.app-aside-content {
  width: 70rem;
  margin-left: auto;
  margin-right: -158rem;
}

.aside-wrapper {
  position: fixed;
  bottom: 35rem;
  z-index: 200;
  transition: opacity 0.6s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.aside-wrapper.is-hidden {
  opacity: 0;
  pointer-events: none;
}
.aside-wrapper.is-visible {
  opacity: 1;
  pointer-events: auto;
}

.aside-button, button.aside-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70rem;
  height: 70rem;
  border-radius: 50%;
  filter: drop-shadow(0px 7px 13px rgba(0, 0, 0, 0.1));
}
.aside-button + .aside-button {
  margin-top: 12rem;
}
.aside-button__text {
  display: block;
  margin-top: 4rem;
  font-size: 12rem;
  line-height: 14rem;
  font-weight: 500;
  color: #fff;
}

button.aside-button > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-up-button {
  background-color: #fff;
}

.help-center-button {
  background-color: #4a5366;
}

.app-aside-menu {
  position: fixed;
  top: 50%;
  right: calc(50% - 903rem);
  z-index: 300;
  width: 123rem;
  border: 1px solid #cecece;
  background-color: #fff;
  box-shadow: 0px 9rem 10rem rgba(0, 0, 0, 0.1);
  border-radius: 20rem;
  transform: translateY(-50%);
}
.app-aside-menu > ul > li a {
  position: relative;
}
.app-aside-menu > ul > li:first-child a {
  border-radius: 20rem 20rem 0 0;
}
.app-aside-menu > ul > li:last-child a {
  border-radius: 0 0 20rem 20rem;
}
.app-aside-menu > ul > li:last-child a:hover:before {
  content: none;
}
.app-aside-menu > ul > li:first-child:last-child a {
  border-radius: 20rem;
}
.app-aside-menu > ul > li + li {
  position: relative;
}
.app-aside-menu > ul > li + li:before {
  content: "";
  position: absolute;
  top: -1px;
  right: 14rem;
  left: 14rem;
  height: 1px;
  background-color: #c2cfd7;
}
.app-aside-menu .aside-menu-button {
  display: block;
  padding-top: 12rem;
  padding-right: 14rem;
  padding-bottom: 12rem;
  padding-left: 14rem;
  text-align: center;
  background-color: #fff;
}
.app-aside-menu .aside-menu-button:hover {
  background-color: #5085ec;
}
.app-aside-menu .aside-menu-button:hover [class^=icon] {
  display: none;
}
.app-aside-menu .aside-menu-button:hover [class^=icon][class*=hover] {
  display: inline-block;
}
.app-aside-menu .aside-menu-button:hover .aside-menu-text {
  color: #fff;
}
.app-aside-menu .aside-menu-button:hover:before {
  content: "";
  position: absolute;
  right: 14rem;
  bottom: 0;
  z-index: 1;
  left: 14rem;
  height: 1px;
  background-color: #5085ec;
}
.app-aside-menu .aside-menu-button [class^=icon] {
  vertical-align: top;
}
.app-aside-menu .aside-menu-button [class^=icon][class*=hover] {
  display: none;
}
.app-aside-menu .aside-menu-text {
  display: block;
  margin-top: 2rem;
  font-size: 12rem;
  line-height: 19rem;
  color: #21343f;
}

.depth2 {
  background-color: #fff;
  box-shadow: 0px 10rem 10rem rgba(0, 0, 0, 0.1);
}
.depth2-content {
  position: relative;
  min-width: 1440rem;
}
.depth2-container {
  position: relative;
  width: 1360rem !important;
  padding: 0 10rem;
}
.depth2-container:before, .depth2-container:after {
  content: "";
  position: absolute;
  z-index: 10;
  top: 6rem;
  width: 37rem;
  height: 38rem;
  background: linear-gradient(-270deg, #fff 13.89%, rgba(255, 255, 255, 0) 91.2%), transparent;
}
.depth2-container:before {
  left: 0px;
}
.depth2-container:after {
  right: 0px;
  background: linear-gradient(270deg, #fff 13.89%, rgba(255, 255, 255, 0) 91.2%), transparent;
}
.depth2-item {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  width: auto;
}
.depth2-item > a {
  display: block;
  padding: 12rem 20rem 16rem;
}
.depth2-item > a > span {
  display: block;
  position: relative;
}
.depth2-item.is-active > a > span {
  color: #1472ce;
}
.depth2-item.is-active > a > span:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: -6rem;
  left: 0;
  height: 2rem;
  background-color: #1472ce;
}
.depth2-button-next, .depth2-button-prev {
  position: absolute;
  top: 13rem;
  width: 24rem;
  height: 24rem;
  cursor: pointer;
}
.depth2-button-next {
  right: calc(50% - 720rem);
}
.depth2-button-next [class^=icon] {
  transform: rotate(180deg);
}
.depth2-button-prev {
  left: calc(50% - 720rem);
}

.depth3 {
  padding-top: 20rem;
}
.depth3-content {
  min-width: 1440rem;
}
.depth3-container {
  display: flex;
  flex-wrap: wrap;
  width: 1300rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: -20rem;
}
.depth3-container > li {
  margin-right: 20rem;
  margin-bottom: 20rem;
}
.depth3--lv2 {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  height: 0;
  background-color: #fff;
  box-shadow: 0px 10rem 20rem rgba(0, 0, 0, 0.2);
  transition: height 0.44s 0.1s cubic-bezier(0.04, 0.04, 0.12, 0.96), box-shadow 0.44s 0.44s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.depth3--lv2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 1px;
  background-color: #1472ce;
  pointer-events: none;
  transition: opacity 0.45s 0.3s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  opacity: 0;
  pointer-events: none;
}
.depth3--lv2.is-active {
  height: 463rem;
  transition: height 0.44s 0.1s cubic-bezier(0.04, 0.04, 0.12, 0.96), box-shadow 0.44s 0.1s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.depth3--lv2.is-active:before {
  opacity: 1;
  transition: opacity 0.22s 0.1s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.depth3--lv2 .depth3-content {
  display: flex;
  position: relative;
  max-width: 1440rem;
  height: 100%;
  margin: 0 auto;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}
.depth3--lv2 .depth3-content > div {
  flex: 0 0 25%;
}
.depth3--lv2 .depth-wrapper {
  position: relative;
}
.depth3--lv2 .depth-wrapper + .depth-wrapper {
  border-left: 1px solid #ddd;
}
.depth3--lv2 .depth-wrapper ul {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 10rem;
  padding-bottom: 13rem;
}
.depth3--lv2 .depth-wrapper ul > li a {
  display: block;
  padding: 10rem 70rem 4rem 70rem;
}
.depth3--lv2 .depth-wrapper ul > li a:hover {
  background-color: #1472ce;
  color: #fff;
}
.depth3--lv2 .depth-wrapper ul > li a span {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
}
.depth3--lv2 .depth-wrapper ul > li.is-active a {
  background-color: #1472ce;
  color: #fff;
}

.is-menu {
  overflow: hidden;
}
.is-menu .app-aside-menu {
  display: none;
}

.sitemap > ul {
  width: 1440rem;
  margin-right: auto;
  margin-left: auto;
}
.sitemap > ul > li + li {
  margin-top: 20rem;
}
.sitemap > ul > li > [role=button] {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
  display: block;
  position: relative;
  padding-bottom: 10rem;
  border-bottom: 2rem solid #666;
  border-bottom: 1px solid #bbb;
}
.sitemap > ul > li > [role=button]:before {
  content: "";
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  position: absolute;
  top: 5rem;
  right: 0;
  width: 32rem;
  height: 32rem;
  background-position: -286rem 0;
  transform: rotateX(180deg);
  transition: transform 0.22s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.sitemap > ul > li > ul {
  display: none;
  flex-wrap: wrap;
}
.sitemap > ul > li > ul > li {
  flex: 0 0 220rem;
  margin-right: 20rem;
  padding-top: 20rem;
  padding-bottom: 20rem;
}
.sitemap > ul > li > ul > li > [role=button] {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  display: inline-block;
}
.sitemap > ul > li > ul > li > [role=button]:hover {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 700;
  color: #1472ce;
}
.sitemap > ul > li > ul > li > ul {
  padding: 10rem 8rem 10rem 20rem;
}
.sitemap > ul > li > ul > li > ul > li > [role=button] {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
}
.sitemap > ul > li > ul > li > ul > li > [role=button]:hover {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  color: #1472ce;
  text-decoration: underline;
}
.sitemap > ul > li > ul > li > ul > li + li {
  margin-top: 8rem;
}
.sitemap > ul > li > ul > li:hover > [role=button] {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 700;
  color: #1472ce;
}
.sitemap > ul > li.is-active > [role=button]:before {
  transform: rotateX(0deg);
}
.sitemap > ul > li.is-active > ul {
  display: flex;
}

.breadcrumb {
  display: flex;
  padding-top: 15rem;
}
.breadcrumb li {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  position: relative;
  color: rgba(0, 0, 0, 0.5);
}
.breadcrumb li + li {
  padding-left: 26rem;
}
.breadcrumb li + li:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 7rem;
  left: 10rem;
  width: 6rem;
  height: 10rem;
  background-position: -227rem -59rem;
  pointer-events: none;
  opacity: 0.5;
}
.breadcrumb li:first-child > a:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  display: inline-block;
  position: relative;
  top: 2rem;
  width: 13rem;
  height: 16rem;
  margin-right: 8rem;
  background-position: -68rem -143rem;
}
.breadcrumb li:last-child {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  color: rgb(0, 0, 0);
}
.breadcrumb li:last-child:before {
  opacity: 1;
}

.app-classroom-header {
  height: 138rem;
  padding: 30rem 40rem;
  background: linear-gradient(255.15deg, #784ef0 2.96%, rgba(98, 58, 213, 0) 98.99%), #205dd3;
  border-radius: 20rem 20rem 0px 0px;
}
.app-classroom-header > span {
  display: block;
  color: #fff;
}
.app-classroom-header > strong {
  font-size: 32rem;
  line-height: 42rem;
  font-weight: 500;
  display: block;
  margin-top: 10rem;
  color: #fff;
}
.app-classroom-list {
  padding: 15rem 30rem;
  border: 1px solid #cecece;
  border-radius: 0 0 20rem 20rem;
}
.app-classroom-list > li + li {
  border-top: 1px solid #ddd;
}
.app-classroom-list > li > a {
  display: block;
  position: relative;
  padding: 17rem 40rem;
  transition: color 0.22s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.app-classroom-list > li > a:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 18rem;
  left: 10rem;
  width: 24rem;
  height: 24rem;
  background-position-y: -177rem;
}
.app-classroom-list > li > a.classroom-home:before {
  background-position-x: 0;
}
.app-classroom-list > li > a.classroom-intro:before {
  background-position-x: -25rem;
}
.app-classroom-list > li > a.classroom-notice:before {
  background-position-x: -50rem;
}
.app-classroom-list > li > a.classroom-learning:before {
  background-position-x: -75rem;
}
.app-classroom-list > li > a.classroom-progress:before {
  background-position-x: -100rem;
}
.app-classroom-list > li > a.classroom-student:before {
  background-position-x: -125rem;
}
.app-classroom-list > li > a.classroom-instructor:before {
  background-position-x: -150rem;
}
.app-classroom-list > li > a.classroom-reaction:before {
  background-position-x: -175rem;
}
.app-classroom-list > li > a.classroom-test:before {
  background-position-x: -200rem;
}
.app-classroom-list > li > a.classroom-task:before {
  background-position-x: -225rem;
}
.app-classroom-list > li > a.classroom-qna:before {
  background-position-x: -250rem;
}
.app-classroom-list > li > a.classroom-board:before {
  background-position-x: -275rem;
}
.app-classroom-list > li > a.classroom-library:before {
  background-position-x: -300rem;
}
.app-classroom-list > li > a.classroom-review:before {
  background-position-x: -325rem;
}
.app-classroom-list > li > a:hover:before {
  background-position-y: -202rem;
}
.app-classroom-list > li.is-active a {
  color: #1472ce;
}
.app-classroom-list > li.is-active a:before {
  background-position-y: -202rem;
}

.modal, .modal--standard02, .modal--standard {
  overflow: auto;
  display: none;
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.modal--standard {
  background-color: rgba(0, 0, 0, 0.7);
}
.modal--standard02 {
  background-color: rgba(0, 0, 0, 0.3);
}
.modal-close-button {
  position: absolute;
  top: 30rem;
  right: 30rem;
}
.modal__content {
  display: flex;
  justify-content: center;
  -webkit-box-pack: center;
  width: 100%;
  height: 100%;
}
.modal__content-table {
  overflow: hidden;
  display: table;
  width: 100%;
  height: 100%;
}
.modal__content-cell {
  display: table-cell;
  vertical-align: middle;
}
.modal__content-wrapper, .modal__content-wrapper--xs, .modal__content-wrapper--sm, .modal__content-wrapper--md, .modal__content-wrapper--lg {
  margin-right: auto;
  margin-left: auto;
  padding-top: 120rem;
  padding-bottom: 120rem;
}
.modal__content-padding {
  position: relative;
  z-index: 1;
  margin-right: auto;
  margin-left: auto;
}
.modal-content {
  background-color: #fff;
  box-shadow: 0px 0px 40rem rgba(0, 0, 0, 0.25);
  border-radius: 15rem 15rem 10rem 10rem;
}
.modal-header {
  background-color: #042b69;
  border-radius: 10rem 10rem 0 0;
  color: #fff;
}
.modal-footer[data-bgColor=grey] {
  background-color: #f4f4f4;
  border-radius: 0 0 10rem 10rem;
}
.modal-buttons {
  display: flex;
  justify-content: center;
  margin-top: 30rem;
}
.modal__button-group__center {
  text-align: center;
}
.modal__button-group__left {
  float: left;
}
.modal__button-group__right {
  float: right;
}
.modal__button-group a + a, .modal__button-group a + button, .modal__button-group button + button, .modal__button-group button + a {
  margin-left: 10rem;
}
.modal-close-button {
  position: absolute;
  top: 0;
  right: 0;
}
.is-modal {
  overflow: hidden;
}

.modal--open {
  display: block;
  z-index: 350;
}

.modal__content-wrapper--lg {
  width: 1080rem;
}
.modal__content-wrapper--lg .modal-header {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  padding: 14rem 104rem 14rem 40rem;
}
.modal__content-wrapper--lg .modal-header span {
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  max-height: 64rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
}
.modal__content-wrapper--lg .modal-footer {
  padding: 30rem 40rem;
}
.modal__content-wrapper--lg .modal-subsection {
  padding: 30rem 40rem;
}
.modal__content-wrapper--lg .modal-close-button {
  width: 74rem;
  height: 60rem;
}
.modal__content-wrapper--lg .modal-close-button > span {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  width: 24rem;
  height: 24rem;
  margin-left: 10rem;
  background-position: -147rem -101rem;
}
.modal__content-wrapper--md {
  width: 800rem;
}
.modal__content-wrapper--md .modal-header {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  padding: 14rem 104rem 14rem 40rem;
}
.modal__content-wrapper--md .modal-footer {
  padding: 30rem 40rem;
}
.modal__content-wrapper--md .modal-subsection {
  padding: 40rem;
}
.modal__content-wrapper--md .modal-close-button {
  width: 74rem;
  height: 60rem;
}
.modal__content-wrapper--md .modal-close-button > span {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  width: 24rem;
  height: 24rem;
  margin-left: 10rem;
  background-position: -147rem -101rem;
}
.modal__content-wrapper--sm {
  width: 600rem;
}
.modal__content-wrapper--sm .modal-header {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  padding: 14rem 56rem 14rem 20rem;
}
.modal__content-wrapper--sm .modal-subsection {
  padding: 40rem;
}
.modal__content-wrapper--sm .modal-footer {
  padding: 30rem 20rem;
}
.modal__content-wrapper--sm .modal-close-button {
  width: 66rem;
  height: 60rem;
}
.modal__content-wrapper--sm .modal-close-button > span {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  width: 16rem;
  height: 16rem;
  margin-left: 10rem;
  background-position: -147rem -84rem;
}
.modal__content-wrapper--xs {
  width: 400rem;
}
.modal__content-wrapper--xs .modal-header {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  padding: 11rem 56rem 11rem 20rem;
}
.modal__content-wrapper--xs .modal-subsection {
  padding: 30rem 20rem;
}
.modal__content-wrapper--xs .modal-footer {
  padding: 30rem 20rem;
}
.modal__content-wrapper--xs .modal-close-button {
  width: 46rem;
  height: 48rem;
}
.modal__content-wrapper--xs .modal-close-button > span {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  align-items: center;
  display: flex;
  width: 16rem;
  height: 16rem;
  margin-left: 10rem;
  background-position: -147rem -84rem;
}

.app-popup-container, .app-popup-container--full, .app-popup-container--lg, .app-popup-container--md, .app-popup-container--sm {
  margin-right: auto;
  margin-left: auto;
}
.app-popup-container .popup-main > .section, .app-popup-container--full .popup-main > .section, .app-popup-container--lg .popup-main > .section, .app-popup-container--md .popup-main > .section, .app-popup-container--sm .popup-main > .section, .app-popup-container .section--full.popup-main > div, .app-popup-container--full .section--full.popup-main > div, .app-popup-container--lg .section--full.popup-main > div, .app-popup-container--md .section--full.popup-main > div, .app-popup-container--sm .section--full.popup-main > div {
  width: auto;
}
.app-popup-container--sm .popup-main {
  padding: 40rem;
}
.app-popup-container--sm .popup-main > .section .section-header h1, .app-popup-container--sm .popup-main > .section .section-header--line h1, .app-popup-container--sm .popup-main > .section .section-header--line--star h1, .app-popup-container--sm .section--full.popup-main > div .section-header h1, .app-popup-container--sm .section--full.popup-main > div .section-header--line h1, .app-popup-container--sm .section--full.popup-main > div .section-header--line--star h1 {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  padding-bottom: 20rem;
}
.app-popup-container--md .popup-main {
  padding: 30rem 40rem;
}
.app-popup-container--md .popup-main > .section .section-header h1, .app-popup-container--md .popup-main > .section .section-header--line h1, .app-popup-container--md .popup-main > .section .section-header--line--star h1, .app-popup-container--md .section--full.popup-main > div .section-header h1, .app-popup-container--md .section--full.popup-main > div .section-header--line h1, .app-popup-container--md .section--full.popup-main > div .section-header--line--star h1 {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  padding-bottom: 20rem;
}
.app-popup-container--md .popup-footer {
  padding: 30rem 40rem;
}
.app-popup-container--lg .popup-main {
  padding: 40rem;
  padding-bottom: 180rem;
}
.app-popup-container--lg .popup-main > .section .section-header h1, .app-popup-container--lg .popup-main > .section .section-header--line h1, .app-popup-container--lg .popup-main > .section .section-header--line--star h1, .app-popup-container--lg .section--full.popup-main > div .section-header h1, .app-popup-container--lg .section--full.popup-main > div .section-header--line h1, .app-popup-container--lg .section--full.popup-main > div .section-header--line--star h1 {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  padding-bottom: 20rem;
}
.app-popup-container--lg .popup-main > .section .board-header h1, .app-popup-container--lg .popup-main > .section .board-header--align-r h1, .app-popup-container--lg .popup-main > .section .board-header--align-c h1, .app-popup-container--lg .section--full.popup-main > div .board-header h1, .app-popup-container--lg .section--full.popup-main > div .board-header--align-r h1, .app-popup-container--lg .section--full.popup-main > div .board-header--align-c h1 {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
}
.app-popup-container--lg .popup-footer {
  padding: 40rem;
}
.app-popup-container--full {
  width: 100%;
}
.app-popup-container .table-wrapper, .app-popup-container--sm .table-wrapper, .app-popup-container--md .table-wrapper, .app-popup-container--lg .table-wrapper, .app-popup-container--full .table-wrapper {
  overflow-x: visible;
  overflow-y: visible;
}
.app-popup-container .table-wrapper .table, .app-popup-container .table-wrapper .table--lv3, .app-popup-container .table-wrapper .table--lv2, .app-popup-container .table-wrapper .table--lv2-high, .app-popup-container .table-wrapper .table--border-grey, .app-popup-container--sm .table-wrapper .table, .app-popup-container--sm .table-wrapper .table--lv3, .app-popup-container--sm .table-wrapper .table--lv2, .app-popup-container--sm .table-wrapper .table--lv2-high, .app-popup-container--sm .table-wrapper .table--border-grey, .app-popup-container--md .table-wrapper .table, .app-popup-container--md .table-wrapper .table--lv3, .app-popup-container--md .table-wrapper .table--lv2, .app-popup-container--md .table-wrapper .table--lv2-high, .app-popup-container--md .table-wrapper .table--border-grey, .app-popup-container--lg .table-wrapper .table, .app-popup-container--lg .table-wrapper .table--lv3, .app-popup-container--lg .table-wrapper .table--lv2, .app-popup-container--lg .table-wrapper .table--lv2-high, .app-popup-container--lg .table-wrapper .table--border-grey, .app-popup-container--full .table-wrapper .table, .app-popup-container--full .table-wrapper .table--lv3, .app-popup-container--full .table-wrapper .table--lv2, .app-popup-container--full .table-wrapper .table--lv2-high, .app-popup-container--full .table-wrapper .table--border-grey {
  width: 100%;
}

.popup-footer[data-bgcolor=grey] {
  background-color: #f4f4f4;
}

/*******************************************************************************
* Tree container
*/
ul.dynatree-container {
  font-family: tahoma, arial, helvetica;
  font-size: 10pt; /* font size should not be too big */
  white-space: nowrap;
  padding: 3rem;
  margin: 0; /* issue 201 */
  background-color: white;
  border: 1px dotted gray;
  overflow: auto;
  height: 100%; /* issue 263 */
}

ul.dynatree-container ul {
  padding: 0 0 0 16rem;
  margin: 0;
}

ul.dynatree-container li {
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  -moz-background-clip: border;
  -moz-background-inline-policy: continuous;
  -moz-background-origin: padding;
  background-attachment: scroll;
  background-color: transparent;
  background-position: 0 0;
  background-repeat: repeat-y;
  background-image: none; /* no v-lines */
  margin: 0;
  padding: 1px 0 0 0;
}

/* Suppress lines for last child node */
ul.dynatree-container li.dynatree-lastsib {
  background-image: none;
}

/* Suppress lines if level is fixed expanded (option minExpandLevel) */
ul.dynatree-no-connector > li {
  background-image: none;
}

/* Style, when control is disabled */
.ui-dynatree-disabled ul.dynatree-container {
  opacity: 0.5;
  /*	filter: alpha(opacity=50); /* Yields a css warning */
  background-color: silver;
}

/*******************************************************************************
* Common icon definitions
*/
span.dynatree-empty,
span.dynatree-vline,
span.dynatree-connector,
span.dynatree-expander,
span.dynatree-icon,
span.dynatree-checkbox,
span.dynatree-radio,
span.dynatree-drag-helper-img,
#dynatree-drop-marker {
  width: 16rem;
  height: 16rem;
  /*	display: -moz-inline-box; /* @ FF 1+2 removed for issue 221*/
  /*	-moz-box-align: start; /* issue 221 */
  display: inline-block; /* Required to make a span sizeable */
  vertical-align: top;
  background-repeat: no-repeat;
  background-position: left;
  background-image: url("icons.gif");
  background-position: 0 0;
}

/** Used by 'icon' node option: */
ul.dynatree-container img {
  width: 16rem;
  height: 16rem;
  margin-left: 3rem;
  vertical-align: top;
  border-style: none;
}

/*******************************************************************************
* Lines and connectors
*/
/*
span.dynatree-empty
{
}
span.dynatree-vline
{
}
*/
span.dynatree-connector {
  background-image: none;
}

/*
.dynatree-lastsib span.dynatree-connector
{
}
*/
/*******************************************************************************
* Expander icon
* Note: IE6 doesn't correctly evaluate multiples class names,
*		 so we create combined class names that can be used in the CSS.
*
* Prefix: dynatree-exp-
* 1st character: 'e': expanded, 'c': collapsed
* 2nd character (optional): 'd': lazy (Delayed)
* 3rd character (optional): 'l': Last sibling
*/
span.dynatree-expander {
  background-position: 0px -80rem;
  cursor: pointer;
}

span.dynatree-expander:hover {
  background-position: -16rem -80rem;
}

.dynatree-exp-e span.dynatree-expander,
.dynatree-exp-ed span.dynatree-expander,
.dynatree-exp-el span.dynatree-expander,
.dynatree-exp-edl span.dynatree-expander {
  background-position: -32rem -80rem;
}

.dynatree-exp-e span.dynatree-expander:hover,
.dynatree-exp-ed span.dynatree-expander:hover,
.dynatree-exp-el span.dynatree-expander:hover,
.dynatree-exp-edl span.dynatree-expander:hover {
  background-position: -48rem -80rem;
}

.dynatree-loading span.dynatree-expander {
  background-position: 0 0;
  background-image: url("loading.gif");
}

/*******************************************************************************
* Checkbox icon
*/
span.dynatree-checkbox {
  margin-left: 3rem;
  background-position: 0px -32rem;
}

span.dynatree-checkbox:hover {
  background-position: -16rem -32rem;
}

.dynatree-partsel span.dynatree-checkbox {
  background-position: -64rem -32rem;
}

.dynatree-partsel span.dynatree-checkbox:hover {
  background-position: -80rem -32rem;
}

.dynatree-selected span.dynatree-checkbox {
  background-position: -32rem -32rem;
}

.dynatree-selected span.dynatree-checkbox:hover {
  background-position: -48rem -32rem;
}

/*******************************************************************************
* Radiobutton icon
* This is a customization, that may be activated by overriding the 'checkbox'
* class name as 'dynatree-radio' in the tree options.
*/
span.dynatree-radio {
  margin-left: 3rem;
  background-position: 0px -48rem;
}

span.dynatree-radio:hover {
  background-position: -16rem -48rem;
}

.dynatree-partsel span.dynatree-radio {
  background-position: -64rem -48rem;
}

.dynatree-partsel span.dynatree-radio:hover {
  background-position: -80rem -48rem;
}

.dynatree-selected span.dynatree-radio {
  background-position: -32rem -48rem;
}

.dynatree-selected span.dynatree-radio:hover {
  background-position: -48rem -48rem;
}

/*******************************************************************************
* Node type icon
* Note: IE6 doesn't correctly evaluate multiples class names,
*		 so we create combined class names that can be used in the CSS.
*
* Prefix: dynatree-ico-
* 1st character: 'e': expanded, 'c': collapsed
* 2nd character (optional): 'f': folder
*/
span.dynatree-icon {
  margin-left: 3rem;
  background-position: 0px 0px;
}

.dynatree-has-children span.dynatree-icon {
  /*    background-position: 0px -16px; */
}

.dynatree-ico-cf span.dynatree-icon {
  background-position: 0px -16rem;
}

.dynatree-ico-ef span.dynatree-icon {
  background-position: -64rem -16rem;
}

/* Status node icons */
.dynatree-statusnode-wait span.dynatree-icon {
  background-image: url("loading.gif");
}

.dynatree-statusnode-error span.dynatree-icon {
  background-position: 0px -112rem;
  /*	background-image: url("ltError.gif");*/
}

/*******************************************************************************
* Node titles
*/
/* @Chrome: otherwise hit area of node titles is broken (issue 133)
	Removed again for issue 165; (133 couldn't be reproduced) */
span.dynatree-node {
  /*	display: -moz-inline-box; /* issue 133, 165, 172, 192. removed for issue 221 */
  /*	-moz-box-align: start; /* issue 221 */
  display: inline-block; /* issue 373 Required to make a span sizeable */
  vertical-align: top;
}

/* Remove blue color and underline from title links */
ul.dynatree-container a {
  color: black; /* inherit doesn't work on IE */
  text-decoration: none;
  vertical-align: top;
  margin: 0px;
  margin-left: 3rem;
  /*	outline: 0; /* @ Firefox, prevent dotted border after click */
  /* Set transparent border to prevent jumping when active node gets a border
  (we can do this, because this theme doesn't use vertical lines)
  */
  border: 1px solid white; /* Note: 'transparent' would not work in IE6 */
}

ul.dynatree-container a:hover {
  /*	text-decoration: underline; */
  background: #f2f7fd; /* light blue */
  border-color: #b8d6fb; /* darker light blue */
}

span.dynatree-node a {
  display: inline-block; /* Better alignment, when title contains <br> */
  /*	vertical-align: top;*/
  padding-left: 3rem;
  padding-right: 3rem; /* Otherwise italic font will be outside bounds */
  /*	line-height: 16px; /* should be the same as img height, in case 16 px */
}

span.dynatree-folder a {
  /*	font-weight: bold; */ /* custom */
}

ul.dynatree-container a:focus,
span.dynatree-focused a:link {
  background-color: #efebde; /* gray */
}

span.dynatree-has-children a {
  /*	font-style: oblique; /* custom: */
}

span.dynatree-selected a {
  /*	color: green; */
  font-style: italic;
}

span.dynatree-active a {
  border: 1px solid #99defd;
  background-color: #d8f0fa;
}

/*******************************************************************************
* Drag'n'drop support
*/
/*** Helper object ************************************************************/
div.dynatree-drag-helper a {
  border: 1px solid gray;
  background-color: white;
  padding-left: 5rem;
  padding-right: 5rem;
  opacity: 0.8;
}

span.dynatree-drag-helper-img {
  /*
  position: relative;
  left: -16px;
  */
}

div.dynatree-drag-helper {
  /*    border-color: green;
  background-color: red;*/
}

div.dynatree-drop-accept span.dynatree-drag-helper-img {
  background-position: -32rem -112rem;
}

div.dynatree-drag-helper.dynatree-drop-reject {
  border-color: red;
}

div.dynatree-drop-reject span.dynatree-drag-helper-img {
  background-position: -16rem -112rem;
}

/*** Drop marker icon *********************************************************/
#dynatree-drop-marker {
  width: 24rem;
  position: absolute;
  background-position: 0 -128rem;
  margin: 0;
}

#dynatree-drop-marker.dynatree-drop-after,
#dynatree-drop-marker.dynatree-drop-before {
  width: 64rem;
  background-position: 0 -144rem;
}

#dynatree-drop-marker.dynatree-drop-copy {
  background-position: -64rem -128rem;
}

#dynatree-drop-marker.dynatree-drop-move {
  background-position: -64rem -128rem;
}

/*** Source node while dragging ***********************************************/
span.dynatree-drag-source {
  /* border: 1px dotted gray; */
  background-color: #e0e0e0;
}

span.dynatree-drag-source a {
  color: gray;
}

/*** Target node while dragging cursor is over it *****************************/
span.dynatree-drop-target {
  /*border: 1px solid gray;*/
}

span.dynatree-drop-target.dynatree-drop-accept a {
  /*border: 1px solid green;*/
  background-color: #3169c6 !important;
  color: white !important; /* @ IE6 */
  text-decoration: none;
}

span.dynatree-drop-target.dynatree-drop-reject {
  /*border: 1px solid red;*/
}

.org-tree #tree {
  padding: 30rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.org-tree #tree > ul > li span.dynatree-exp-cl a {
  color: black;
  font-weight: 400;
}
.org-tree span.dynatree-active a {
  border-color: #fff;
  background-color: transparent;
}
.org-tree span.dynatree-exp-cl a:hover,
.org-tree span.dynatree-exp-c a:hover {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  color: #1472ce;
}
.org-tree span.dynatree-exp-cl.dynatree-active a,
.org-tree span.dynatree-exp-c.dynatree-active a {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  color: #1472ce;
}
.org-tree span.dynatree-expander,
.org-tree span.dynatree-icon,
.org-tree #dynatree-drop-marker {
  background: url("/images/ui22/sprite/education-work.png");
  background-size: 62rem 42rem;
}
.org-tree ul.dynatree-container {
  padding: 0;
  border: 0;
}
.org-tree ul.dynatree-container ul {
  padding-left: 6rem;
}
.org-tree ul.dynatree-container .dynatree-title {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  margin-left: 4rem;
  padding: 0;
}
.org-tree ul.dynatree-container .dynatree-title:hover {
  border-color: #fff;
  background-color: transparent;
}
.org-tree ul.dynatree-container span.dynatree-node {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.org-tree ul.dynatree-container span.dynatree-connector {
  width: 10rem;
  height: 12rem;
}
.org-tree .dynatree-has-children span.dynatree-icon {
  background-position: -26rem 0px;
}
.org-tree .dynatree-ico-ef span.dynatree-icon {
  background-position: 0 -26rem;
}
.org-tree span.dynatree-expander {
  width: 10rem;
  height: 12rem;
  background-position: -26rem -26rem;
}
.org-tree .dynatree-exp-e span.dynatree-expander,
.org-tree .dynatree-exp-ed span.dynatree-expander,
.org-tree .dynatree-exp-el span.dynatree-expander,
.org-tree .dynatree-exp-edl span.dynatree-expander {
  background-position: -52rem 0px;
}

.button, .rect-button, .line-button, .line-button--xs, .line-button--sm, .line-button--md, .rect-line-button, .rect-solid-button, .rect-solid-button--sm, .solid-button, .solid-button--xs, .learning-status__quiz .quiz-list__item-answer .tit, .solid-button--sm, .solid-button--md, .solid-button--lg {
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.button-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.solid-button, .solid-button--xs, .learning-status__quiz .quiz-list__item-answer .tit, .solid-button--sm, .solid-button--md, .solid-button--lg {
  position: relative;
  background-color: #666;
  border-radius: 24rem;
  color: #fff;
  transition: background 0.22s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.solid-button--lg {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  height: 56rem;
  padding: 0 40rem;
}
.solid-button--md {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  height: 48rem;
  padding: 0 40rem;
  white-space: nowrap;
}
.solid-button--md i[class^=icon] {
  margin-right: 8rem;
}
.solid-button--sm {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  height: 40rem;
  padding: 0 20rem;
}
.solid-button--sm i[class^=icon] {
  margin-right: 8rem;
}
.solid-button--xs, .learning-status__quiz .quiz-list__item-answer .tit {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  height: 32rem;
  padding: 0 16rem;
}
.solid-button--xs i[class^=icon], .learning-status__quiz .quiz-list__item-answer .tit i[class^=icon] {
  margin-right: 8rem;
}
.solid-button[data-theme=black], [data-theme=black].solid-button--lg, [data-theme=black].solid-button--md, [data-theme=black].solid-button--sm, [data-theme=black].solid-button--xs, .learning-status__quiz .quiz-list__item-answer [data-theme=black].tit {
  background-color: #222;
}
.solid-button[data-theme=grey], [data-theme=grey].solid-button--lg, [data-theme=grey].solid-button--md, [data-theme=grey].solid-button--sm, [data-theme=grey].solid-button--xs, .learning-status__quiz .quiz-list__item-answer [data-theme=grey].tit {
  background-color: #666;
}
.solid-button[data-theme=light-grey], [data-theme=light-grey].solid-button--lg, [data-theme=light-grey].solid-button--md, [data-theme=light-grey].solid-button--sm, [data-theme=light-grey].solid-button--xs, .learning-status__quiz .quiz-list__item-answer [data-theme=light-grey].tit {
  background-color: #b8b8b8;
}
.solid-button[data-theme=light-grey02], [data-theme=light-grey02].solid-button--lg, [data-theme=light-grey02].solid-button--md, [data-theme=light-grey02].solid-button--sm, [data-theme=light-grey02].solid-button--xs, .learning-status__quiz .quiz-list__item-answer [data-theme=light-grey02].tit {
  background-color: #888;
}
.solid-button[data-theme=light-blue], [data-theme=light-blue].solid-button--lg, [data-theme=light-blue].solid-button--md, [data-theme=light-blue].solid-button--sm, [data-theme=light-blue].solid-button--xs, .learning-status__quiz .quiz-list__item-answer [data-theme=light-blue].tit {
  background-color: #5085ec;
}
.solid-button[data-theme=light-blue02], [data-theme=light-blue02].solid-button--lg, [data-theme=light-blue02].solid-button--md, [data-theme=light-blue02].solid-button--sm, [data-theme=light-blue02].solid-button--xs, .learning-status__quiz .quiz-list__item-answer [data-theme=light-blue02].tit {
  background-color: #a8b1c2;
}
.solid-button[data-theme=blue], [data-theme=blue].solid-button--lg, [data-theme=blue].solid-button--md, [data-theme=blue].solid-button--sm, [data-theme=blue].solid-button--xs, .learning-status__quiz .quiz-list__item-answer [data-theme=blue].tit {
  background-color: #042b69;
}
.solid-button.is-disabled, .is-disabled.solid-button--lg, .is-disabled.solid-button--md, .is-disabled.solid-button--sm, .is-disabled.solid-button--xs, .learning-status__quiz .quiz-list__item-answer .is-disabled.tit {
  background-color: #cecece;
  pointer-events: none;
}

.rect-solid-button, .rect-solid-button--sm {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  min-width: 180rem;
  height: 56rem;
  padding: 0 40rem;
  background-color: #666;
  border-radius: 10rem;
  color: #fff;
}
.rect-solid-button--sm {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  height: 40rem;
  padding: 0 20rem;
}
.rect-solid-button--sm i[class^=icon] {
  margin-right: 8rem;
}
.rect-solid-button[data-theme=black], [data-theme=black].rect-solid-button--sm {
  background-color: #222;
}
.rect-solid-button[data-theme=blue], [data-theme=blue].rect-solid-button--sm {
  background-color: #042b69;
}
.rect-solid-button[data-theme=light-grey], [data-theme=light-grey].rect-solid-button--sm {
  background-color: #b8b8b8;
}
.rect-solid-button[data-theme=light-grey02], [data-theme=light-grey02].rect-solid-button--sm {
  background-color: #888;
}
.rect-solid-button[data-theme=dark-grey], [data-theme=dark-grey].rect-solid-button--sm {
  background-color: #222;
}
.rect-solid-button[data-theme=light-blue], [data-theme=light-blue].rect-solid-button--sm {
  background-color: #5085ec;
}
.rect-solid-button.is-disabled, .is-disabled.rect-solid-button--sm {
  background-color: #b8b8b8;
  pointer-events: none;
}

.rect-line-button {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  min-width: 180rem;
  height: 56rem;
  border: 2rem solid #222;
  background-color: #fff;
  border-radius: 10rem;
}
.rect-line-button.is-disabled {
  background-color: #b8b8b8;
  pointer-events: none;
}

.line-button, .line-button--xs, .line-button--sm, .line-button--md {
  border: 1px solid #222;
  background-color: #fff;
  border-radius: 20rem;
  transition: background 0.11s cubic-bezier(0.04, 0.04, 0.12, 0.96), color 0.11s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  white-space: nowrap;
}
.line-button--md {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  min-width: 80rem;
  height: 48rem;
  border-radius: 24rem;
}
.line-button--sm {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  height: 40rem;
  padding: 0 19rem;
}
.line-button--sm i[class^=icon] {
  margin-right: 6rem;
}
.line-button--sm .button-text + i[class^=icon] {
  margin-left: 6rem;
  margin-right: 0;
}
.line-button--xs {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  height: 32rem;
  padding: 0 19rem;
}
.line-button[data-theme=grey], [data-theme=grey].line-button--md, [data-theme=grey].line-button--sm, [data-theme=grey].line-button--xs {
  border-color: #666;
  color: #666;
}
.line-button[data-theme=grey].is-active, [data-theme=grey].is-active.line-button--md, [data-theme=grey].is-active.line-button--sm, [data-theme=grey].is-active.line-button--xs, .line-button[data-theme=grey]:hover, [data-theme=grey].line-button--md:hover, [data-theme=grey].line-button--sm:hover, [data-theme=grey].line-button--xs:hover {
  background-color: #666;
}
.line-button[data-theme=blue], [data-theme=blue].line-button--md, [data-theme=blue].line-button--sm, [data-theme=blue].line-button--xs {
  border-color: #1472ce;
  color: #1472ce;
}
.line-button[data-theme=blue].is-active, [data-theme=blue].is-active.line-button--md, [data-theme=blue].is-active.line-button--sm, [data-theme=blue].is-active.line-button--xs, .line-button[data-theme=blue]:hover, [data-theme=blue].line-button--md:hover, [data-theme=blue].line-button--sm:hover, [data-theme=blue].line-button--xs:hover {
  background-color: #1472ce;
  color: #fff;
}
.line-button.is-disabled, .is-disabled.line-button--md, .is-disabled.line-button--sm, .is-disabled.line-button--xs {
  border-color: #b8b8b8;
  background-color: #f4f4f4;
  color: #888;
  pointer-events: none;
}
.line-button.is-disabled [class^=icon], .is-disabled.line-button--md [class^=icon], .is-disabled.line-button--sm [class^=icon], .is-disabled.line-button--xs [class^=icon] {
  filter: grayscale(100%) opacity(40%);
}

.more-button {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: inline-flex;
  border: 1px solid #cecece;
  background-color: #fff;
  border-radius: 100rem;
}
.more-button > .button-content {
  width: 196rem;
  height: 44rem;
}
.more-button-wrapper {
  padding-top: 30rem;
  text-align: center;
}
.more-button--sm, .more-button--sm--wh {
  width: 24rem;
  height: 24rem;
  border: 1px solid #cecece;
  border-radius: 4rem;
}
.more-button--sm > .button-content, .more-button--sm--wh > .button-content {
  width: auto;
  height: auto;
  padding: 3rem;
}
.more-button--sm .icon-common-more, .more-button--sm--wh .icon-common-more {
  filter: grayscale(100%) opacity(60%);
}
.more-button--sm--wh {
  border-color: #fff;
}

.text-button, .text-button--sm, .text-button--md, .text-button--underline {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
}
.text-button .button-content, .text-button--sm .button-content, .text-button--md .button-content, .text-button--underline .button-content {
  display: inline;
  text-align: left;
}
.text-button [class^=icon], .text-button--sm [class^=icon], .text-button--md [class^=icon], .text-button--underline [class^=icon],
.text-button [class^=flag],
.text-button--sm [class^=flag],
.text-button--md [class^=flag],
.text-button--underline [class^=flag] {
  margin-left: 5rem;
}
.text-button--underline {
  text-decoration: underline;
  text-underline-position: under;
}
.text-button--underline:hover {
  text-decoration: underline;
}
.text-button--md {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
}
.text-button--md .button-text + i[class^=icon] {
  margin-left: 8rem;
}
.text-button--md i[class^=icon] + .button-text {
  margin-right: 8rem;
}
.text-button--sm {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}
.text-button--sm .button-text + i[class^=icon] {
  margin-left: 6rem;
}
.text-button--sm i[class^=icon] + .button-text {
  margin-right: 6rem;
}
.text-button--icon--md {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
}
.text-button--icon--md .button-text + i[class^=icon] {
  margin-left: 6rem;
}
.text-button--icon--md i[class^=icon] + .button-text {
  margin-right: 6rem;
}
.text-button--icon--sm {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}
.text-button--icon--sm .button-text + i[class^=icon] {
  margin-left: 6rem;
}
.text-button--icon--sm i[class^=icon] + .button-text {
  margin-right: 6rem;
}

.rect-button {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  position: relative;
  height: 40rem;
  padding: 0 16rem;
  border: 1px solid #888;
  background-color: #fff;
  border-radius: 4rem;
  color: #888;
  transition: color 0.11s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.rect-button:before {
  content: "";
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  border: 2rem solid transparent;
  border-radius: 4rem;
  transition: border 0.11s cubic-bezier(0.04, 0.04, 0.12, 0.96);
  pointer-events: none;
}
.rect-button .button-text + [class^=icon],
.rect-button [class^=icon] + .button-text {
  margin-left: 6rem;
}
.rect-button.is-active, .rect-button:hover {
  color: #000;
}
.rect-button.is-active:before, .rect-button:hover:before {
  border-color: #000;
}
.rect-button[data-theme=green].is-active, .rect-button[data-theme=green]:hover {
  background-color: #1b8c6a;
  color: #fff;
}
.rect-button[data-theme=green].is-active:before, .rect-button[data-theme=green]:hover:before {
  border-color: #1b8c6a;
}
.rect-button[data-theme=mint].is-active, .rect-button[data-theme=mint]:hover {
  background-color: #54cbaa;
  color: #fff;
}
.rect-button[data-theme=mint].is-active:before, .rect-button[data-theme=mint]:hover:before {
  border-color: #54cbaa;
}
.rect-button[data-theme=purple].is-active, .rect-button[data-theme=purple]:hover {
  background-color: #9673ff;
  color: #fff;
}
.rect-button[data-theme=purple].is-active:before, .rect-button[data-theme=purple]:hover:before {
  border-color: #9673ff;
}
.rect-button[data-theme=yellow].is-active, .rect-button[data-theme=yellow]:hover {
  background-color: #ffbd3e;
  color: #fff;
}
.rect-button[data-theme=yellow].is-active:before, .rect-button[data-theme=yellow]:hover:before {
  border-color: #ffbd3e;
}
.rect-button[data-theme=red].is-active, .rect-button[data-theme=red]:hover {
  background-color: #ff7070;
  color: #fff;
}
.rect-button[data-theme=red].is-active:before, .rect-button[data-theme=red]:hover:before {
  border-color: #ff7070;
}

.icon-button {
  display: inline-flex;
  align-items: center;
}

.buttons-wrapper {
  display: flex;
  align-items: center;
}
.buttons-wrapper [role=button] + [role=button] {
  margin-left: 10rem;
}
.buttons-wrapper.flex-center {
  justify-content: center;
}
.buttons-wrapper.flex-end {
  justify-content: flex-end;
}
.buttons-wrapper.space-between {
  justify-content: space-between;
}

.popup-main h2 {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
}
.popup-main h3 {
  padding-bottom: 20rem;
}
.popup-main .teacher-info {
  grid-template-columns: repeat(4, 1fr);
}
.popup-main .application-header {
  padding: 20rem 40rem;
}
.popup-main .application-header > strong {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
}
.popup-main .application-header .share-info {
  padding-top: 4rem;
}
.popup-main .application-header--lv2 {
  padding: 20rem 40rem;
}
.popup-main .application-header--lv2 .header > strong {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
}
.popup-main .application-main figure {
  flex: 0 0 47%;
  margin-right: 20rem;
}
.popup-main .application-main .application-info {
  flex: 1;
  padding: 16rem 30rem;
}
.popup-main .application-main .application-info > div {
  padding: 14rem 0;
}
.popup-main .application-main .application-info > div > strong {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 500;
  line-height: 19px;
}
.popup-main .application-main .application-info > div > div {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 400;
}
.popup-main .application-main .application-info > div > div > [class^=flag] {
  height: 24rem;
  line-height: 22rem;
}
.popup-main .preview-course .application-header {
  padding-top: 29rem;
  padding-bottom: 29rem;
}
.popup-main .preview-course .application-header > strong {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  text-align: center;
}
.popup-main .preview-course .application-main {
  flex-direction: column;
}
.popup-main .preview-course .application-main figure {
  flex: 1;
  margin-right: 0;
}
.popup-main .preview-course .application-main figure img {
  width: 100%;
}
.popup-main .preview-course .application-main .desc-container {
  margin-top: 40rem;
}
.popup-main .preview-course .application-main .desc-container > strong {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  display: block;
  padding-bottom: 20rem;
  border-bottom: 2rem solid rgba(34, 34, 34, 0.9);
}
.popup-main .preview-course .application-main .desc-container > div {
  padding: 20rem 0;
  border-bottom: 1px solid rgba(206, 206, 206, 0.9);
}
.popup-main .sort-breadcrumb {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 400;
}
.popup-main .review > ul > li {
  padding: 20rem 0;
}
.popup-main .learning-goals > div {
  padding: 30rem 30rem 10rem;
}
.popup-main .learning-goals > div > strong {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
}
.popup-main .post-container.view-type02 > ul {
  gap: 20rem;
}
.popup-main .card--lv2,
.popup-main .card-image {
  width: auto;
  height: auto;
}
.popup-main .post-swiper .card-image {
  width: 235rem;
  height: 128rem;
}
.popup-main .is-fixed.tab-container--fixed-mode .tab--lv3 {
  left: 0;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 40rem;
  padding-left: 40rem;
}

.pop-buttons-wrapper {
  margin-top: 30rem;
  padding-top: 30rem;
  border-top: 1px solid #cecece;
}
.pop-buttons-wrapper:after {
  content: "";
  display: block;
  clear: both;
}
.pop-buttons-wrapper [role=button] + [role=button] {
  margin-left: 10rem;
}
.pop-buttons-wrapper .buttons-right {
  float: right;
}
.pop-buttons-wrapper .buttons-left {
  float: left;
}
.pop-buttons-wrapper .buttons-center {
  text-align: center;
}
.pop-buttons-wrapper.border-none {
  margin-top: 0;
  border-top: 0;
}

.check-list {
  padding: 0 160rem 0 40rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.check-list > div {
  display: flex;
  align-content: center;
  width: 520rem;
  padding: 20rem 0 20rem 96rem;
}
.check-list > div + div {
  position: relative;
}
.check-list > div + div:before {
  content: "";
  position: absolute;
  top: -1px;
  right: 0;
  left: 0;
  height: 2rem;
  background-image: url("/images/ui22/common/dotted-line.png");
  background-position: center;
  background-size: 8rem auto;
  background-repeat: repeat-x;
}
.check-list > div > strong {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
}
.check-list > div > div {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 40rem;
}
.check-list > div > div > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  color: #1472ce;
}
.check-list > div > div > span {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-left: 10rem;
}
.check-list > div > div > span [class^=icon] {
  margin-right: 5rem;
}

.students-list {
  padding: 30rem 40rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.students-list > div + div {
  display: flex;
  position: relative;
  margin-top: 20rem;
  padding-top: 22rem;
}
.students-list > div + div:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 2rem;
  background-image: url("/images/ui22/common/dotted-line.png");
  background-position: center;
  background-size: 8rem auto;
  background-repeat: repeat-x;
}
.students-list > div + div [class^=icon] {
  margin-right: 5rem;
}
.students-list > div > strong {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  display: block;
}
.students-list > div > span {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  display: block;
  margin-top: 10rem;
  color: #1472ce;
}

.popup-main .terms-list {
  grid-template-columns: repeat(4, 1fr);
  padding: 30rem 48rem;
}

h1 {
  font-size: 40rem;
  line-height: 52rem;
  font-weight: 700;
}

h2 {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
  padding-bottom: 20rem;
}

h3 {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
}

.sign-in {
  overflow: hidden;
  width: 400rem;
  border: 1px solid #cecece;
  border-radius: 20rem 20rem 0 0;
}
.sign-in .tab, .sign-in .tab--lv3, .sign-in .tab--lv2, .sign-in .tab--lv1 {
  display: flex;
  background-color: #fff;
  border-radius: 20rem 20rem 0 0;
}
.sign-in .tab [role=tab], .sign-in .tab--lv3 [role=tab], .sign-in .tab--lv2 [role=tab], .sign-in .tab--lv1 [role=tab] {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  flex: 1;
  height: 54rem;
  background-color: #f3f3f3;
  color: #999;
  border-top: none;
  border-bottom: 1px solid #d9d9d9;
}
.sign-in .tab [role=tab]:first-child, .sign-in .tab--lv3 [role=tab]:first-child, .sign-in .tab--lv2 [role=tab]:first-child, .sign-in .tab--lv1 [role=tab]:first-child, .sign-in .tab [role=tab]:last-child, .sign-in .tab--lv3 [role=tab]:last-child, .sign-in .tab--lv2 [role=tab]:last-child, .sign-in .tab--lv1 [role=tab]:last-child {
  border-right: 0;
  border-left: 0;
}
.sign-in .tab [role=tab][aria-selected=true], .sign-in .tab--lv3 [role=tab][aria-selected=true], .sign-in .tab--lv2 [role=tab][aria-selected=true], .sign-in .tab--lv1 [role=tab][aria-selected=true] {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  background-color: #fff;
  border-right: none;
  border-bottom: 1px solid #fff;
  border-left: none;
  color: #042b69;
}
.sign-in .tab [role=tab][aria-selected=true]:first-child, .sign-in .tab--lv3 [role=tab][aria-selected=true]:first-child, .sign-in .tab--lv2 [role=tab][aria-selected=true]:first-child, .sign-in .tab--lv1 [role=tab][aria-selected=true]:first-child, .sign-in .tab [role=tab][aria-selected=true]:last-child, .sign-in .tab--lv3 [role=tab][aria-selected=true]:last-child, .sign-in .tab--lv2 [role=tab][aria-selected=true]:last-child, .sign-in .tab--lv1 [role=tab][aria-selected=true]:last-child {
  border-top: 0;
  border-right: 0;
}
.sign-in .tab [role=tab] + [role=tab], .sign-in .tab--lv3 [role=tab] + [role=tab], .sign-in .tab--lv2 [role=tab] + [role=tab], .sign-in .tab--lv1 [role=tab] + [role=tab] {
  border-left: 1px solid #d9d9d9;
}
.sign-in > strong {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  display: block;
  padding: 20rem 20rem 15rem;
  background-color: #fff;
  color: #1472ce;
}
.sign-in .tab-pannel {
  padding: 0 20rem 30rem;
  background-color: #fff;
}
.sign-in .form-input--img + .form-input--img {
  margin-top: 10rem;
}
.sign-in .sign-in-button {
  margin-top: 10rem;
}
.sign-in-util {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60rem;
  padding-top: 16rem;
  padding-right: 20rem;
  padding-bottom: 18rem;
  padding-left: 20rem;
  border-right: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  border-left: 1px solid #cecece;
  background-color: #fff;
  border-radius: 0 0 20rem 20rem;
}
.sign-in-util .tooltip-button {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  color: #888;
}

.login-wrapper {
  display: flex;
  padding-bottom: 220rem;
}
.login-wrapper > div:nth-child(1) {
  flex: 0 0 400rem;
}
.login-wrapper > div:nth-child(2) {
  flex: 1;
  margin-left: 20rem;
}
.login-wrapper .login-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 380rem;
  padding: 92rem;
  background-image: url("/images/ui22/util/hyundai-autoever-e-campus.png");
  border-radius: 20rem;
}
.login-wrapper .login-image > strong {
  font-size: 40rem;
  line-height: 64rem;
  font-weight: 700;
  color: #fff;
}
.login-wrapper .login-image > p {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  margin-top: 20rem;
  color: #fff;
}

.notice {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20rem;
}
.notice li {
  background: #ffffff;
  box-shadow: 0px 10rem 10rem rgba(0, 0, 0, 0.1);
  border-radius: 20rem;
}
.notice-content {
  padding: 24rem;
}
.notice-main {
  position: relative;
  padding-bottom: 50rem;
}
.notice-main > strong {
  font-size: 18rem;
  line-height: 26rem;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  max-height: 78rem;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
  display: block;
  height: 78rem;
}
.notice-main .attached-file {
  position: absolute;
  bottom: 13rem;
  right: 0;
}
.notice-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 10rem;
  border-top: 1px solid #cecece;
}
.notice-footer .hits {
  font-size: 14rem;
  line-height: 19rem;
  font-weight: 400;
  color: #666;
}
.notice-footer .hits em {
  color: #000;
  font-weight: 500;
}
.notice-footer .date {
  font-size: 14rem;
  line-height: 19rem;
  font-weight: 400;
  color: #000;
}

.education-manager {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 45rem;
}
.education-manager > li {
  padding-top: 29rem;
  padding-bottom: 25rem;
  border-top: 2rem solid #1472ce;
  border-bottom: 1px solid #cecece;
}
.education-manager-content {
  padding-right: 8rem;
  padding-left: 8rem;
  text-align: right;
}
.education-manager-content .group {
  display: block;
  font-size: 18rem;
  line-height: 26rem;
  color: #666;
}
.education-manager-content .manager {
  display: block;
  margin-top: 5rem;
  font-size: 20rem;
  line-height: 32rem;
  color: #000;
}
.education-manager-footer {
  padding-right: 27rem;
  padding-left: 27rem;
  margin-top: 16rem;
  font-size: 16rem;
  line-height: 25rem;
  color: #666;
}
.education-manager-footer > div {
  display: flex;
  position: relative;
  padding-left: 28rem;
}
.education-manager-footer > div [class^=icon] {
  position: absolute;
  top: 4rem;
  left: 0;
}

.table, .table--lv3, .table--lv2, .table--lv2-high, .table--border-grey {
  border-top: 2rem solid #333;
}
.table th, .table--lv3 th, .table--lv2 th, .table--lv2-high th, .table--border-grey th {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  height: 50rem;
  border-bottom: 1px solid #cecece;
  background-color: #f2f7fb;
  vertical-align: middle;
}
.table th + td, .table--lv3 th + td, .table--lv2 th + td, .table--lv2-high th + td, .table--border-grey th + td, .table th + th, .table--lv3 th + th, .table--lv2 th + th, .table--lv2-high th + th, .table--border-grey th + th {
  border-left: 1px solid #cecece;
}
.table th .tooltip-container, .table--lv3 th .tooltip-container, .table--lv2 th .tooltip-container, .table--lv2-high th .tooltip-container, .table--border-grey th .tooltip-container {
  top: 3rem;
  text-align: left;
}
.table th .tooltip, .table--lv3 th .tooltip, .table--lv2 th .tooltip, .table--lv2-high th .tooltip, .table--border-grey th .tooltip {
  align-items: flex-start;
}
.table th .tooltip-button, .table--lv3 th .tooltip-button, .table--lv2 th .tooltip-button, .table--lv2-high th .tooltip-button, .table--border-grey th .tooltip-button {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
}
.table td, .table--lv3 td, .table--lv2 td, .table--lv2-high td, .table--border-grey td {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  height: 50rem;
  padding: 12rem 24rem;
  border-bottom: 1px solid #cecece;
  vertical-align: middle;
}
.table td + td, .table--lv3 td + td, .table--lv2 td + td, .table--lv2-high td + td, .table--border-grey td + td {
  border-left: 1px solid #cecece;
}
.table td.no-data, .table--lv3 td.no-data, .table--lv2 td.no-data, .table--lv2-high td.no-data, .table--border-grey td.no-data {
  padding: 36rem 0;
  text-align: center;
}
.table td.is-reply::before, .table--lv3 td.is-reply::before, .table--lv2 td.is-reply::before, .table--lv2-high td.is-reply::before, .table--border-grey td.is-reply::before, .table td.is-reply--depth2::before, .table--lv3 td.is-reply--depth2::before, .table--lv2 td.is-reply--depth2::before, .table--lv2-high td.is-reply--depth2::before, .table--border-grey td.is-reply--depth2::before {
  content: "";
  display: inline-block;
  width: 12rem;
  height: 12rem;
  border: 1px solid #aaa;
  border-top: 0;
  border-right: 0;
  margin-right: 5rem;
}
.table td.is-reply--depth2:before, .table--lv3 td.is-reply--depth2:before, .table--lv2 td.is-reply--depth2:before, .table--lv2-high td.is-reply--depth2:before, .table--border-grey td.is-reply--depth2:before {
  margin-left: 34rem;
}
.table td.is-file [class^=icon], .table--lv3 td.is-file [class^=icon], .table--lv2 td.is-file [class^=icon], .table--lv2-high td.is-file [class^=icon], .table--border-grey td.is-file [class^=icon], .table td.is-lock [class^=icon], .table--lv3 td.is-lock [class^=icon], .table--lv2 td.is-lock [class^=icon], .table--lv2-high td.is-lock [class^=icon], .table--border-grey td.is-lock [class^=icon] {
  margin-left: 7rem;
  vertical-align: middle;
}
.table td.is-new span + span, .table--lv3 td.is-new span + span, .table--lv2 td.is-new span + span, .table--lv2-high td.is-new span + span, .table--border-grey td.is-new span + span, .table td.is-hot span + span, .table--lv3 td.is-hot span + span, .table--lv2 td.is-hot span + span, .table--lv2-high td.is-hot span + span, .table--border-grey td.is-hot span + span, .table td.is-reply span + span, .table--lv3 td.is-reply span + span, .table--lv2 td.is-reply span + span, .table--lv2-high td.is-reply span + span, .table--border-grey td.is-reply span + span, .table td.is-reply--depth2 span + span, .table--lv3 td.is-reply--depth2 span + span, .table--lv2 td.is-reply--depth2 span + span, .table--lv2-high td.is-reply--depth2 span + span, .table--border-grey td.is-reply--depth2 span + span {
  margin-left: 5rem;
}
.table .notice-row td, .table--lv3 .notice-row td, .table--lv2 .notice-row td, .table--lv2-high .notice-row td, .table--border-grey .notice-row td {
  background-color: #faf8ee;
}
.table-button--text-icon i[class^=icon] {
  margin-left: 20rem;
}
.table--border-grey {
  border-width: 1px;
  border-color: #cecece;
}
.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}
.table-wrapper > .table, .table-wrapper > .table--lv3, .table-wrapper > .table--lv2, .table-wrapper > .table--lv2-high, .table-wrapper > .table--border-grey {
  width: 1440rem;
}
.table--lv2 th, .table--lv2-high th,
.table--lv2 td,
.table--lv2-high td {
  padding-right: 24rem;
  padding-left: 30rem;
}
.table--lv2 th > span:not(.tooltip-container), .table--lv2-high th > span:not(.tooltip-container) {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}
.table--lv2 th + td, .table--lv2-high th + td {
  border-left: 0;
}
.table--lv2-high th,
.table--lv2-high td {
  height: 80rem;
}
.table--lv3 th {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  height: 40rem;
  padding-top: 8rem;
  padding-bottom: 10rem;
}
.table--lv3 td {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  height: 40rem;
  padding-top: 8rem;
  padding-right: 12rem;
  padding-bottom: 10rem;
  padding-left: 12rem;
}
.table .border-left, .table--border-grey .border-left, .table--lv2 .border-left, .table--lv2-high .border-left, .table--lv3 .border-left {
  border-left: 1px solid #cecece;
}
.table .bg-gray, .table--border-grey .bg-gray, .table--lv2 .bg-gray, .table--lv2-high .bg-gray, .table--lv3 .bg-gray {
  background-color: #f4f4f4;
}
.table.border-none, .border-none.table--border-grey, .border-none.table--lv2, .border-none.table--lv2-high, .border-none.table--lv3 {
  border-top: 0;
}

.pagination {
  display: flex;
  justify-content: center;
  padding-top: 40rem;
}
.pagination:empty {
  display: none;
}
.pagination a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32rem;
  height: 32rem;
}
.pagination > a {
  border: 1px solid #cecece;
}
.pagination > a:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  width: 12rem;
  height: 12rem;
}
.pagination > a + a {
  margin-left: 10rem;
}
.pagination .btn-first:before {
  background-position: 0 -21rem;
}
.pagination .btn-prev:before {
  background-position: -13rem -21rem;
}
.pagination .btn-next:before {
  background-position: -13rem -21rem;
  transform: rotate(-180deg);
}
.pagination .btn-last:before {
  background-position: 0 -21rem;
  transform: rotate(-180deg);
}
.pagination .page {
  display: flex;
  padding: 0 10rem;
}
.pagination .page > a {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  text-align: center;
}
.pagination .page > a.is-current {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  color: #1472ce;
  text-decoration: underline;
}

.tooltip {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
}
.tooltip-container {
  display: inline-flex;
  align-items: center;
  position: relative;
}
.tooltip-container .tooltip {
  position: static;
}
.tooltip-button {
  display: inline-flex;
}
.tooltip-button .button-content {
  display: inline-flex;
  align-items: center;
}
.tooltip-button .button-content [class^=icon] {
  margin-left: 8rem;
}
.tooltip-aside--left .tooltip-aside-container {
  right: auto;
  left: 0;
}
.tooltip-aside--right .tooltip-aside-container {
  right: 0;
  left: auto;
}
.tooltip-aside-container {
  position: absolute;
  top: 30rem;
  z-index: 200;
  width: 324rem;
  padding-top: 20rem;
  padding-right: 54rem;
  padding-bottom: 20rem;
  padding-left: 20rem;
  background-color: #222;
  border-radius: 10rem;
  color: #fff;
  transform: translateY(20rem);
  opacity: 0;
  pointer-events: none;
}
.tooltip-aside-container strong {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  display: block;
}
.tooltip-aside-container ol {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  margin-top: 10rem;
  padding-left: 16rem;
}
.tooltip-aside-container ol li {
  list-style: decimal;
}
.tooltip-aside-container .desc {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  display: block;
}
.tooltip-aside-container.is-active {
  transform: translateY(0);
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.22s cubic-bezier(0.04, 0.04, 0.12, 0.96), transform 0.22s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.tooltip-aside-close-button {
  position: relative;
  display: inline-block;
  width: 16rem;
  height: 16rem;
  background-color: transparent;
  border: none;
  position: absolute;
  top: 20rem;
  right: 20rem;
}
.tooltip-aside-close-button:before, .tooltip-aside-close-button:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 21.2274rem;
  height: 1.4rem;
  background-color: #fff;
}
.tooltip-aside-close-button:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.tooltip-aside-close-button:after {
  transform: translate(-50%, -50%) rotate(135deg);
}
.tooltip-aside-close-button > span {
  overflow: hidden;
  display: block;
  text-indent: 100%;
  white-space: nowrap;
}

.select {
  display: inline-flex;
  width: 320rem;
}
.select-content {
  display: block;
  width: 100%;
  position: relative;
}
.select-content > select {
  display: none;
}
.select-content:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 13rem;
  right: 30rem;
  z-index: 1;
  width: 24rem;
  height: 24rem;
  background-position: -147rem -59rem;
  transition: transform 0.22s linear;
  pointer-events: none;
}
.select-button {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: inline-flex;
  position: relative;
  width: 100%;
  height: 48rem;
  padding-top: 10rem;
  padding-right: 64rem;
  padding-bottom: 10rem;
  padding-left: 30rem;
  border: 1px solid #cecece;
  background-color: #fff;
  border-radius: 30rem;
  text-align: left;
  color: #000;
}
.select-button span {
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select-list {
  position: absolute;
  top: 58rem;
  right: 0;
  left: 0;
  z-index: 200;
  padding: 10rem;
  border: 1px solid #cecece;
  background-color: #fff;
  box-shadow: 0px 10rem 10rem rgba(0, 0, 0, 0.1);
  border-radius: 20rem;
  opacity: 0;
  pointer-events: none;
}
.select-list-content {
  overflow: hidden;
  overflow-y: auto;
  max-height: 240rem;
}
.select-list-content::-webkit-scrollbar {
  border-radius: 20rem;
}
.select-list-content::-webkit-scrollbar-track {
  border-radius: 20rem;
}
.select-list-content::-webkit-scrollbar-thumb {
  border-radius: 20rem;
}
.select-list button {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  width: 100%;
  height: 48rem;
  padding: 11rem 12rem;
  border-radius: 10rem;
}
.select-list button span {
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select-list button:hover {
  background-color: #f4f4f4;
}
.select-list .is-active button {
  color: #1472ce;
}
.select.is-active .select-content:before {
  transform: rotateX(-180deg);
}
.select.is-active .select-list {
  opacity: 1;
  pointer-events: auto;
}
.select.is-active .select-list.is-reverse {
  top: auto;
  bottom: 58rem;
}
.select.is-disabled {
  pointer-events: none;
}
.select.is-disabled .select-content:before {
  filter: grayscale(100%) opacity(40%);
}
.select.is-disabled .select-button {
  background-color: #f4f4f4;
  color: #666;
}

.form--vertical .form-row {
  display: flex;
}
.form--vertical .form-row + .form-row {
  margin-top: 20rem;
}
.form-tit {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  flex: 0 0 140rem;
  padding-right: 20rem;
}
.form-content {
  flex: 1;
}

.required:after {
  content: " *";
  color: #f65959;
}

.type--refer {
  padding-left: 16rem;
}
.type--refer li {
  text-indent: -16rem;
}
.type--refer li + li {
  margin-top: 6rem;
}

.notice-view__container .notice-view__header {
  padding-top: 20rem;
  padding-bottom: 20rem;
  border-top: 2rem solid #222;
  border-bottom: 2rem solid #cecece;
}
.notice-view__container .notice-view__header.border-top-none {
  border-top: 0;
}
.notice-view__container .notice-view__tit {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  display: block;
}
.notice-view__container .notice-view__tit [class^=icon] {
  position: relative;
  top: 2rem;
  margin-left: 12rem;
}
.notice-view__container .notice-view__tit [class^=icon] + [class^=icon] {
  margin-left: 0;
}
.notice-view__container .notice-view__tit [class^=flag] {
  position: relative;
  top: -4rem;
  margin-left: 15rem;
}
.notice-view__container .notice-view__tit [class^=flag] + [class^=flag] {
  margin-left: 0;
}
.notice-view__container .notice-view__info {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: flex;
  margin-top: 10rem;
}
.notice-view__container .notice-view__info > span + span {
  position: relative;
  margin-left: 20rem;
  padding-left: 21rem;
}
.notice-view__container .notice-view__info > span + span:before {
  content: "";
  position: absolute;
  top: 5rem;
  left: 0;
  width: 1px;
  height: 12rem;
  background-color: #b8b8b8;
}
.notice-view__container .notice-view__main {
  padding-top: 20rem;
  padding-bottom: 20rem;
  border-bottom: 1px solid #cecece;
}
.notice-view__container .notice-view__attached-file {
  display: flex;
  align-items: center;
  margin-top: 20rem;
  padding: 20rem 0;
  background-color: #f2f7fb;
  border-radius: 20rem;
}
.notice-view__container .notice-view__attached-file-tit {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 135rem;
  text-align: center;
}
.notice-view__container .notice-view__attached-file-tit [class^=icon] {
  margin-right: 7rem;
}
.notice-view__container .notice-view__attached-file-content {
  flex: 1;
  position: relative;
  padding-left: 41rem;
}
.notice-view__container .notice-view__attached-file-content:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  background-color: #a8b1c2;
}
.notice-view__container .notice-view__attached-file-content > div + div {
  margin-top: 8rem;
}
.notice-view__container .notice-view__content {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
}
.notice-view__container-group .questions .notice-view__container .notice-view__main {
  border-bottom: 0;
}
.notice-view__container-group .answer {
  padding-bottom: 20rem;
  border-bottom: 1px solid #cecece;
}
.notice-view__container-group .answer .notice-view__container {
  padding-right: 40rem;
  padding-bottom: 20rem;
  padding-left: 40rem;
  background-color: #f4f4f4;
  border-radius: 20rem;
}
.notice-view__container-group .answer .notice-view__container .notice-view__header {
  border-top: 0;
}
.notice-view__container-group .answer .notice-view__container .notice-view__main {
  border-bottom: 0;
}

.flag, .flag--grey, .flag--purple, .flag--red {
  font-size: 10rem;
  line-height: 14rem;
  font-weight: 500;
  display: inline-flex;
  padding: 3rem 8rem;
  color: #fff;
  border-radius: 4rem;
}
.flag--red {
  background-color: #f65959;
}
.flag--purple {
  background-color: #9673ff;
}
.flag--grey {
  background-color: #888;
}
.flag--lv2, .flag--lv3--olive, .flag--lv3--orange, .flag--lv3--blue02, .flag--lv3--blue, .flag--lv3--pink, .flag--lv3--mint, .flag--lv3--white, .flag--lv3--grey, .flag--lv3--purple, .flag--lv3--green, .flag--lv3--red, .flag--lv2--olive, .flag--lv2--orange, .flag--lv2--blue02, .flag--lv2--blue, .flag--lv2--pink, .flag--lv2--mint, .flag--lv2--white, .flag--lv2--grey, .flag--lv2--purple, .flag--lv2--green, .flag--lv2--red {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 500;
  display: inline-flex;
  height: 20rem;
  padding: 0 10rem;
  border-radius: 4rem;
  line-height: 18rem;
}
.flag--lv2--red {
  border: 1px solid #f65959;
  color: #f65959;
}
.flag--lv2--green {
  border: 1px solid #1b8c6a;
  color: #1b8c6a;
}
.flag--lv2--purple {
  border: 1px solid #9a44de;
  color: #9a44de;
}
.flag--lv2--grey {
  border: 1px solid #666;
  background-color: #f4f4f4;
  color: #666;
}
.flag--lv2--white {
  border: 1px solid #666;
  background-color: #fff;
  color: #666;
}
.flag--lv2--mint {
  border: 1px solid #54cbaa;
  background-color: #fff;
  color: #54cbaa;
}
.flag--lv2--pink {
  border: 1px solid #dc58bf;
  background-color: #fff;
  color: #dc58bf;
}
.flag--lv2--blue {
  border: 1px solid #1596df;
  background-color: #fff;
  color: #1596df;
}
.flag--lv2--blue02 {
  border: 1px solid #5085ec;
  background-color: #e2f5ff;
  color: #5085ec;
}
.flag--lv2--orange {
  border: 1px solid #ed892c;
  background-color: #fff;
  color: #ed892c;
}
.flag--lv2--olive {
  border: 1px solid #7f9c06;
  background-color: #fff;
  color: #7f9c06;
}
.flag--lv3--red {
  color: #f65959;
}
.flag--lv3--green {
  color: #1b8c6a;
}
.flag--lv3--purple {
  color: #9a44de;
}
.flag--lv3--grey {
  color: #666;
}
.flag--lv3--white {
  color: #666;
}
.flag--lv3--mint {
  color: #54cbaa;
}
.flag--lv3--pink {
  color: #dc58bf;
}
.flag--lv3--blue {
  color: #1596df;
}
.flag--lv3--blue02 {
  color: #5085ec;
}
.flag--lv3--orange {
  color: #ed892c;
}
.flag--lv3--olive {
  color: #7f9c06;
}

.popup-util {
  display: flex;
  justify-content: flex-end;
  margin-right: 24rem;
  position: relative;
}
.popup-util-content {
  position: fixed;
  bottom: 30rem;
}
.popup-util .popup-up-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32rem;
  height: 32rem;
  border-radius: 50%;
  background-color: #4a5366;
}

.card {
  width: 330rem;
  background-color: #fff;
  border-radius: 20rem;
  box-shadow: 0px 10rem 10rem rgba(0, 0, 0, 0.1);
}
.card-image {
  overflow: hidden;
  position: relative;
  width: 330rem;
  height: 180rem;
  border-radius: 20rem;
}
.card-image img {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  border-radius: 20rem 20rem 0 0;
}
.card-image .image-info-top {
  position: absolute;
  top: 16rem;
  right: 16rem;
  left: 16rem;
}
.card-image .image-info-top > span.image-info-right {
  float: right;
}
.card-image .image-info-top > span.limage-info-left {
  float: left;
}
.card-image .image-info {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 10rem;
  bottom: 10rem;
  left: 10rem;
}
.card-image .image-info > div > span {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  height: 32rem;
  padding: 5rem 10rem;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 20rem;
  color: #fff;
}
.card-image .image-info > div > span [class^=icon] {
  margin-right: 8rem;
}
.card-image .image-info--lv2 {
  position: absolute;
  top: 0;
  right: 0;
}
.card-image .image-info--lv2 > div > span {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 80rem;
  height: 40rem;
  border-radius: 0 20rem;
  text-align: center;
  color: #fff;
}
.card-image .image-info--lv2 > div > span.info__red {
  background-color: #f65959;
}
.card-image .image-info--lv2 > div > span.info__blue {
  background-color: #5085ec;
}
.card-state {
  margin-bottom: -8rem;
}
.card-state > span {
  margin-right: 8rem;
  margin-bottom: 8rem;
}
.card-tit {
  display: block;
}
.card-info {
  margin-top: 20rem;
}
.card-util > div {
  display: flex;
}
.card-util > div + div {
  margin-top: 6rem;
}
.card-util > div > strong {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  margin-right: 8rem;
  color: #666;
}
.card-util > div > span {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}
.card-util > div > ul {
  display: flex;
  flex: 1;
}
.card-util > div > ul > li {
  display: flex;
  align-items: center;
}
.card-util > div > ul > li + li {
  position: relative;
  margin-left: 16rem;
  padding-left: 17rem;
}
.card-util > div > ul > li + li:before {
  content: "";
  position: absolute;
  top: 5rem;
  left: 0;
  width: 1px;
  height: 12rem;
  background-color: #b8b8b8;
}
.card-util > div > ul > li > strong {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  color: #666;
}
.card-util > div > ul > li > span {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}
.card-util > div > ul > li > strong,
.card-util > div > ul > li [class^=icon] {
  margin-right: 8rem;
}
.card-breadcrumb {
  display: flex;
  align-items: center;
}
.card-breadcrumb > ul {
  display: flex;
}
.card-breadcrumb > ul > li {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
}
.card-breadcrumb > ul > li + li {
  position: relative;
  padding-left: 26rem;
}
.card-breadcrumb > ul > li + li:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 8rem;
  left: 10rem;
  width: 6rem;
  height: 10rem;
  background-position: -227rem -59rem;
  pointer-events: none;
  filter: grayscale(100%) opacity(40%);
}
.card-progress {
  display: flex;
  justify-content: space-between;
}
.card-progress .progress-container {
  position: relative;
  height: 16rem;
  margin-top: 3rem;
}
.card-progress .progress-container .progress-content {
  overflow: hidden;
  height: 100%;
  background-color: #ddd;
  border-radius: 4rem;
}
.card-progress .progress-container .progress-current {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #1472ce;
  border-radius: 4rem;
}
.card-progress .progress-container .progress-rate {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  position: absolute;
  top: -3rem;
  left: calc(100% + 10rem);
  white-space: nowrap;
  color: #1472ce;
}
.card-progress .state {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  text-align: right;
}
.card-before-learn {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  margin-top: 11rem;
  padding: 5rem;
  background: #f5f5f5;
  border-radius: 4rem;
  text-align: center;
}
.card-period {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  display: flex;
  margin-top: 6rem;
}
.card-period > strong {
  margin-right: 8rem;
  font-weight: 400;
  color: #666;
}
.card-period > span {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}

.card .card-main {
  padding: 20rem 20rem 24rem;
}

.card--lv2 {
  width: 330rem;
}
.card--lv2 .card-main .card-tit {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-top: 10rem;
}
.card--lv2 .card-main .card-tit .card-tit-sequence {
  display: none;
}

.view-type01 .card--lv2, .view-type01--lv2 .card--lv2 {
  display: flex;
  position: relative;
  width: auto;
  padding: 40rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.view-type01 .card--lv2 .card-image, .view-type01--lv2 .card--lv2 .card-image {
  flex: 0 0 330rem;
  margin-right: 40rem;
}
.view-type01 .card--lv2 .card-image img, .view-type01--lv2 .card--lv2 .card-image img {
  border-radius: 20rem;
}
.view-type01 .card--lv2 .card-main, .view-type01--lv2 .card--lv2 .card-main {
  flex: 1;
}
.view-type01 .card--lv2 .card-main .card-tit, .view-type01--lv2 .card--lv2 .card-main .card-tit {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  max-height: 64rem;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
}
.view-type01 .card--lv2 .card-main .card-tit .card-tit-sequence, .view-type01--lv2 .card--lv2 .card-main .card-tit .card-tit-sequence {
  display: inline-flex;
}
.view-type01 .card--lv2 .card-main .card-tit + .card-desc, .view-type01--lv2 .card--lv2 .card-main .card-tit + .card-desc {
  margin-top: 10rem;
}
.view-type01 .card--lv2 .card-main .card-util, .view-type01--lv2 .card--lv2 .card-main .card-util {
  position: absolute;
  top: 40rem;
  right: 40rem;
}
.view-type01 .card--lv2 .card-main .card-breadcrumb, .view-type01--lv2 .card--lv2 .card-main .card-breadcrumb {
  margin-top: 20rem;
}
.view-type01 .card--lv2 .card-main .card-breadcrumb > strong, .view-type01--lv2 .card--lv2 .card-main .card-breadcrumb > strong {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  margin-right: 20rem;
}
.view-type01 .card--lv2 .card-main .card-breadcrumb + .card-tag, .view-type01--lv2 .card--lv2 .card-main .card-breadcrumb + .card-tag {
  margin-top: 10rem;
}
.view-type01 .card--lv2 .card-main .card-progress, .view-type01--lv2 .card--lv2 .card-main .card-progress {
  margin-top: 30rem;
}
.view-type01 .card--lv2 .card-main .card-progress > div, .view-type01--lv2 .card--lv2 .card-main .card-progress > div {
  flex: 0 0 860rem;
}
.view-type01 .card--lv2 .card-main .card-tag, .view-type01--lv2 .card--lv2 .card-main .card-tag {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20rem;
  color: #888;
}
.view-type01 .card--lv2 .card-main .card-tag > li, .view-type01--lv2 .card--lv2 .card-main .card-tag > li {
  margin-right: 20rem;
  margin-bottom: 20rem;
}
.view-type01 .card--lv2 .card-main .card-desc, .view-type01--lv2 .card--lv2 .card-main .card-desc {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  max-height: 26rem;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
}
.view-type01--lv2 .card--lv2 {
  padding: 0;
  border: 0;
}

.view-type02 .card--lv2 .card-image img {
  border-radius: 0;
}
.view-type02 .card--lv2 .card-main {
  position: relative;
  padding-top: 30rem;
  padding-right: 10rem;
  padding-bottom: 10rem;
  padding-left: 10rem;
}
.view-type02 .card--lv2 .card-main .card-state {
  margin-top: 10rem;
}
.view-type02 .card--lv2 .card-main .card-tit {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  max-height: 52rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
  height: 52rem;
}
.view-type02 .card--lv2 .card-main .card-tit .card-tit-sequence {
  display: none;
}
.view-type02 .card--lv2 .card-main .card-tit + .card-breadcrumb {
  margin-top: 20rem;
}
.view-type02 .card--lv2 .card-main .card-util {
  margin-top: 20rem;
}
.view-type02 .card--lv2 .card-main .card-breadcrumb {
  margin-top: 6rem;
}
.view-type02 .card--lv2 .card-main .card-breadcrumb > strong {
  display: none;
}
.view-type02 .card--lv2 .card-main .card-breadcrumb > ul > li {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
}
.view-type02 .card--lv2 .card-main .card-breadcrumb > ul > li:last-child {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}
.view-type02 .card--lv2 .card-main .card-progress {
  position: absolute;
  top: 8rem;
  right: 10rem;
  left: 10rem;
}
.view-type02 .card--lv2 .card-main .card-progress > div {
  flex: 0 0 180rem;
}

[data-bgColor] .view-type02 .card-image img {
  border-radius: 0;
}
[data-bgColor] .view-type02 .card--lv2 {
  height: 100%;
}
[data-bgColor] .view-type02 .card--lv2 .card-main {
  height: calc(100% - 180rem);
  padding: 40rem 10rem 20rem;
  background-color: #fff;
  border-radius: 0 0 20rem 20rem;
}
[data-bgColor] .view-type02 .card--lv2 .card-tit,
[data-bgColor] .view-type02 .card--lv2 .card-state {
  margin-top: 0;
}

.post-container {
  margin-top: 40rem;
}
.post-container > ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40rem;
}
.post-container.view-type01 > ul, .post-container.view-type01--lv2 > ul {
  grid-template-columns: 1fr;
}
.post-container.view-type01 .no-data, .post-container.view-type01--lv2 .no-data {
  border: 1px solid #cecece;
  justify-content: center;
  box-shadow: none;
}
.post-container.view-type02 > ul {
  grid-template-columns: repeat(4, 1fr);
}
.post-container.view-type02 > ul.view-type-no-data {
  grid-template-columns: repeat(1, 1fr);
}
.post-container.view-type02 > ul.view-type-no-data .no-data {
  border: 1px solid #cecece;
  justify-content: center;
  box-shadow: none;
}
.post-container .no-data {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  padding: 70rem;
  background-color: #fff;
  box-shadow: 0px 10rem 20rem rgba(0, 0, 0, 0.2);
  border-radius: 20rem;
  text-align: center;
}

.post-swiper-ctl {
  display: flex;
  align-items: center;
}
.post-swiper-ctl [role=button] + [role=button] {
  margin-left: 20rem;
}
.post-swiper-prev [class^=icon] {
  transform: rotate(180deg);
}
.post-swiper-prev, .post-swiper-next {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 16rem;
  height: 16rem;
  transform-origin: 50%;
  cursor: pointer;
}
.post-swiper-prev.swiper-button-disabled, .post-swiper-next.swiper-button-disabled {
  filter: grayscale(100%) opacity(50%);
}

[data-bgcolor] .card {
  border-radius: 20rem;
}
[data-bgcolor] .card-image {
  position: relative;
  border-radius: 20rem 20rem 0 0;
}

.find-course {
  padding: 24rem 40rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
  background-color: #f2f7fb;
}
.find-course > div {
  display: flex;
  justify-content: space-between;
}
.find-course > div + div {
  margin-top: 10rem;
}
.find-course-sec {
  display: flex;
  align-items: center;
}
.find-course-sec > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: block;
  margin-right: 30rem;
}
.find-course-sec .select,
.find-course-sec .form-input,
.find-course-sec .form-input--icon-button,
.find-course-sec .form-input--datepicker,
.find-course-sec .form-input--img {
  width: 260rem;
}
.find-course-button {
  margin-left: 20rem;
}

.course-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.course-item + .course-item {
  margin-top: 20rem;
}
.course-header {
  flex: 1;
}
.course-main, .course-main-flex-end {
  flex: 0 0 400rem;
  text-align: right;
}
.course-main .course-status, .course-main-flex-end .course-status {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 700;
  width: 180rem;
  margin-left: auto;
  text-align: center;
}
.course-main .course-status .course-status-approval-waiting, .course-main-flex-end .course-status .course-status-approval-waiting {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 700;
  color: #f65959;
}
.course-main .course-status .course-status-learning, .course-main-flex-end .course-status .course-status-learning {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: baseline;
  height: 40rem;
  color: #042b69;
}
.course-main .course-status .course-status-learning > strong, .course-main-flex-end .course-status .course-status-learning > strong {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
  font-weight: 700;
}
.course-main .course-status .course-status-learning > span, .course-main-flex-end .course-status .course-status-learning > span {
  margin-left: 4rem;
  font-weight: 500;
}
.course-main .course-status .course-status-completion, .course-main-flex-end .course-status .course-status-completion {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
  display: block;
  height: 40rem;
  color: #1472ce;
  font-weight: 700;
}
.course-main .buttons-wrapper, .course-main-flex-end .buttons-wrapper {
  justify-content: flex-end;
  margin-top: 10rem;
}
.course-main .buttons-wrapper [role=button], .course-main-flex-end .buttons-wrapper [role=button] {
  margin-top: 0;
}
.course-footer {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-top: 28rem;
  padding-top: 22rem;
  background: url("/images/ui22/common/dotted-line.png");
  background-size: 8rem auto;
  background-repeat: repeat-x;
}
.course-footer-left {
  display: flex;
  flex: 1;
}
.course-item-button {
  margin-top: 40rem;
}
.course-category > span + span {
  margin-left: 8rem;
}
.course-breadcrumb {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: flex;
  margin-top: 12rem;
}
.course-breadcrumb > li + li {
  position: relative;
}
.course-breadcrumb > li + li:before {
  content: ">";
  display: inline-flex;
  margin: 0 4rem;
}
.course-tit {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  display: block;
  margin-top: 18rem;
}
.course-period {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.course-period [class^=icon] {
  margin-right: 5rem;
}
.course-period > span {
  margin-left: 4rem;
}
.course-period [class^=flag] {
  margin-left: 8rem;
}
.course-period + .course-review-period, .course-period + .course-progress {
  margin-left: 80rem;
}
.course-review-period {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.course-review-period [class^=icon] {
  margin-right: 5rem;
}
.course-review-period > strong {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  margin-right: 10rem;
  color: #666;
}
.course-tag {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  display: flex;
  flex-wrap: wrap;
  color: #666;
}
.course-tag > span + span {
  margin-left: 20rem;
}
.course-progress-container {
  display: flex;
  align-items: center;
  position: relative;
}
.course-progress-container [class^=icon] {
  margin-right: 8rem;
}
.course-progress-content {
  overflow: hidden;
  position: relative;
  width: 160rem;
  height: 16rem;
  background-color: #ddd;
  border-radius: 4rem;
}
.course-progress-content .progress-current {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #1472ce;
  border-radius: 4rem;
}
.course-progress-rate {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  position: absolute;
  top: -1px;
  left: calc(100% + 10rem);
  color: #1472ce;
}

.section-header--line, .section-header--line--star {
  margin-bottom: 20rem;
  border-bottom: 2rem solid rgba(34, 34, 34, 0.9);
}
.section-header--line--star {
  display: flex;
}
.section-header--line--star .star-point-container {
  margin-top: -20rem;
  margin-left: 20rem;
}

.teacher-info {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30rem;
}
.teacher-info > li {
  flex: 0 0 264rem;
}
.teacher-info figure img {
  border: 1px solid #cecece;
  border-radius: 10rem;
}
.teacher-info figure figcaption {
  margin-top: 20rem;
}
.teacher-info figure figcaption strong {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  display: block;
}
.teacher-info figure figcaption strong > span {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: inline-flex;
  position: relative;
  top: -1px;
  margin-left: 5rem;
}
.teacher-info figure figcaption p {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  margin-top: 10rem;
  color: #666;
}
.teacher-info[data-view="4"] {
  grid-template-columns: repeat(4, 1fr);
}

.text-and-button > .text + [role=button] {
  margin-left: 7rem;
}

.share-info {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  min-width: 310rem;
  justify-content: flex-end;
}
.share-info > span {
  display: inline-flex;
  align-items: center;
}
.share-info > span > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  margin-right: 8rem;
  color: #666;
}
.share-info > span [class^=icon] {
  margin-right: 8rem;
}
.share-info > span + span {
  position: relative;
  margin-left: 16rem;
  padding-left: 17rem;
}
.share-info > span + span:before {
  content: "";
  position: absolute;
  top: 3rem;
  left: 0;
  width: 1px;
  height: 16rem;
  background-color: #b8b8b8;
}

.application-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 29rem 30rem 29rem 40rem;
  border: 1px solid #cecece;
  background-color: #f2f7fb;
  border-radius: 20rem;
}
.application-header > strong {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
  flex: 1;
}
.application-header > strong [role=button] {
  position: relative;
  top: -3rem;
}
.application-header .learning-util-container {
  flex: 1;
  margin-left: 40rem;
}
.application-header .learning-util-container .learning-util {
  justify-content: flex-end;
}
.application-header--lv2 {
  padding: 29rem 30rem 29rem 40rem;
  border: 1px solid #cecece;
  background-color: #f2f7fb;
  border-radius: 20rem;
}
.application-header--lv2 .header > strong {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
}
.application-header--lv2 .util {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16rem;
}

.application-main {
  display: flex;
  margin-top: 30rem;
}
.application-main figure {
  flex: 1;
  margin-right: 30rem;
}
.application-main figure img {
  border-radius: 20rem;
}
.application-main .application-info {
  flex: 0 0 640rem;
  padding: 19rem 39rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.application-main .application-info > div {
  display: flex;
  padding: 20rem 0;
}
.application-main .application-info > div + div {
  position: relative;
}
.application-main .application-info > div + div:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 2rem;
  background-image: url("/images/ui22/common/dotted-line.png");
  background-position: center;
  background-size: 8rem auto;
  background-repeat: repeat-x;
}
.application-main .application-info > div > strong {
  display: inline-flex;
  align-items: center;
  flex: 0 0 90rem;
  margin-right: 20rem;
}
.application-main .application-info > div > strong [class^=icon] {
  margin-right: 5rem;
}
.application-main .application-info > div > div {
  flex: 1;
  font-weight: 500;
}
.application-main .application-info > div .course-tag {
  margin-bottom: -20rem;
}
.application-main .application-info > div .course-tag > span {
  margin-right: 20rem;
  margin-bottom: 20rem;
}
.application-main .application-info > div .course-tag > span + span {
  margin-left: 0;
}
.application-main .application-info > div .share-info > span {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
}
.application-main .application-info > div [class^=flag--lv2] {
  height: 28rem;
  line-height: 27rem;
}
.application-main .application-info .sort-breadcrumb {
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
}
.application-main .application-info .sort-breadcrumb > li + li {
  position: relative;
  padding-left: 26rem;
}
.application-main .application-info .sort-breadcrumb > li + li:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 6rem;
  left: 10rem;
  width: 6rem;
  height: 10rem;
  background-position: -227rem -59rem;
  opacity: 0.5;
}

.review {
  border-top: 2rem solid rgba(34, 34, 34, 0.9);
}
.review > ul > li {
  padding: 20rem 20rem 30rem;
  border-bottom: 1px solid rgba(206, 206, 206, 0.9);
}
.review-header {
  display: flex;
  justify-content: space-between;
}
.review-header > div strong {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.review-header > div strong > span {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: inline-flex;
  margin-left: 10rem;
  color: #666;
}
.review-header > strong {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.review-header > strong > span {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: inline-flex;
  margin-left: 10rem;
  color: #666;
}
.review-main {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  margin-top: 10rem;
}
.review-main p {
  white-space: pre-line;
}
.review-main-util {
  display: flex;
  justify-content: flex-end;
  margin-top: 10rem;
}
.review-main-util [role=button] + [role=button] {
  margin-left: 10rem;
}
.form-textarea + .review {
  border-top: 0;
}

.review-register-container {
  padding-top: 20rem;
  border-top: 2rem solid #222;
}

.star-point-container {
  display: flex;
  align-items: center;
}
.star-point-container .star-point {
  display: flex;
}
.star-point-container .star-point-item {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  width: 24rem;
  height: 24rem;
  background-position: -193rem -101rem;
}
.star-point-container .star-point-item + span {
  margin-left: 5rem;
}
.star-point-container .star-point-score em {
  font-weight: 500;
  color: #1472ce;
}
.star-point-container .star-point-apply {
  margin-left: 10rem;
}
.star-point-container[data-size=sm] .star-point-item {
  width: 16rem;
  height: 16rem;
  background-position: -193rem -126rem;
}
.star-point-container[data-size=sm] .star-point-item + span {
  margin-left: 2rem;
}
.star-point-container[data-size=sm] .star-point {
  position: relative;
  top: -2rem;
}
.star-point-container[data-size=sm] .star-point[data-star="1"] > .star-point-button:nth-last-child(n+5) .star-point-item,
.star-point-container[data-size=sm] .star-point[data-star="1"] > .star-point-item:nth-last-child(n+5) {
  background-position: -210rem -126rem;
}
.star-point-container[data-size=sm] .star-point[data-star="2"] > .star-point-button:nth-last-child(n+4) .star-point-item,
.star-point-container[data-size=sm] .star-point[data-star="2"] > .star-point-item:nth-last-child(n+4) {
  background-position: -210rem -126rem;
}
.star-point-container[data-size=sm] .star-point[data-star="3"] > .star-point-button:nth-last-child(n+3) .star-point-item,
.star-point-container[data-size=sm] .star-point[data-star="3"] > .star-point-item:nth-last-child(n+3) {
  background-position: -210rem -126rem;
}
.star-point-container[data-size=sm] .star-point[data-star="4"] > .star-point-button:nth-last-child(n+2) .star-point-item,
.star-point-container[data-size=sm] .star-point[data-star="4"] > .star-point-item:nth-last-child(n+2) {
  background-position: -210rem -126rem;
}
.star-point-container[data-size=sm] .star-point[data-star="5"] > .star-point-button:nth-last-child(n+1) .star-point-item,
.star-point-container[data-size=sm] .star-point[data-star="5"] > .star-point-item:nth-last-child(n+1) {
  background-position: -210rem -126rem;
}
.star-point-container[data-size=sm] .star-point-button + .star-point-button {
  margin-left: 5rem;
}
.star-point-container[data-size=sm] .star-point-score {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-left: 5rem;
}
.star-point-container[data-size=md] .star-point-item {
  width: 24rem;
  height: 24rem;
  background-position: -193rem -101rem;
}
.star-point-container[data-size=md] .star-point-item + span {
  margin-left: 5rem;
}
.star-point-container[data-size=md] .star-point {
  position: relative;
  top: -2rem;
}
.star-point-container[data-size=md] .star-point[data-star="1"] > .star-point-button:nth-last-child(n+5) .star-point-item,
.star-point-container[data-size=md] .star-point[data-star="1"] > .star-point-item:nth-last-child(n+5) {
  background-position: -218rem -101rem;
}
.star-point-container[data-size=md] .star-point[data-star="2"] > .star-point-button:nth-last-child(n+4) .star-point-item,
.star-point-container[data-size=md] .star-point[data-star="2"] > .star-point-item:nth-last-child(n+4) {
  background-position: -218rem -101rem;
}
.star-point-container[data-size=md] .star-point[data-star="3"] > .star-point-button:nth-last-child(n+3) .star-point-item,
.star-point-container[data-size=md] .star-point[data-star="3"] > .star-point-item:nth-last-child(n+3) {
  background-position: -218rem -101rem;
}
.star-point-container[data-size=md] .star-point[data-star="4"] > .star-point-button:nth-last-child(n+2) .star-point-item,
.star-point-container[data-size=md] .star-point[data-star="4"] > .star-point-item:nth-last-child(n+2) {
  background-position: -218rem -101rem;
}
.star-point-container[data-size=md] .star-point[data-star="5"] > .star-point-button:nth-last-child(n+1) .star-point-item,
.star-point-container[data-size=md] .star-point[data-star="5"] > .star-point-item:nth-last-child(n+1) {
  background-position: -218rem -101rem;
}
.star-point-container[data-size=md] .star-point-button + .star-point-button {
  margin-left: 5rem;
}
.star-point-container[data-size=md] .star-point-score {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-left: 10rem;
}
.star-point-container.align-c {
  justify-content: center;
}

.accordion > div {
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.accordion > div + div {
  margin-top: 20rem;
}
.accordion div.accordion-button > strong {
  cursor: pointer;
}
.accordion-button {
  display: block;
  padding: 30rem;
}
.accordion-button > strong {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  display: block;
  position: relative;
  padding-left: 40rem;
}
.accordion-button > strong:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 30rem;
  height: 30rem;
  background-color: #a8b1c2;
  background-position: -265rem -59rem;
  border-radius: 50%;
}
.accordion-button.icon-right > strong {
  padding-right: 40rem;
  padding-left: 0;
}
.accordion-button.icon-right > strong:before {
  right: 0;
  left: auto;
}
.accordion-button > div {
  display: none;
  margin-top: 20rem;
}
.accordion .is-active .accordion-button > strong {
  cursor: pointer;
}
.accordion .is-active .accordion-button > strong:before {
  background-color: #5085ec;
  background-position: -234rem -59rem;
}
.accordion .is-active .accordion-button > div {
  display: block;
}
.accordion-answer {
  border-top: 1px solid #cecece;
  padding-top: 20rem;
}

.classification, .classification--equality {
  display: flex;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.classification > div, .classification--equality > div {
  flex: 1;
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.classification > div:nth-child(1), .classification--equality > div:nth-child(1) {
  display: flex;
  align-items: center;
  flex: 0 0 188rem;
  padding: 0 40rem;
}
.classification > div:nth-child(2), .classification--equality > div:nth-child(2), .classification > div:nth-child(3), .classification--equality > div:nth-child(3) {
  flex: 0 0 216rem;
}
.classification > div:nth-child(4), .classification--equality > div:nth-child(4) {
  flex: 1;
}
.classification > div:nth-child(4) ul li a:before, .classification--equality > div:nth-child(4) ul li a:before {
  content: "+" !important;
}
.classification > div:nth-child(4) ul li a.is-active:before, .classification--equality > div:nth-child(4) ul li a.is-active:before {
  content: "-" !important;
}
.classification > div + div, .classification--equality > div + div {
  flex: 0 0 380rem;
  padding-right: 10rem;
  border-left: 1px solid #ddd;
}
.classification > div ul li a, .classification--equality > div ul li a {
  display: block;
  padding-top: 12rem;
  padding-bottom: 14rem;
  padding-left: 20rem;
}
.classification > div ul li a:before, .classification--equality > div ul li a:before {
  content: "+";
  display: inline-block;
  margin-right: 4rem;
  vertical-align: top;
}
.classification > div ul li.is-active > a, .classification--equality > div ul li.is-active > a {
  color: #000;
  background-color: #f1f6fb;
}
.classification > div ul li.is-active > a:before, .classification--equality > div ul li.is-active > a:before {
  content: "-";
}
.classification > div > ul, .classification--equality > div > ul {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  overflow-y: scroll;
  height: 182rem;
  color: #666;
}
.classification > div > ul::-webkit-scrollbar, .classification--equality > div > ul::-webkit-scrollbar {
  width: 20rem;
  height: 100rem;
}
.classification > div > ul::-webkit-scrollbar-track, .classification--equality > div > ul::-webkit-scrollbar-track {
  background-color: #ddd;
}
.classification > div > ul::-webkit-scrollbar, .classification--equality > div > ul::-webkit-scrollbar {
  width: 6rem;
  height: 6rem;
}
.classification > div > ul::-webkit-scrollbar-thumb, .classification--equality > div > ul::-webkit-scrollbar-thumb {
  background-color: #b8b8b8;
  border-radius: 3rem;
}
.classification > div > ul > li.is-active > a, .classification--equality > div > ul > li.is-active > a {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}
.classification > div > ul > li ul, .classification--equality > div > ul > li ul {
  display: none;
  overflow: visible;
  height: auto;
  margin-left: 20rem;
}
.classification > div > ul > li ul li, .classification--equality > div > ul > li ul li {
  color: #666;
}
.classification > div > ul > li ul li.is-active > a, .classification--equality > div > ul > li ul li.is-active > a {
  color: #000;
  background-color: #f1f6fb;
}
.classification--equality > div:nth-child(1) {
  flex: 0 0 293rem;
}
.classification--equality > div:nth-child(2) {
  flex: 0 0 381rem;
}
.classification--equality > div:nth-child(3) {
  flex: 0 0 381rem;
}
.classification--equality > div:nth-child(4) {
  flex: 0 0 381rem;
}

.teacher-detail {
  display: flex;
  flex-wrap: wrap;
  padding: 40rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.teacher-detail figure {
  flex: 0 0 224rem;
  margin-right: 40rem;
}
.teacher-detail figure img {
  border: 1px solid #cecece;
  border-radius: 10rem;
}
.teacher-detail-main {
  display: flex;
}
.teacher-detail-main .teacher-detail-section {
  flex: 0 0 280rem;
}
.teacher-detail-footer {
  flex: 0 0 100%;
  border-top: 1px solid #cecece;
}
.teacher-detail-section > div {
  display: flex;
  padding-top: 14rem;
  padding-bottom: 14rem;
  border-bottom: 1px solid #cecece;
}
.teacher-detail-section > div.border-none {
  border-bottom: 0;
}
.teacher-detail-section > div > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  flex: 0 0 100rem;
}
.teacher-detail-section > div > span {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  flex: 1;
}
.teacher-detail-section + .teacher-detail-section {
  margin-left: 40rem;
}

.underline {
  text-decoration: underline;
}

.learning-goals {
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.learning-goals > div {
  padding: 40rem 40rem 20rem;
}
.learning-goals > div + div {
  border-top: 1px solid #cecece;
}
.learning-goals > div > strong {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  display: block;
  padding-bottom: 20rem;
  border-bottom: 2rem solid rgba(34, 34, 34, 0.9);
}
.learning-goals ul {
  padding: 0 20rem;
}
.learning-goals ul > li {
  padding: 20rem 0;
}
.learning-goals ul > li + li {
  border-top: 1px solid #cecece;
}

.page-search {
  display: flex;
  justify-content: center;
  margin-top: 20rem;
}
.page-search > div + div {
  margin-left: 10rem;
}
.page-search .select {
  width: 240rem;
}
.page-search .form-input--icon-button {
  width: 440rem;
}

.post-sort-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40rem;
  padding: 26rem 40rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.post-sort-container > strong {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
}
.post-sort-container .type-select [role=button] + [role=button] {
  margin-left: 8rem;
}
.post-sort-container .form-flex > li + li, .post-sort-container .form-flex--inline > li + li, .post-sort-container .form-flex--jc-between > li + li, .post-sort-container .form-flex--align-c > li + li {
  margin-left: 16rem;
}
.post-sort-container .posting-order {
  display: flex;
}
.post-sort-container .posting-order > li + li {
  position: relative;
  margin-left: 16rem;
  padding-left: 17rem;
}
.post-sort-container .posting-order > li + li:before {
  content: "";
  position: absolute;
  top: 5rem;
  left: 0;
  width: 1px;
  height: 16rem;
  background-color: #ddd;
}
.post-sort-container .posting-order > li [role=button] {
  color: #888;
  transition: color 0.11s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.post-sort-container .posting-order > li [role=button].is-active, .post-sort-container .posting-order > li [role=button]:hover {
  color: #000;
}
.post-sort-container .sort-view-control {
  display: flex;
  margin-left: 40rem;
}
.post-sort-container .sort-method {
  display: flex;
}
.post-sort-container .sort-method [role=button] {
  width: 48rem;
  height: 48rem;
}
.post-sort-container .sort-method [role=button] + [role=button] {
  margin-left: 8rem;
}
.post-sort-container .sort-method [role=button].sort-view-type01:after {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: calc(50% - 12rem);
  left: calc(50% - 12rem);
  width: 24rem;
  height: 24rem;
  background-position: -210rem 0;
}
.post-sort-container .sort-method [role=button].sort-view-type01:hover:after, .post-sort-container .sort-method [role=button].sort-view-type01.is-active:after {
  background-position: -235rem 0;
}
.post-sort-container .sort-method [role=button].sort-view-type02:after {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: calc(50% - 12rem);
  left: calc(50% - 12rem);
  width: 24rem;
  height: 24rem;
  background-position: -210rem -25rem;
}
.post-sort-container .sort-method [role=button].sort-view-type02:hover:after, .post-sort-container .sort-method [role=button].sort-view-type02.is-active:after {
  background-position: -235rem -25rem;
}
.post-sort-container .select {
  width: 240rem;
  margin-left: 30rem;
}

.ctrl-buttons {
  margin-left: 10rem;
}

.swiper-ctrl-container {
  display: flex;
  align-items: center;
}
.swiper-ctrl-container .swiper-pagination-bullet {
  width: 8rem;
  height: 8rem;
  opacity: 1;
  transition: all 0.11s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.swiper-ctrl-container .swiper-pagination-bullet + .swiper-pagination-bullet {
  margin-left: 10rem;
}
.swiper-ctrl-container .swiper-pagination-bullet-active {
  width: 60rem;
  height: 8rem;
  border-radius: 4rem;
}

[data-autoplay=true] .ctrl-buttons .play-button {
  display: none;
}
[data-autoplay=true] .ctrl-buttons .pause-button {
  display: inline-flex;
}

[data-autoplay=false] .ctrl-buttons .play-button {
  display: inline-flex;
}
[data-autoplay=false] .ctrl-buttons .pause-button {
  display: none;
}

.section-sub-main {
  overflow: hidden;
  margin-top: 50rem;
  border-radius: 20rem;
}
.section-sub-main-header {
  position: absolute;
  left: 60rem;
  bottom: 60rem;
  z-index: 10;
}
.section-sub-main-content {
  position: relative;
}
.section-sub-main-content .txt-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 390rem;
  height: 100%;
  padding: 60rem;
  background-color: #1472ce;
}
.section-sub-main-content .txt-wrap > strong {
  font-size: 32rem;
  line-height: 42rem;
  font-weight: 500;
  color: #fff;
}
.section-sub-main-content .txt-wrap > p {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  margin-top: 20rem;
  color: #fff;
}
.section-sub-main-content .img-wrap {
  overflow: hidden;
  position: relative;
  width: 1050rem;
  height: 490rem;
  margin-left: 390rem;
}

.swiper-ctrl-container .swiper-pagination {
  position: relative;
  top: auto;
  left: auto;
}
.swiper-ctrl-container[data-theme=white] .swiper-pagination-bullet {
  background-color: #fff;
}

.page-bottom-buttons-wrapper {
  padding-top: 40rem;
  border-top: 2rem solid rgba(34, 34, 34, 0.9);
}
.page-bottom-buttons-wrapper:after {
  content: "";
  display: block;
  clear: both;
}
.page-bottom-buttons-wrapper .buttons-left {
  float: left;
}
.page-bottom-buttons-wrapper .buttons-right {
  float: right;
}
.page-bottom-buttons-wrapper [role=button] + [role=button] {
  margin-left: 10rem;
}
.page-bottom-buttons-wrapper .buttons-center {
  text-align: center;
}
.page-bottom-buttons-wrapper.border-none {
  border-top: 0;
}

.application-util {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10rem;
}
.application-util > li {
  border: 1px solid #cecece;
}
.application-util > li [class^=icon] {
  position: relative;
  top: -1px;
  margin-right: 2rem;
  vertical-align: middle;
}
.application-util[data-size=sm] {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 500;
  line-height: 32rem;
}
.application-util[data-size=sm] > li {
  height: 32rem;
  margin-right: 5rem;
  margin-bottom: 5rem;
  padding: 0 9rem;
  border-radius: 6rem;
}
.application-util[data-size=md] {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  line-height: 46rem;
}
.application-util[data-size=md] > li {
  height: 46rem;
  margin-right: 5rem;
  margin-bottom: 10rem;
  padding: 0 6rem;
  border-radius: 10rem;
}

.lookup-period {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 24rem 100rem 24rem 40rem;
  background: #ffffff;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.lookup-period__tit {
  display: inline-block;
  margin-right: 20rem;
}
.lookup-period__list {
  display: flex;
  align-items: center;
  grid-gap: 32rem;
  margin-right: 10rem;
}
.lookup-period .lookup-period--button {
  position: absolute;
  top: 24rem;
  right: 40rem;
  border-radius: 24rem;
}

.select-calendar {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
}
.select-calendar em {
  display: inline-block;
  margin: 0 15rem;
  font-weight: 400;
}

.edu-card, .edu-card--lv2 {
  margin-top: 40rem;
}
.edu-card-item, .edu-card-item--lv2 {
  padding: 30rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.edu-card-item + .edu-card-item, .edu-card-item--lv2 + .edu-card-item, .edu-card-item + .edu-card-item--lv2, .edu-card-item--lv2 + .edu-card-item--lv2 {
  margin-top: 20rem;
}
.edu-card-item--lv2 {
  position: relative;
  min-height: 269rem;
  padding-left: 460rem;
}
.edu-card-item--lv2 .card-image {
  position: absolute;
  top: 30rem;
  left: 30rem;
  width: 400rem;
  height: 218rem;
}
.edu-card-item--lv2 .card-image img {
  width: 100%;
}
.edu-card-item--lv2 .edu-card-tit {
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  max-height: 64rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
  height: 64rem;
}
.edu-card-item--lv2 .card-desc {
  overflow: hidden;
  display: -webkit-box;
  width: calc(100% - 210rem);
  max-height: 22rem;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
}
.edu-card-item--lv2 .edu-card-section + .edu-card-section, .edu-card-item--lv2 .edu-card-section--line + .edu-card-section, .edu-card-item--lv2 .edu-card-section + .edu-card-section--line, .edu-card-item--lv2 .edu-card-section--line + .edu-card-section--line {
  margin-top: 20rem;
}
.edu-card-item--lv2 .edu-card-footer {
  flex-direction: column;
  justify-content: flex-start;
}
.edu-card-item--lv2 .edu-card-footer > div + div {
  justify-content: flex-start;
  margin-top: 10rem;
}
.edu-card-section, .edu-card-section--line {
  display: flex;
}
.edu-card-section > div, .edu-card-section--line > div {
  display: flex;
  align-items: center;
  flex: auto;
}
.edu-card-section > div + div, .edu-card-section--line > div + div {
  flex: 1;
  justify-content: flex-end;
  margin-left: 40rem;
}
.edu-card-section > div > div + div, .edu-card-section--line > div > div + div {
  margin-left: 60rem;
}
.edu-card-section + .edu-card-section, .edu-card-section--line + .edu-card-section, .edu-card-section + .edu-card-section--line, .edu-card-section--line + .edu-card-section--line {
  margin-top: 10rem;
}
.edu-card-section--line {
  position: relative;
  margin-top: 20rem;
  padding-top: 22rem;
}
.edu-card-section--line:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 2rem;
  background-image: url("/images/ui22/common/dotted-line.png");
  background-position: center;
  background-size: 8rem auto;
  background-repeat: repeat-x;
}
.edu-card-section.no-data, .no-data.edu-card-section--line {
  justify-content: center;
  padding-top: 30rem;
  padding-bottom: 30rem;
}
.edu-card-footer {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 20rem;
  padding-top: 22rem;
}
.edu-card-footer:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 2rem;
  background-image: url("/images/ui22/common/dotted-line.png");
  background-position: center;
  background-size: 8rem auto;
  background-repeat: repeat-x;
}
.edu-card-footer > div {
  display: flex;
}
.edu-card-footer > div + div {
  justify-content: flex-end;
}
.edu-card-footer .edu-card-progress-container {
  padding-right: 0;
}
.edu-card-footer .edu-card-progress-rate {
  position: static;
  margin-left: 10rem;
}
.edu-card-footer .edu-card-organizer + .edu-card-person, .edu-card-footer .edu-card-organizer + .edu-card-place {
  margin-left: 40rem;
}
.edu-card-footer .edu-card-person + .edu-card-organizer, .edu-card-footer .edu-card-person + .edu-card-place {
  margin-left: 40rem;
}
.edu-card-footer .edu-card-place + .edu-card-organizer, .edu-card-footer .edu-card-place + .edu-card-person {
  margin-left: 40rem;
}
.edu-card-tit {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  display: block;
  align-self: center;
}
.edu-card .form-selector input[type=checkbox] + label:before, .edu-card--lv2 .form-selector input[type=checkbox] + label:before {
  top: 5rem;
}
.edu-card .form-selector + .flag-wrapper, .edu-card--lv2 .form-selector + .flag-wrapper {
  margin-left: 12rem;
}
.edu-card .flag-wrapper, .edu-card--lv2 .flag-wrapper {
  align-self: center;
}
.edu-card--lv2 .edu-card-item, .edu-card--lv2 .edu-card-item--lv2 {
  padding: 30rem 40rem;
}

.flag-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8rem;
}
.flag-wrapper + .edu-card-tit {
  margin-top: 10rem;
}
.flag-wrapper + div > .edu-card-tit {
  margin-left: 20rem;
}

.edu-card-date > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  color: #042b69;
}
.edu-card-date .edu-card-date-day {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  margin-left: 10rem;
  padding: 1px 10rem;
  background-color: #f2f7fb;
  border-radius: 100rem;
}

.edu-card-period {
  display: flex;
  align-items: center;
}
.edu-card-period [class^=edu-card-period] + [class^=flag] {
  margin-left: 10rem;
}
.edu-card-period-desc {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-left: 5rem;
}
.edu-card-period-remaining {
  margin-left: 4rem;
  color: #666;
}
.edu-card-period .edu-card-period-main {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  margin-left: 20rem;
}
.edu-card-period + .edu-card-period, .edu-card-period + .edu-card-department, .edu-card-period + .edu-card-review-period, .edu-card-period + .edu-card-progress {
  margin-left: 40rem;
}

.edu-card-tag {
  display: flex;
}
.edu-card-tag > span {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  color: #666;
}
.edu-card-tag > span + span {
  margin-left: 20rem;
}

.edu-card-breadcrumb {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: flex;
  margin-top: 10rem;
}
.edu-card-breadcrumb > li + li {
  position: relative;
}
.edu-card-breadcrumb > li + li:before {
  content: ">";
  display: inline-flex;
  margin: 0 4rem;
  color: #b8b8b8;
}

.edu-card-progress + .edu-card-progress {
  margin-left: 40rem;
}
.edu-card-progress-container {
  display: flex;
  align-items: center;
  position: relative;
  padding-right: 50rem;
}
.edu-card-progress-content {
  overflow: hidden;
  position: relative;
  width: 160rem;
  height: 16rem;
  margin-left: 20rem;
  background-color: #ddd;
  border-radius: 4rem;
}
.edu-card-progress-content .progress-current {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #1472ce;
  border-radius: 4rem;
}
.edu-card-progress-desc {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-left: 5rem;
}
.edu-card-progress-rate {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  position: absolute;
  top: 0;
  left: calc(100% - 40rem);
  color: #1472ce;
}
.edu-card-progress-container[data-theme=blue] .progress-current {
  background-color: #1472ce;
}
.edu-card-progress-container[data-theme=blue] .edu-card-progress-rate {
  color: #1472ce;
}
.edu-card-progress-container[data-theme=grey] .progress-current {
  background-color: #939393;
}
.edu-card-progress-container[data-theme=grey] .edu-card-progress-rate {
  color: #666;
}

.edu-card-review-period {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.edu-card-review-period [class^=icon] {
  margin-right: 5rem;
}
.edu-card-review-period > strong {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  margin-right: 10rem;
  color: #666;
}

.edu-card-organizer-content {
  display: flex;
  align-items: center;
}
.edu-card-organizer-desc {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-left: 5rem;
}
.edu-card-organizer-data {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-left: 20rem;
  color: #042b69;
}

.edu-card-person-content {
  display: flex;
  align-items: center;
}
.edu-card-person-desc {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-left: 5rem;
}
.edu-card-person-data {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-left: 20rem;
  color: #042b69;
}

.edu-card-place-content {
  display: flex;
  align-items: center;
}
.edu-card-place-desc {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-left: 5rem;
}
.edu-card-place-data {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-left: 20rem;
  color: #042b69;
}
.edu-card-place-util {
  margin-left: 10rem;
}

.status-learning {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 700;
  display: block;
  width: 180rem;
  text-align: center;
  color: #042b69;
}
.status-learning > strong {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 700;
  position: relative;
  top: 0;
  color: #5085ec;
}

.status-completion {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 700;
  display: block;
  width: 180rem;
  text-align: center;
  color: #042b69;
}

.status-order-can-change {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 700;
  display: block;
  width: 180rem;
  text-align: center;
  color: #042b69;
}

.status-approval-waiting {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 700;
  display: block;
  width: 180rem;
  text-align: center;
  color: #042b69;
}

.edu-card-department {
  display: flex;
  align-items: center;
}
.edu-card-department [class^=icon] {
  margin-right: 5rem;
}

.order-schedule {
  display: inline-flex;
  align-items: center;
}
.order-schedule-container > div + div {
  margin-top: 12rem;
}
.order-schedule > span {
  display: flex;
  align-items: center;
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
}
.order-schedule > span + span {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  margin-left: 10rem;
}
.order-schedule [class^=icon] {
  margin-right: 5rem;
}

.modal-buttons [role=button] + [role=button] {
  margin-left: 10rem;
}

.overflow-y-wrapper {
  padding: 19rem 10rem 19rem 30rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.overflow-y-wrapper--lv2 {
  padding: 0;
}
.overflow-y-content {
  overflow: hidden;
  overflow-y: scroll;
  height: 158rem;
  padding-right: 20rem;
}

.changeable-order-list {
  display: flex;
}
.changeable-order-list > div {
  flex: 1;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.changeable-order-list > div + div {
  position: relative;
  margin-left: 120rem;
}
.changeable-order-list > div + div:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 114rem;
  left: -72rem;
  width: 24rem;
  height: 44rem;
  background-position: -260rem 0;
  pointer-events: none;
}
.changeable-order-list > div > strong {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  display: block;
  padding: 19rem 40rem;
  background-color: #f2f7fb;
  border-radius: 20rem 20rem 0 0;
}
.changeable-order-list > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 176rem;
}
.changeable-order-list > div .overflow-y-wrapper {
  width: 100%;
  padding: 24rem 40rem;
  border: 0;
  border-radius: 0;
}
.changeable-order-list > div .overflow-y-content {
  height: 128rem;
  padding-right: 30rem;
}
.changeable-order-list > div .order-schedule-container > div {
  display: flex;
  justify-content: space-between;
  height: 32rem;
}

.desired-change-index {
  display: flex;
  justify-content: space-between;
}
.desired-change-index > div > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
}
.desired-change-index > div .select {
  margin-left: 20rem;
}

.learning-util {
  display: flex;
  align-items: center;
}
.learning-util .learning-status {
  display: flex;
}
.learning-util .learning-status > span {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.learning-util .learning-status > span [class^=icon] {
  margin-right: 8rem;
}
.learning-util .learning-status > span + span {
  position: relative;
  margin-left: 10rem;
  padding-left: 11rem;
}
.learning-util .learning-status > span + span:before {
  content: "";
  position: absolute;
  top: 4rem;
  left: 0;
  width: 1px;
  height: 14rem;
  background-color: #b8b8b8;
}
.learning-util .buttons-wrapper {
  margin-left: 20rem;
}
.learning-util .buttons-wrapper [role=button] {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  position: relative;
}
.learning-util .buttons-wrapper [role=button]:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  display: inline-flex;
  margin-right: 4rem;
  width: 16rem;
  height: 16rem;
}
.learning-util .buttons-wrapper [role=button].scrap-button:before {
  background-position: 0 -143rem;
}
.learning-util .buttons-wrapper [role=button].scrap-button.is-active, .learning-util .buttons-wrapper [role=button].scrap-button:hover {
  border-color: #4a5366;
  background-color: #4a5366;
  color: #fff;
}
.learning-util .buttons-wrapper [role=button].scrap-button.is-active:before, .learning-util .buttons-wrapper [role=button].scrap-button:hover:before {
  background-position: 0 -160rem;
}
.learning-util .buttons-wrapper [role=button].recomm-button:before {
  background-position: -17rem -143rem;
}
.learning-util .buttons-wrapper [role=button].recomm-button.is-active, .learning-util .buttons-wrapper [role=button].recomm-button:hover {
  border-color: #f65959;
  background-color: #f65959;
  color: #fff;
}
.learning-util .buttons-wrapper [role=button].recomm-button.is-active:before, .learning-util .buttons-wrapper [role=button].recomm-button:hover:before {
  background-position: -17rem -160rem;
}
.learning-util .buttons-wrapper [role=button].share-button:before {
  background-position: -34rem -143rem;
}
.learning-util .buttons-wrapper [role=button].share-button.is-active, .learning-util .buttons-wrapper [role=button].share-button:hover {
  border-color: #5085ec;
  background-color: #5085ec;
  color: #fff;
}
.learning-util .buttons-wrapper [role=button].share-button.is-active:before, .learning-util .buttons-wrapper [role=button].share-button:hover:before {
  background-position: -34rem -160rem;
}

.is-temp {
  position: relative;
  padding-top: 50%;
  background-color: #222;
}
.is-temp:before {
  content: "임시 영역 (개발 시 .is-temp 삭제)";
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: #fff;
}

.form-address > div {
  display: flex;
  align-items: center;
}
.form-address > div + div {
  margin-top: 8rem;
}
.form-address > div > span {
  margin-left: 10rem;
}
.form-address > div > .form-selector {
  margin-left: 20rem;
}
.form-address > div > .form-input + .form-input, .form-address > div > .form-input--icon-button + .form-input, .form-address > div > .form-input + .form-input--icon-button, .form-address > div > .form-input--icon-button + .form-input--icon-button, .form-address > div > .form-input--datepicker + .form-input, .form-address > div > .form-input--datepicker + .form-input--icon-button, .form-address > div > .form-input + .form-input--datepicker, .form-address > div > .form-input--icon-button + .form-input--datepicker, .form-address > div > .form-input--datepicker + .form-input--datepicker, .form-address > div > .form-input--img + .form-input, .form-address > div > .form-input--img + .form-input--icon-button, .form-address > div > .form-input--img + .form-input--datepicker, .form-address > div > .form-input + .form-input--img, .form-address > div > .form-input--icon-button + .form-input--img, .form-address > div > .form-input--datepicker + .form-input--img, .form-address > div > .form-input--img + .form-input--img {
  margin-left: 10rem;
}
.form-address .address-desc {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  display: block;
  margin-top: 16rem;
}

.form-search-container {
  padding: 24rem 40rem;
  border: 1px solid #cecece;
  background-color: #f2f7fb;
  border-radius: 20rem;
}
.form-search-container > div {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 48rem;
  padding-right: 120rem;
}
.form-search-container > div + div {
  margin-top: 10rem;
}
.form-search-container > div > strong {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  align-self: flex-start;
  margin-right: 40rem;
  padding-top: 9rem;
}
.form-search-container > div .form-search-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  margin-bottom: -10rem;
}
.form-search-container > div .form-search-content > div {
  display: flex;
  align-items: center;
  margin-right: 60rem;
  margin-bottom: 10rem;
}
.form-search-container > div .form-search-content > div.flex--full {
  flex: 1;
}
.form-search-container > div .form-search-content > div > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: block;
  margin-right: 20rem;
}
.form-search-container > div .form-search-content--flex > div > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: block;
}
.form-search-container > div .form-search-content--grid {
  flex: 1;
}
.form-search-container > div .form-search-content--grid > div {
  display: flex;
  align-items: center;
}
.form-search-container > div .form-search-content--grid > div > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: block;
  white-space: nowrap;
}
.form-search-container > div > [role=button] {
  position: absolute;
  top: 0;
  right: 0;
}
.form-search-container.border-none {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.form-flex, .form-flex--inline, .form-flex--jc-between, .form-flex--align-c {
  display: flex;
}
.form-flex > div, .form-flex--inline > div, .form-flex--jc-between > div, .form-flex--align-c > div {
  display: flex;
  align-items: center;
}
.form-flex > div > strong, .form-flex--inline > div > strong, .form-flex--jc-between > div > strong, .form-flex--align-c > div > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: block;
  margin-right: 20rem;
}
.form-flex > div + div, .form-flex--inline > div + div, .form-flex--jc-between > div + div, .form-flex--align-c > div + div {
  margin-left: 10rem;
}
.form-flex .form-col, .form-flex--inline .form-col, .form-flex--jc-between .form-col, .form-flex--align-c .form-col {
  flex: 1;
  display: block;
}
.form-flex--align-c {
  align-items: center;
}
.form-flex--jc-between {
  justify-content: space-between;
}
.form-flex--inline {
  display: inline-flex;
}

.form-nameset {
  display: inline-block;
}
.form-nameset .form-input-name {
  vertical-align: middle;
}
.form-nameset .form-input-name + .form-input-content {
  margin-left: 20rem;
}
.form-nameset .form-input-content {
  display: inline-flex;
  vertical-align: middle;
}
.form-nameset .form-input-content + .form-input-name {
  margin-left: 10rem;
}
.form-nameset.form-input .form-input-content,
.form-nameset .form-input-content {
  display: inline-flex;
  vertical-align: middle;
}
.form-nameset .form-input--datepicker + .form-input-name {
  margin-left: 10rem;
}
.form-nameset + .form-nameset {
  margin-left: 40rem;
}
.form-nameset.form-input--datepicker .form-input-content {
  display: inline-flex;
}

.video-content .video-area, .video-content .video-area--temp {
  overflow: hidden;
  position: relative;
  height: 810rem;
}
.video-content .video-area--temp {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
}
.video-content .video-area--temp:before {
  content: "플레이어 위치";
  color: #fff;
}
.video-content .video-area iframe, .video-content .video-area--temp iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.video-content .video-info {
  padding-top: 20rem;
  padding-bottom: 20rem;
  border-bottom: 1px solid #cecece;
}
.video-content .video-info > div {
  padding: 20rem;
}
.video-content .video-info > div + div {
  position: relative;
  padding-bottom: 0;
}
.video-content .video-info > div + div:before {
  content: "";
  position: absolute;
  top: -1px;
  right: 0;
  left: 0;
  height: 2rem;
  background-image: url("/images/ui22/common/dotted-line.png");
  background-position: center;
  background-size: 8rem auto;
  background-repeat: repeat-x;
}
.video-content .video-info-header > strong {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  max-height: 152rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
}
.video-content .video-info-summary > strong {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  display: block;
}
.video-content .video-info-summary .util {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10rem;
}
.video-content .video-info-summary .script-button .script-close-button {
  display: none;
}
.video-content .video-info-summary .script-button .script-close-button [class^=icon] {
  transform: rotateX(180deg);
}
.video-content .video-info-summary .script-button.is-active .script-open-button {
  display: none;
}
.video-content .video-info-summary .script-button.is-active .script-close-button {
  display: inline-flex;
}
.video-content .video-info-summary .script-content {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: none;
  margin-top: 20rem;
  padding: 30rem 45rem 30rem 30rem;
  background-color: #f8f8f8;
  color: #666;
}
.video-content .video-info-summary .script-content.is-active {
  display: block;
}
.video-content .video-info-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10rem;
}
.video-content .video-info-details {
  display: flex;
  justify-content: space-between;
  margin-top: 10rem;
}
.video-content .card-breadcrumb > strong {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  display: block;
  margin-right: 20rem;
}

.board-header, .board-header--align-r, .board-header--align-c {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 40rem;
  padding-bottom: 20rem;
}
.board-header > div, .board-header--align-r > div, .board-header--align-c > div {
  display: flex;
  align-items: baseline;
}
.board-header .board-header-tit, .board-header--align-r .board-header-tit, .board-header--align-c .board-header-tit {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
  padding-bottom: 0;
}
.board-header .board-header-tit + .board-header-desc, .board-header--align-r .board-header-tit + .board-header-desc, .board-header--align-c .board-header-tit + .board-header-desc {
  margin-top: 10rem;
}
.board-header .board-header-tit.h3, .board-header--align-r .board-header-tit.h3, .board-header--align-c .board-header-tit.h3 {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
}
.board-header .board-header-subtit, .board-header--align-r .board-header-subtit, .board-header--align-c .board-header-subtit {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
}
.board-header-desc {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
}
.board-header-desc i[class^=icon] {
  position: relative;
  top: -2rem;
  margin: 0 4rem;
  vertical-align: middle;
}
.board-header-desc[data-prefix-bullet] {
  position: relative;
  margin-left: 10rem;
  padding-left: 14rem;
}
.board-header-desc[data-prefix-bullet]:before {
  content: "•";
  position: absolute;
  top: 0;
  left: 0;
}
.board-header-case {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  position: relative;
  top: -2rem;
  margin-left: 8rem;
}
.board-header-case > strong {
  font-weight: 500;
}
.board-header--align-c {
  align-items: center;
}
.board-header-notice .info-msg, .board-header-notice .form-file-desc, .board-header-notice .info-msg--md {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
}
.board-header-notice .info-msg[data-prefix], .board-header-notice [data-prefix].form-file-desc, .board-header-notice [data-prefix].info-msg--md {
  padding-left: 20rem;
}
.board-header--align-r {
  justify-content: flex-end;
}

.tab-header {
  position: relative;
}
.tab-header > .buttons-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.info-msg, .form-file-desc, .info-msg--md {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  display: flex;
  margin-top: 10rem;
  color: #222;
}
.info-msg[data-prefix], [data-prefix].form-file-desc, [data-prefix].info-msg--md {
  position: relative;
  padding-left: 17rem;
}
.info-msg[data-prefix]:before, [data-prefix].form-file-desc:before, [data-prefix].info-msg--md:before {
  position: absolute;
  top: 0;
  left: 2rem;
}
.info-msg:before, .form-file-desc:before, .info-msg--md:before {
  content: attr(data-prefix);
}
.info-msg--md {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  margin-top: 0;
}
.info-msg--md[data-prefix] {
  display: flex;
  padding-left: 0;
}
.info-msg--md[data-prefix]:before {
  position: relative;
  margin-left: 4rem;
  margin-right: 10rem;
}
.info-msg-box {
  padding: 20rem 30rem;
  border-radius: 20rem;
  background-color: #f2f7fb;
}
.info-msg-box .info-msg, .info-msg-box .form-file-desc, .info-msg-box .info-msg--md {
  margin-top: 0;
}
.info-msg-box.border {
  border: 1px solid #cecece;
}

.survey-summary-list {
  padding: 30rem 40rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.survey-summary-list li {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.survey-summary-list li + li {
  margin-top: 15rem;
  padding-top: 15rem;
  border-top: 1px solid #ddd;
}
.survey-summary-list__tit {
  flex: 0 0 140rem;
  font-weight: 500;
}
.survey-summary-list__detail {
  flex: 1;
}

.dev-result {
  display: flex;
}
.dev-result > div + div {
  flex: 1;
}
.dev-result > div > strong {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  margin-right: 10rem;
  padding: 5rem 20rem;
  background-color: #f2f7fb;
  border-radius: 20rem;
  color: #1472ce;
}
.dev-result > div > span {
  flex: 1;
}

.ellipsis {
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
}

.user-settings {
  text-align: center;
}
.user-settings li + li {
  margin-top: 20rem;
}
.user-settings li.is-active [role=button] {
  font-weight: 500;
  color: #1472ce;
  pointer-events: none;
}
.user-settings [role=button] {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
}
.user-settings [role=button]:hover {
  font-weight: 500;
  color: #1472ce;
}

.sign-line, .sign-line--inline {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 10rem;
}
.sign-line--inline {
  display: inline-flex;
}
.sign-line li + li:before, .sign-line--inline li + li:before {
  content: "";
  display: inline-block;
  width: 9rem;
  height: 9rem;
  margin-right: 12rem;
  vertical-align: middle;
  border: 1px solid #b8b8b8;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg);
}

.auto-logout > p {
  text-align: center;
}
.auto-logout > p + p {
  margin-top: 20rem;
}
.auto-logout .time-remaining {
  font-weight: 500;
}

.profile {
  display: flex;
  padding: 40rem;
  border: 1px solid #cecece;
  background-color: #f2f7fb;
  border-radius: 20rem;
}
.profile-container {
  display: grid;
  row-gap: 20rem;
}
.profile-header {
  flex: 0 0 380rem;
  margin-right: 20rem;
  background-color: #fff;
  border-radius: 20rem;
}
.profile-header figure img {
  width: 100%;
  height: 236rem;
  border-radius: 20rem 20rem 0 0;
}
.profile-header figcaption {
  padding: 26rem 20rem;
}
.profile-header figcaption .profile-name {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-header figcaption .profile-name > strong {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
}
.profile-header figcaption .profile-name > span {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  margin-left: 10rem;
}
.profile-header figcaption .profile-desc {
  margin-top: 4rem;
  text-align: center;
}
.profile-main {
  flex: 1;
}
.profile-contact {
  display: flex;
  padding: 10rem 0;
  background-color: #fff;
  border-radius: 20rem;
}
.profile-contact > div {
  flex: 1;
  padding: 0 40rem;
}
.profile-contact > div + div {
  position: relative;
}
.profile-contact > div + div:before {
  content: "";
  position: absolute;
  top: 7rem;
  left: 0;
  width: 1px;
  height: 12rem;
  background-color: #b8b8b8;
}
.profile-contact + .profile-content {
  margin-top: 20rem;
}
.profile-content {
  min-height: 290rem;
  padding: 10rem 40rem;
  background-color: #fff;
  border-radius: 20rem;
}
.profile-content > div {
  padding-top: 20rem;
  padding-bottom: 20rem;
}
.profile-content > div + div {
  position: relative;
}
.profile-content > div + div:before {
  content: "";
  position: absolute;
  top: -1px;
  right: 0;
  left: 0;
  height: 2rem;
  background-image: url("/images/ui22/common/dotted-line.png");
  background-position: center;
  background-size: 8rem auto;
  background-repeat: repeat-x;
}
.profile-info {
  display: flex;
  align-items: center;
}
.profile-info [class^=icon] {
  margin-right: 5rem;
}
.profile-info .profile-info-tit, .profile-info .profile-info-tit-align-c {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: flex;
  flex: 0 0 118rem;
  padding-right: 10rem;
}
.profile-info .profile-info-tit-align-c {
  align-self: baseline;
}
.profile-info .profile-info-desc {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  color: #222;
}
.profile-info .profile-breadcrumb {
  display: flex;
}
.profile-info .profile-breadcrumb + .profile-breadcrumb {
  margin-top: 10rem;
}
.profile-info .profile-breadcrumb > li + li {
  position: relative;
  padding-left: 26rem;
}
.profile-info .profile-breadcrumb > li + li:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 8rem;
  left: 10rem;
  width: 6rem;
  height: 10rem;
  background-position: -227rem -59rem;
  opacity: 0.5;
}

.summary-card-list {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 40rem;
}
.summary-card-list[data-columns="5"] {
  grid-template-columns: repeat(5, auto);
}
.summary-card-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 256rem;
  padding: 24rem 40rem 25rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.summary-card-item.complete {
  background-color: #f2f7fb;
}
.summary-card-item.complete .summary-card-info em {
  color: #1472ce;
}
.summary-card-info {
  font-size: 20rem;
  line-height: 1.6;
  font-weight: 500;
  color: #888;
}
.summary-card-info em {
  display: inline-block;
  margin-right: 4rem;
  font-size: 32rem;
  line-height: 1.3;
  color: #000;
}

.bo-info {
  padding: 24rem 40rem 14rem 40rem;
  border-radius: 20rem;
  background-color: #f2f7fb;
  border: 1px solid #cecece;
}
.bo-info-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.bo-info-item {
  position: relative;
  margin-bottom: 10rem;
  padding-left: 20rem;
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
}
.bo-info-item:before {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: "·";
  display: block;
  font-size: 30rem;
  line-height: 1;
}
.bo-info-item:after {
  content: "";
  display: inline-block;
  width: 1px;
  height: 20rem;
  margin: 0 40rem;
  background-color: #b8b8b8;
  vertical-align: text-bottom;
}
.bo-info-item:last-child:after {
  display: none;
}
.bo-info-item.border-none:after {
  display: none;
}
.bo-info-tit {
  display: inline-block;
  margin-right: 20rem;
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
}

.text-book {
  display: flex;
  padding: 30rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.text-book-img {
  flex: 0 0 160rem;
}
.text-book-img img {
  border: 1px solid #cecece;
  border-radius: 10rem;
}
.text-book-content {
  flex: 1;
  margin-left: 40rem;
}
.text-book-content > div {
  margin-top: 10rem;
  padding-bottom: 10rem;
}
.text-book-content > div + div {
  margin-top: 20rem;
}
.text-book-content > div > strong {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  display: block;
}
.text-book-content > div .sign-info {
  margin-top: 10rem;
}
.text-book-content > div .sign-line, .text-book-content > div .sign-line--inline {
  margin-top: 10rem;
}

.level-test-schedule {
  padding: 30rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.level-test-schedule h3 {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  padding-bottom: 20rem;
}
.level-test-schedule-content {
  display: flex;
}
.level-test-schedule-content .table, .level-test-schedule-content .table--border-grey, .level-test-schedule-content .table--lv2, .level-test-schedule-content .table--lv2-high, .level-test-schedule-content .table--lv3 {
  flex: 1;
}
.level-test-schedule-notice {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 430rem;
  margin-left: 10rem;
  padding: 30rem 40rem 40rem 30rem;
  border-left: 1px solid #cecece;
  background-color: #f4f4f4;
}
.level-test-schedule-notice [role=button] {
  align-self: flex-end;
}

.round-square-box {
  padding: 30rem 40rem;
  background-color: #f2f7fb;
  border-radius: 20rem;
}
.round-square-box p {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
}
.round-square-box p em {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
}
.round-square-box + .form-selector {
  margin-top: 20rem;
}

.tb-ct-pg {
  display: flex;
  padding: 30rem 40rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.tb-ct-pg-container-tit {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  display: block;
  padding-bottom: 20rem;
}
.tb-ct-pg-container + .tb-ct-pg-container {
  margin-top: 40rem;
}
.tb-ct-pg-wrapper {
  display: grid;
  row-gap: 20rem;
}
.tb-ct-pg-status, .tb-ct-pg-status--active, .tb-ct-pg-status--inactive {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60rem;
  height: 60rem;
  background-color: #b8b8b8;
  border-radius: 10rem;
  color: #fff;
}
.tb-ct-pg-status--inactive {
  background-color: #a8b1c2;
}
.tb-ct-pg-status--active {
  background-color: #5085ec;
}
.tb-ct-pg > div.tb-ct-pg-status-wrapper {
  align-self: center;
  flex: 0 0 60rem;
}
.tb-ct-pg > div.tb-ct-pg-content-wrapper {
  flex: 1;
  margin-left: 20rem;
}
.tb-ct-pg > div.tb-ct-pg-content-wrapper > div + div {
  margin-top: 10rem;
}
.tb-ct-pg > div.tb-ct-pg-buttons-wrapper {
  flex: 0 0 auto;
  align-self: center;
  margin-left: 20rem;
}
.tb-ct-pg > div.tb-ct-pg-buttons-wrapper .tb-ct-pg-status-text {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
}
.tb-ct-pg-tit {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  max-height: 32rem;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
}
.tb-ct-pg .edu-card-progress {
  display: flex;
  align-items: center;
}
.tb-ct-pg .edu-card-period-main {
  font-weight: 400;
}

.l-w-status {
  display: none;
}
.l-w-status:checked ~ .l-w-container .l-w .l-w-left {
  margin-left: 0;
}
.l-w-status:checked ~ .l-w-container .l-w .l-w-left iframe {
  width: 100%;
}
.l-w-status:checked ~ .l-w-container .l-w .category-menu-open {
  display: none;
}
.l-w-status:checked ~ .l-w-container .l-w .category-menu-close {
  display: block;
}

.l-w {
  display: flex;
  height: 100%;
}
.l-w-container {
  overflow: hidden;
  height: 100vh;
  min-height: 500rem;
}
.l-w-left {
  flex: 0 0 320rem;
  position: relative;
  margin-left: -280rem;
  background-color: #f2f7fb;
  transition: margin-left 0.55s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.l-w-right {
  flex: 1;
  position: relative;
  background-color: #00061f;
}
.l-w-right .learning-play-button {
  position: absolute;
  top: calc(50% - 40rem);
  left: calc(50% - 40rem);
}

.category {
  padding: 20rem;
  border: 1px solid #cecece;
  background-color: #fff;
  border-radius: 20rem;
}
.category.is-active ul {
  display: flex;
}
.category.is-active .category-button {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
}
.category.is-active .category-button:before {
  background-position: -265rem -126rem;
}
.category-area {
  overflow-y: auto;
  position: relative;
  padding: 20rem 40rem 20rem 20rem;
}
.category-container {
  display: flex;
  flex-direction: column;
  gap: 10rem;
}
.category-button {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  display: inline-flex;
  position: relative;
  padding-left: 34rem;
  line-height: 26rem;
}
.category-button:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 24rem;
  height: 24rem;
  background-position: -265rem -101rem;
}
.category-menu-container {
  position: absolute;
  top: 37rem;
  right: -17rem;
  transform: rotate(-90deg);
}
.category-menu-container label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.category-menu-open, .category-menu-close {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 400;
  display: none;
  padding: 5rem 10rem 6rem;
  background-color: #1472ce;
  border-radius: 10rem 10rem 0 0;
  color: #fff;
}
.category-menu-open {
  display: block;
}
.category ul {
  display: none;
  flex-direction: column;
  gap: 10rem;
  margin-top: 10rem;
}
.category ul:after {
  content: "";
  display: block;
  clear: both;
}
.category ul a {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  position: relative;
  float: left;
  padding-left: 25rem;
}
.category ul a:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 20rem;
  height: 20rem;
  background-position: -244rem -101rem;
}
.category ul .is-active a {
  color: #1472ce;
}
.category ul .is-active a:before {
  background-position: -244rem -122rem;
}

.recipient {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10rem;
}
.recipient > li {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  position: relative;
  height: 40rem;
  margin-right: 10rem;
  margin-bottom: 10rem;
  padding: 9rem 20rem;
  border: 1px solid #b8b8b8;
  border-radius: 24rem;
  color: #666;
}
.recipient-user {
  display: flex;
}
.recipient-delete-button {
  position: relative;
  width: 14rem;
  height: 14rem;
  margin-left: 8rem;
}
.recipient-delete-button > span {
  position: relative;
  display: inline-block;
  width: 14rem;
  height: 14rem;
  background-color: transparent;
  border: none;
}
.recipient-delete-button > span:before, .recipient-delete-button > span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 17.799rem;
  height: 2rem;
  background-color: #d9d9d9;
}
.recipient-delete-button > span:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.recipient-delete-button > span:after {
  transform: translate(-50%, -50%) rotate(135deg);
}
.recipient-delete-button > span > span {
  overflow: hidden;
  display: block;
  text-indent: 100%;
  white-space: nowrap;
}

.slide-ctrl-container {
  display: flex;
  align-items: center;
}
.slide-ctrl-pagination {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  margin: 0 10rem;
}
.slide-ctrl-button-prev, .slide-ctrl-button-next {
  width: 16rem;
  height: 16rem;
}
.slide-ctrl-button-prev i[class^=icon], .slide-ctrl-button-next i[class^=icon] {
  vertical-align: top;
}
.slide-ctrl-button-prev.swiper-button-disabled, .slide-ctrl-button-next.swiper-button-disabled {
  filter: grayscale(100%) opacity(50%);
}
.slide-ctrl-button-prev i[class^=icon] {
  transform: rotate(180deg);
}

.faq-swiper {
  overflow: visible;
}
.faq-swiper-container {
  overflow: hidden;
  position: relative;
  padding: 0 55rem;
}
.faq-swiper-container:before, .faq-swiper-container:after {
  content: "";
  position: absolute;
  z-index: 10;
  top: 0;
  width: 70rem;
  height: 40rem;
  background: linear-gradient(-270deg, #fff 55%, rgba(255, 255, 255, 0) 91.2%), transparent;
  pointer-events: none;
}
.faq-swiper-container:before {
  left: 0;
}
.faq-swiper-container:after {
  right: 0;
  background: linear-gradient(270deg, #fff 50%, rgba(255, 255, 255, 0) 91.2%), transparent;
}
.faq-swiper-button-prev, .faq-swiper-button-next {
  position: absolute;
  top: 6rem;
  z-index: 50;
  width: 15rem;
  height: 27rem;
}
.faq-swiper-button-prev.swiper-button-disabled, .faq-swiper-button-next.swiper-button-disabled {
  filter: grayscale(100%) opacity(50%);
}
.faq-swiper-button-prev {
  position: absolute;
  display: inline-block;
  width: 15rem;
  height: 27rem;
  left: 0;
}
.faq-swiper-button-prev:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15rem;
  height: 15rem;
  transform: translate(-50%, -50%) scale(1, 1.1111111111) rotate(45deg);
  margin-left: 6rem;
  border-left: 2rem solid #222;
  border-bottom: 2rem solid #222;
}
.faq-swiper-button-next {
  position: absolute;
  display: inline-block;
  width: 15rem;
  height: 27rem;
  right: 0;
}
.faq-swiper-button-next:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15rem;
  height: 15rem;
  transform: translate(-50%, -50%) scale(1, 1.1111111111) rotate(45deg);
  margin-left: -6rem;
  border-top: 2rem solid #222;
  border-right: 2rem solid #222;
}
.faq-swiper .swiper-slide {
  width: auto;
  padding: 0 5rem;
}
.faq-swiper [aria-selected=true],
.faq-swiper [role=tab]:hover {
  background-color: #5085ec;
  border-color: #5085ec;
  color: #fff;
}

.form-time em {
  display: inline-block;
  margin: 0 15rem;
}

.help > div + div {
  margin-top: 20rem;
}
.help > div > p {
  margin-top: 8rem;
}
.help .round-square-box > ul {
  margin-top: 8rem;
}

.process-step {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.process-item {
  flex: 1;
}
.process-item.is-checked .process-bar:before {
  background-image: none;
  background-color: #5085ec;
  border-radius: 50%;
}
.process-item.is-checked .process-bar:after {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 9rem;
  left: 7rem;
  z-index: 1;
  width: 16rem;
  height: 12rem;
  background-position: -296rem -59rem;
}
.process-item.is-checked .bar {
  background-color: #5085ec;
}
.process-bar {
  position: relative;
  width: 100%;
  height: 30rem;
}
.process-bar:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 30rem;
  height: 30rem;
  background-position: 0 -226rem;
  border-radius: 0;
}
.process-bar .bar {
  position: relative;
  top: calc(50% - 2rem);
  width: 100%;
  height: 4rem;
  background-color: #5085ec;
  color: transparent;
  font-size: 0;
}
.process-info {
  margin-top: 10rem;
}
.process-info .date {
  color: #666;
  font-size: 14rem;
  line-height: 1.6;
}

.search-filter {
  display: flex;
  justify-content: space-between;
}
.search-filter-fixed-container {
  position: relative;
  margin-top: 10rem;
  height: 158rem;
}
.search-filter-fixed-container.is-fixed .search-filter-fixed-content {
  position: fixed;
  top: 0;
  z-index: 300;
  width: 1440rem;
}
.search-filter-fixed-content {
  padding: 30rem 0;
  background-color: #fff;
}
.search-filter > div {
  padding-right: 0;
}
.search-filter > div + div {
  margin-top: 0;
  margin-left: 0;
}
.search-filter > div > strong {
  margin-right: 20rem;
}

.result-filter {
  display: flex;
}
.result-filter > li + li {
  position: relative;
  margin-left: 16rem;
  padding-left: 17rem;
}
.result-filter > li + li:before {
  content: "";
  position: absolute;
  top: 7rem;
  left: 0;
  width: 1px;
  height: 16rem;
  background-color: #ddd;
}
.result-filter > li [role=button] {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  color: #888;
}
.result-filter > li [role=button].is-active {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  color: #222;
}

.search-result-type {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  padding: 11rem 40rem;
  background-color: #042b69;
  border-radius: 24rem;
  color: #fff;
}
.search-result-container + .search-result-container {
  margin-top: 60rem;
}
.search-result-container .view-type01--lv2 .card--lv2 .card-util {
  top: 0;
}
.search-result-wrapper {
  display: grid;
  border-top: 2rem solid #222;
}
.search-result-item {
  padding: 40rem 0;
  border-bottom: 1px solid #cecece;
}
.search-result-item-no-data {
  padding-top: 70rem;
  text-align: center;
}
.search-result-item-no-data .desc {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-top: 20rem;
}

.search-rcmd {
  margin-top: 60rem;
  padding: 0 60rem;
}
.search-rcmd > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: block;
  text-align: center;
}
.search-rcmd ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12rem;
  margin-top: 20rem;
}
.search-rcmd ul > li [role=button] {
  height: auto;
  align-items: flex-start;
  background-color: #f4f4f4;
  color: #000;
}
.search-rcmd ul > li [role=button].is-active {
  background-color: #1472ce;
  color: #fff;
}
.search-rcmd ul > li [role=button]:hover {
  background-color: #1472ce;
  color: #fff;
}
.search-rcmd ul > li [role=button]:before {
  content: "#";
}

.terms-content > h2 {
  margin-bottom: 40rem;
  padding-bottom: 20rem;
  border-bottom: 2rem solid #222;
}

.terms-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10rem;
  margin-top: 20rem;
  padding: 40rem 62rem;
  background-color: #f2f7fb;
  border-radius: 20rem;
}
.terms-list [role=button] {
  width: 100%;
  height: auto;
  min-height: 44rem;
  padding: 9rem;
  border-radius: 4rem;
}
.terms-list [role=button].is-active, .terms-list [role=button]:hover {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  background-color: #1472ce;
  border-color: #1472ce;
  color: #fff;
}

.section-content {
  padding-top: 20rem;
  padding-bottom: 20rem;
}
.section-content > h3 {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
}
.section-content > div {
  margin-left: 20rem;
}
.section-content > div > p {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  margin-top: 10rem;
  color: #666;
}
.section-content > div > p em {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  color: #000;
}
.section-content > div .info-msg, .section-content > div .form-file-desc, .section-content > div .info-msg--md {
  color: #000;
}

.terms-t01 {
  margin-top: 20rem;
}
.terms-t01 > li {
  padding-left: 25rem;
}
.terms-t01 > li + li {
  margin-top: 20rem;
}
.terms-t01 > li > div {
  margin-left: 10rem;
}
.terms-t01 > li > div .info-msg, .terms-t01 > li > div .form-file-desc, .terms-t01 > li > div .info-msg--md {
  padding-left: 14rem;
  color: #666;
}
.terms-t01 > li > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: inline-block;
  text-indent: -25rem;
}
.terms-t01 > li p {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  margin-top: 10rem;
  color: #666;
}
.terms-t01 > li .info-msg span, .terms-t01 > li .form-file-desc span, .terms-t01 > li .info-msg--md span {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  color: #666;
}

.terms-service > div > div {
  margin-left: 20rem;
}
.terms-service > div + div {
  margin-top: 40rem;
}
.terms-service h2 {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  padding-bottom: 10rem;
}
.terms-service h2 + p {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  color: #666;
}

.terms-sign {
  margin-top: 40rem;
  border-top: 1px solid #cecece;
}
.terms-sign > div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 40rem;
}
.terms-sign > div > span {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
}
.terms-sign > div > span + span {
  margin-top: 10rem;
}

.report-notice > p {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  margin-bottom: 20rem;
}
.report-notice > div + div {
  margin-top: 40rem;
}
.report-notice > div > div {
  padding: 30rem 40rem 40rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.report-notice > div > div > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: block;
  padding-bottom: 15rem;
  margin-bottom: 15rem;
  border-bottom: 1px solid #ddd;
}
.report-notice > div > div > div {
  min-height: 78rem;
}
.report-notice > div .form-selector {
  margin-top: 20rem;
}
.report-notice-desc {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  margin-top: 15rem;
}

.category-area {
  overflow-y: auto;
  position: relative;
  height: 100%;
  padding: 20rem 40rem 20rem 20rem;
}
.category-area .dynatree-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10rem;
  height: auto;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-family: inherit;
  white-space: inherit;
}
.category-area .dynatree-container > li {
  padding: 20rem;
  border: 1px solid #cecece;
  background-color: #fff;
  border-radius: 20rem;
}
.category-area .dynatree-container > li > .tree_con > li {
  padding: 0;
}
.category-area .dynatree-container > li > .tree_con > li > .dynatree-node a {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  position: relative;
  padding-top: 2rem;
  padding-left: 34rem;
}
.category-area .dynatree-container > li > .tree_con > li > .dynatree-node a:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 24rem;
  height: 24rem;
  background-position: -265rem -101rem;
}
.category-area .dynatree-container > li > .tree_con > li > .dynatree-node.dynatree-expanded a {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  padding-top: 0;
}
.category-area .dynatree-container > li > .tree_con > li > .dynatree-node.dynatree-expanded a:before {
  background-position: -265rem -126rem;
}
.category-area span.dynatree-icon {
  display: none;
}
.category-area span.dynatree-expander {
  display: none;
}
.category-area span.dynatree-connector {
  display: none;
}
.category-area span.dynatree-node a {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
.category-area span.dynatree-node a:focus {
  background-color: transparent;
}
.category-area span.dynatree-selected a {
  text-decoration: underline;
  font-style: normal;
  color: #000;
}
.category-area span.dynatree-active a {
  border: 0;
  background-color: transparent;
}
.category-area ul.dynatree-container ul {
  padding: 0;
}
.category-area ul.dynatree-container a {
  border: 0;
}
.category-area ul.dynatree-container a:hover {
  background-color: transparent;
}
.category-area ul.dynatree-container img {
  display: none;
  position: absolute;
  top: 2rem;
  left: 0;
  width: 20rem;
  height: 20rem;
  margin: 0;
}
.category-area ul.tree_con ul.tree_con {
  margin-top: 16rem;
}
.category-area ul.tree_con ul.tree_con > li img {
  display: block;
}
.category-area ul.tree_con ul.tree_con > li + li {
  margin-top: 8rem;
}
.category-area ul.tree_con ul.tree_con span.dynatree-node {
  position: relative;
  padding-left: 25rem;
}

.online-guide {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30rem;
}
.online-guide-item {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding: 30rem 20rem 20rem;
  border: 1px solid #cecece;
  box-shadow: 0px 11rem 10rem rgba(0, 0, 0, 0.1);
  border-radius: 20rem;
}
.online-guide p {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  margin-top: 40rem;
  text-align: right;
}
.online-guide [role=button] {
  margin-top: 20rem;
}
.online-guide .ci-area {
  width: 100%;
  margin-top: 40rem;
  padding: 34rem 20rem 20rem;
  background-color: #e6f4f8;
  border-radius: 10rem;
  text-align: right;
}

.get-ratio {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: inherit;
  width: 100%;
  transform: translate(-50%, -50%);
}

.h-img {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}

.v-img {
  position: absolute;
  top: 50%;
  left: 0;
  max-width: inherit;
  width: 100%;
  transform: translateY(-50%);
}

.radius-box, .radius-box--4040, .radius-box--3030, .radius-box--2040, .radius-box--2030, .radius-box--2020 {
  width: 100%;
  border: 1px solid #cecece;
  border-radius: 20rem;
  background-color: #fff;
}
.radius-box--2020 {
  padding: 20rem;
}
.radius-box--2030 {
  padding: 20rem 30rem;
}
.radius-box--2040 {
  padding: 20rem 40rem;
}
.radius-box--3030 {
  padding: 30rem;
}
.radius-box--4040 {
  padding: 40rem;
}

.toggle-container {
  display: inline-flex;
  position: relative;
  padding: 2rem;
  background-color: #1472ce;
  border-radius: 24rem;
}
.toggle-container .toggle-content {
  display: flex;
  align-items: center;
  color: #fff;
}
.toggle-container .toggle-content > span {
  position: relative;
  height: 44rem;
  padding: 0 26rem;
}
.toggle-container .toggle-content > span > span {
  position: relative;
  z-index: 1;
}
.toggle-container .toggle-content > span:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 22rem;
}
.toggle-container .toggle-content > span + span {
  margin-left: 2rem;
}
.toggle-container .toggle-content > span:nth-child(1) {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  line-height: 43rem;
  color: #fff;
}
.toggle-container .toggle-content > span:nth-child(2) {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  line-height: 43rem;
  color: #1472ce;
}
.toggle-container .toggle-content > span:nth-child(2):before {
  background-color: #fff;
}
.toggle-container input[type=checkbox] {
  display: none;
}
.toggle-container input[type=checkbox] + label {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
}
.toggle-container input[type=checkbox]:checked ~ .toggle-content > span:nth-child(1) {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  line-height: 43rem;
  color: #1472ce;
}
.toggle-container input[type=checkbox]:checked ~ .toggle-content > span:nth-child(1):before {
  background-color: #fff;
}
.toggle-container input[type=checkbox]:checked ~ .toggle-content > span:nth-child(2) {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  line-height: 43rem;
  color: #fff;
}
.toggle-container input[type=checkbox]:checked ~ .toggle-content > span:nth-child(2):before {
  background-color: transparent;
}

.terms-content > strong {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
}
.terms-content > strong + p {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
}
.terms-content > strong + p em {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
}

.terms-section > strong {
  display: block;
  font-weight: 500;
  margin-bottom: 10rem;
}
.terms-section p + p {
  margin-top: 10rem;
}
.terms-section p > em {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
}
.terms-section + .terms-section {
  margin-top: 20rem;
}

.grid-column, .grid-column__col3 {
  display: grid;
}
.grid-column__col3 {
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 10rem;
}

.round-box {
  padding: 24rem 40rem;
  border-radius: 20rem;
}
.round-box em {
  font-weight: 500;
}

.stop-watching {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 60rem;
  border-top: 1px solid #cecece;
}
.stop-watching:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 1px;
  background-color: #cecece;
}
.stop-watching > [role=button] {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  flex: 1;
  height: 60rem;
}

.dynatree-status-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10rem;
  padding: 20rem;
  border: 1px solid #cecece;
  background-color: #fff;
  border-radius: 20rem;
}
.dynatree-status-info > div {
  display: flex;
  align-items: center;
}
.dynatree-status-info > div > span {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 400;
  margin-left: 5rem;
}

.edu-application-form > div > strong {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  display: block;
  margin-bottom: 20rem;
}
.edu-application-form > div > div {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  color: #222;
}
.edu-application-form > div + div {
  margin-top: 40rem;
}
.edu-application-form .form-footer {
  margin-top: 30rem;
  padding-top: 30rem;
  border-top: 1px solid #cecece;
}
.edu-application-form .date {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
  margin-top: 30rem;
  text-align: center;
}
.edu-application-form .sign {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  margin-top: 10rem;
  text-align: right;
}

.ckeditor-content table {
  border-collapse: collapse;
}
.ckeditor-content td {
  border: 1px solid #b0b0b0;
  padding: 5rem 10rem;
}

.notice-view__content table {
  border-collapse: collapse;
}
.notice-view__content td {
  border: 1px solid #b0b0b0;
  padding: 5rem 10rem;
}

.ui-widget.ui-widget-content {
  width: 322rem;
  padding: 0 20rem;
  border: 1px solid #cecece;
  box-shadow: 0px 10rem 10rem rgba(0, 0, 0, 0.1);
  border-radius: 20rem;
}
.ui-widget.ui-widget-content:after {
  content: "";
  position: absolute;
  bottom: 28rem;
  left: 20rem;
  width: 84rem;
  height: 19rem;
  background: url("/images/ui22//common/datepicker-info.png") 0 0/84rem no-repeat;
}

.ui-datepicker .ui-datepicker-header {
  margin: 0 -20rem;
  padding: 20rem;
  background-color: #f2f7fb;
  border: 0;
  border-radius: 20rem 20rem 0 0;
}
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
  top: calc(50% - 8rem);
  width: 16rem;
  height: 16rem;
}
.ui-datepicker .ui-datepicker-title {
  display: flex;
  gap: 10rem;
  justify-content: center;
}
.ui-datepicker .ui-datepicker-next {
  right: 20rem;
}
.ui-datepicker .ui-datepicker-next-hover {
  border: 0;
  background-color: transparent;
}
.ui-datepicker .ui-datepicker-next span {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  background-position: -48rem -143rem;
}
.ui-datepicker .ui-datepicker-prev {
  left: 20rem;
}
.ui-datepicker .ui-datepicker-prev-hover {
  border: 0;
  background-color: transparent;
}
.ui-datepicker .ui-datepicker-prev span {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  background-position: -48rem -143rem;
  transform: rotate(180deg);
}
.ui-datepicker select.ui-datepicker-year,
.ui-datepicker select.ui-datepicker-month {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  position: relative;
  width: 100rem;
  height: 44rem;
  margin: 0;
  padding: 0 20rem;
  border: 1px solid #cecece;
  border-radius: 30rem;
  background: #fff url("/images/ui22//common/arrow-down-bk-16.png") right 20rem top 50%/16rem no-repeat;
}
.ui-datepicker .ui-datepicker-calendar {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 400;
  margin: 0 0 12rem;
}
.ui-datepicker .ui-state-default {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 400;
  border: 0;
  background-color: transparent;
  color: #222;
  line-height: 30rem;
}
.ui-datepicker .ui-state-highlight {
  background-color: #ddd;
  border-radius: 50%;
}
.ui-datepicker .ui-state-active {
  background-color: #1472ce;
  border-radius: 50%;
  color: #fff;
}
.ui-datepicker th {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 400;
  padding: 20rem 0 6rem;
  color: #000;
}
.ui-datepicker td {
  padding: 6rem 0;
}
.ui-datepicker td a {
  width: 30rem;
  height: 30rem;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  border: 0;
  text-align: center;
  color: #222;
}
.ui-datepicker .ui-datepicker-buttonpane {
  display: flex;
  flex-direction: row-reverse;
  margin: 0;
  padding: 20rem 0;
  border-color: #cecece;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  float: none;
  height: 32rem;
  margin: 0 0 0 8rem;
  padding: 3rem 20rem;
  background-color: #042b69;
  border-radius: 24rem;
  color: #fff;
  opacity: 1;
}
.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-close {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  float: none;
  height: 32rem;
  margin: 0;
  padding: 3rem 20rem;
  background-color: #b8b8b8;
  border-radius: 24rem;
  color: #fff;
  opacity: 1;
}

.error {
  width: 100%;
  min-width: 700rem;
  height: 100%;
  text-align: center;
  padding-top: 120rem;
}
.error h2 {
  font-size: 40rem;
  line-height: 52rem;
  font-weight: 700;
  font-weight: 700;
}
.error h2 [class^=icon] {
  display: block;
  margin: 0 auto 35rem;
}
.error h2 + p {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
  margin-top: 16rem;
}
.error h2 + p + p {
  margin-top: 40rem;
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
}
.error-buttons {
  display: flex;
  justify-content: center;
  margin-top: 60rem;
}
.error-buttons [role=button] + [role=button] {
  margin-left: 20rem;
}
.error .table, .error .table--border-grey, .error .table--lv2, .error .table--lv2-high, .error .table--lv3 {
  width: 500rem;
  margin: 60rem auto 0;
}
.error .table th, .error .table--border-grey th, .error .table--lv2 th, .error .table--lv2-high th, .error .table--lv3 th {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  padding: 12rem 20rem;
}
.error .table td, .error .table--border-grey td, .error .table--lv2 td, .error .table--lv2-high td, .error .table--lv3 td {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  padding: 12rem 20rem;
}

.form-text {
  display: inline-block;
  position: relative;
}
.form-text-content {
  display: inline-block;
  width: 100%;
}
.form-text input[type=text],
.form-text input[type=password] {
  min-width: 100rem;
  height: 48rem;
  padding-top: 11rem;
  padding-right: 20rem;
  padding-bottom: 11rem;
  padding-left: 20rem;
  border: 1px solid #d0d0d0;
  border-radius: 4rem;
  font-size: 18rem;
  line-height: 26rem;
}

.form-textarea {
  display: inline-block;
  position: relative;
  width: 100%;
}
.form-textarea-content {
  display: inline-flex;
  width: 100%;
}
.form-textarea-content > span {
  flex: 1;
  display: block;
}
.form-textarea-label {
  display: inline-flex;
  width: 100%;
  padding-top: 19rem;
  padding-right: 9rem;
  padding-bottom: 19rem;
  padding-left: 29rem;
  border: 1px solid #cecece;
  border-radius: 24rem;
}
.form-textarea-label textarea {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  width: 100%;
  height: 120rem;
  padding-right: 10rem;
  border: 0;
  resize: none;
}
.form-textarea-apply-button {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  flex: 0 0 230rem;
  display: inline-block;
  width: 230rem;
  height: 160rem;
  margin-left: 10rem;
  background-color: #666;
  border-radius: 20rem;
  color: #fff;
}
.form-textarea-length {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  margin-top: 10rem;
  float: right;
  color: #888;
}
.form-textarea-length em {
  color: #222;
  font-weight: 400;
}
.form-textarea-util {
  display: flex;
  justify-content: flex-end;
  margin-top: 20rem;
}
.form-textarea-util [role=button] + [role=button] {
  margin-left: 10rem;
}
.form-textarea.is-disabled .form-textarea-label {
  background: #f4f4f4;
}

.form-selector {
  display: inline-flex;
  position: relative;
  vertical-align: middle;
}
.form-selector input[type=radio] {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
  border: 0;
}
.form-selector input[type=radio] + label {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: inline-block;
  position: relative;
  min-height: 20rem;
  padding-left: 30rem;
}
.form-selector input[type=radio] + label:before {
  content: "";
  position: absolute;
  top: 3rem;
  left: 0;
  width: 20rem;
  height: 20rem;
  border: 1px solid #888;
  background-color: #fff;
  border-radius: 50%;
}
.form-selector input[type=radio][readonly] + label:before {
  border-color: #ccc;
  background-color: #f6f6f6;
  filter: grayscale(100%) opacity(40%);
}
.form-selector input[type=radio]:disabled + label:before {
  border-color: #ccc;
  background-color: #f6f6f6;
  filter: grayscale(100%) opacity(40%);
  pointer-events: none;
}
.form-selector input[type=radio]:checked + label:before {
  border: 6rem solid #1472ce;
  border-radius: 50%;
}
.form-selector input[type=checkbox] {
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0px 0px 99.9% 99.9%);
  border: 0;
}
.form-selector input[type=checkbox] + label {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: inline-block;
  position: relative;
  min-height: 20rem;
  padding-left: 30rem;
}
.form-selector input[type=checkbox] + label:before {
  content: "";
  position: absolute;
  top: 3rem;
  left: 0;
  width: 20rem;
  height: 20rem;
  border: 1px solid #888;
  border-radius: 4rem;
  background-color: #fff;
}
.form-selector input[type=checkbox] + label:after {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 8rem;
  left: 4rem;
  width: 11rem;
  height: 9rem;
  background-position: -42rem 0;
  opacity: 0;
}
.form-selector input[type=checkbox]:disabled + label:before {
  border-color: #b8b8b8;
  background-color: #f4f4f4;
}
.form-selector input[type=checkbox]:checked + label:before {
  border: 0;
  background-color: #1472ce;
}
.form-selector input[type=checkbox]:checked + label:after {
  opacity: 1;
}
.form-selector input[type=checkbox]:checked:disabled + label:before {
  filter: grayscale(100%) opacity(40%);
}
.form-selector.text-empty input[type=radio] + label {
  width: 20rem;
  height: 20rem;
  padding-left: 0;
  vertical-align: middle;
}
.form-selector.text-empty input[type=radio] + label::before, .form-selector.text-empty input[type=radio] + label::after {
  top: 0;
}
.form-selector.text-empty input[type=checkbox] + label {
  width: 20rem;
  height: 20rem;
  padding-left: 0;
  vertical-align: middle;
}
.form-selector.text-empty input[type=checkbox] + label::before {
  top: 0;
}
.form-selector.text-empty input[type=checkbox] + label::after {
  top: 5rem;
}

.form-selector__list .form-selector + .form-selector {
  margin-left: 40rem;
}
.form-selector__list--ttb .form-selector {
  display: block;
}

.form-input, .form-input--icon-button, .form-input--datepicker, .form-input--img {
  display: inline-block;
}
.form-input .form-input-content, .form-input--icon-button .form-input-content, .form-input--datepicker .form-input-content, .form-input--img .form-input-content {
  display: flex;
  position: relative;
}
.form-input input[type=text], .form-input--icon-button input[type=text], .form-input--datepicker input[type=text], .form-input--img input[type=text],
.form-input input[type=password],
.form-input--icon-button input[type=password],
.form-input--datepicker input[type=password],
.form-input--img input[type=password] {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  min-width: 100rem;
  height: 48rem;
  padding: 12rem 29rem;
  border: 1px solid #cecece;
  border-radius: 30rem;
}
.form-input input[type=text]:read-only, .form-input--icon-button input[type=text]:read-only, .form-input--datepicker input[type=text]:read-only, .form-input--img input[type=text]:read-only,
.form-input input[type=password]:read-only,
.form-input--icon-button input[type=password]:read-only,
.form-input--datepicker input[type=password]:read-only,
.form-input--img input[type=password]:read-only {
  background-color: #f4f4f4;
  color: #666;
}
.form-input input[type=text]:disabled, .form-input--icon-button input[type=text]:disabled, .form-input--datepicker input[type=text]:disabled, .form-input--img input[type=text]:disabled,
.form-input input[type=password]:disabled,
.form-input--icon-button input[type=password]:disabled,
.form-input--datepicker input[type=password]:disabled,
.form-input--img input[type=password]:disabled {
  background-color: #f4f4f4;
  color: #666;
}
.form-input[data-status=error] input[type=text], [data-status=error].form-input--icon-button input[type=text], [data-status=error].form-input--datepicker input[type=text], [data-status=error].form-input--img input[type=text],
.form-input[data-status=error] input[type=password],
[data-status=error].form-input--icon-button input[type=password],
[data-status=error].form-input--datepicker input[type=password],
[data-status=error].form-input--img input[type=password] {
  border-color: #f65959;
}
.form-input[data-status=error] .form-input-msg, [data-status=error].form-input--icon-button .form-input-msg, [data-status=error].form-input--datepicker .form-input-msg, [data-status=error].form-input--img .form-input-msg {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  display: flex;
  margin-top: 4rem;
  padding: 0 30rem;
  color: #f65959;
}
.form-input[data-status=success] input[type=text], [data-status=success].form-input--icon-button input[type=text], [data-status=success].form-input--datepicker input[type=text], [data-status=success].form-input--img input[type=text],
.form-input[data-status=success] input[type=password],
[data-status=success].form-input--icon-button input[type=password],
[data-status=success].form-input--datepicker input[type=password],
[data-status=success].form-input--img input[type=password] {
  border-color: #1472ce;
}
.form-input[data-status=success] .form-input-msg, [data-status=success].form-input--icon-button .form-input-msg, [data-status=success].form-input--datepicker .form-input-msg, [data-status=success].form-input--img .form-input-msg {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  display: flex;
  margin-top: 4rem;
  padding: 0 30rem;
  color: #1472ce;
}
.form-input--img input[type=text],
.form-input--img input[type=password] {
  padding-left: 64rem;
}
.form-input--img [class^=icon] {
  position: absolute;
  top: 50%;
  left: 30rem;
  transform: translateY(-50%);
  pointer-events: none;
}
.form-input--img.input-user .form-input-content:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 11rem;
  left: 30rem;
  width: 24rem;
  height: 24rem;
  background-position: 0 -34rem;
  pointer-events: none;
}
.form-input--img.input-password .form-input-content:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 11rem;
  left: 30rem;
  width: 24rem;
  height: 24rem;
  background-position: -25rem -34rem;
  pointer-events: none;
}
.form-input--datepicker {
  width: 200rem;
}
.form-input--datepicker .form-input-content:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: 13rem;
  right: 31rem;
  width: 20rem;
  height: 20rem;
  pointer-events: none;
}
.form-input--datepicker input[type=text],
.form-input--datepicker input[type=password] {
  padding-right: 64rem;
}
.form-input--datepicker input[type=text]:read-only,
.form-input--datepicker input[type=password]:read-only {
  background-color: #fff;
}
.form-input--datepicker input[type=text]:disabled,
.form-input--datepicker input[type=password]:disabled {
  background-color: #f4f4f4;
}
.form-input--datepicker [class^=icon] {
  position: absolute;
  top: 50%;
  left: 30rem;
  transform: translateY(-50%);
}
.form-input--datepicker.is-disabled .form-input-content:before {
  filter: grayscale(100%) opacity(40%);
}
.form-input--icon-button {
  position: relative;
}
.form-input--icon-button input[type=text],
.form-input--icon-button input[type=password] {
  padding-right: 94rem;
  padding-left: 30rem;
}
.form-input--icon-button .search-button {
  position: absolute;
  top: 50%;
  right: 30rem;
  transform: translateY(-50%);
}
.form-input--icon-button .delete-button {
  position: absolute;
  top: 50%;
  right: 66rem;
  width: 20rem;
  height: 20rem;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: #ddd;
  opacity: 0;
}
.form-input--icon-button .delete-button .button-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-input--icon-button.is-written .delete-button {
  opacity: 1;
  transition: opacity 0.22s cubic-bezier(0.04, 0.04, 0.12, 0.96);
}
.form-input--icon-button.is-disabled .search-button {
  pointer-events: none;
}
.form-input--icon-button.is-disabled .search-button [class^=icon] {
  filter: grayscale(100%) opacity(40%);
}
.form-input--icon-button.is-disabled .delete-button {
  display: none;
}
.form-input--double {
  display: inline-flex;
  align-items: center;
}
.form-input--double > em {
  margin: 0 10rem;
}

.form-file {
  display: inline-flex;
  position: relative;
}
.form-file .form-input, .form-file .form-input--img, .form-file .form-input--datepicker, .form-file .form-input--icon-button {
  display: flex;
}
.form-file .form-input input[type=text], .form-file .form-input--img input[type=text], .form-file .form-input--datepicker input[type=text], .form-file .form-input--icon-button input[type=text] {
  flex: 1;
  padding-right: 64rem;
  background-color: #fff;
}
.form-file-desc {
  margin-top: 10rem;
}
.form-file-notice > li {
  margin-top: 6rem;
}
.form-file .browse-files-button {
  margin-left: 10rem;
  cursor: pointer;
}
.form-file .form-upload {
  display: flex;
}
.form-file .form-upload + .browse-reset-button {
  margin-left: 10rem;
}
.form-file .browse-add-button,
.form-file .browse-remove-button {
  margin-left: 10rem;
}
.form-file .browse-add-button + .browse-reset-button,
.form-file .browse-remove-button + .browse-reset-button {
  margin-left: 40rem;
}
.form-file .browse-add-button {
  border-color: #888;
}
.form-file .browse-remove-button {
  border-color: #ddd;
}
.form-file .browse-file-delete-button {
  position: absolute;
  top: 12rem;
  right: 30rem;
}
.form-file.is-disabled .form-input input[type=text], .form-file.is-disabled .form-input--img input[type=text], .form-file.is-disabled .form-input--datepicker input[type=text], .form-file.is-disabled .form-input--icon-button input[type=text] {
  background-color: #f4f4f4;
}
.form-file.is-disabled .browse-files-button {
  pointer-events: none;
}

.is-spinner {
  overflow: hidden;
}

.spinner-set, .spinner {
  position: absolute;
  top: calc(50% - 24rem);
  left: calc(50% - 24rem);
  width: 48rem;
  height: 48rem;
}

.spinner {
  position: relative;
  border: 5rem solid #5085ec;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: rotation 1s linear infinite;
}
.spinner-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 450;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}
.spinner-container.is-active {
  display: block;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tab, .tab--lv3, .tab--lv2, .tab--lv1 {
  display: flex;
}
.tab-container, .tab-container--fixed-mode {
  position: relative;
}
.tab-container--fixed-mode {
  height: 88rem;
  margin-top: -40rem;
}
.tab-container--fixed-mode .tab--lv3 {
  padding-top: 40rem;
  background-color: #fff;
}
.tab-container.is-fixed .tab--lv3, .is-fixed.tab-container--fixed-mode .tab--lv3 {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 1440rem;
}
.tab--lv1 > [role=tab] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200rem;
  height: 48rem;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #c1c1c1;
  background-color: #f4f4f4;
  font-size: 18rem;
  line-height: 26rem;
  font-weight: 500;
}
.tab--lv1 > [role=tab][aria-selected=true] {
  border-top: 1px solid #c1c1c1;
  border-right: 1px solid #c1c1c1;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #c1c1c1;
  background-color: #fff;
}
.tab--lv2 {
  position: relative;
}
.tab--lv2:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #cecece;
}
.tab--lv2 > [role=tab] {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200rem;
  height: 56rem;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  background-color: #f4f4f4;
  color: #888;
}
.tab--lv2 > [role=tab]:first-child {
  border-left: 1px solid #cecece;
  border-radius: 20rem 0 0 0;
}
.tab--lv2 > [role=tab]:last-child {
  border-right: 1px solid #cecece;
  border-radius: 0 20rem 0 0;
}
.tab--lv2 > [role=tab]:last-child[aria-selected=true] {
  border-right: 1px solid #cecece;
}
.tab--lv2 > [role=tab]:first-child:last-child {
  border-radius: 20rem 20rem 0 0;
}
.tab--lv2 > [role=tab] + [role=tab] {
  border-left: 1px solid #cecece;
}
.tab--lv2 > [role=tab][aria-selected=true] {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  position: relative;
  border: 1px solid #cecece;
  border-right: 0;
  border-bottom: 1px solid #fff;
  background-color: #fff;
  color: #042b69;
}
.tab--lv3 > [role=button] {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  flex: 1;
  padding: 10rem;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  background-color: #f4f4f4;
}
.tab--lv3 > [role=button] + [role=button] {
  border-left: 1px solid #cecece;
}
.tab--lv3 > [role=button].is-active {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 700;
  border-color: #1472ce;
  background-color: #1472ce;
  color: #fff;
}
.tab--lv3 > [role=button].is-active + [role=button] {
  border-left-color: #1472ce;
}
.tab--lv3 > [role=button]:first-child {
  border-left: 1px solid #cecece;
  border-radius: 10rem 0 0 10rem;
}
.tab--lv3 > [role=button]:last-child {
  border-right: 1px solid #cecece;
  border-radius: 0 10rem 10rem 0;
}
.tab--lv4 {
  position: relative;
}
.tab--lv4:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #b8b8b8;
}
.tab--lv4 > [role=tab],
.tab--lv4 > [role=button] {
  display: inline-flex;
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  position: relative;
  padding-bottom: 12rem;
}
.tab--lv4 > [role=tab] + [role=tab], .tab--lv4 > [role=tab] + [role=button],
.tab--lv4 > [role=button] + [role=tab],
.tab--lv4 > [role=button] + [role=button] {
  margin-left: 80rem;
}
.tab--lv4 > [role=tab][aria-selected=true], .tab--lv4 > [role=tab].is-active,
.tab--lv4 > [role=button][aria-selected=true],
.tab--lv4 > [role=button].is-active {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 700;
  color: #1472ce;
}
.tab--lv4 > [role=tab][aria-selected=true]:before, .tab--lv4 > [role=tab].is-active:before,
.tab--lv4 > [role=button][aria-selected=true]:before,
.tab--lv4 > [role=button].is-active:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3rem;
  background-color: #1472ce;
}
.tab.search-tab [role=tab], .search-tab.tab--lv1 [role=tab], .search-tab.tab--lv2 [role=tab], .search-tab.tab--lv3 [role=tab] {
  flex: 0 0 206rem;
}

.popup-address .address-search {
  display: flex;
}
.popup-address .address-search .form-input, .popup-address .address-search .form-input--img, .popup-address .address-search .form-input--datepicker, .popup-address .address-search .form-input--icon-button {
  flex: 1;
}
.popup-address .address-search [role=button] {
  margin-left: 10rem;
}
.popup-address .address-header {
  margin-top: 20rem;
}
.popup-address .address-main .address-table-header {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  margin-top: 30rem;
  padding-bottom: 20rem;
}
.popup-address .address-notice strong {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  display: block;
  color: #222;
}
.popup-address .address-notice ul {
  margin-top: 8rem;
}
.popup-address .address-notice ul > li {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  position: relative;
  padding-left: 20rem;
  color: #666;
}
.popup-address .address-notice ul > li:before {
  content: "";
  position: absolute;
  top: 10rem;
  left: 8rem;
  width: 3rem;
  height: 3rem;
  background-color: #666;
  border-radius: 50%;
}
.popup-address .address-item {
  display: flex;
}
.popup-address .address-item + .address-item {
  margin-top: 8rem;
}
.popup-address .address-item .address-item-tit {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100rem;
  height: 28rem;
  margin-right: 10rem;
  background-color: #f4f4f4;
  text-align: center;
}
.popup-address .address-item .address-item-desc {
  flex: 1;
}

.authentication-method {
  display: flex;
  align-items: center;
}
.authentication-method .form-selector__list {
  margin-right: 60rem;
}
.authentication-method .form-selector__list .form-selector input[type=radio] + label {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
}

.authentication-number {
  display: flex;
  align-items: center;
}
.authentication-number > span + span {
  margin-left: 10rem;
}

.notice-popup__container {
  display: flex;
  flex-direction: column;
}
.notice-popup__container .notice-popup__header {
  padding: 40rem 40rem 20rem;
}
.notice-popup__container .notice-popup__header > strong {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  max-height: 128rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
}
.notice-popup__container .notice-popup__main {
  flex: 1;
  padding: 40rem 40rem 100rem;
  line-height: 1;
}
.notice-popup__container .notice-popup__main img {
  width: 100%;
}
.notice-popup__container .notice-popup__main--temp {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 600rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}
.notice-popup__container .notice-popup__footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}
.notice-popup__container .notice-popup-buttons {
  display: flex;
  border-top: 1px solid #cecece;
}
.notice-popup__container .notice-popup-buttons button {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  flex: 1;
  height: 60rem;
  text-align: center;
}
.notice-popup__container .notice-popup-buttons button + button {
  position: relative;
}
.notice-popup__container .notice-popup-buttons button + button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1px;
  background-color: #cecece;
}

.proxy-approver h2 {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  padding-bottom: 0;
}
.proxy-approver .form-flex, .proxy-approver .form-flex--align-c, .proxy-approver .form-flex--jc-between, .proxy-approver .form-flex--inline {
  align-items: center;
}

[data-modal-name=change-password] .table td, [data-modal-name=change-password] .table--border-grey td, [data-modal-name=change-password] .table--lv2 td, [data-modal-name=change-password] .table--lv2-high td, [data-modal-name=change-password] .table--lv3 td {
  padding-left: 20rem;
}
[data-modal-name=change-password] .modal-subsection {
  padding-bottom: 40rem;
}
[data-modal-name=change-password] .type--refer {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
}
[data-modal-name=change-password] .form-flex, [data-modal-name=change-password] .form-flex--align-c, [data-modal-name=change-password] .form-flex--jc-between, [data-modal-name=change-password] .form-flex--inline {
  align-items: center;
}
[data-modal-name=change-password] .form-flex .form-input + span, [data-modal-name=change-password] .form-flex--align-c .form-input + span, [data-modal-name=change-password] .form-flex--jc-between .form-input + span, [data-modal-name=change-password] .form-flex--inline .form-input + span, [data-modal-name=change-password] .form-flex .form-input--img + span, [data-modal-name=change-password] .form-flex--align-c .form-input--img + span, [data-modal-name=change-password] .form-flex--jc-between .form-input--img + span, [data-modal-name=change-password] .form-flex--inline .form-input--img + span, [data-modal-name=change-password] .form-flex .form-input--datepicker + span, [data-modal-name=change-password] .form-flex--align-c .form-input--datepicker + span, [data-modal-name=change-password] .form-flex--jc-between .form-input--datepicker + span, [data-modal-name=change-password] .form-flex--inline .form-input--datepicker + span, [data-modal-name=change-password] .form-flex .form-input--icon-button + span, [data-modal-name=change-password] .form-flex--align-c .form-input--icon-button + span, [data-modal-name=change-password] .form-flex--jc-between .form-input--icon-button + span, [data-modal-name=change-password] .form-flex--inline .form-input--icon-button + span {
  margin-left: 10rem;
}
[data-modal-name=change-password] .change-password-notice {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
}

[data-modal-name=enter-reason] .form-textarea-label textarea {
  height: 228rem;
}

[data-modal-name=logout] .desc {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  text-align: center;
}
[data-modal-name=logout] .time-remaining {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-top: 20rem;
  text-align: center;
  color: #1472ce;
}

[data-modal-name=notice] .section-modal {
  display: flex;
  flex-direction: column;
}
[data-modal-name=notice] .modal-subsection {
  flex: 1;
  position: relative;
  height: calc(100% - 116rem);
  padding-bottom: 116rem;
}
[data-modal-name=notice] .modal-subsection .modal-notice-content {
  overflow-y: auto;
  height: 100%;
}
[data-modal-name=notice] .modal-subsection .modal-buttons {
  position: absolute;
  right: 0;
  bottom: 30rem;
  left: 0;
}

[data-modal-name=opinion] .form-textarea-label textarea {
  height: 228rem;
}

[data-modal-name=reset-password] .table td, [data-modal-name=reset-password] .table--border-grey td, [data-modal-name=reset-password] .table--lv2 td, [data-modal-name=reset-password] .table--lv2-high td, [data-modal-name=reset-password] .table--lv3 td {
  padding: 0 20rem;
}
[data-modal-name=reset-password] .modal-subsection {
  padding-bottom: 40rem;
}
[data-modal-name=reset-password] .type--refer {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
}
[data-modal-name=reset-password] .form-flex, [data-modal-name=reset-password] .form-flex--align-c, [data-modal-name=reset-password] .form-flex--jc-between, [data-modal-name=reset-password] .form-flex--inline {
  align-items: center;
}
[data-modal-name=reset-password] .form-flex .form-input + span, [data-modal-name=reset-password] .form-flex--align-c .form-input + span, [data-modal-name=reset-password] .form-flex--jc-between .form-input + span, [data-modal-name=reset-password] .form-flex--inline .form-input + span, [data-modal-name=reset-password] .form-flex .form-input--img + span, [data-modal-name=reset-password] .form-flex--align-c .form-input--img + span, [data-modal-name=reset-password] .form-flex--jc-between .form-input--img + span, [data-modal-name=reset-password] .form-flex--inline .form-input--img + span, [data-modal-name=reset-password] .form-flex .form-input--datepicker + span, [data-modal-name=reset-password] .form-flex--align-c .form-input--datepicker + span, [data-modal-name=reset-password] .form-flex--jc-between .form-input--datepicker + span, [data-modal-name=reset-password] .form-flex--inline .form-input--datepicker + span, [data-modal-name=reset-password] .form-flex .form-input--icon-button + span, [data-modal-name=reset-password] .form-flex--align-c .form-input--icon-button + span, [data-modal-name=reset-password] .form-flex--jc-between .form-input--icon-button + span, [data-modal-name=reset-password] .form-flex--inline .form-input--icon-button + span {
  margin-left: 10rem;
}

[data-modal-name=scrap] .scrap-header > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: block;
}
[data-modal-name=scrap] .scrap-header .scrap-notice {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 400;
  margin-top: 20rem;
}
[data-modal-name=scrap] .scrap-main {
  margin-top: 40rem;
}
[data-modal-name=scrap] .scrap-main > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: block;
  padding-bottom: 10rem;
}
[data-modal-name=scrap] .scrap-main .form-textarea-label textarea {
  height: 268rem;
}

[data-modal-name=share] .share-header > ul {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
}
[data-modal-name=share] .share-header > ul > li {
  position: relative;
  padding-left: 20rem;
}
[data-modal-name=share] .share-header > ul > li:before {
  content: "";
  position: absolute;
  top: 9rem;
  left: 9rem;
  width: 3rem;
  height: 3rem;
  background-color: #222;
  border-radius: 50%;
}
[data-modal-name=share] .share-header .form-search-content > div > strong {
  width: 58rem;
}
[data-modal-name=share] .share-header .form-search-container {
  padding: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
}
[data-modal-name=share] .share-header .form-search-container [role=button] {
  top: auto;
  bottom: 0;
}
[data-modal-name=share] .share-main {
  margin-top: 20rem;
}
[data-modal-name=share] .share-main > div + div {
  margin-top: 40rem;
}
[data-modal-name=share] .share-main > div + div > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: block;
  padding-bottom: 20rem;
}

[data-modal-name=team-leader-feedback] .form-textarea-label textarea {
  height: 228rem;
}

[data-modal-name=terms-and-privacy] .terms-and-privacy-main {
  padding-top: 20rem;
}
[data-modal-name=terms-and-privacy] .terms-and-privacy-main > div > strong {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  display: block;
  margin-bottom: 20rem;
}
[data-modal-name=terms-and-privacy] .terms-and-privacy-main > div .form-selector {
  display: block;
  margin-top: 20rem;
}
[data-modal-name=terms-and-privacy] .terms-and-privacy-main > div + div {
  margin-top: 40rem;
}
[data-modal-name=terms-and-privacy] .terms-and-privacy-footer {
  margin-top: 30rem;
  padding-top: 30rem;
  border-top: 1px solid #cecece;
}
[data-modal-name=terms-and-privacy] .terms-and-privacy .form-selector input[type=checkbox] + label {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
}

[data-modal-name=view-note-content] .overflow-y-content,
[data-modal-name=view-note-content] .form-textarea-label textarea {
  height: 228rem;
}

[data-modal-name=view-share-content] .overflow-y-content {
  height: 228rem;
}

.step-list {
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-list-item {
  position: relative;
  width: 221rem;
  height: 221rem;
  margin-right: 146rem;
  padding: 54rem 20rem 0;
  border: 1px solid #b8b8b8;
  border-radius: 50%;
  text-align: center;
}
.step-list-item > span {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  color: #1472ce;
}
.step-list-item > p {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  margin-top: 20rem;
}
.step-list-item:last-child {
  margin-right: 0;
}
.step-list-item [class^=icon-] {
  position: absolute;
  top: 50%;
  right: -86rem;
}

hr.section-line, hr.section-line--40 {
  display: block;
  width: 100%;
  max-width: 1440rem;
  height: 1px;
  margin: 0 auto;
  border: 0;
  background-color: #cecece;
}
hr.section-line--40 {
  margin-top: 40rem;
  margin-bottom: 40rem;
}

.step-info {
  text-align: center;
}
.step-info .step-move-button.is-start,
.step-info .step-move-button.is-end {
  opacity: 0.5;
}
.step-info .step-txt {
  display: inline-block;
  margin: 0 20rem;
  font-size: 24rem;
  line-height: 1.6;
  vertical-align: bottom;
}
.step-info .step-txt .current-step {
  color: #1472ce;
}
.step-info .step-txt .current-step .step-number {
  margin-left: 4rem;
}
.step-info .step-txt .total-step .step-number {
  margin-right: 4rem;
}
.step-info .step-number {
  display: inline-block;
  font-size: 32rem;
  font-weight: 500;
}

.line-button--sm.type01 {
  font-size: 12rem;
  line-height: 32rem;
  height: 32rem;
  border-color: #cecece;
  border-radius: 0;
}

.survey-list-item__tit em {
  display: inline-block;
  vertical-align: middle;
  width: auto;
  margin-right: 12rem;
  padding: 0 10rem;
  height: 30rem;
  line-height: 30rem;
  color: #fff;
  background-color: #5085ec;
  border-radius: 10rem;
}

.learning-status__quiz .section-header, .learning-status__quiz .section-header--line, .learning-status__quiz .section-header--line--star {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.learning-status__quiz .quiz-timer .value, .learning-status__quiz .quiz-score .value {
  font-size: 40rem;
  line-height: 1.3;
  font-weight: 700;
  color: #1472ce;
  vertical-align: middle;
}
.learning-status__quiz .quiz-score > span {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
  vertical-align: middle;
}
.learning-status__quiz .advance-msg {
  padding: 20rem 40rem;
  background-color: #f4f4f4;
  border-radius: 20rem;
}
.learning-status__quiz .advance-msg .info-msg, .learning-status__quiz .advance-msg .info-msg--md, .learning-status__quiz .advance-msg .form-file-desc {
  flex-wrap: wrap;
  margin-top: 0;
}
.learning-status__quiz .advance-msg .info-msg ul, .learning-status__quiz .advance-msg .info-msg--md ul, .learning-status__quiz .advance-msg .form-file-desc ul {
  width: 100%;
}
.learning-status__quiz .advance-msg .info-msg ul li, .learning-status__quiz .advance-msg .info-msg--md ul li, .learning-status__quiz .advance-msg .form-file-desc ul li {
  font-size: 14rem;
}
.learning-status__quiz .remind-msg {
  padding: 50rem 40rem;
  background-color: #f2f7fb;
  border-radius: 20rem;
  text-align: center;
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
}
.learning-status__quiz .quiz-list__item {
  margin-bottom: 20rem;
  padding: 30rem 40rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.learning-status__quiz .quiz-list__item-views {
  margin-top: 15rem;
  padding-top: 20rem;
  border-top: 1px solid #ddd;
}
.learning-status__quiz .quiz-list__item-views .form-selector + .form-selector {
  margin-top: 15rem;
}
.learning-status__quiz .quiz-list__item-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.learning-status__quiz .quiz-list__item .quiz-result li {
  display: inline-block;
  min-width: 110rem;
  padding: 5rem 13rem;
  background-color: #f2f7fb;
  border-radius: 10rem;
  text-align: center;
  font-size: 14rem;
  line-height: 1.6;
  font-weight: 500;
  color: #042b69;
}
.learning-status__quiz .quiz-list__item .quiz-result li + li {
  margin-left: 6rem;
}
.learning-status__quiz .quiz-list__item-answer {
  padding: 20rem 22rem;
  border-radius: 20rem;
  background-color: #f4f4f4;
}
.learning-status__quiz .quiz-list__item-answer .tit {
  margin-right: 20rem;
}

.main-visual {
  height: 490rem;
}
.main-visual-content {
  width: 1440rem;
  margin-right: auto;
  margin-left: auto;
}
.main-visual .swiper-slide {
  overflow: hidden;
}

.main-visual-header {
  position: relative;
}
.main-visual-header .main-visual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.main-visual-header .main-visual-bg img {
  position: absolute;
  top: 0;
  left: 50%;
  width: 2560rem;
  transform: translateX(-50%);
  filter: blur(10px);
  -webkit-filter: blur(10px);
}
.main-visual-header .main-visual-content {
  position: relative;
  width: 1440rem;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
}
.main-visual-header .main-visual-text {
  width: 640rem;
  padding-top: 60rem;
}
.main-visual-header .main-visual-tit {
  font-size: 40rem;
  line-height: 52rem;
  font-weight: 700;
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  max-height: 52rem;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
  color: #fff;
}
.main-visual-header .main-visual-desc {
  font-size: 18rem;
  line-height: 29rem;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  max-height: 58rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
  margin-top: 10rem;
  color: #fff;
}
.main-visual-header .main-visual-banner {
  overflow: hidden;
  position: absolute;
  top: 40rem;
  right: 0;
  z-index: 1;
  width: 700rem;
  height: 296rem;
  border-radius: 40rem;
  box-shadow: 0px 10rem 20rem rgba(0, 0, 0, 0.2);
}
.main-visual-header .swiper-container {
  height: 490rem;
}
.main-visual-header .swiper-ctrl-container {
  position: absolute;
  top: 256rem;
  top: 312rem;
  left: 50%;
  margin-left: -720rem;
  z-index: 1;
}
.main-visual-header .main-visual-button-prev,
.main-visual-header .main-visual-button-next {
  position: absolute;
  top: 120rem;
  z-index: 1;
  cursor: pointer;
}
.main-visual-header .main-visual-button-prev {
  left: 50%;
  margin-left: -770rem;
}
.main-visual-header .main-visual-button-prev i[class^=icon] {
  transform: rotate(180deg);
}
.main-visual-header .main-visual-button-next {
  right: 50%;
  margin-right: -770rem;
}

.main-status-container {
  position: relative;
  z-index: 1;
  margin-top: -174rem;
}
.main-status-container:after {
  content: "";
  display: block;
  clear: both;
}
.main-status-container + .main-status-container {
  margin-top: 40rem;
}
.main-status-container > div {
  float: left;
  position: relative;
  width: 700rem;
  height: 296rem;
  padding: 16rem 40rem;
  background-color: #fff;
  box-shadow: 0px 10rem 20rem rgba(0, 0, 0, 0.2);
  border-radius: 20rem;
}
.main-status-container > div + div {
  margin-left: 40rem;
}
.main-status-container .overflow-y-content {
  height: 198rem;
}
.main-status-container .main-status-empty {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 198rem;
  padding: 20rem;
  background-color: #e5ecf8;
  border-radius: 20rem;
  text-align: center;
}
.main-status-container .main-status-util {
  display: flex;
  position: absolute;
  top: 25rem;
  right: 40rem;
}
.main-status-container .main-status-util .slide-ctrl-container {
  margin-left: 20rem;
}
.main-status-container .main-status-util .slide-ctrl-container + .buttons-wrapper {
  margin-left: 20rem;
}
.main-status-container .tab, .main-status-container .tab--lv1, .main-status-container .tab--lv2, .main-status-container .tab--lv3 {
  position: absolute;
  top: 22rem;
  left: 184rem;
}
.main-status-container .tab:before, .main-status-container .tab--lv1:before, .main-status-container .tab--lv2:before, .main-status-container .tab--lv3:before {
  content: none;
}
.main-status-container .tab.char03, .main-status-container .char03.tab--lv1, .main-status-container .char03.tab--lv2, .main-status-container .char03.tab--lv3 {
  left: 126rem;
}
.main-status-container .tab [role=tab], .main-status-container .tab--lv1 [role=tab], .main-status-container .tab--lv2 [role=tab], .main-status-container .tab--lv3 [role=tab] {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  padding-bottom: 2rem;
}
.main-status-container .tab [role=tab] + [role=tab], .main-status-container .tab--lv1 [role=tab] + [role=tab], .main-status-container .tab--lv2 [role=tab] + [role=tab], .main-status-container .tab--lv3 [role=tab] + [role=tab] {
  margin-left: 16rem;
}
.main-status-container .tab [role=tab][aria-selected=true], .main-status-container .tab--lv1 [role=tab][aria-selected=true], .main-status-container .tab--lv2 [role=tab][aria-selected=true], .main-status-container .tab--lv3 [role=tab][aria-selected=true] {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}
.main-status-container .tab [role=tab][aria-selected=true]:before, .main-status-container .tab--lv1 [role=tab][aria-selected=true]:before, .main-status-container .tab--lv2 [role=tab][aria-selected=true]:before, .main-status-container .tab--lv3 [role=tab][aria-selected=true]:before {
  height: 2rem;
}
.main-status-container .performance-container {
  display: flex;
}
.main-status-container .performance-container .main-performance-list {
  margin-left: 40rem;
}
.main-status-container .chart-container {
  display: flex;
  width: 300rem;
}
.main-status-container .chart-container > div {
  flex: 1;
}
.main-status-container .chart-container > div + div {
  margin-left: 20rem;
}
.main-status-container .chart-container > div > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  display: block;
  text-align: center;
}
.main-status-container .chart-container .chart-area {
  position: relative;
  height: 140rem;
  margin-top: 20rem;
}
.main-status-container .chart-container .chart-area .text {
  font-size: 12rem;
  line-height: 19rem;
  font-weight: 500;
  display: flex;
  width: 140rem;
  height: 140rem;
  justify-content: center;
  align-items: center;
}
.main-status-container .chart-container .chart-area .text > strong {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  margin-left: 4rem;
}
.main-status-container .chart-container .chart-area .chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.main-status-container .chart-container .chart-area .chart-inner {
  position: absolute;
  top: -43rem;
  left: 0;
}
.main-status-container .main-performance-list {
  padding-top: 34rem;
}
.main-status-container .main-performance-list .main-performance-item {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  position: relative;
  padding-left: 22rem;
}
.main-status-container .main-performance-list .main-performance-item:before {
  content: "";
  position: absolute;
  top: 5rem;
  left: 0;
  width: 12rem;
  height: 12rem;
  border-radius: 50%;
}
.main-status-container .main-performance-list .main-performance-item + .main-performance-item {
  margin-top: 12rem;
}
.main-status-container .main-performance-list .main-performance-item01:before {
  background-color: #ffbd3e;
}
.main-status-container .main-performance-list .main-performance-item02:before {
  background-color: #54cbaa;
}
.main-status-container .main-performance-list .main-performance-item03:before {
  background-color: #9673ff;
}
.main-status-container .main-performance-list .main-performance-item04:before {
  background-color: #badb5d;
}
.main-status-container .main-performance-list .main-performance-item05:before {
  background-color: #ff7070;
}
.main-status-container .learning-status {
  position: relative;
  padding-top: 20rem;
  padding-bottom: 20rem;
}
.main-status-container .learning-status-swiper {
  height: 236rem;
  margin-top: -20rem;
}
.main-status-container .learning-status + .learning-status {
  border-top: 1px solid #cecece;
}
.main-status-container .learning-status .form-flex > div + div, .main-status-container .learning-status .form-flex--align-c > div + div, .main-status-container .learning-status .form-flex--jc-between > div + div, .main-status-container .learning-status .form-flex--inline > div + div {
  position: relative;
  margin-left: 12rem;
  padding-left: 13rem;
}
.main-status-container .learning-status .form-flex > div + div:before, .main-status-container .learning-status .form-flex--align-c > div + div:before, .main-status-container .learning-status .form-flex--jc-between > div + div:before, .main-status-container .learning-status .form-flex--inline > div + div:before {
  content: "";
  position: absolute;
  top: 5rem;
  left: 0;
  width: 1px;
  height: 12rem;
  background-color: #cecece;
}
.main-status-container .learning-status .date {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
}
.main-status-container .learning-status .date strong {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}
.main-status-container .learning-status .learning-status-tit {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  width: 70%;
  max-height: 26rem;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
}
.main-status-container .learning-status .learning-status-util {
  position: absolute;
  right: 0;
  bottom: 20rem;
}
.main-status-container .learning-status .learning-status-util [class^=learning-status-type] {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  width: 130rem;
  margin-bottom: 4rem;
  text-align: center;
}
.main-status-container .learning-status .learning-status-util .learning-status-type01 {
  color: #1472ce;
}
.main-status-container .learning-status .learning-status-util .learning-status-type02 {
  color: #666;
}
.main-status-container .learning-status .learning-status-util .learning-status-type03 {
  color: #f65959;
}
.main-status-container .learning-status .learning-status-util [role=button] {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  white-space: nowrap;
}
.main-status-container .learning-status .edu-card-progress-desc {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  margin-left: 0;
}
.main-status-container .learning-status .edu-card-progress-content {
  width: 120rem;
  margin-left: 8rem;
}
.main-status-container .learning-status .edu-card-progress-rate {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
  color: #5085ec;
}
.main-status-container .learning-status .progress-current {
  background-color: #5085ec;
}

.main-card-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30rem;
}
.main-card-header > div {
  display: flex;
  align-items: center;
}
.main-card-header h2 {
  font-size: 32rem;
  line-height: 42rem;
  font-weight: 500;
  padding-bottom: 0;
}
.main-card-header h2 + [role=button] {
  margin-left: 20rem;
}
.main-card-header h2 .small {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
  color: #666;
}
.main-card-header .main-card-ctl {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.main-card-header .main-card-ctl + .buttons-wrapper {
  margin-left: 20rem;
}
.main-card-header .main-card-swiper-next,
.main-card-header .main-card-swiper-prev {
  display: inline-flex;
  width: 32rem;
  height: 32rem;
  cursor: pointer;
}
.main-card-header .main-card-swiper-next.swiper-button-disabled,
.main-card-header .main-card-swiper-prev.swiper-button-disabled {
  filter: grayscale(100%) opacity(50%);
}
.main-card-header .main-card-swiper-prev {
  transform: rotate(180deg);
}
.main-card-header .main-card-swiper-pagination {
  width: auto;
  margin: 0 10rem;
  font-weight: 500;
}
.main-card-header [class^=text-button] {
  align-self: flex-end;
}
.main-card-container {
  overflow: hidden;
  width: 1460rem;
  margin-left: -10rem;
  margin-bottom: -20rem;
  padding: 0 10rem 20rem;
}
.main-card-container .card {
  box-shadow: none;
}
.main-card-container .card-tit {
  overflow: hidden;
  display: -webkit-box;
  width: 100%;
  max-height: 52rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: inherit;
  height: 52rem;
  margin-top: 10rem;
}
.main-card-container .card-util {
  margin-top: 20rem;
}
.main-card-container .no-data {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 400;
  padding: 70rem;
  background-color: #fff;
  box-shadow: 0px 10rem 20rem rgba(0, 0, 0, 0.2);
  border-radius: 20rem;
  text-align: center;
}
.main-card-swiper {
  overflow: visible;
}
[data-bgColor] .card {
  box-shadow: 0px 10rem 10rem rgba(0, 0, 0, 0.1);
}

.theme-white .main-card-header {
  position: relative;
  z-index: 1;
  margin-top: -170rem;
}
.theme-white .main-card-header h2 {
  color: #fff;
}
.theme-white .main-card-header h2 + [role=button] {
  color: #fff;
}
.theme-white .main-card-header h2 + [role=button] [class^=icon] {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  background-position: -319rem 0;
}
.theme-white .main-card-header .main-card-swiper-pagination {
  color: #fff;
}
.theme-white .main-card-header .main-card-swiper-next,
.theme-white .main-card-header .main-card-swiper-prev {
  position: relative;
}
.theme-white .main-card-header .main-card-swiper-next:before,
.theme-white .main-card-header .main-card-swiper-prev:before {
  background-image: url("/images/ui22/common/common.png");
  background-size: 400rem auto;
  background-repeat: no-repeat;
  content: "";
  display: block;
  width: 32rem;
  height: 32rem;
  background-position: -336rem 0;
}
.theme-white .main-card-header .main-card-swiper-next [class^=icon],
.theme-white .main-card-header .main-card-swiper-prev [class^=icon] {
  display: none;
}

.learning-detail, .learning-detail-lv2 {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  grid-gap: 20rem;
  position: relative;
}
.learning-detail > a, .learning-detail-lv2 > a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.learning-detail__list {
  position: relative;
  flex: 1;
  min-width: 223rem;
  padding: 24rem 30rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.learning-detail__list.total {
  border-width: 2rem;
  border-color: #ece1a5;
  background-color: #fffae2;
}
.learning-detail__list.total .learning-detail__name {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 700;
}
.learning-detail__list.total .learning-detail__name .small {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}
.learning-detail__list.total .value {
  color: #1472ce;
}
.learning-detail__list.total .icon-box:before {
  background-color: #fff;
}
.learning-detail__list.always {
  border-width: 2rem;
  border-color: #88abef;
  background-color: #d0e0ff;
}
.learning-detail__list.always .learning-detail__name {
  font-size: 16rem;
  line-height: 26rem;
  font-weight: 700;
}
.learning-detail__list.always .value,
.learning-detail__list.always .unit {
  color: #000;
}
.learning-detail__list.always .learning-detail__info .icon-box:before, .learning-detail__list.always .learning-detail__info--lv2 .icon-box:before {
  background: rgba(255, 255, 255, 0.3);
}
.learning-detail__name {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}
.learning-detail__info, .learning-detail__info--lv2 {
  margin-top: 4rem;
}
.learning-detail__info strong, .learning-detail__info--lv2 strong {
  display: flex;
  align-items: baseline;
  font-size: 16rem;
  line-height: 1.6;
  font-weight: 500;
}
.learning-detail__info .value, .learning-detail__info--lv2 .value {
  font-size: 24rem;
  line-height: 38rem;
  font-weight: 500;
}
.learning-detail__info .unit, .learning-detail__info--lv2 .unit {
  margin-left: 4rem;
  color: #888;
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}
.learning-detail__info .unit.per, .learning-detail__info--lv2 .unit.per {
  font-size: 14rem;
  line-height: 22rem;
  font-weight: 500;
}
.learning-detail__info .unit.per:before, .learning-detail__info--lv2 .unit.per:before {
  display: inline-block;
  content: "/";
  margin-right: 4rem;
}
.learning-detail__info > span:not(.icon-box), .learning-detail__info--lv2 > span:not(.icon-box) {
  display: block;
  font-size: 12rem;
  line-height: 1.6;
  font-weight: 400;
  color: #888;
}
.learning-detail__info .icon-box, .learning-detail__info--lv2 .icon-box {
  position: absolute;
  top: 50%;
  right: 30rem;
  transform: translateY(-50%);
  display: block;
  width: 54rem;
  height: 54rem;
  padding-right: 6rem;
  padding-bottom: 6rem;
}
.learning-detail__info .icon-box:before, .learning-detail__info--lv2 .icon-box:before {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  display: block;
  width: 40rem;
  height: 40rem;
  border-radius: 50%;
  background-color: #f4f4f4;
}
.learning-detail__info .icon-box [class^=icon-], .learning-detail__info--lv2 .icon-box [class^=icon-] {
  position: relative;
}
.learning-detail__info--lv2 {
  margin-top: 0;
}
.learning-detail__info--lv2 > strong {
  position: relative;
}
.learning-detail__info--lv2 > strong:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #222;
}
.learning-detail__info--lv2 .value {
  font-size: 32rem;
  line-height: 42rem;
  font-weight: 500;
}
.learning-detail__info--lv2 .unit {
  font-size: 20rem;
  line-height: 32rem;
  font-weight: 500;
}
.learning-detail-lv2 {
  align-items: center;
}
.learning-detail-lv2 .learning-detail__list {
  padding-top: 54rem;
  padding-bottom: 54rem;
}
.learning-detail-lv2 .icon-box {
  width: auto;
  height: auto;
  padding: 0;
}
.learning-detail-lv2 .icon-box:before {
  right: -6rem;
  bottom: -6rem;
}

.graph-area {
  display: flex;
  justify-content: space-between;
  grid-gap: 20rem;
}
.graph-area .learning-detail__list {
  display: flex;
  align-items: center;
  min-height: 162rem;
}

.learning-graph {
  min-width: 954rem;
}
.learning-graph__item {
  padding: 24rem 30rem;
  border: 1px solid #cecece;
  border-radius: 20rem;
}
.learning-graph__item li {
  display: flex;
  align-items: center;
  grid-gap: 24rem;
}
.learning-graph__item li + li {
  margin-top: 12rem;
}
.learning-graph__bar {
  display: inline-block;
  position: relative;
  width: 220rem;
  height: 30rem;
}
.learning-graph__bar[data-theme=purple] .learning-graph__current {
  background-color: #9673ff;
}
.learning-graph__bar[data-theme=red] .learning-graph__current {
  background-color: #ffbd3e;
}
.learning-graph__bar[data-theme=yellow] .learning-graph__current {
  background-color: #ff7070;
}
.learning-graph__current {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0%;
  border-radius: 0px 20rem 20rem 0px;
}
.learning-graph__label {
  position: absolute;
  left: calc(100% + 20rem);
  white-space: nowrap;
  font-weight: 500;
}
.learning-graph__label em {
  font-size: 14rem;
  color: #1472ce;
  vertical-align: baseline;
}
.learning-graph__label em:first-child {
  margin-left: 5rem;
}

.table-header {
  margin-bottom: 20rem;
}
.table-header h3 span {
  display: inline-block;
  margin-left: 8rem;
  font-size: 16rem;
}

.pg-regular-learning-home {
  margin-bottom: -120rem;
}
.pg-regular-learning-home .last-section {
  padding-bottom: 96rem;
}
.pg-regular-learning-home .section-header > h2, .pg-regular-learning-home .section-header--line > h2, .pg-regular-learning-home .section-header--line--star > h2 {
  font-size: 32rem;
  line-height: 42rem;
  font-weight: 500;
  padding-bottom: 30rem;
}
.pg-regular-learning-home .post-container {
  margin-top: 0;
}

[class^=icon-common] {
  display: inline-block;
  flex-shrink: 0;
  background-image: url(/images/ui22/sprite/common.png);
  background-repeat: no-repeat;
  background-size: 466rem 462rem;
  white-space: nowrap;
  text-indent: 200%;
  overflow: hidden;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  [class^=icon-common] {
    background-image: url(/images/ui22/sprite/common@2x.png);
  }
}
.icon-common-add {
  width: 20rem;
  height: 20rem;
  background-position: -218rem -220rem;
}

.icon-common-admission {
  width: 12rem;
  height: 16rem;
  background-position: -428rem -50rem;
}

.icon-common-arrow-down-bk-8 {
  width: 10rem;
  height: 10rem;
  background-position: -428rem -76rem;
}

.icon-common-arrow-down-bk {
  width: 18rem;
  height: 10rem;
  background-position: -338rem -236rem;
}

.icon-common-arrow-left-bk-12 {
  width: 12rem;
  height: 12rem;
  background-position: -74rem -446rem;
}

.icon-common-arrow-left-bk {
  width: 32rem;
  height: 32rem;
  background-position: 0px -260rem;
}

.icon-common-arrow-left {
  width: 24rem;
  height: 24rem;
  background-position: -126rem -260rem;
}

.icon-common-arrow-right-60 {
  width: 30rem;
  height: 60rem;
  background-position: -298rem -186rem;
}

.icon-common-arrow-right-80 {
  width: 40rem;
  height: 80rem;
  background-position: -298rem -96rem;
}

.icon-common-arrow-right-bk-12 {
  width: 12rem;
  height: 12rem;
  background-position: -96rem -446rem;
}

.icon-common-arrow-right-bk-16 {
  width: 16rem;
  height: 16rem;
  background-position: -144rem -416rem;
}

.icon-common-arrow-right-bk {
  width: 32rem;
  height: 32rem;
  background-position: -42rem -260rem;
}

.icon-common-arrow-right {
  width: 28rem;
  height: 23rem;
  background-position: -180rem -220rem;
}

.icon-common-attached-file-20 {
  width: 20rem;
  height: 20rem;
  background-position: -248rem -220rem;
}

.icon-common-attached-file {
  width: 16rem;
  height: 16rem;
  background-position: -170rem -416rem;
}

.icon-common-autoever {
  width: 142rem;
  height: 40rem;
  background-position: -110rem -60rem;
}

.icon-common-book-grey-16 {
  width: 16rem;
  height: 16rem;
  background-position: -196rem -416rem;
}

.icon-common-book-grey {
  width: 24rem;
  height: 24rem;
  background-position: -160rem -260rem;
}

.icon-common-book {
  width: 24rem;
  height: 24rem;
  background-position: -194rem -260rem;
}

.icon-common-business-hours {
  width: 18rem;
  height: 18rem;
  background-position: -60rem -416rem;
}

.icon-common-calculator {
  width: 40rem;
  height: 40rem;
  background-position: -338rem -186rem;
}

.icon-common-calendar-16 {
  width: 16rem;
  height: 16rem;
  background-position: -222rem -416rem;
}

.icon-common-calender {
  width: 24rem;
  height: 24rem;
  background-position: -228rem -260rem;
}

.icon-common-certificate {
  width: 16rem;
  height: 16rem;
  background-position: -248rem -416rem;
}

.icon-common-checkbox-checked {
  width: 20rem;
  height: 20rem;
  background-position: -204rem -382rem;
}

.icon-common-checkbox-default {
  width: 20rem;
  height: 20rem;
  background-position: -234rem -382rem;
}

.icon-common-ci-28 {
  width: 87rem;
  height: 28rem;
  background-position: -348rem -96rem;
}

.icon-common-ci-blue {
  width: 142rem;
  height: 40rem;
  background-position: -298rem 0px;
}

.icon-common-ci-grey {
  width: 178rem;
  height: 50rem;
  background-position: -110rem 0px;
}

.icon-common-ci-white {
  width: 178rem;
  height: 50rem;
  background-position: 0px -110rem;
}

.icon-common-clipboard-list-16 {
  width: 16rem;
  height: 16rem;
  background-position: -274rem -416rem;
}

.icon-common-clipboard-list {
  width: 24rem;
  height: 24rem;
  background-position: -262rem -260rem;
}

.icon-common-contents-16 {
  width: 16rem;
  height: 16rem;
  background-position: -300rem -416rem;
}

.icon-common-contents {
  width: 24rem;
  height: 24rem;
  background-position: -296rem -260rem;
}

.icon-common-counting-16 {
  width: 16rem;
  height: 16rem;
  background-position: -326rem -416rem;
}

.icon-common-credit-card-arrow-right-alt-16 {
  width: 16rem;
  height: 16rem;
  background-position: -352rem -416rem;
}

.icon-common-credit-card-arrow-right-alt {
  width: 24rem;
  height: 24rem;
  background-position: -330rem -260rem;
}

.icon-common-ctrl-pause-bk {
  width: 24rem;
  height: 24rem;
  background-position: -364rem -260rem;
}

.icon-common-ctrl-pause-wh {
  width: 24rem;
  height: 24rem;
  background-position: -398rem -260rem;
}

.icon-common-ctrl-play-bk {
  width: 24rem;
  height: 24rem;
  background-position: -37rem -302rem;
}

.icon-common-ctrl-play-wh {
  width: 24rem;
  height: 24rem;
  background-position: -71rem -302rem;
}

.icon-common-delete-grey-bk {
  width: 20rem;
  height: 20rem;
  background-position: -264rem -382rem;
}

.icon-common-delete-grey {
  width: 24rem;
  height: 24rem;
  background-position: -105rem -302rem;
}

.icon-common-delete {
  width: 12rem;
  height: 12rem;
  background-position: -118rem -446rem;
}

.icon-common-department {
  width: 24rem;
  height: 24rem;
  background-position: -139rem -302rem;
}

.icon-common-download {
  width: 12rem;
  height: 14rem;
  background-position: -52rem -446rem;
}

.icon-common-e-campus-global-26 {
  width: 86rem;
  height: 26rem;
  background-position: -348rem -134rem;
}

.icon-common-e-campus-global {
  width: 120rem;
  height: 36rem;
  background-position: -298rem -50rem;
}

.icon-common-education-16 {
  width: 16rem;
  height: 16rem;
  background-position: -378rem -416rem;
}

.icon-common-education {
  width: 24rem;
  height: 24rem;
  background-position: -173rem -302rem;
}

.icon-common-email {
  width: 24rem;
  height: 24rem;
  background-position: -207rem -302rem;
}

.icon-common-essential-16 {
  width: 16rem;
  height: 16rem;
  background-position: -404rem -416rem;
}

.icon-common-essential {
  width: 24rem;
  height: 24rem;
  background-position: -241rem -302rem;
}

.icon-common-exclamation-mark {
  width: 100rem;
  height: 100rem;
  background-position: 0px 0px;
}

.icon-common-help-center {
  width: 24rem;
  height: 25rem;
  background-position: -262rem -60rem;
}

.icon-common-learning-complete {
  width: 20rem;
  height: 20rem;
  background-position: -294rem -382rem;
}

.icon-common-learning-ongoing {
  width: 20rem;
  height: 20rem;
  background-position: -324rem -382rem;
}

.icon-common-like-line-bk {
  width: 20rem;
  height: 20rem;
  background-position: -354rem -382rem;
}

.icon-common-like-line-wh {
  width: 24rem;
  height: 24rem;
  background-position: -275rem -302rem;
}

.icon-common-like {
  width: 16rem;
  height: 16rem;
  background-position: -450rem 0px;
}

.icon-common-lock-16 {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -26rem;
}

.icon-common-lock {
  width: 24rem;
  height: 24rem;
  background-position: -309rem -302rem;
}

.icon-common-mail {
  width: 18rem;
  height: 18rem;
  background-position: -88rem -416rem;
}

.icon-common-major {
  width: 24rem;
  height: 24rem;
  background-position: -343rem -302rem;
}

.icon-common-marker-16 {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -52rem;
}

.icon-common-marker {
  width: 24rem;
  height: 24rem;
  background-position: -377rem -302rem;
}

.icon-common-mobile-alt-2-16 {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -78rem;
}

.icon-common-mobile-alt-2 {
  width: 24rem;
  height: 24rem;
  background-position: -411rem -302rem;
}

.icon-common-money {
  width: 24rem;
  height: 24rem;
  background-position: 0px -348rem;
}

.icon-common-more-wh {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -104rem;
}

.icon-common-more {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -130rem;
}

.icon-common-move {
  width: 24rem;
  height: 24rem;
  background-position: -34rem -348rem;
}

.icon-common-notice {
  width: 40rem;
  height: 40rem;
  background-position: -388rem -186rem;
}

.icon-common-ok-16 {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -156rem;
}

.icon-common-ok {
  width: 24rem;
  height: 24rem;
  background-position: -68rem -348rem;
}

.icon-common-organization {
  width: 24rem;
  height: 24rem;
  background-position: -102rem -348rem;
}

.icon-common-page-up {
  width: 27rem;
  height: 36rem;
  background-position: 0px -302rem;
}

.icon-common-password {
  width: 24rem;
  height: 24rem;
  background-position: -136rem -348rem;
}

.icon-common-person-16 {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -182rem;
}

.icon-common-person-bl-40 {
  width: 40rem;
  height: 40rem;
  background-position: -188rem -110rem;
}

.icon-common-person-wh-40 {
  width: 40rem;
  height: 40rem;
  background-position: -238rem -110rem;
}

.icon-common-person {
  width: 24rem;
  height: 24rem;
  background-position: -170rem -348rem;
}

.icon-common-phone {
  width: 18rem;
  height: 18rem;
  background-position: -116rem -416rem;
}

.icon-common-place {
  width: 24rem;
  height: 24rem;
  background-position: -204rem -348rem;
}

.icon-common-play-80 {
  width: 80rem;
  height: 80rem;
  background-position: 0px -170rem;
}

.icon-common-play {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -208rem;
}

.icon-common-popup-up {
  width: 20rem;
  height: 20rem;
  background-position: -384rem -382rem;
}

.icon-common-question-bk {
  width: 20rem;
  height: 20rem;
  background-position: -414rem -382rem;
}

.icon-common-question {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -234rem;
}

.icon-common-rate {
  width: 24rem;
  height: 24rem;
  background-position: -238rem -348rem;
}

.icon-common-rate02 {
  width: 24rem;
  height: 24rem;
  background-position: -272rem -348rem;
}

.icon-common-recent-search {
  width: 20rem;
  height: 20rem;
  background-position: 0px -416rem;
}

.icon-common-remove {
  width: 20rem;
  height: 2rem;
  background-position: -348rem -170rem;
}

.icon-common-search-result {
  width: 80rem;
  height: 80rem;
  background-position: -90rem -170rem;
}

.icon-common-search {
  width: 24rem;
  height: 24rem;
  background-position: -306rem -348rem;
}

.icon-common-share-bk {
  width: 20rem;
  height: 20rem;
  background-position: -30rem -416rem;
}

.icon-common-share {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -260rem;
}

.icon-common-sign-in {
  width: 24rem;
  height: 24rem;
  background-position: -340rem -348rem;
}

.icon-common-sign-out-16 {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -286rem;
}

.icon-common-sign-out-24 {
  width: 24rem;
  height: 24rem;
  background-position: -374rem -348rem;
}

.icon-common-sort-16 {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -312rem;
}

.icon-common-sort {
  width: 24rem;
  height: 24rem;
  background-position: -408rem -348rem;
}

.icon-common-star {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -338rem;
}

.icon-common-student {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -364rem;
}

.icon-common-study {
  width: 32rem;
  height: 32rem;
  background-position: -84rem -260rem;
}

.icon-common-survey {
  width: 40rem;
  height: 40rem;
  background-position: -180rem -170rem;
}

.icon-common-tel {
  width: 24rem;
  height: 24rem;
  background-position: 0px -382rem;
}

.icon-common-time-16 {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -390rem;
}

.icon-common-time-24 {
  width: 24rem;
  height: 24rem;
  background-position: -34rem -382rem;
}

.icon-common-time-wh-16 {
  width: 16rem;
  height: 16rem;
  background-position: -450rem -416rem;
}

.icon-common-time {
  width: 24rem;
  height: 24rem;
  background-position: -68rem -382rem;
}

.icon-common-to {
  width: 24rem;
  height: 24rem;
  background-position: -102rem -382rem;
}

.icon-common-user {
  width: 24rem;
  height: 24rem;
  background-position: -136rem -382rem;
}

.icon-common-video-slash-16 {
  width: 16rem;
  height: 16rem;
  background-position: 0px -446rem;
}

.icon-common-video-slash {
  width: 24rem;
  height: 24rem;
  background-position: -170rem -382rem;
}

.icon-common-view {
  width: 16rem;
  height: 16rem;
  background-position: -26rem -446rem;
}

.icon-common-wallet {
  width: 40rem;
  height: 40rem;
  background-position: -230rem -170rem;
}

[class^=icon-education-work] {
  display: inline-block;
  flex-shrink: 0;
  background-image: url(/images/ui22/sprite/education-work.png);
  background-repeat: no-repeat;
  background-size: 62rem 42rem;
  white-space: nowrap;
  text-indent: 200%;
  overflow: hidden;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  [class^=icon-education-work] {
    background-image: url(/images/ui22/sprite/education-work@2x.png);
  }
}
.icon-education-work-tree-file {
  width: 16rem;
  height: 16rem;
  background-position: 0px 0px;
}

.icon-education-work-tree-folder--close {
  width: 16rem;
  height: 16rem;
  background-position: -26rem 0px;
}

.icon-education-work-tree-folder--open {
  width: 16rem;
  height: 16rem;
  background-position: 0px -26rem;
}

.icon-education-work-tree-status--close {
  width: 10rem;
  height: 12rem;
  background-position: -26rem -26rem;
}

.icon-education-work-tree-status--open {
  width: 10rem;
  height: 12rem;
  background-position: -52rem 0px;
}

[class^=icon-groups] {
  display: inline-block;
  flex-shrink: 0;
  background-image: url(/images/ui22/sprite/groups.png);
  background-repeat: no-repeat;
  background-size: 212rem 138rem;
  white-space: nowrap;
  text-indent: 200%;
  overflow: hidden;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  [class^=icon-groups] {
    background-image: url(/images/ui22/sprite/groups@2x.png);
  }
}
.icon-groups-business {
  width: 64rem;
  height: 64rem;
  background-position: 0px 0px;
}

.icon-groups-management {
  width: 64rem;
  height: 64rem;
  background-position: -74rem 0px;
}

.icon-groups-purchase {
  width: 64rem;
  height: 64rem;
  background-position: 0px -74rem;
}

.icon-groups-quality {
  width: 64rem;
  height: 64rem;
  background-position: -74rem -74rem;
}

.icon-groups-rnd {
  width: 64rem;
  height: 64rem;
  background-position: -148rem 0px;
}

.icon-groups-scm {
  width: 64rem;
  height: 64rem;
  background-position: -148rem -74rem;
}

[class^=icon-idp] {
  display: inline-block;
  flex-shrink: 0;
  background-image: url(/images/ui22/sprite/idp.png);
  background-repeat: no-repeat;
  background-size: 70rem 30rem;
  white-space: nowrap;
  text-indent: 200%;
  overflow: hidden;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  [class^=icon-idp] {
    background-image: url(/images/ui22/sprite/idp@2x.png);
  }
}
.icon-idp-process-step--check {
  width: 30rem;
  height: 30rem;
  background-position: 0px 0px;
}

.icon-idp-process-step {
  width: 30rem;
  height: 30rem;
  background-position: -40rem 0px;
}

[class^=icon-learning-status] {
  display: inline-block;
  flex-shrink: 0;
  background-image: url(/images/ui22/sprite/learning-status.png);
  background-repeat: no-repeat;
  background-size: 216rem 164rem;
  white-space: nowrap;
  text-indent: 200%;
  overflow: hidden;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  [class^=icon-learning-status] {
    background-image: url(/images/ui22/sprite/learning-status@2x.png);
  }
}
.icon-learning-status-cemina {
  width: 48rem;
  height: 48rem;
  background-position: 0px 0px;
}

.icon-learning-status-contnent_sum {
  width: 48rem;
  height: 48rem;
  background-position: -58rem 0px;
}

.icon-learning-status-elearning {
  width: 48rem;
  height: 48rem;
  background-position: 0px -58rem;
}

.icon-learning-status-group {
  width: 48rem;
  height: 48rem;
  background-position: -58rem -58rem;
}

.icon-learning-status-kplus-wh {
  width: 48rem;
  height: 48rem;
  background-position: -116rem 0px;
}

.icon-learning-status-kplus {
  width: 48rem;
  height: 48rem;
  background-position: -116rem -58rem;
}

.icon-learning-status-live {
  width: 48rem;
  height: 48rem;
  background-position: 0px -116rem;
}

.icon-learning-status-scrap {
  width: 48rem;
  height: 48rem;
  background-position: -58rem -116rem;
}

.icon-learning-status-share {
  width: 48rem;
  height: 48rem;
  background-position: -116rem -116rem;
}

.icon-learning-status-time {
  width: 42rem;
  height: 40rem;
  background-position: -174rem 0px;
}

[class^=icon-shortcut] {
  display: inline-block;
  flex-shrink: 0;
  background-image: url(/images/ui22/sprite/shortcut.png);
  background-repeat: no-repeat;
  background-size: 490rem 440rem;
  white-space: nowrap;
  text-indent: 200%;
  overflow: hidden;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  [class^=icon-shortcut] {
    background-image: url(/images/ui22/sprite/shortcut@2x.png);
  }
}
.icon-shortcut-academy {
  width: 40rem;
  height: 40rem;
  background-position: 0px 0px;
}

.icon-shortcut-academy_hover {
  width: 40rem;
  height: 40rem;
  background-position: -50rem 0px;
}

.icon-shortcut-application {
  width: 40rem;
  height: 40rem;
  background-position: 0px -50rem;
}

.icon-shortcut-application_hover {
  width: 40rem;
  height: 40rem;
  background-position: -50rem -50rem;
}

.icon-shortcut-calculator {
  width: 40rem;
  height: 40rem;
  background-position: -100rem 0px;
}

.icon-shortcut-calculator_hover {
  width: 40rem;
  height: 40rem;
  background-position: -100rem -50rem;
}

.icon-shortcut-car-trend {
  width: 40rem;
  height: 40rem;
  background-position: 0px -100rem;
}

.icon-shortcut-car-trend_hover {
  width: 40rem;
  height: 40rem;
  background-position: -50rem -100rem;
}

.icon-shortcut-cn-study {
  width: 40rem;
  height: 40rem;
  background-position: -100rem -100rem;
}

.icon-shortcut-cn-study_hover {
  width: 40rem;
  height: 40rem;
  background-position: -150rem 0px;
}

.icon-shortcut-core-value {
  width: 40rem;
  height: 40rem;
  background-position: -150rem -50rem;
}

.icon-shortcut-core-value_hover {
  width: 40rem;
  height: 40rem;
  background-position: -150rem -100rem;
}

.icon-shortcut-cst {
  width: 40rem;
  height: 40rem;
  background-position: 0px -150rem;
}

.icon-shortcut-cst_hover {
  width: 40rem;
  height: 40rem;
  background-position: -50rem -150rem;
}

.icon-shortcut-e-book {
  width: 40rem;
  height: 40rem;
  background-position: -100rem -150rem;
}

.icon-shortcut-e-book_hover {
  width: 40rem;
  height: 40rem;
  background-position: -150rem -150rem;
}

.icon-shortcut-e-library {
  width: 40rem;
  height: 40rem;
  background-position: -200rem 0px;
}

.icon-shortcut-e-library_hover {
  width: 40rem;
  height: 40rem;
  background-position: -200rem -50rem;
}

.icon-shortcut-e-pass {
  width: 40rem;
  height: 40rem;
  background-position: -200rem -100rem;
}

.icon-shortcut-e-pass_hover {
  width: 40rem;
  height: 40rem;
  background-position: -200rem -150rem;
}

.icon-shortcut-e-test {
  width: 40rem;
  height: 40rem;
  background-position: 0px -200rem;
}

.icon-shortcut-e-test_hover {
  width: 40rem;
  height: 40rem;
  background-position: -50rem -200rem;
}

.icon-shortcut-edu-institution {
  width: 40rem;
  height: 40rem;
  background-position: -100rem -200rem;
}

.icon-shortcut-edu-institution_hover {
  width: 40rem;
  height: 40rem;
  background-position: -150rem -200rem;
}

.icon-shortcut-electronic-library {
  width: 40rem;
  height: 40rem;
  background-position: -200rem -200rem;
}

.icon-shortcut-electronic-library_hover {
  width: 40rem;
  height: 40rem;
  background-position: -250rem 0px;
}

.icon-shortcut-eng-tel {
  width: 40rem;
  height: 40rem;
  background-position: -250rem -50rem;
}

.icon-shortcut-eng-tel_hover {
  width: 40rem;
  height: 40rem;
  background-position: -250rem -100rem;
}

.icon-shortcut-finance {
  width: 40rem;
  height: 40rem;
  background-position: -250rem -150rem;
}

.icon-shortcut-finance_hover {
  width: 40rem;
  height: 40rem;
  background-position: -250rem -200rem;
}

.icon-shortcut-flow {
  width: 40rem;
  height: 40rem;
  background-position: 0px -250rem;
}

.icon-shortcut-flow_hover {
  width: 40rem;
  height: 40rem;
  background-position: -50rem -250rem;
}

.icon-shortcut-gec {
  width: 40rem;
  height: 40rem;
  background-position: -100rem -250rem;
}

.icon-shortcut-gec_hover {
  width: 40rem;
  height: 40rem;
  background-position: -150rem -250rem;
}

.icon-shortcut-group-edu {
  width: 40rem;
  height: 40rem;
  background-position: -200rem -250rem;
}

.icon-shortcut-group-edu_hover {
  width: 40rem;
  height: 40rem;
  background-position: -250rem -250rem;
}

.icon-shortcut-hrdi {
  width: 40rem;
  height: 40rem;
  background-position: -300rem 0px;
}

.icon-shortcut-hrdi_hover {
  width: 40rem;
  height: 40rem;
  background-position: -300rem -50rem;
}

.icon-shortcut-idp {
  width: 40rem;
  height: 40rem;
  background-position: -300rem -100rem;
}

.icon-shortcut-idp_hover {
  width: 40rem;
  height: 40rem;
  background-position: -300rem -150rem;
}

.icon-shortcut-job-profile {
  width: 40rem;
  height: 40rem;
  background-position: -300rem -200rem;
}

.icon-shortcut-job-profile_hover {
  width: 40rem;
  height: 40rem;
  background-position: -300rem -250rem;
}

.icon-shortcut-kmooc {
  width: 40rem;
  height: 40rem;
  background-position: 0px -300rem;
}

.icon-shortcut-kmooc_hover {
  width: 40rem;
  height: 40rem;
  background-position: -50rem -300rem;
}

.icon-shortcut-language-study {
  width: 40rem;
  height: 40rem;
  background-position: -100rem -300rem;
}

.icon-shortcut-language-study_hover {
  width: 40rem;
  height: 40rem;
  background-position: -150rem -300rem;
}

.icon-shortcut-law-edu {
  width: 40rem;
  height: 40rem;
  background-position: -200rem -300rem;
}

.icon-shortcut-law-edu_hover {
  width: 40rem;
  height: 40rem;
  background-position: -250rem -300rem;
}

.icon-shortcut-lecture-room {
  width: 40rem;
  height: 40rem;
  background-position: -300rem -300rem;
}

.icon-shortcut-lecture-room_hover {
  width: 40rem;
  height: 40rem;
  background-position: -350rem 0px;
}

.icon-shortcut-my-learning1 {
  width: 40rem;
  height: 40rem;
  background-position: -350rem -50rem;
}

.icon-shortcut-my-learning1_hover {
  width: 40rem;
  height: 40rem;
  background-position: -350rem -100rem;
}

.icon-shortcut-my-learning2 {
  width: 40rem;
  height: 40rem;
  background-position: -350rem -150rem;
}

.icon-shortcut-my-learning2_hover {
  width: 40rem;
  height: 40rem;
  background-position: -350rem -200rem;
}

.icon-shortcut-online-edu {
  width: 40rem;
  height: 40rem;
  background-position: -350rem -250rem;
}

.icon-shortcut-online-edu_hover {
  width: 40rem;
  height: 40rem;
  background-position: -350rem -300rem;
}

.icon-shortcut-regular-study {
  width: 40rem;
  height: 40rem;
  background-position: 0px -350rem;
}

.icon-shortcut-regular-study_hover {
  width: 40rem;
  height: 40rem;
  background-position: -50rem -350rem;
}

.icon-shortcut-safety-edu {
  width: 40rem;
  height: 40rem;
  background-position: -100rem -350rem;
}

.icon-shortcut-safety-edu_hover {
  width: 40rem;
  height: 40rem;
  background-position: -150rem -350rem;
}

.icon-shortcut-smart-learning-flatform {
  width: 40rem;
  height: 40rem;
  background-position: -200rem -350rem;
}

.icon-shortcut-smart-learning-flatform_hover {
  width: 40rem;
  height: 40rem;
  background-position: -250rem -350rem;
}

.icon-shortcut-smart-learning {
  width: 40rem;
  height: 40rem;
  background-position: -300rem -350rem;
}

.icon-shortcut-smart-learning_hover {
  width: 40rem;
  height: 40rem;
  background-position: -350rem -350rem;
}

.icon-shortcut-spa {
  width: 40rem;
  height: 40rem;
  background-position: -400rem 0px;
}

.icon-shortcut-spa2 {
  width: 40rem;
  height: 40rem;
  background-position: -400rem -50rem;
}

.icon-shortcut-spa2_hover {
  width: 40rem;
  height: 40rem;
  background-position: -400rem -100rem;
}

.icon-shortcut-spa_hover {
  width: 40rem;
  height: 40rem;
  background-position: -400rem -150rem;
}

.icon-shortcut-speakerphone {
  width: 40rem;
  height: 40rem;
  background-position: -400rem -200rem;
}

.icon-shortcut-speakerphone_hover {
  width: 40rem;
  height: 40rem;
  background-position: -400rem -250rem;
}

.icon-shortcut-steel-mooc {
  width: 40rem;
  height: 40rem;
  background-position: -400rem -300rem;
}

.icon-shortcut-steel-mooc_hover {
  width: 40rem;
  height: 40rem;
  background-position: -400rem -350rem;
}

.icon-shortcut-tbl {
  width: 40rem;
  height: 40rem;
  background-position: 0px -400rem;
}

.icon-shortcut-tbl_hover {
  width: 40rem;
  height: 40rem;
  background-position: -50rem -400rem;
}

.icon-shortcut-touch-class {
  width: 40rem;
  height: 40rem;
  background-position: -100rem -400rem;
}

.icon-shortcut-touch-class_hover {
  width: 40rem;
  height: 40rem;
  background-position: -150rem -400rem;
}

.icon-shortcut-training-guide {
  width: 40rem;
  height: 40rem;
  background-position: -200rem -400rem;
}

.icon-shortcut-training-guide_hover {
  width: 40rem;
  height: 40rem;
  background-position: -250rem -400rem;
}

.icon-shortcut-video-call {
  width: 40rem;
  height: 40rem;
  background-position: -300rem -400rem;
}

.icon-shortcut-video-call_hover {
  width: 40rem;
  height: 40rem;
  background-position: -350rem -400rem;
}

.icon-shortcut-wallet {
  width: 40rem;
  height: 40rem;
  background-position: -400rem -400rem;
}

.icon-shortcut-wallet_hover {
  width: 40rem;
  height: 40rem;
  background-position: -450rem 0px;
}