.breadcrumb {
  display: flex;
  padding-top: 15px;
  li {
    @include b3;
    position: relative;
    color: rgba(0, 0, 0, 0.5);
    & + li {
      padding-left: 26px;
      &:before {
        @include commonSprite;
        content: '';
        position: absolute;
        top: 7px;
        left: 10px;
        width: 6px;
        height: 10px;
        background-position: -227px -59px;
        pointer-events: none;
        opacity: 0.5;
      }
    }
    &:first-child {
      > a {
        &:before {
          @include commonSprite;
          content: '';
          display: inline-block;
          position: relative;
          top: 2px;
          width: 13px;
          height: 16px;
          margin-right: 8px;
          background-position: -68px -143px;
        }
      }
    }
    &:last-child {
      @include b3-medium;
      color: rgba(0, 0, 0, 1);
      &:before {
        opacity: 1;
      }
    }
  }
}
