[data-modal-name=scrap] {
	.scrap {
		&-header {
			> strong {
				@include b2-medium;
				display: block;
			}
			.scrap-notice {
				@include b4;
				margin-top: 20px;
			}
		}
		&-main {
			margin-top: 40px;
			> strong {
				@include b2-medium;
				display: block;
				padding-bottom: 10px;
			}
			.form-textarea-label {
				textarea {
					height: 268px;
				}
			}
		}
	}
}