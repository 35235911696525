	[class^=icon-learning-status] { display: inline-block; flex-shrink: 0; background-image: url(/images/ui22/sprite/learning-status.png);
	background-repeat: no-repeat; background-size:216px
	164px; white-space:nowrap; text-indent:200%; overflow:hidden; }

	@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) { [class^=icon-learning-status] {
	background-image: url(/images/ui22/sprite/learning-status@2x.png); } }

	.icon-learning-status-cemina{ width:48px; height:48px; background-position:
	0px
	0px; }
	.icon-learning-status-contnent_sum{ width:48px; height:48px; background-position:
	-58px
	0px; }
	.icon-learning-status-elearning{ width:48px; height:48px; background-position:
	0px
	-58px; }
	.icon-learning-status-group{ width:48px; height:48px; background-position:
	-58px
	-58px; }
	.icon-learning-status-kplus-wh{ width:48px; height:48px; background-position:
	-116px
	0px; }
	.icon-learning-status-kplus{ width:48px; height:48px; background-position:
	-116px
	-58px; }
	.icon-learning-status-live{ width:48px; height:48px; background-position:
	0px
	-116px; }
	.icon-learning-status-scrap{ width:48px; height:48px; background-position:
	-58px
	-116px; }
	.icon-learning-status-share{ width:48px; height:48px; background-position:
	-116px
	-116px; }
	.icon-learning-status-time{ width:42px; height:40px; background-position:
	-174px
	0px; }
