.popup-address {
  .address-search {
    display: flex;
    .form-input {
      flex: 1;
    }
    [role='button'] {
      margin-left: 10px;
    }
  }
  .address-header {
    margin-top: 20px;
  }
  .address-main {
    .address-table-header {
      @include b3;
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      padding-bottom: 20px;
    }
  }
  .address-notice {
    strong {
      @include b3;
      display: block;
      color: $gray-scale-color02;
    }
    ul {
      margin-top: 8px;
      > li {
        @include b3;
        position: relative;
        padding-left: 20px;
        color: $gray-scale-color03;
        &:before {
          content: '';
          position: absolute;
          top: 10px;
          left: 8px;
          width: 3px;
          height: 3px;
          background-color: $gray-scale-color03;
          border-radius: 50%;
        }
      }
    }
  }
  .address-item {
    display: flex;
    & + .address-item {
      margin-top: 8px;
    }
    .address-item-tit {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 28px;
      margin-right: 10px;
      background-color: $gray-scale-color08;
      text-align: center;
    }
    .address-item-desc {
      flex: 1;
    }
  }
}
