// 개인교육실적 및 이력
.learning-detail {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  grid-gap: 20px;
  position: relative;
  > a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $level01;
  }
  &__list {
    position: relative;
    flex: 1;
    min-width: 223px;
    padding: 24px 30px;
    border: 1px solid #cecece;
    border-radius: 20px;
    &.total {
      border-width: 2px;
      border-color: #ece1a5;
      background-color: #fffae2;
      .learning-detail__name {
        @include b2-bold;
        .small {
          @include b3-medium;
        }
      }
      .value {
        color: $primary-color02;
      }
      .icon-box:before {
        background-color: #fff;
      }
    }
    &.always {
      // color: #fff;
      border-width: 2px;
      border-color: $etc-color02;
      background-color: $etc-color;
      .learning-detail__name {
        @include b2-bold;
      }
      .value,
      .unit {
        color: $gray-scale-color;
      }
      .learning-detail__info {
        .icon-box:before {
          background: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }
  &__name {
    @include b3-medium;
  }
  &__info {
    margin-top: 4px;
    strong {
      display: flex;
      align-items: baseline;
      font-size: 16px;
      line-height: 1.6;
      font-weight: 500;
    }
    .value {
      // @include text(32px, 1.3, 500);
      @include h2;
    }
    .unit {
      margin-left: 4px;
      color: $gray-scale-color04;
      // @include text(20px, 1.6, 500);
      @include b3-medium;
      &.per {
        // font-size: 24px;
        @include b3-medium;
        &:before {
          display: inline-block;
          content: '\002F';
          margin-right: 4px;
        }
      }
    }
    & > span:not(.icon-box) {
      display: block;
      @include text(12px);
      color: $gray-scale-color04;
    }
    .icon-box {
      position: absolute;
      top: 50%;
      right: 30px;
      transform: translateY(-50%);
      display: block;
      width: 54px;
      height: 54px;
      padding-right: 6px;
      padding-bottom: 6px;
      &:before {
        position: absolute;
        right: 0;
        bottom: 0;
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $gray-scale-color08;
        // background: rgba(255, 255, 255, 0.4);
      }
      [class^='icon-'] {
        position: relative;
      }
    }
    &--lv2 {
      @extend .learning-detail__info;
      margin-top: 0;
      > strong {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 1px;
          background-color: $gray-scale-color02;
        }
      }
      .value {
        @include text(32px, 42px, 500);
      }
      .unit {
        @include h3;
      }
    }
  }
  &-lv2 {
    @extend .learning-detail;
    align-items: center;
    .learning-detail__list {
      padding-top: 54px;
      padding-bottom: 54px;
    }
    .icon-box {
      width: auto;
      height: auto;
      padding: 0;
      &:before {
        right: -6px;
        bottom: -6px;
      }
    }
  }
}
.graph-area {
  display: flex;
  justify-content: space-between;
  grid-gap: 20px;
  .learning-detail__list {
    display: flex;
    align-items: center;
    min-height: 162px;
  }
}
.learning-graph {
  min-width: 954px;
  &__item {
    padding: 24px 30px;
    border: 1px solid #cecece;
    border-radius: 20px;
    li {
      display: flex;
      align-items: center;
      grid-gap: 24px;
      & + li {
        margin-top: 12px;
      }
    }
  }
  &__bar {
    display: inline-block;
    position: relative;
    width: 220px;
    height: 30px;

    &[data-theme='purple'] {
      .learning-graph__current {
        background-color: $graph-color;
      }
    }
    &[data-theme='red'] {
      .learning-graph__current {
        background-color: $graph-color03;
      }
    }
    &[data-theme='yellow'] {
      .learning-graph__current {
        background-color: $graph-color04;
      }
    }
  }
  &__current {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0%;
    border-radius: 0px 20px 20px 0px;
  }
  &__label {
    position: absolute;
    left: calc(100% + 20px);
    white-space: nowrap;
    font-weight: 500;
    em {
      font-size: 14px;
      color: $primary-color02;
      vertical-align: baseline;
      &:first-child {
        margin-left: 5px;
      }
    }
  }
}
.table-header {
  margin-bottom: 20px;
  h3 span {
    display: inline-block;
    margin-left: 8px;
    font-size: 16px;
  }
}
