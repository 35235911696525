@charset 'utf-8';

// * -------------------------------
// * Reset
// * -------------------------------
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,
b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,
menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;vertical-align:baseline;font-size:inherit;color:inherit;box-sizing:border-box}
dl,ul,ol,menu,li{list-style:none;}
fieldset,img{border:0 none;box-sizing:border-box}
input,select,textarea,button{vertical-align:middle;margin:0;padding:0;font-family:inherit;box-sizing:border-box}
button{border:0 none;background-color:transparent;cursor:pointer;box-sizing:border-box}
address,caption,cite,code,dfn,em,var{font-style:normal;font-weight:normal;box-sizing:border-box}
input,textarea,img{max-width:100%}
a,a:hover,a:visited,a:active{text-decoration:none;}
sup{font-size:small;vertical-align: text-top;}
sub{font-size:small;vertical-align: text-bottom;}
button,select{-webkit-appearance:none;-moz-appearance:none;-ms-appearance:none;appearance:none}
select::-ms-expand{display:none}/* Hide Native Arrow IE 10, 11 */
table{width:100%;table-layout:fixed;border-collapse:collapse;border-spacing:0}
table caption{overflow:hidden;position:absolute;width:1px;height:1px;clip:rect(1px, 1px, 1px, 1px);}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}/* HTML5 for older browsers */
section, main{display:block;}
img{vertical-align:top;}
h1,h2,h3,h4,h5,h6,em,strong{font-weight:600;}

// * -------------------------------
// * iPhone
// * -------------------------------
input:disabled,
textarea:disabled{opacity:1;-webkit-text-fill-color:inherit;}
textarea:focus-visible{outline:none;}
input[type="text"],
input[type="password"]{-webkit-appearance:none;border-radius:0;}

:before,
:after {
    box-sizing: border-box;
}