[data-modal-name='terms-and-privacy'] {
  .terms-and-privacy {
    &-main {
      padding-top: 20px;
      > div {
        > strong {
          @include b1-medium;
          display: block;
          margin-bottom: 20px;
        }
        .form-selector {
          display: block;
          margin-top: 20px;
        }
        & + div {
          margin-top: 40px;
        }
      }
    }
    &-footer {
      margin-top: 30px;
      padding-top: 30px;
      border-top: 1px solid $gray-scale-color06;
    }
    .form-selector {
      input[type='checkbox'] + label {
        @include b2;
      }
    }
  }
}
