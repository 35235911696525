[data-modal-name='notice'] {
  .section-modal {
    display: flex;
    flex-direction: column;
  }
  .modal-subsection {
    flex: 1;
    position: relative;
    height: calc(100% - 116px);
    padding-bottom: 116px;
    .modal-notice-content {
      overflow-y: auto;
      height: 100%;
      // height: calc(100vh - 86px);
    }
    .modal-buttons {
      position: absolute;
      right: 0;
      bottom: 30px;
      left: 0;
    }
  }
}
