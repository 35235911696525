.error {
  width: 100%;
  min-width: 700px;
  height: 100%;
  text-align: center;
  padding-top: 120px;
  h2 {
    @include h1;
    font-weight: 700;
    [class^='icon'] {
      display: block;
      margin: 0 auto 35px;
    }
    + p {
      @include h2;
      margin-top: 16px;
      & + p {
        margin-top: 40px;
        @include b2;
      }
    }
  }
  &-buttons {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    [role='button'] {
      & + [role='button'] {
        margin-left: 20px;
      }
    }
  }
  .table {
    width: 500px;
    margin: 60px auto 0;
    th {
      @include b2-medium;
      padding: 12px 20px;
    }
    td {
      @include b2;
      padding: 12px 20px;
    }
  }
}
