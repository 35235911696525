.grid-container {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  row-gap: 10px;
  column-gap: 40px;

  > div {
    box-sizing: content-box;
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  // column-gap: 40px;
}

@for $i from 1 to 17 {
  .gcs-#{$i} {
    grid-column-start: $i;
  }
}
@for $i from 1 to 17 {
  .gce-#{$i} {
    grid-column-end: $i;
  }
}

[class^='col-'] {
  display: flex;
  align-items: center;
}

.col-auto {
  flex: auto;
  width: auto;
}

@for $i from 1 to 12 {
  .col-#{$i} {
    @include col($i);
    @include colWidth($i);
  }
}
