.app-footer {
  position: relative;
  height: 166rem;
  background-color: $secondary-color04;
  &-content {
    display: flex;
    width: $min-width;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;
    padding-bottom: 30px;
    .app-footer-ci {
      flex: 0 0 178px;
    }
    > div {
      flex: 1;
      margin-left: 48px;
    }
  }
  &__util {
    display: flex;
    > li {
      position: relative;
      font-size: $text-sm;
      line-height: 22px;
      color: #fff;
      & + li {
        margin-left: 16px;
        padding-left: 17px;
        &:before {
          content: '';
          position: absolute;
          top: 5px;
          left: 0;
          width: 1px;
          height: 12px;
          background-color: #fff;
        }
      }
    }
  }
  &__address {
    @include text($text-sm, 22px, 400);
    display: flex;
    // margin-top: 10px;
    color: #fff;
  }
  &__business-hours {
    @include text($text-sm, 22px, 400);
    margin-left: 42px;
    color: #fff;
  }
  &__copyright {
    @include text($text-sm, 22px, 400);
    // margin-top: 20px;
    color: #fff;
    opacity: 0.6;
  }
}
