.pd0 {
  padding: 0 !important;
}
.pd40 {
  padding: 40px !important;
}
.pdt0 {
  padding-top: 0 !important;
}
.pdt10 {
  padding-top: 10px !important;
}
.pdt20 {
  padding-top: 30px !important;
}
.pdt30 {
  padding-top: 30px !important;
}
.pdt40 {
  padding-top: 40px !important;
}
.pdt60 {
  padding-top: 60px !important;
}
.pdt70 {
  padding-top: 70px !important;
}
.pdr0 {
  padding-right: 0 !important;
}
.pdr10 {
  padding-right: 10px !important;
}
.pdr30 {
  padding-right: 30px !important;
}
.pdb0 {
  padding-bottom: 0 !important;
}
.pdb10 {
  padding-bottom: 10px !important;
}
.pdb15 {
  padding-bottom: 15px !important;
}
.pdb20 {
  padding-bottom: 20px !important;
}
.pdb30 {
  padding-bottom: 30px !important;
}
.pdb40 {
  padding-bottom: 40px !important;
}
.pdb60 {
  padding-bottom: 60px !important;
}
.pdb100 {
  padding-bottom: 100px !important;
}
.pdl0 {
  padding-left: 0 !important;
}
.pdl10 {
  padding-left: 10px !important;
}
.pdl20 {
  padding-left: 20px !important;
}
.pdl24 {
  padding-left: 24px !important;
}
.mt0 {
  margin-top: 0 !important;
}
.mt4 {
  margin-top: 4px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mt6 {
  margin-top: 6px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mt16 {
  margin-top: 16px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt26 {
  margin-top: 26px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mt60 {
  margin-top: 60px !important;
}
.mt80 {
  margin-top: 80px !important;
}
.mr0 {
  margin-right: 0 !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mr25 {
  margin-right: 25px !important;
}
.mr30 {
  margin-right: 30px !important;
}
.mr40 {
  margin-right: 40px !important;
}
.mr50 {
  margin-right: 50px !important;
}
.mr100 {
  margin-right: 100px !important;
}
.mr180 {
  margin-right: 180px !important;
}
.mb0 {
  margin-bottom: 0 !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.mr120 {
  margin-right: 120px !important;
}
.ml8 {
  margin-left: 8px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.ml14 {
  margin-left: 14px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.ml24 {
  margin-left: 24px !important;
}
.ml25 {
  margin-left: 25px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.ml40 {
  margin-left: 40px !important;
}
.ml60 {
  margin-left: 60px !important;
}

a.link {
  color: blue;
  text-decoration: underline;
  word-break: break-all;
}
.full {
  width: 100% !important;
}

.align-l {
  text-align: left;
}
.align-c {
  text-align: center;
}
.align-r {
  text-align: right;
}

// Size
.w-44 {
  width: 44px !important;
}
.w-50 {
  width: 50px !important;
}
.w-58 {
  width: 58px !important;
}
.w-60 {
  width: 60px !important;
}
.w-66 {
  width: 66px !important;
}
.w-70 {
  width: 70px !important;
}
.w-72 {
  width: 72px !important;
}
.w-76 {
  width: 76px !important;
}
.w-78 {
  width: 78px !important;
}
.w-82 {
  width: 82px !important;
}
.w-87 {
  width: 87px !important;
}
.w-96 {
  width: 96px !important;
}
.w-100 {
  width: 100px !important;
}
.w-105 {
  width: 105px !important;
}
.w-110 {
  width: 110px !important;
}
.w-120 {
  width: 120px !important;
}
.w-122 {
  width: 122px !important;
}
.w-130 {
  width: 130px !important;
}
.w-140 {
  width: 140px !important;
}
.w-160 {
  width: 160px !important;
}
.w-156 {
  width: 156px !important;
}
.w-160 {
  width: 160px !important;
}
.w-170 {
  width: 170px !important;
}
.w-180 {
  width: 180px !important;
}
.w-190 {
  width: 190px !important;
}
.w-200 {
  width: 200px !important;
}
.w-210 {
  width: 210px !important;
}
.w-220 {
  width: 220px !important;
}
.w-240 {
  width: 240px !important;
}
.w-250 {
  width: 250px !important;
}
.w-268 {
  width: 268px !important;
}
.w-280 {
  width: 280px !important;
}
.w-293 {
  width: 293px !important;
}
.w-300 {
  width: 300px !important;
}
.w-310 {
  width: 310px !important;
}
.w-320 {
  width: 320px !important;
}
.w-340 {
  width: 340px !important;
}
.w-360 {
  width: 360px !important;
}
.w-390 {
  width: 390px !important;
}
.w-364 {
  width: 364px !important;
}
.w-440 {
  width: 440px !important;
}
.w-490 {
  width: 490px !important;
}
.w-500 {
  width: 500px !important;
}
.w-530 {
  width: 530px !important;
}
.w-540 {
  width: 540px !important;
}
.w-546 {
  width: 546px !important;
}
.w-580 {
  width: 580px !important;
}
.w-596 {
  width: 596px !important;
}
.w-600 {
  width: 600px !important;
}
.w-620 {
  width: 620px !important;
}
.w-630 {
  width: 630px !important;
}
.w-656 {
  width: 656px !important;
}
.w-690 {
  width: 690px !important;
}
.w-705 {
  width: 705px !important;
}
.w-760 {
  width: 760px !important;
}
.w-777 {
  width: 777px !important;
}
.w-780 {
  width: 777px !important;
}
.w-830 {
  width: 830px !important;
}
.w-848 {
  width: 848px !important;
}
.w-857 {
  width: 857px !important;
}
.w-1100 {
  width: 1100px !important;
}
.w-1200 {
  width: 1200px !important;
}
.w-1300 {
  width: 1300px !important;
}

// Typography
.h1 {
  @include h1;
}
.h2 {
  @include h2;
}
.h3 {
  @include h3;
}
.h4 {
  font-size: 18px !important;
  line-height: 29px !important;
  font-weight: 500 !important;
}
.b1 {
  @include b1;
  &-bold {
    @include b1-bold;
  }
  &-medium {
    @include b1-medium;
  }
}
.b2 {
  font-size: 16px !important;
  line-height: 26px !important;
  font-weight: 400 !important;
  &-medium {
    font-size: 16px !important;
    line-height: 26px !important;
    font-weight: 500 !important;
  }
  &.dashed-top {
    padding-top: 20px;
    border-top: 1px dashed $gray-scale-color05;
  }
}
.b3 {
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: 400 !important;
  &-medium {
    font-size: 14px !important;
    line-height: 22px !important;
    font-weight: 500 !important;
  }
}
.b4 {
  @include b4;
  &-medium {
    @include b4-medium;
  }
}

// Font
.c-black02 {
  color: $gray-scale-color02 !important;
}
.c-blue {
  color: $primary-color !important;
}
.c-blue02 {
  color: $primary-color02 !important;
}
.c-blue03 {
  color: $primary-color03 !important;
}
.c-grey {
  color: $gray-scale-color03 !important;
}
.c-grey02 {
  color: $gray-scale-color04 !important;
}
.c-red {
  color: $secondary-color02 !important;
}
.c-yellow {
  color: $graph-color03 !important;
}

.bg-yellow {
  background-color: $graph-color03 !important;
  &-op5 {
    background-color: rgba(255, 189, 62, 0.5) !important;
  }
}

.bg-beige {
  background-color: #faf8ee !important;
}
.bg-blue03 {
  background-color: $secondary-color07 !important;
}

// Primary Color
.primary-color {
  background-color: $primary-color;
}
.primary-color02 {
  background-color: $primary-color02;
}
.primary-color03 {
  background-color: $primary-color03;
}
.primary-color04 {
  background-color: $primary-color04;
}

// Secondary Color
.secondary-color {
  background-color: $secondary-color;
}
.secondary-color02 {
  background-color: $secondary-color02;
}
.secondary-color03 {
  background-color: $secondary-color03;
}
.secondary-color04 {
  background-color: $secondary-color04;
}
.secondary-color05 {
  background-color: $secondary-color05;
}
.secondary-color06 {
  background-color: $secondary-color06;
}
.secondary-color07 {
  background-color: $secondary-color07;
}
.secondary-color08 {
  background-color: $secondary-color08;
}

// Gray Scale Color
.gray-scale-color {
  background-color: $gray-scale-color;
}
.gray-scale-color02 {
  background-color: $gray-scale-color02;
}
.gray-scale-color03 {
  background-color: $gray-scale-color03;
}
.gray-scale-color04 {
  background-color: $gray-scale-color04;
}
.gray-scale-color05 {
  background-color: $gray-scale-color05;
}
.gray-scale-color06 {
  background-color: $gray-scale-color06;
}
.gray-scale-color07 {
  background-color: $gray-scale-color07;
}
.gray-scale-color08 {
  background-color: $gray-scale-color08;
}

// Graph Color
.graph-color {
  background-color: $graph-color;
}
.graph-color02 {
  background-color: $graph-color02;
}
.graph-color03 {
  background-color: $graph-color03;
}
.graph-color04 {
  background-color: $graph-color04;
}
.graph-color05 {
  background-color: $graph-color05;
}

.jc-center {
  justify-content: center;
}

.border-bottom-2 {
  border-bottom: 2px solid $gray-scale-color02;
}

.ps-re {
  position: relative;
}

.self-align-r {
  justify-self: flex-end;
}

.flex-justify-between {
  justify-content: space-between !important;
}

.flex-align-stretch {
  align-items: stretch !important;
}
.flex-align-center {
  align-items: center !important;
}

.overflow-visible {
  overflow: visible !important;
}

.flex-box {
  display: flex !important;
}

.pos-s {
  position: static !important;
}

.font-medium {
  font-weight: 500 !important;
}

.line2-bottom {
  border-bottom: 2px solid $gray-scale-color03;
}

.main-last-section {
  margin-bottom: -120px;
}
