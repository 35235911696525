// [EC-HA-5001] IDP수립안내
.step-list {
  display: flex;
  align-items: center;
  justify-content: center;
  &-item {
    position: relative;
    width: 221px;
    height: 221px;
    margin-right: 146px;
    padding: 54px 20px 0;
    border: 1px solid $gray-scale-color05;
    border-radius: 50%;
    text-align: center;
    > span {
      @include b2-medium;
      color: $primary-color02;
    }
    > p {
      @include b3;
      margin-top: 20px;
    }
    &:last-child {
      margin-right: 0;
    }
    [class^='icon-'] {
      position: absolute;
      top: 50%;
      right: -86px;
    }
  }
}
hr.section-line {
  display: block;
  width: 100%;
  max-width: 1440px;
  height: 1px;
  margin: 0 auto;
  border: 0;
  background-color: $gray-scale-color06;
  &--40 {
    @extend .section-line;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.step-info {
  text-align: center;
  .step-move-button.is-start,
  .step-move-button.is-end {
    opacity: 0.5;
  }
  .step-txt {
    display: inline-block;
    margin: 0 20px;
    font-size: 24px;
    line-height: 1.6;
    vertical-align: bottom;
    .current-step {
      color: $primary-color02;
      .step-number {
        margin-left: 4px;
      }
    }
    .total-step {
      .step-number {
        margin-right: 4px;
      }
    }
  }
  .step-number {
    display: inline-block;
    font-size: 32px;
    font-weight: 500;
  }
}

.line-button--sm.type01 {
  font-size: 12px;
  line-height: 32px;
  height: 32px;
  border-color: $gray-scale-color06;
  border-radius: 0;
}
