.ui-widget.ui-widget-content {
  width: 322px;
  padding: 0 20px;
  border: 1px solid $gray-scale-color06;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  &:after {
    content: '';
    position: absolute;
    bottom: 28px;
    left: 20px;
    width: 84px;
    height: 19px;
    background: url($imgPath + '/common/datepicker-info.png') 0 0 / 84px no-repeat;
  }
}

.ui-datepicker {
  // z-index: $level10;
  .ui-datepicker-header {
    margin: 0 -20px;
    padding: 20px;
    background-color: $secondary-color07;
    border: 0;
    border-radius: 20px 20px 0 0;
  }
  .ui-datepicker-next,
  .ui-datepicker-prev {
    top: calc(50% - 8px);
    width: 16px;
    height: 16px;
  }
  .ui-datepicker-title {
    display: flex;
    gap: 10px;
    // flex-direction: row-reverse;
    justify-content: center;
  }
  .ui-datepicker-next {
    right: 20px;
    &-hover {
      border: 0;
      background-color: transparent;
    }
    span {
      @include commonSprite;
      background-position: -48px -143px;
    }
  }
  .ui-datepicker-prev {
    left: 20px;
    &-hover {
      border: 0;
      background-color: transparent;
    }
    span {
      @include commonSprite;
      background-position: -48px -143px;
      transform: rotate(180deg);
    }
  }
  select.ui-datepicker-year,
  select.ui-datepicker-month {
    @include b3;
    position: relative;
    width: 100px;
    height: 44px;
    margin: 0;
    padding: 0 20px;
    border: 1px solid $gray-scale-color06;
    border-radius: 30px;
    background: #fff url($imgPath + '/common/arrow-down-bk-16.png') right 20px top 50% / 16px no-repeat;
  }
  .ui-datepicker-calendar {
    @include b4;
    margin: 0 0 12px;
  }
  .ui-state-default {
    @include b4;
    border: 0;
    background-color: transparent;
    color: $gray-scale-color02;
    line-height: 30px;
  }
  .ui-state-highlight {
    background-color: $gray-scale-color07;
    border-radius: 50%;
  }
  .ui-state-active {
    background-color: $primary-color02;
    border-radius: 50%;
    color: #fff;
  }
  th {
    @include b4;
    padding: 20px 0 6px;
    color: $gray-scale-color;
  }
  td {
    padding: 6px 0;
    a {
      width: 30px;
      height: 30px;
      margin-right: auto;
      margin-left: auto;
      padding: 0;
      border: 0;
      text-align: center;
      color: $gray-scale-color02;
    }
  }
  .ui-datepicker-buttonpane {
    display: flex;
    flex-direction: row-reverse;
    margin: 0;
    padding: 20px 0;
    border-color: $gray-scale-color06;
    button.ui-datepicker-current {
      @include b2-medium;
      float: none;
      height: 32px;
      margin: 0 0 0 8px;
      padding: 3px 20px;
      background-color: $primary-color;
      border-radius: 24px;
      color: #fff;
      opacity: 1;
    }
    button.ui-datepicker-close {
      // display: none;
      @include b2-medium;
      float: none;
      height: 32px;
      margin: 0;
      padding: 3px 20px;
      background-color: $gray-scale-color05;
      border-radius: 24px;
      color: #fff;
      opacity: 1;
    }
  }
}
