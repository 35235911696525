.authentication-method {
  display: flex;
  align-items: center;
  .form-selector__list {
    margin-right: 60px;
    .form-selector {
      input[type='radio'] + label {
        @include b2;
      }
    }
  }
}

.authentication-number {
  display: flex;
  align-items: center;
  > span {
    & + span {
      margin-left: 10px;
    }
  }
}
