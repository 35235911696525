// Knowledge+
.pg-regular-learning-home {
  margin-bottom: -120px;
  .last-section {
    padding-bottom: 96px;
  }
  .section-header {
    > h2 {
      @include text(32px, 42px, 500);
      padding-bottom: 30px;
    }
  }
  .post-container {
    margin-top: 0;
  }
}
