.app-aside-menu {
  position: fixed;
  top: 50%;
  right: calc(50% - 903px);
  z-index: $level08;
  width: 123px;
  border: 1px solid $gray-scale-color06;
  background-color: #fff;
  box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  transform: translateY(-50%);
  > ul {
    > li {
      a {
        position: relative;
      }
      &:first-child {
        a {
          border-radius: 20px 20px 0 0;
        }
      }
      &:last-child {
        a {
          border-radius: 0 0 20px 20px;
          &:hover {
            &:before {
              content: none;
            }
          }
        }
      }
      &:first-child:last-child {
        a {
          border-radius: 20px;
        }
      }
      & + li {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: -1px;
          right: 14px;
          left: 14px;
          height: 1px;
          background-color: #c2cfd7;
        }
      }
    }
  }
  .aside-menu-button {
    display: block;
    padding-top: 12px;
    padding-right: 14px;
    padding-bottom: 12px;
    padding-left: 14px;
    text-align: center;
    background-color: #fff;
    &:hover {
      background-color: $primary-color03;
      [class^='icon'] {
        display: none;
      }
      [class^='icon'][class*='hover'] {
        display: inline-block;
      }
      .aside-menu-text {
        color: #fff;
      }
      &:before {
        content: '';
        position: absolute;
        right: 14px;
        bottom: 0;
        z-index: $level01;
        left: 14px;
        height: 1px;
        background-color: $primary-color03;
      }
    }
    [class^='icon'] {
      vertical-align: top;
    }
    [class^='icon'][class*='hover'] {
      display: none;
    }
  }
  .aside-menu-text {
    display: block;
    margin-top: 2px;
    font-size: $text-xs;
    line-height: 19px;
    color: #21343f;
  }
  // &.is-break {
  //   top: auto;
  //   bottom: 320px;
  //   transform: none;
  // }
}
